import { action, makeObservable } from 'mobx';
import { api } from '../../shared/api/api';
import { EntityStore } from '../../shared/stores/EntityStore';

class TestResultsFilesStore extends EntityStore {
	constructor() {
		super('test-results-files');
		makeObservable(this);
	}

	@action
	async getTestResultsFiles(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}

	@action
	async processTestResultsFromCurrentFile(query) {
		const result = await api.post(`${this.entityPluralEndpoint}/process`, {});
		return result;
	}

	@action
	async downloadFile(file) {
		const result = await api.get(
			`${this.entityPluralEndpoint}/${file.id}/download`,
			{ responseType: 'blob' }
		);
		var blob = new Blob([result], {
			type: ''
		});
		var link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = file.filename;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		return result;
	}

	@action
	async importTestsFromFile(testsFile) {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		const formData = new FormData();
		formData.append('file', testsFile);
		const result = await api.post(
			`${this.entityPluralEndpoint}/import`,
			formData,
			config
		);
		return result;
	}
}

export const testResultsFilesStore = new TestResultsFilesStore();
