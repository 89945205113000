import * as Yup from 'yup';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import { FormFieldError } from '../layout/form-fields/FormFieldError';
import { CandidatesDropdown } from '../candidates/shared/CandidatesDropdown';
import { jobOpeningStore } from './JobOpeningStore';

const ValidationSchema = Yup.object().shape({
	candidates: Yup.string().required().label('Candidates')
});

export class JobOpeningCandidateAdd extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					candidates: this.props.job.candidates.length
						? this.props.job.candidates.map(item => item.candidate)
						: []
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'JobOpeningCandidateAdd')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Candidate <span className="red-text">*</span>
							</label>
							<div className="control">
								<Fragment>
									<CandidatesDropdown
										value={formikBag.values.candidates}
										onFocus={() => {
											formikBag.setFieldTouched('candidates');
										}}
										onValueChange={items => {
											if (!items) {
												formikBag.setFieldValue('candidates', []);
											} else {
												formikBag.setFieldValue('candidates', items);
											}
										}}
									/>
									<FormFieldError field="candidates" />
								</Fragment>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	async save(values) {
		const request = {
			candidateIds: values.candidates.map(candidate => candidate.id)
		};
		jobOpeningStore.addCandidates(this.props.job.id, request).then(result => {
			this.resultNotification(
				result,
				null,
				null,
				'Add Candidates',
				'The Candidates have been successfully added to the Job.'
			);
		});
	}
}
