import React, { useState } from 'react';
import { EditableStar } from './EditableStars';
import { ReadOnlyStar } from './ReadOnlyStar';
import { Icon } from 'components/layout/misc/Icon';

export const ScorecardItem = ({
	item: externalItem = {},
	editable,
	onChange,
	collapsible = true
}) => {
	const [item, setItem] = useState({ ...externalItem });

	return (
		<tr>
			<td className="ScorecardContent__ItemContent is-vcentered has-text-left">
				<div className="level is-justify-content-flex-start">
					<span className="pl-4">{item.name}</span>
					{item.description ? (
						<span
							className="pl-3 tooltip has-tooltip-multiline is-tooltip-top"
							data-tooltip={item.description}>
							<Icon icon="fa-info-circle" />
						</span>
					) : null}
				</div>
			</td>
			<td style={{ width: '180px' }} className="is-vcentered">
				<div className="level">
					{editable ? (
						<EditableStar
							key={item.id}
							identifier={item.id}
							rating={item.score}
							onChange={score => {
								setItem({
									...item,
									score: score
								});
								onChange && onChange(item, score);
							}}
						/>
					) : (
						<div className="StarWrapper">
							<ReadOnlyStar
								key={item.id}
								width={20}
								height={20}
								outlined
								outlinedColor="#FDDA53"
								rating={item.score}
								tooltipMessage={`${item.relativeScore.toFixed(2)} out of 5`}
								hasTooltip={false}
							/>
						</div>
					)}
				</div>
			</td>
			{collapsible ? <td className="is-vcentered" /> : null}
		</tr>
	);
};
