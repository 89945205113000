import React, { useState } from 'react';
import { ScorecardItem } from './ScorecardItem';
import { Icon } from 'components/layout/misc/Icon';
import { ReadOnlyStar } from './ReadOnlyStar';

const formatToPercent = Intl.NumberFormat('en-US', { style: 'percent' }).format;

export const ScorecardSection = ({
	section,
	editable,
	onChange,
	collapsible = true
}) => {
	const [open, setOpen] = useState(true);

	return (
		<table className="ScorecardContent__Section table is-fullwidth mb-0">
			<thead>
				<tr>
					<th className="is-vcentered">
						<div className="level is-justify-content-flex-start">
							<span className="is-grey is-5 is-uppercase">{section.name}</span>
							{section.description ? (
								<span
									className="pl-3 tooltip is-tooltip-top has-tooltip-multiline"
									data-tooltip={section.description}>
									<Icon icon="fa-info-circle" />
								</span>
							) : null}
						</div>
					</th>
					<th style={{ width: '180px' }} className="is-vcentered">
						{!editable && (
							<div className="level">
								<ReadOnlyStar
									rating={Number(section.relativeScore)}
									width={20}
									height={20}
									outlined
									outlinedColor="#FDDA53"
								/>
								<span
									className="tooltip is-tooltip-top"
									data-tooltip={`This score represents ${formatToPercent(
										section.weight
									)} of the overall score.`}>
									<Icon icon={'fa-info-circle'} />
								</span>
							</div>
						)}
					</th>
					{collapsible ? (
						<th style={{ width: '60px' }} className="is-vcentered">
							<button
								className="ToggleSectionButton button level-item"
								onClick={event => {
									event.stopPropagation();
									event.preventDefault();

									setOpen(!open);
								}}>
								<Icon
									className="is-big"
									icon={open ? 'fa-chevron-down' : 'fa-chevron-up'}
								/>
							</button>
						</th>
					) : null}
				</tr>
			</thead>
			<tbody>
				{open &&
					section.items.map(item => (
						<ScorecardItem
							key={`${section.id}-${item.id}`}
							item={item}
							editable={editable}
							onChange={(item, score) => {
								onChange && onChange(section.id, item.id, score);
							}}
							collapsible={collapsible}
						/>
					))}
			</tbody>
		</table>
	);
};
