import { FieldRequiredIndicator } from '../../../components/layout/form-fields/inputs/FieldRequiredIndicator';
import React, { Fragment } from 'react';
import { Dropdown } from '../../../components/layout/form-fields/inputs/Dropdown';
import { SkillDropdown } from '../../../components/layout/form-fields/inputs/SkillDropdown';
import { candidatesStore } from '../CandidatesStore';

export class CandidateProfileAboutKnownSkills extends React.Component {
	state = {
		value: []
	};
	constructor() {
		super();
		this.ref = React.createRef();
	}
	componentDidMount() {
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
	}
	getExperienceAndExpertiseFromForm(knownSkill) {
		const knownSkills = this.props.formikBag.values.knownSkills || [];
		return knownSkills.find(skill => {
			return knownSkill.id === skill.id ? skill : null;
		});
	}
	render() {
		const errorsEnum = {
			fieldsError: 'Please complete all fields for every Technology',
			knownSkillsError: 'Please select at least one Technology'
		};

		return (
			<Fragment>
				<div className="expertise-areas">
					<div className="columns">
						<div className="column is-full">
							<div className="field has-text-left">
								<label
									className={
										this.props.styleVersion === 'v2'
											? 'title is-5 has-text-weight-bold'
											: 'subtitle is-7'
									}>
									What are the <strong>technologies</strong> you know best?{' '}
									{this.props.required ? <FieldRequiredIndicator /> : null}
								</label>
								<SkillDropdown
									ref={this.ref}
									value={this.props.formikBag.values.knownSkills}
									styleVersion={this.props.styleVersion}
									onValueChange={values => {
										const formValues = (values || []).map(value => {
											const existingKnownSkill =
												this.getExperienceAndExpertiseFromForm(value);
											return {
												name: value.name,
												id: value.id,
												experienceYears: existingKnownSkill
													? existingKnownSkill.experienceYears
													: null,
												level: existingKnownSkill
													? existingKnownSkill.level
													: null
											};
										});
										this.props.formikBag.setFieldValue(
											'knownSkills',
											formValues
										);
										this.props.formikBag.setFieldTouched(
											'knownSkills',
											true,
											false
										);
										this.setState({ value: values });
									}}
									optionValue={'value'}
									isInvalid={
										this.props.formikBag.touched.knownSkills &&
										this.props.formikBag.errors.knownSkills ===
											errorsEnum.knownSkillsError
									}
								/>
							</div>
						</div>
					</div>
					{(this.props.formikBag.values.knownSkills || []).map(
						(knownSkill, index) => {
							return (
								<Fragment key={index}>
									<div
										className={`columns ${
											this.props.styleVersion === 'v2'
												? ''
												: 'is-multiline add-margin-top'
										} `}>
										<div
											className={`column ${
												this.props.styleVersion === 'v2' ? 'is-5' : 'is-12'
											} `}>
											<div className="selector-tag is-marginless">
												<div className="tag-content">{knownSkill.name}</div>
											</div>
										</div>
										<div
											className={`column ${
												this.props.styleVersion === 'v2'
													? 'is-3 is-marginless'
													: 'is-12'
											} `}>
											<div className="control">
												<Dropdown
													key={Math.random()}
													enableFixedMode={true}
													value={knownSkill.level}
													styleVersion={this.props.styleVersion}
													placeholder={'Expertise Level'}
													onValueChange={value => {
														knownSkill.level = value;

														this.ref.current.props.onValueChange(
															this.props.formikBag.values.knownSkills
														);
													}}
													options={candidatesStore.knownSkillLevelOptions}
													optionValue={'value'}
													isInvalid={
														this.props.formikBag.errors.knownSkills ===
															errorsEnum.fieldsError && !knownSkill.level
													}
												/>
											</div>
										</div>
										<div
											className={`column ${
												this.props.styleVersion === 'v2'
													? 'is-4 is-marginless'
													: 'is-12'
											} `}>
											<div className="control">
												<Dropdown
													enableFixedMode={true}
													key={Math.random()}
													value={knownSkill.experienceYears}
													styleVersion={this.props.styleVersion}
													placeholder={'Years of experience'}
													onValueChange={value => {
														knownSkill.experienceYears = value;

														this.ref.current.props.onValueChange(
															this.props.formikBag.values.knownSkills
														);
													}}
													options={candidatesStore.knownSkillYearsOptions}
													optionValue={'value'}
													isInvalid={
														this.props.formikBag.errors.knownSkills ===
															errorsEnum.fieldsError &&
														!knownSkill.experienceYears
													}
												/>
											</div>
										</div>
									</div>
								</Fragment>
							);
						}
					)}
				</div>
			</Fragment>
		);
	}
}
