import React from 'react';

export class FileInput extends React.Component {
	render() {
		return (
			<input
				className={
					'input ' + (this.props.styleVersion ? this.props.styleVersion : '')
				}
				name={this.props.field.name}
				type="file"
				{...(this.props.accept ? { accept: this.props.accept } : {})}
				onChange={e => {
					let file = e.target.files[0];
					if (file) {
						this.props.setFieldValue(this.props.field.name, file);
					}
				}}
			/>
		);
	}
}
