import moment from 'moment-timezone';

export class Utils {
	formatMonthYearDate(date) {
		if (date) {
			return moment(date).format('MMM YYYY');
		} else {
			return 'Present';
		}
	}

	arrangeArray(array, moveIndex, toIndex) {
		const item = array[moveIndex];
		const length = array.length;
		const diff = moveIndex - toIndex;

		if (diff > 0) {
			return [
				...array.slice(0, toIndex),
				item,
				...array.slice(toIndex, moveIndex),
				...array.slice(moveIndex + 1, length)
			];
		} else if (diff < 0) {
			const targetIndex = toIndex + 1;
			return [
				...array.slice(0, moveIndex),
				...array.slice(moveIndex + 1, targetIndex),
				item,
				...array.slice(targetIndex, length)
			];
		}
		return array;
	}
}

const utils = new Utils();
export { utils };
