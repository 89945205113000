import React, { Fragment } from 'react';
import { CandidateProfileExperienceSkills } from './CandidateProfileExperienceSkills';
import { CandidateProfileExperienceProjects } from './CandidateProfileExperienceProjects';
import { utils } from '../../../shared/stores/Utils';
import { Button } from '../../../components/layout/button/Button';
import { dialogStore } from '../../../components/layout/modals/DialogStore';
import { candidatesStore } from '../CandidatesStore';
import { notificationStore } from '../../../components/layout/notifications/NotificationStore';
import { candidateProfileStore } from '../CandidateProfileStore';
import { modalStore } from '../../../components/layout/modals/ModalStore';
import { CandidateProfileExperienceEdit } from './CandidateProfileExperienceEdit';
import { observer } from 'mobx-react';

@observer
export class CandidateProfileExperience extends React.Component {
	render() {
		return this.props.experiences.map(experience => {
			return (
				<Fragment key={experience.id}>
					<div className="description-content">
						<div className="vertical-divider">
							<div className="divider-cicle"></div>
							<div className="divider-line"></div>
						</div>
						<div
							className={`description-section CandidateProfileExperience ${
								!experience.visible ? 'is-disabled' : ''
							}`}>
							<div className="is-pulled-right button-group">
								{!candidateProfileStore.isLocked && (
									<>
										<Button
											className="is-rounded is-small edit-button"
											icon="trash"
											iconClass="has-text-danger"
											onClick={() => {
												dialogStore.openDialog(
													{
														title: 'Delete Experience',
														message:
															'This experience entry will be deleted. Are you sure?',
														confirmLabel: 'Delete',
														confirmButtonClass: 'is-danger'
													},
													() => this.delete(experience)
												);
											}}
										/>
										<Button
											className="is-rounded is-small edit-button"
											icon="pencil-alt"
											iconClass="has-text-grey"
											onClick={() => {
												modalStore.openModal(
													{
														title: 'Edit Experience',
														position: 'center',
														size: 'XL'
													},
													<CandidateProfileExperienceEdit
														candidate={this.props.candidate}
														experience={experience}
														handleSuccess={() => {
															candidateProfileStore.reloadCandidate();
														}}
													/>
												);
											}}
										/>
									</>
								)}
							</div>
							<div className="title is-5">
								{experience.jobTitle}
								{experience.location && (
									<span className="subtitle is-7 location">
										<i className="fas fa-map-marker-alt" />{' '}
										<span>{experience.location}</span>
									</span>
								)}
							</div>
							<div className="subtitle is-blue-text is-6 company">
								{experience.company}
								<span>
									{' '}
									| {utils.formatMonthYearDate(experience.from)} -{' '}
									{utils.formatMonthYearDate(experience.to)}
								</span>
							</div>
							<div className="description">{experience.description}</div>
							{experience.skills.length > 0 ? (
								<CandidateProfileExperienceSkills skills={experience.skills} />
							) : null}
							{experience.candidateExperienceProjects.length > 0 ? (
								<CandidateProfileExperienceProjects
									projects={experience.candidateExperienceProjects}
								/>
							) : null}
						</div>
					</div>
				</Fragment>
			);
		});
	}

	async delete(item) {
		candidatesStore.deleteExperience(item.id).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'Experience Deleted',
					'The Experience has been successfully deleted.'
				);
				candidateProfileStore.reloadCandidate();
			} else {
				notificationStore.pushErrorNotification(
					'Delete Failed',
					'Failed to delete the Experience'
				);
			}
		});
	}
}
