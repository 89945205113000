import React, { Fragment } from 'react';
import { CandidateProfileExperienceSkills } from './CandidateProfileExperienceSkills';
import { CandidateProfileExperienceProjects } from './CandidateProfileExperienceProjects';
import { utils } from 'shared/stores/Utils';

export class CandidateProfileExperience extends React.Component {
	render() {
		return this.props.experiences.map((experience, index) => {
			return (
				<Fragment key={experience.id}>
					<div className="description-content">
						<div className="vertical-divider">
							<div className="divider-cicle"></div>
							<div className="divider-line"></div>
						</div>
						<div
							className={`description-section CandidateProfileExperience ${
								!experience.visible ? 'is-disabled' : ''
							}`}>
							<div className="title is-5">
								{experience.jobTitle}
								{experience.location && (
									<span className="subtitle is-7 location">
										<i className="fas fa-map-marker-alt" />{' '}
										<span>{experience.location}</span>
									</span>
								)}
							</div>
							<div className="subtitle is-blue-text is-6 company">
								{experience.company}
								<span>
									{' '}
									| {utils.formatMonthYearDate(experience.from)} -{' '}
									{utils.formatMonthYearDate(experience.to)}
								</span>
							</div>
							<div className="description">{experience.description}</div>
							{experience.skills.length > 0 ? (
								<CandidateProfileExperienceSkills skills={experience.skills} />
							) : null}
							{experience.candidateExperienceProjects.length > 0 ? (
								<CandidateProfileExperienceProjects
									projects={experience.candidateExperienceProjects}
								/>
							) : null}
						</div>
					</div>
				</Fragment>
			);
		});
	}
}
