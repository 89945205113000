import React from 'react';
import { withRouter } from 'react-router-dom';
import { candidatesStore } from '../CandidatesStore';
import { dialogStore } from '../../../components/layout/modals/DialogStore';

@withRouter
export class CandidateProfileEmailVerification extends React.Component {
	componentDidMount() {
		const token = this.props.match.params.token;
		this.verifyEmail(token);
	}
	render() {
		return false;
	}

	async verifyEmail(token) {
		const result = await candidatesStore.verifyEmail({ token });
		const message =
			result.status === 'success'
				? 'Your email has been verified.'
				: 'Your email has not been verified.';
		dialogStore.openDialog(
			{
				title: 'Email Verification',
				message,
				confirmLabel: 'OK',
				confirmButtonClass: 'primary-v2',
				hideCancelButton: true
			},
			() => {
				this.props.history.push('/');
			}
		);
	}
}
