import React from 'react';
import { Icon } from '../../../layout/misc/Icon';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { ListStore } from '../../../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { candidateChangelogsStore } from './CandidateChangelogsStore';
import { CandidateChangelogTable } from './CandidateChangelogTable';
import { Pagination } from '../../../layout/pagination/Pagination';
import { MultiDropdown } from '../../../layout/form-fields/inputs/MultiDropdown';
import { ModalComponent } from 'components/layout/modals/ModalComponent';

@withRouter
@observer
export class CandidateChangelog extends ModalComponent {
	state = {
		currentTime: moment()
	};

	constructor(props) {
		super(props);
		this.listStore = new ListStore(candidateChangelogsStore, {
			entityName: 'Candidate Changelogs'
		});
	}

	componentDidMount() {
		candidateChangelogsStore.setQuery('candidateId', this.props.candidate.id);
		candidateChangelogsStore.setQuery('sort', {
			column: 'createdAt',
			asc: false
		});
		this.listStore.search();
	}

	handleSearchByAction = async actions => {
		candidateChangelogsStore.setQuery(
			'actions',
			actions.map(action => action.value)
		);
		this.listStore.search();
	};

	render() {
		return (
			<div className="CandidateProfileExpectationsModal">
				<section className="modal-card-body">
					<div className="CandidateChangelog">
						<div className="columns is-justify-content-flex-end">
							<div className="column changelog-actions-filter">
								<MultiDropdown
									styleVersion="v2"
									placeholder="Search by event types"
									options={candidateChangelogsStore.actionOptions}
									onValueChange={this.handleSearchByAction}
								/>
							</div>
						</div>

						<div className="columns">
							<CandidateChangelogTable
								data={this.listStore.data}
								sort={this.listStore.store.query.sort}
								onSort={this.listStore.handleSort}
								userId={this.props.candidate.userId}
							/>
						</div>
						<div className="columns">
							<div className="column is-12 bottom-pagination">
								<Pagination
									loading={this.listStore.loading}
									pagination={candidateChangelogsStore.query.pagination}
									onPaginationChange={this.handlePaginationChange}
								/>
							</div>
						</div>
					</div>
				</section>
				<footer className="modal-card-foot"></footer>
			</div>
		);
	}

	handlePaginationChange = value => {
		this.listStore.handlePaginationChange(value);
		this.listStore.search();
	};

	isColumnSorted(column) {
		if (this.props.sort && this.props.sort.column === column) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}
}
