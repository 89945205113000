import React from 'react';
import clsx from 'clsx';
import { DynamicCircle } from './DynamicCircle';

export const ScorecardCircle = ({
	tooltipMessage,
	hasTooltip = true,
	tooltipDirection = 'top',
	...props
}) => {
	const { rating } = props;
	const roundedRating = rating.toFixed(1);

	return (
		<span
			className={clsx(`is-tooltip-${tooltipDirection}`, {
				tooltip: hasTooltip
			})}
			data-tooltip={tooltipMessage ?? `${roundedRating} out of 5`}>
			<DynamicCircle {...props} rating={Number(roundedRating)} />
		</span>
	);
};
