import { action, makeObservable } from 'mobx';
import { api } from '../../shared/api/api';
import { EntityStore } from '../../shared/stores/EntityStore';

class UserStore extends EntityStore {
	constructor() {
		super('users');
		makeObservable(this);
	}

	@action
	async invite(email, name, role) {
		const result = await api.post(this.entityPluralEndpoint + '/invite', {
			email: email,
			name: name,
			role: role
		});
		return result;
	}

	@action
	async resendInvite(id) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${id}/invite`,
			null
		);
		return result;
	}

	@action
	async updateLastAccess(id) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/${id}/access`,
			null
		);
		return result;
	}
}

export const userStore = new UserStore();
