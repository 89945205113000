import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../components/layout/button/Button';
import { modalStore } from '../../components/layout/modals/ModalStore';
import { CandidateEdit } from './CandidateEdit';
import { candidateProfileStore } from './CandidateProfileStore';
import { CandidateProfileRepos } from './repos/CandidateProfileRepos';
import { CandidateProfileImage } from './profile-image/CandidateProfileImage';
import { CandidateProfileScore } from './profile-score/CandidateProfileScore';
import { CandidateProfileTopSkills } from '../../components/candidates/profile/top-skills/CandidateProfileTopSkills';
import { observer } from 'mobx-react';
import { isMobile } from 'shared/utils';
import { Icon } from './Icon';
import CandidateProfileShortBio from './CandidateProfileShortBio';
import AnchorIcons from './AnchorIcons';

export const CandidateProfileBio = observer(props => {
	const [isIntersecting, setIsIntersecting] = useState(true);
	const [activeSection, setActiveSection] = useState();

	const bioRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsIntersecting(entry.isIntersecting);
			},
			{ rootMargin: isMobile() ? '-132px' : '-80px' }
		);
		observer.observe(bioRef.current);

		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		const observerSections = new IntersectionObserver(
			entries => {
				const visibleSection = entries.find(
					entry => entry.isIntersecting
				)?.target;

				if (visibleSection) {
					setActiveSection(visibleSection.id);
				}
			},
			{ rootMargin: '-30% 0px -70% 0px' }
		);

		const experienceSection = document.getElementById('experience');
		const scorecardsSection = document.getElementById('scorecards');
		const technicalTestsSection = document.getElementById('technical-tests');
		const educationSection = document.getElementById('education');
		const certificationsSection = document.getElementById('certifications');

		if (experienceSection) {
			observerSections.observe(experienceSection);
		}

		if (scorecardsSection) {
			observerSections.observe(scorecardsSection);
		}

		if (technicalTestsSection) {
			observerSections.observe(technicalTestsSection);
		}

		if (educationSection) {
			observerSections.observe(educationSection);
		}

		if (certificationsSection) {
			observerSections.observe(certificationsSection);
		}

		return () => observerSections.disconnect();
	}, []);

	const handleAnchorClick = (
		e,
		sectionId,
		scrollOffset = isMobile() ? 132 : 80
	) => {
		e.preventDefault();
		if (activeSection !== sectionId) {
			setActiveSection(sectionId);
		}

		const yOffset = scrollOffset;

		const section = document.getElementById(sectionId);

		const y =
			section.getBoundingClientRect().top + document.body.scrollTop - yOffset;

		document.body.scrollTo({
			top: y,
			behavior: 'smooth'
		});
	};

	const candidate = props.candidate;
	const config = candidate.candidateProfile;

	return (
		<>
			<div id="header" className="bio-wrapper is-edit" ref={bioRef}>
				<div className="bio portal is-edit">
					<div className="bio-left">
						<CandidateProfileImage candidate={props.candidate} />
						<AnchorIcons
							candidate={props.candidate}
							handleAnchorClick={handleAnchorClick}
							activeSection={activeSection}
						/>
					</div>
					<div className="bio-description-wrapper ">
						<div className="name-container">
							<div
								className={`name ${
									!config.showPersonalInfo ? 'is-disabled' : ''
								}`}>
								<h1 className="has-edit-button">
									{props.candidate.name}
									<div className="edit-button-wrapper" title="Edit Your Name">
										{!candidateProfileStore.isLocked && (
											<Button
												className="is-rounded is-small edit-button"
												icon="pencil-alt"
												iconClass="has-text-white"
												onClick={event => {
													modalStore.openModal(
														{ title: 'Edit Profile', position: 'center' },
														<CandidateEdit
															candidate={props.candidate}
															editAttribute={'name'}
															handleSuccess={() => {
																candidateProfileStore.reloadCandidate();
															}}
														/>
													);
												}}
											/>
										)}
									</div>
								</h1>
							</div>
						</div>

						<div className="aboutMe-container bio-subsection-wrapper">
							<div className="aboutMe has-edit-button">
								<h3
									className={`title is-5 ${
										!config.showAboutMe ? 'is-disabled' : ''
									}`}>
									<Icon icon="fa-regular fa-user" />
									Bio
								</h3>
								{props.candidate.aboutMe ? (
									<p>
										{props.candidate.aboutMe}
										<div
											className="edit-button-wrapper about-me"
											title="Edit Profile Bio">
											{!candidateProfileStore.isLocked && (
												<Button
													className="is-rounded  edit-button"
													icon="pencil-alt"
													iconClass="has-text-white"
													onClick={event => {
														modalStore.openModal(
															{ title: 'Edit Bio', position: 'center' },
															<CandidateEdit
																candidate={props.candidate}
																editAttribute={'about'}
																handleSuccess={() => {
																	candidateProfileStore.reloadCandidate();
																}}
															/>
														);
													}}
												/>
											)}
										</div>
									</p>
								) : (
									<p>
										Please write a brief summary of your professional profile.
										Remember to write about yourself in the 3rd person (max 200
										characters)
										<div
											className="about-me edit-button-wrapper"
											title="Edit your bio">
											{!candidateProfileStore.isLocked && (
												<Button
													className="is-rounded is-small is-circle add-button"
													icon="plus"
													iconClass="has-text-white"
													onClick={event => {
														modalStore.openModal(
															{ title: 'Create Bio', position: 'center' },
															<CandidateEdit
																candidate={props.candidate}
																editAttribute={'about'}
																handleSuccess={() => {
																	candidateProfileStore.reloadCandidate();
																}}
															/>
														);
													}}
												/>
											)}
										</div>
									</p>
								)}
							</div>
						</div>
						<div className="top-skills-container bio-subsection-wrapper">
							<div
								className={`title is-inline-block  ${
									!config.showSkills ? 'is-disabled' : ''
								}`}>
								<Icon icon="fa-solid fa-desktop" />
								Top Technologies
							</div>
							{props.candidate.topSkills.length > 0 ? (
								<CandidateProfileTopSkills
									isDisabledStyle={!config.showSkills}
									skills={props.candidate.topSkills}
								/>
							) : (
								<div className="sub-section-content">
									<p>
										You have no skills listed in your profile yet, add some
										experience!
									</p>
								</div>
							)}
						</div>
						<div className="is-flex">
							<div className="location-container bio-subsection-wrapper has-edit-button">
								<div
									className={`title is-inline-block ${
										!config.showLocation ? 'is-disabled' : ''
									}`}>
									<Icon icon="fa-solid fa-map-marker-alt" />
									Location
								</div>
								<div>
									<span>
										{props.candidate.country
											? props.candidate.country.name
											: null}
										<div
											className="edit-button-wrapper"
											title="Edit your Location">
											{!candidateProfileStore.isLocked && (
												<Button
													className="is-rounded edit-button"
													icon="pencil-alt"
													iconClass="has-text-white"
													onClick={event => {
														modalStore.openModal(
															{ title: 'Edit Location', position: 'center' },
															<CandidateEdit
																candidate={props.candidate}
																editAttribute={'location'}
																handleSuccess={() => {
																	candidateProfileStore.reloadCandidate();
																}}
															/>
														);
													}}
												/>
											)}
										</div>
									</span>
								</div>
							</div>
						</div>
						<CandidateProfileRepos
							onConfigChange={props.onConfigChange}
							candidate={props.candidate}
							repos={props.candidate.candidateRepos}
						/>
						<CandidateProfileScore
							candidate={props.candidate}
							handleSuccessPublish={() => {
								candidateProfileStore.reloadCandidate();
							}}
						/>
					</div>
				</div>
			</div>
			<CandidateProfileShortBio
				candidate={candidate}
				isIntersecting={isIntersecting}
				handleAnchorClick={(e, sectionId) =>
					handleAnchorClick(e, sectionId, isMobile() ? 252 : 200)
				}
				activeSection={activeSection}
			/>
		</>
	);
});
