import { Button } from '../../../layout/button/Button';
import React, { useMemo, useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function CandidateProfileImageCrop({ image, onCropImage }) {
	const [cropObj, setCropObj] = useState({});

	const imgRef = useRef();

	const imgSrc = useMemo(() => {
		return image ? URL.createObjectURL(image) : null;
	}, [image]);

	const centerCropOnLoadImage = e => {
		const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

		const ASPECT = 1;

		const percentCrop = centerCrop(
			makeAspectCrop(
				{
					unit: '%',
					width: 100
				},
				ASPECT,
				width,
				height
			),
			width,
			height
		);

		setCropObj({ crop: null, percentCrop });
	};

	async function onSubmitCrop(completedCrop, imageType, onCropImage) {
		if (completedCrop) {
			// create a canvas element to draw the cropped image
			const canvas = document.createElement('canvas');

			// get the image element
			const image = imgRef.current;

			// draw the image on the canvas
			if (image) {
				const scaleX = image.naturalWidth / image.width;
				const scaleY = image.naturalHeight / image.height;
				const ctx = canvas.getContext('2d');
				const pixelRatio = window.devicePixelRatio;
				canvas.width = completedCrop.width * pixelRatio * scaleX;
				canvas.height = completedCrop.height * pixelRatio * scaleY;

				if (ctx) {
					ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
					ctx.imageSmoothingQuality = 'high';

					ctx.drawImage(
						image,
						completedCrop.x * scaleX,
						completedCrop.y * scaleY,
						completedCrop.width * scaleX,
						completedCrop.height * scaleY,
						0,
						0,
						completedCrop.width * scaleX,
						completedCrop.height * scaleY
					);
				}

				let croppedImage;

				try {
					croppedImage = await new Promise(resolve => {
						canvas.toBlob(file => {
							resolve(file);
						}, imageType);
					});
				} catch (error) {
					croppedImage = null;
				}

				onCropImage(croppedImage);
			}
		}
	}

	return (
		<>
			<div className="columns">
				<div className="column is-full">
					{imgSrc ? (
						<ReactCrop
							crop={cropObj.percentCrop}
							onChange={(crop, percentCrop) =>
								setCropObj({ crop, percentCrop })
							}
							aspect={1}
							circularCrop
							keepSelection>
							<img
								alt="preview"
								ref={imgRef}
								src={imgSrc}
								onLoad={centerCropOnLoadImage}
							/>
						</ReactCrop>
					) : null}
				</div>
			</div>
			<div className="columns">
				<div className="column is-full">
					{cropObj.crop ? (
						<Button
							onClick={() =>
								onSubmitCrop(cropObj.crop, image.type, onCropImage)
							}
							className="button is-primary is-rounded"
							label="Crop / Resize"
						/>
					) : null}
				</div>
			</div>
		</>
	);
}

export default CandidateProfileImageCrop;
