import _ from 'lodash';
import React from 'react';
import { Icon } from '../../misc/Icon';

export class InputField extends React.Component {
	state = {
		defaultValue: null
	};

	componentDidMount() {
		this.setState({ defaultValue: this.props.defaultValue });
	}

	render() {
		if (
			this.props.type === 'text' ||
			this.props.type === 'email' ||
			this.props.type === 'search' ||
			this.props.type === 'textarea'
		) {
			return (
				<div className={'field ' + this.props.className}>
					{this.props.label ? (
						<label className="subtitle is-7">{this.props.label}</label>
					) : null}
					<p className={'control ' + (this.props.icon ? 'has-icons-left' : '')}>
						{this.props.type === 'textarea' ? (
							<textarea
								cols={4}
								ref={this.props.inputRef}
								value={this.props.value}
								name={this.props.field}
								className="input"
								placeholder={this.getPlaceHolderText()}
								onChange={event => this.handleChange(event)}
							/>
						) : (
							<input
								key={`${
									this.props.resetInput
										? Math.floor(Math.random() * 1000)
										: 'input'
								}`}
								disabled={this.props.disabled}
								autoFocus={this.props.autoFocus}
								ref={this.props.inputRef}
								{...(this.props.value && { value: this.props.value })}
								defaultValue={this.state.defaultValue}
								name={this.props.field}
								className="input"
								placeholder={this.getPlaceHolderText()}
								type={this.props.type ? this.props.type : 'text'}
								onChange={event => {
									event.persist();
									this.props.debounce
										? this.handleChangeDebounced(event)
										: this.handleChange(event);
								}}
							/>
						)}
						{this.iconDisplay()}
					</p>
				</div>
			);
		}
		return <div>Field type not supported</div>;
	}

	getPlaceHolderText() {
		if (this.props.placeholder) {
			return this.props.placeholder;
		} else if (this.props.label) {
			return this.props.label;
		} else {
			return this.props.field;
		}
	}

	handleChange(event) {
		let value = event.target.value;
		if (this.props.prefix) {
			let rawValue = value.substr(this.props.prefix.length, value.length);
			value = this.props.prefix + rawValue;
		}
		if (this.props.onValueChange) {
			this.props.onValueChange(value);
		}
	}

	handleChangeDebounced = _.debounce(
		this.handleChange,
		this.props.debounceTime || 150
	);

	iconDisplay() {
		if (this.props.icon) {
			return <Icon className={'is-small is-left'} icon={this.props.icon} />;
		}
		return null;
	}
}
