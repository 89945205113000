import React, { Component } from 'react';
import { Icon } from 'components/layout/misc/Icon';
import { ScorecardSection } from './ScorecardSection';
import { ScorecardCircle } from './ScorecardCircle';
import { isMobile } from 'shared/utils';

export class Scorecard extends Component {
	state = {
		open: false
	};

	constructor() {
		super();
		this.toggleContainer = this.toggleContainer.bind(this);
	}

	toggleContainer(event) {
		event.stopPropagation();
		event.preventDefault();

		this.setState({ open: !this.state.open });
	}

	render() {
		const { scorecard } = this.props;

		return (
			<div
				className={`ScorecardContainer is-flex is-flex-direction-row gap-4 ${
					this.state.open ? 'is-open' : ''
				}`}>
				<div className="Tab tabbed-panel">
					<div
						className="Tab__Title is-flex is-justify-content-space-between"
						onClick={this.toggleContainer}>
						<h3 className="title is-5">{scorecard.name}</h3>
						<div className="horizontal-divider"></div>
						<div className="is-flex is-align-items-center gap-5">
							<ScorecardCircle
								key={scorecard.overallScore}
								rating={Number(scorecard.overallScore)}
								width={25}
								height={25}
								fullCircleColor="#19E1D0"
								emptyCircleColor="#314EBD"
								type="circle"
								hasTooltip={!isMobile()}
							/>
						</div>
						<button
							className="ToggleScorecardButton button level-item"
							onClick={this.toggleContainer}>
							<Icon
								className="is-big"
								icon={this.state.open ? 'fa-chevron-down' : 'fa-chevron-up'}
							/>
						</button>
					</div>
					{this.state.open ? (
						<>
							<div className="Tab__Content">
								{/*ScorecardTabContent*/}
								{scorecard && (
									<div className="ScorecardContent">
										<div className="ScorecardContent__SectionList">
											{scorecard.sections.map((section, index) => (
												<ScorecardSection
													key={`ScorecardSection-${index}-${section.score}`}
													section={section}
													collapsible
												/>
											))}
										</div>
									</div>
								)}
							</div>
							<div className="ScorecardInfoBox__content">
								<p className="title is-7">Comments</p>
								<p className="is-text-pre-wrap is-text-word-break">
									{scorecard.publicNotes}
								</p>
							</div>
						</>
					) : null}
				</div>
			</div>
		);
	}
}
