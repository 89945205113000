import { EntityStore } from '../../../shared/stores/EntityStore';
import { action, makeObservable } from 'mobx';
import { api } from '../../../shared/api/api';

class CountryStore extends EntityStore {
	constructor() {
		super('countries');
		makeObservable(this);
	}

	@action
	async getCountries(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}
}

export const countryStore = new CountryStore();
