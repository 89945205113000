import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import { utils } from '../../../shared/stores/Utils';
import RepoDraggableItem from './RepoDraggableItem';
import { CandidateProfileRepoEditForm } from './CandidateProfileRepoEditForm';
import { candidatesStore } from '../CandidatesStore';

export class CandidateProfileReposEdit extends ModalComponent {
	state = {
		isAddingEditing: false,
		editRepo: null
	};

	getInitialValues() {
		let repos = [];
		if (this.props.repos.length > 0) {
			repos = this.props.repos.map((item, index) => {
				return {
					id: item.id,
					url: item.url,
					repoKey: item.repoKey,
					index: index
				};
			});
		}
		return { repos: repos };
	}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileReposEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full has-text-left">
						<div className="tabbed-panel add-margin-top">
							<div className="box small">
								<div className="description-section">
									<div className="sub-section has-text-left">
										<div className="sub-section-content">
											{formikBag.values.repos.map((repo, index) => {
												return (
													<RepoDraggableItem
														isEditing={
															this.state.editRepo &&
															this.state.editRepo.index === repo.index
														}
														key={repo.index}
														repo={repo}
														index={index}
														onMove={(dragIndex, hoverIndex) => {
															const repos = utils.arrangeArray(
																formikBag.values.repos,
																dragIndex,
																hoverIndex
															);
															formikBag.setFieldValue('repos', repos);
														}}
														onEdit={repo => {
															this.setState({
																editRepo: repo,
																isAddingEditing: true
															});
														}}
														onDelete={repo => {
															const repos = formikBag.values.repos.filter(
																item => item.index !== repo.index
															);
															formikBag.setFieldValue('repos', repos);
														}}
													/>
												);
											})}
											{formikBag.values.repos.length === 0 ? (
												<div className="has-text-centered">No repos yet</div>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={
						'columns ' + (this.state.isAddingEditing ? 'is-hidden' : '')
					}>
					<div className="column is-full has-text-right">
						<button
							type="button"
							className="button add-button is-primary is-rounded is-small"
							onClick={event => {
								this.setState({ isAddingEditing: true, editRepo: null });
							}}>
							Add New
						</button>
					</div>
				</div>
				{!this.state.isAddingEditing ? null : (
					<CandidateProfileRepoEditForm
						editRepo={this.state.editRepo}
						onCancel={() => {
							this.setState({ isAddingEditing: false, editRepo: null });
						}}
						onSave={repo => {
							const repos = [...formikBag.values.repos];
							// is edit
							if (this.state.editRepo !== null) {
								let match = repos.find(
									item => item.index === this.state.editRepo.index
								);
								if (match) {
									match.url = repo.url;
									match.repoKey = repo.repoKey;
								}
								// is create
							} else {
								let newIndex = repos.length + 1;
								const newRepo = {
									url: repo.url,
									repoKey: repo.repoKey,
									index: newIndex
								};
								repos.push(newRepo);
							}
							formikBag.setFieldValue('repos', repos);
							formikBag.setFieldValue('updated', true);
							this.setState({
								// repos: repos,
								isAddingEditing: false,
								editRepo: null
							});
						}}
					/>
				)}
			</Fragment>
		);
	}

	async save(values) {
		const request = {
			repos: values.repos
		};
		candidatesStore.createRepos(request).then(result => {
			this.resultNotification(result, 'Candidate Experience', 'Edit');
		});
	}
}
