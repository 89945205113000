import React from 'react';

export class CandidateProfileExperienceSkills extends React.Component {
	render() {
		return (
			<div className="sub-section technologies">
				<div className="subtitle is-7">
					<i className="fa-solid fa-desktop" />
					Technologies
				</div>
				<div className="sub-section-content">
					{this.props.skills.map(skill => {
						return (
							<span key={skill.id} className="tag is-blue">
								{skill.name}
							</span>
						);
					})}
				</div>
			</div>
		);
	}
}
