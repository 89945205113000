import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { UserMenu } from './user-menu/UserMenu';
import { isMobile } from '../../../shared/utils';

@withRouter
export class Header extends React.Component {
	render() {
		const { location } = this.props.history;

		return (
			<div className="Header">
				{isMobile() && location.pathname !== '/profile' ? (
					<div className="warning-mobile-message">
						<p>This site is optimized for desktop devices.</p>
					</div>
				) : null}{' '}
				{this.renderTopNav()}{' '}
			</div>
		);
	}

	redirectHome() {
		this.props.history.push('/');
	}

	renderTopNav() {
		return (
			<nav className="level">
				<div className="level-left">
					<NavLink to="/" activeClassName="is-active logo">
						<img alt="logo" src="/logo.svg" />
					</NavLink>
				</div>
				<div className="level-right">
					<div className="level-item">
						<UserMenu />
					</div>
				</div>
			</nav>
		);
	}

	renderUserNotifications() {
		return (
			<div className="level-item">
				<span
					className="badge has-badge-rounded is-badge-danger"
					data-badge="10">
					<i className="fas fa-bell has-text-grey" />
					<span className="notification-separator" />
				</span>
			</div>
		);
	}
}
