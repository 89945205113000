import { Button } from '../../../components/layout/button/Button';
import React from 'react';
import onClickOutside from 'react-onclickoutside';

@onClickOutside
class CandidatesTableActions extends React.Component {
	state = {
		openDropdown: false
	};

	handleClickOutside() {
		this.handleOnDropdownClick(true);
	}

	handleOnDropdownClick = (close = false) => {
		this.setState({ openDropdown: close ? false : !this.state.openDropdown });
	};

	render() {
		return (
			<div
				class={`dropdown is-right ${
					this.state.openDropdown ? 'is-active' : ''
				}`}>
				<div
					class="dropdown-trigger"
					onClick={() => this.handleOnDropdownClick()}>
					<button
						class="button"
						aria-haspopup="true"
						aria-controls="dropdown-menu">
						<span class="icon is-small">
							<i class="fas fa-ellipsis-vertical" aria-hidden="true"></i>
						</span>
					</button>
				</div>
				<div class="dropdown-menu" id="dropdown-menu" role="menu">
					<div class="dropdown-content">
						{this.props.actions.map(action => (
							<div class="dropdown-item">
								<Button
									label={action.label}
									title={action.title}
									className="button is-text"
									onClick={action.onClickHandler}
									icon={action.icon}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default CandidatesTableActions;
