import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { FormField, FormFieldTypes } from '../../layout/form-fields/FormField';
import { scorecardTemplateStore } from '../ScorecardTemplateStore';

const defaultValues = {
	name: ''
};

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	description: Yup.string().label('Description')
});

export class ScorecardTemplateSectionForm extends ModalComponent {
	get isEdit() {
		return this.props.data !== undefined;
	}

	render() {
		return (
			<Formik
				initialValues={{
					...defaultValues,
					...this.props.data
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'ScorecardTemplateSectionForm')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<div className="columns">
				<div className="column is-full">
					<FormField
						autoFocus={true}
						key="name"
						required
						className="has-text-left"
						label="Name"
						field="name"
						type={FormFieldTypes.TEXT}
						placeholder=""
						maxlength={255}
					/>
					<FormField
						key="description"
						className="has-text-left"
						label="Description"
						field="description"
						type={FormFieldTypes.TEXTAREA}
						placeholder=""
						maxlength={255}
					/>
				</div>
			</div>
		);
	}

	async save(values) {
		const { templateId, data } = this.props;

		if (this.isEdit) {
			return scorecardTemplateStore
				.updateSection(templateId, data.id, values)
				.then(result => {
					this.closeWithSuccess(result);
				});
		} else {
			return scorecardTemplateStore
				.createSection(templateId, values)
				.then(result => {
					this.closeWithSuccess(result);
				});
		}
	}
}
