import React from 'react';

export class Icon extends React.Component {
	render() {
		const style = this.props.fab ? 'fab' : 'fas';

		return (
			<i
				className={`icon ${this.props.className || ''} ${style} ${
					this.props.icon
				}`}
			/>
		);
	}
}
