import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import { Dropdown } from '../../../components/layout/form-fields/inputs/Dropdown';
import { candidatesStore } from '../CandidatesStore';
import { candidatesStore as candidateStoreAdmin } from '../../../components/candidates/CandidatesStore';
import { JobRoleDropdown } from '../../../components/layout/form-fields/inputs/JobRoleDropdown';
import { MultiDropdown } from '../../../components/layout/form-fields/inputs/MultiDropdown';
import { SkillDropdown } from '../../../components/layout/form-fields/inputs/SkillDropdown';
import { TimezoneDropdown } from '../../../components/timezones/shared/TimezoneDropdown';

const ValidationSchema = Yup.object().shape({
	preferredJobRoles: Yup.array()
		.min(1, 'Please select at least one Role')
		.required(),
	preferredSkills: Yup.array()
		.min(1, 'Please select at least one Skill')
		.required(),
	preferredWorkLocation: Yup.string().required(),
	preferredPositionType: Yup.array()
		.min(1, 'Please select at least one Position Type')
		.required(),
	preferredWorkshifts: Yup.array()
		.min(1, 'Please select at least one Workshift')
		.required()
});

export class CandidateProfileExpectationsEditAdmin extends ModalComponent {
	state = {
		saving: false
	};
	getValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	isInitialValid(props) {
		if (!props.validationSchema) return true;
		return props.validationSchema.isValidSync(props.initialValues);
	}
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				isInitialValid={this.isInitialValid}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(
						formikBag,
						'CandidateProfileExpectationsEditAdmin'
					)
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns wizard-row">
					<div className="column is-full">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								What types of roles are you interested in?
							</label>
							<JobRoleDropdown
								value={formikBag.values.preferredJobRoles}
								onValueChange={values =>
									formikBag.setFieldValue('preferredJobRoles', values)
								}
							/>
						</div>
					</div>
				</div>
				<div className="columns wizard-row">
					<div className="column is-full">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								What are the technologies you would like to work with?
							</label>
							<SkillDropdown
								value={formikBag.values.preferredSkills}
								onValueChange={values =>
									formikBag.setFieldValue('preferredSkills', values)
								}
							/>
						</div>
					</div>
				</div>
				<div className="columns wizard-row">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								Where do you prefer working from?
							</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.preferredWorkLocation}
									onValueChange={value =>
										formikBag.setFieldValue('preferredWorkLocation', value)
									}
									options={candidatesStore.preferredWorkLocationOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns wizard-row">
					<div className="column is-full">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								What type of positions are you interested in?
							</label>
							<MultiDropdown
								value={formikBag.values.preferredPositionType}
								options={candidatesStore.preferredPositionTypeOptions}
								onValueChange={values =>
									formikBag.setFieldValue('preferredPositionType', values)
								}
							/>
						</div>
					</div>
				</div>
				<div className="columns wizard-row">
					<div className="column is-full">
						<div className=" has-text-left">
							<label className="subtitle is-7">What is your time zone?</label>
							<TimezoneDropdown
								value={formikBag.values.preferredTimeZone}
								onValueChange={value =>
									formikBag.setFieldValue('preferredTimeZone', value)
								}
							/>
						</div>
					</div>
				</div>
				<div className="columns wizard-row">
					<div className="column is-full">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								Which workshifts would you be willing to work in?
							</label>
							<MultiDropdown
								value={formikBag.values.preferredWorkshifts}
								options={candidatesStore.preferredWorkshiftsOptions}
								onValueChange={values => {
									const sortedValues = values.sort(this.orderWorkshift);
									return formikBag.setFieldValue(
										'preferredWorkshifts',
										sortedValues
									);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="columns wizard-row">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								Are you actively looking for a new job right now ?
							</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.lookingForJob}
									onValueChange={value =>
										formikBag.setFieldValue('lookingForJob', value)
									}
									options={candidatesStore.lookingForJobOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		const preferredPositionType = (
			this.props.candidate.preferredPositionType || []
		).map(item => {
			return {
				text: this.getValueFromOptions(
					item,
					candidatesStore.preferredPositionTypeOptions
				),
				value: item
			};
		});
		const preferredWorkshifts = (
			this.props.candidate.preferredWorkshifts || []
		).map(item => {
			return {
				text: this.getValueFromOptions(
					item,
					candidatesStore.preferredWorkshiftsOptions
				),
				value: item
			};
		});
		const preferredSkills = (this.props.candidate.preferredSkills || []).map(
			item => {
				return item.skill;
			}
		);
		const candidateTimeZone = this.props.candidate.timezone
			? this.props.candidate.timezone
			: null;
		const preferredJobRoles = (
			this.props.candidate.preferredJobRoles || []
		).map(item => {
			return { ...item.jobRole };
		});
		return {
			preferredJobRoles: preferredJobRoles,
			preferredWorkLocation: this.props.candidate.preferredWorkLocation,
			preferredSkills: preferredSkills,
			preferredTimeZone: candidateTimeZone,
			lookingForJob: this.props.candidate.lookingForJob,
			preferredPositionType,
			preferredWorkshifts
		};
	}

	saving(status) {
		if (status === false || status === true) {
			this.setState({ saving: status });
		} else {
			this.setState({ saving: true });
		}
	}

	async save(values) {
		const valuesToSave = {
			candidate: {
				...values,
				preferredPositionType: values.preferredPositionType.map(
					item => item.value
				),
				preferredWorkshifts: values.preferredWorkshifts.map(item => item.value),
				preferredTimeZone: values.preferredTimeZone.id
			},
			lookingForJob: values.lookingForJob,
			preferredJobRoles: values.preferredJobRoles,
			preferredSkills: values.preferredSkills
		};
		candidateStoreAdmin
			.updateExpectationsAdmin(this.props.candidate.id, valuesToSave)
			.then(result => {
				this.setState({ saving: false });
				this.resultNotification(
					result,
					'Work Preferences',
					null,
					'Work Preferences',
					'Your expectations were saved successfully.'
				);
			});
	}

	orderWorkshift(workshiftA, workshiftB) {
		if (workshiftA === 'anytime') {
			return 1;
		}
		const shiftListA = workshiftA.value.split('-');
		const shiftStartsA = shiftListA[0];
		const militaryShiftTimeA = parseInt(
			moment(shiftStartsA, 'ha').format('HH')
		);
		const shiftListB = workshiftB.value.split('-');
		const shiftStartsB = shiftListB[0];
		const militaryShiftTimeB = parseInt(
			moment(shiftStartsB, 'ha').format('HH')
		);
		return militaryShiftTimeA > militaryShiftTimeB ? 1 : -1;
	}
}
