import { action, observable, makeObservable } from 'mobx';
import { api } from '../../../../shared/api/api';
import { notificationStore } from '../../../layout/notifications/NotificationStore';

class CandidateCompensationsStore {
	@observable compensations;
	endpointRootURL = 'candidates/compensations';

	constructor() {
		makeObservable(this);
	}

	@action
	setCompensations(compensations) {
		this.compensations = compensations;
	}

	@action
	async getCompensations(candidateId) {
		const result = await api.get(`/candidates/${candidateId}/compensations`);
		// on fail display error notification
		if (result.status === 'fail') {
			console.error(result.message || result.error);
			notificationStore.pushErrorNotification(
				'Search Error',
				'An error occurred trying to get the compensation list'
			);
			this.setCompensations([]);
		} else {
			this.setCompensations(result?.data?.rows ?? []);
		}
	}

	@action
	async create(candidateId, request) {
		const result = await api.post(
			`candidates/${candidateId}/compensations`,
			request
		);

		return result;
	}

	@action
	async delete(candidateId, compensationId) {
		return await api.delete(
			`candidates/${candidateId}/compensations/${compensationId}`
		);
	}
}

export const candidateCompensationsStore = new CandidateCompensationsStore();
