import React from 'react';

export class CandidateProfileTopSkills extends React.Component {
	setTopSkillsOpacity = topSkills => {
		const INDEX_FIRST_ELEMENT = 0;
		let lastIndexAssigned = 1;
		let index;
		const newTopSkills = [];
		for (index = 0; index < topSkills.length; index++) {
			const isFirstElement = index === INDEX_FIRST_ELEMENT;
			const skill = topSkills[index];
			if (isFirstElement) {
				skill.opacity = 1.1 - `0.${lastIndexAssigned}`;
				newTopSkills.push(skill);
				continue;
			}
			const previousSkill = topSkills[index - 1];
			const previousSkillHasSameWeight =
				previousSkill.candidateTopSkills.weight ===
				skill.candidateTopSkills.weight;
			if (previousSkillHasSameWeight) {
				skill.opacity = 1.1 - `0.${lastIndexAssigned}`;
				newTopSkills.push(skill);
				continue;
			}
			lastIndexAssigned++;
			skill.opacity = 1.1 - `0.${lastIndexAssigned}`;
			newTopSkills.push(skill);
		}
		return newTopSkills;
	};

	render() {
		const topSkills = this.setTopSkillsOpacity(this.props.skills || []);
		return (
			<div
				className={`sub-section CandidateProfileTopSkills ${
					this.props.isDisabledStyle ? 'is-disabled' : ''
				}`}>
				<div className="sub-section-content">
					{this.props.shouldShorten && topSkills.length > 0 ? (
						<>
							<span
								key={topSkills[0].id}
								className="tag is-blue"
								style={{
									opacity: topSkills[0].opacity
								}}>
								{topSkills[0].name}
							</span>
							{topSkills.slice(1).length > 0 ? (
								<span
									className="tooltip is-tooltip-top"
									data-tooltip={topSkills
										.slice(1)
										.map(skill => skill.name)
										.join(' | ')}>
									+{topSkills.slice(1).length} More
								</span>
							) : null}
						</>
					) : (
						topSkills.map(skill => {
							return (
								<span
									key={skill.id}
									className="tag is-blue"
									style={{
										opacity: skill.opacity
									}}>
									{skill.name}
								</span>
							);
						})
					)}
				</div>
			</div>
		);
	}
}
