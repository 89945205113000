import React from 'react';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';
import { Loader } from 'components/layout/misc/Loader';
import { observer } from 'mobx-react';
import { HTTP_ERROR } from '../shared/api/api';
import { RequestProfileForm } from './components/RequestProfileForm';
import { CandidatePublicProfileBio } from './components/CandidatePublicProfileBio';
import { NotFound } from 'components/layout/security/NotFound';
import { candidateProfileStore } from 'components/candidates/profile/CandidateProfileStore';
import { CandidateProfileSections } from './components/CandidateProfileSections';
import { CandidateProfileHeader } from 'components/candidates/profile/header/CandidateProfileHeader';
import { PublicFooter } from 'components/layout/footer/PublicFooter';

@withRouter
@observer
export class CandidatePublicProfile extends React.Component {
	state = {
		NOT_FOUND: false,
		EXPIRED: false,
		candidateId: null,
		candidateSlug: null
	};

	componentDidMount() {
		const { history, location } = this.props;
		const queryParameters = new URLSearchParams(location.search);
		const token = queryParameters.get('token');

		document.title = 'Prediktive Talent Network';
		// If we do not have a token, there is no reason to show anything on this page
		if (!token && !location.state) {
			history.push('/');
		} else if (location.state && location.state.slug) {
			// Means we got redirected from a /p/:slug url without token so we gotta try to get info from this slug
			this.setState({
				EXPIRED: true,
				candidateSlug: location.state.slug
			});
		} else {
			void this.loadCandidate(token);
			document.body.classList.remove('has-navbar-fixed-top');
		}
	}

	componentWillUnmount() {
		if (!document.body.classList.contains('has-navbar-fixed-top')) {
			document.body.classList.add('has-navbar-fixed-top');
		}
	}

	async loadCandidate(token) {
		const result = await candidateProfileStore.getPublicProfile(token);
		// Token is damaged, unreadable, non-existent, invalid
		if (
			[
				HTTP_ERROR.ERROR,
				HTTP_ERROR.NOT_FOUND,
				HTTP_ERROR.SERVER_ERROR
			].includes(result.statusCode)
		) {
			this.setState({ NOT_FOUND: true });
		} else if (result.statusCode === HTTP_ERROR.FORBIDDEN) {
			this.setState({ EXPIRED: true, candidateId: result.candidateId });
		} else {
			candidateProfileStore.setCandidate(result.data);

			const { id, name, country, location } = result.data;

			ReactGA.event({
				category: 'Candidate',
				action: 'View',
				label: `View Profile: Candidate - (id=${id}, name=${name}, country=${country?.name}, location=${location})`,
				value: 1
			});
		}
	}

	render() {
		const { candidate } = candidateProfileStore;
		const { candidateId, candidateSlug } = this.state;
		const showSections =
			candidate &&
			(candidate.candidateExperiences.length > 0 ||
				candidate.candidateEducations.length > 0 ||
				candidate.candidateCertifications.length > 0 ||
				candidate.candidateTestResults.length > 0 ||
				candidate.candidateScorecards.length > 0);

		if (candidate) {
			return (
				<>
					<div className="CandidateProfile public-profile">
						<CandidateProfileHeader candidate={candidate} standalone />
						<CandidatePublicProfileBio
							candidate={candidate}
							publicProfile={true}
						/>
						{showSections && <CandidateProfileSections candidate={candidate} />}
					</div>
					<PublicFooter />
				</>
			);
		} else if (this.state.NOT_FOUND) {
			return <NotFound />;
		} else if (this.state.EXPIRED) {
			return (
				<RequestProfileForm
					candidateId={candidateId}
					candidateSlug={candidateSlug}
				/>
			);
		} else {
			return <Loader type="page-loader" />;
		}
	}
}
