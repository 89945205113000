import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { modalStore } from './ModalStore';

@withRouter
@observer
export class Modal extends React.Component {
	render() {
		return <div className="Modal">{this.renderModal()}</div>;
	}

	renderModal() {
		return (
			<div className={`modal ${this.getModalClass()} ${modalStore.position}`}>
				<div className="modal-background" />
				<div className={`modal-card ${modalStore.size}`}>
					<header className="modal-card-head">
						<p className="modal-card-title title is-2 is-size-4-mobile">
							{this.getTitle()}
						</p>
						<a href={'void'} onClick={e => this.handleClickClose(e)}>
							<i className="fas fa-times" />
						</a>
						<div className="border-line" />
					</header>
					{modalStore.getModalComponent()}
				</div>
			</div>
		);
	}

	getTitle() {
		return modalStore.title ? modalStore.title : '';
	}

	getModalClass() {
		return modalStore.isOpen === true ? 'is-active ' : '';
	}

	handleClickClose(e) {
		e.preventDefault();
		if (modalStore.modalComponent.props.handleCancelClose) {
			modalStore.modalComponent.props.handleCancelClose('close');
		}
		modalStore.closeDialog();
	}
}
