import React from 'react';
import Select from 'react-select';

export class MultiDropdown extends React.Component {
	state = {
		value: null,
		loaded: false
	};
	componentDidMount() {
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
		this.setState({ loaded: true });
	}

	render() {
		if (this.state.loaded) {
			const { placeholder = 'You can choose more than one option' } =
				this.props;

			return (
				<Select
					menuPosition={'fixed'}
					maxMenuHeight={300}
					className={
						'react-select ' +
						(this.props.styleVersion ? this.props.styleVersion : '') +
						(this.props.isInvalid ? ' has-error' : '')
					}
					valueKey={'value'}
					cacheOptions
					isMulti
					value={this.state.value}
					placeholder={placeholder}
					options={this.props.options}
					getOptionValue={this.getOptionValue}
					getOptionLabel={this.getOptionLabel}
					noOptionsMessage={this.noOptionsMessage}
					onChange={item => {
						if (this.props.onValueChange) {
							this.props.onValueChange(item);
						}
						this.setState({ value: item });
					}}
				/>
			);
		} else {
			return null;
		}
	}

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 1) {
			return 'No matches found';
		} else {
			return 'Enter text to search job roles';
		}
	};

	getOptionValue = option => option.value;

	getOptionLabel = option => `${option.text}`;
}
