import React from 'react';
import { testStore } from '../TestStore';
import Async from 'react-select/async';

export class TestsDropdown extends React.Component {
	state = {
		value: null
	};

	componentDidMount() {
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
	}

	render() {
		const { placeholder = 'Test', field = 'tests' } = this.props;

		return (
			<Async
				field={field}
				menuPosition={'fixed'}
				className="react-select"
				cacheOptions
				isMulti={this.props.isMulti}
				value={this.state.value}
				placeholder={placeholder}
				defaultOptions
				loadOptions={this.loadOptions}
				getOptionValue={option => option.id}
				getOptionLabel={option =>
					this.props.deduplicate
						? option.name
						: `${option.name} ${option.version ? `(${option.version})` : ''}`
				}
				noOptionsMessage={this.noOptionsMessage}
				onChange={items => {
					this.setState({ value: items });
					if (this.props.onValueChange) {
						this.props.onValueChange(items);
					}
				}}
				onFocus={() => {
					if (this.props.onFocus) {
						this.props.onFocus();
					}
				}}
			/>
		);
	}

	loadOptions = inputValue => {
		return new Promise(async resolve => {
			resolve(
				this.props.deduplicate
					? this.getDeduplicatedTests(inputValue)
					: this.getTests(inputValue)
			);
		});
	};

	getTests = async inputValue => {
		inputValue = inputValue.replace(/\W/g, '');
		const result = await testStore.getTests(inputValue);
		return result.data;
	};

	getDeduplicatedTests = async inputValue => {
		inputValue = inputValue.replace(/\W/g, '');
		const result = await testStore.getDeduplicatedTests(inputValue);
		return result.data;
	};

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 1) {
			return 'No matches found';
		} else {
			return 'Enter text to search Test';
		}
	};
}
