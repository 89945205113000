import { observable, reaction, action, computed, makeObservable } from 'mobx';
import { api } from '../api/api';
import localStorage from 'local-storage';
import { userStore } from '../../components/users/UserStore';
import { USER_ROLES } from './RolesAllowed';

export const TOKEN_KEY = 'pbookjwt';
export const PROFILE_KEY = 'pbookprofile';
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

class AuthStore {
	@observable token = localStorage ? localStorage.get(TOKEN_KEY) : null;
	@observable profile = null;
	@observable ready = false;

	constructor() {
		makeObservable(this);
		reaction(
			() => this.token,
			token => {
				if (token) {
					localStorage.set(TOKEN_KEY, token);
				} else {
					localStorage.clear(TOKEN_KEY);
				}
			}
		);
	}

	@action
	setToken(token, profile) {
		this.token = token;
		this.profile = profile;
	}

	@action
	setReady(value) {
		this.ready = value;
	}

	@action
	logout() {
		this.token = null;
		this.profile = null;
		this.ready = true;
	}

	@action
	setProfile(profile) {
		this.profile = profile;
		this.setReady(true);
	}

	@action
	async fetchLoggedUserProfile() {
		const result = await api.get('users/profile', null);
		return result;
	}

	@action
	async syncLoggedUserProfile() {
		const userProfileResponse = await this.fetchLoggedUserProfile();
		if (userProfileResponse.status === 'success') {
			const profile = userProfileResponse.data;
			this.setProfile(profile);
			return profile;
		}
		return;
	}

	initAuthentication() {
		if (!authStore.isUserLogged()) {
			authStore.setReady(true);
			return;
		}
		authStore.syncLoggedUserProfile().then(() => {
			if (authStore.profile) {
				userStore.updateLastAccess(authStore.profile.id);
			}
		});
	}

	isUserLogged() {
		return this.token !== null;
	}

	@computed
	get profileImage() {
		if (authStore.profile) {
			return authStore.profile.imageUrl;
		}
		return '';
	}

	@computed
	get fullName() {
		if (authStore.profile) {
			const { name } = authStore.profile;
			return `${name}`;
		}
		return '';
	}

	@computed
	get email() {
		if (authStore.profile) {
			return authStore.profile.email;
		}
		return '';
	}

	isAdmin() {
		return authStore.profile.role === USER_ROLES.ADMIN;
	}

	isManager() {
		return authStore.profile.role === USER_ROLES.MANAGER;
	}

	isRecruiter() {
		return authStore.profile.role === USER_ROLES.RECRUITER;
	}

	isCandidate() {
		return authStore.profile.role === USER_ROLES.CANDIDATE;
	}

	isUserDisabled(role) {
		return authStore.profile.disabled;
	}

	isUserInRole(role) {
		return authStore.profile.role === role;
	}
}

const authStore = new AuthStore();
export { authStore };
