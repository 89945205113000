import React from 'react';
import { candidatesStore } from '../CandidatesStore';
import { Workshifts } from '../../../components/timezones/Workshifts';
import moment from 'moment';
import { ModalComponent } from 'components/layout/modals/ModalComponent';

export class CandidateProfileExpectations extends ModalComponent {
	displayValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	render() {
		const candidate = this.props.candidate;

		const preferredJobRoles = (
			this.props.candidate.preferredJobRoles || []
		).map(item => {
			return { ...item.jobRole };
		});
		return (
			<div className="CandidateProfileExpectationsModal">
				<section className="modal-card-body">
					{this.props.renderInternalSectionButtons()}
					<div className="description-section">
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											What type of roles are you interested in?
										</label>
									</div>
									<div className="add-margin-top">
										{preferredJobRoles.map(jobRole => {
											return (
												<span
													key={jobRole.id}
													className="tag is-blue add-margin-right">
													{jobRole.name}
												</span>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											What are the technologies you would like to work with?
										</label>
									</div>
									<div className="add-margin-top">
										{(candidate.preferredSkills || []).map(item => {
											return (
												<span
													key={item.skill.id}
													className="tag is-blue add-margin-right">
													{item.skill.name}
												</span>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											Where do you prefer working from?
										</label>
									</div>
									{this.displayValueFromOptions(
										candidate.preferredWorkLocation,
										candidatesStore.preferredWorkLocationOptions
									)}
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											What type of positions are you interested in?
										</label>
									</div>
									<div className="add-margin-top">
										{(candidate.preferredPositionType || []).map(
											(positionType, index) => {
												return (
													<span key={index} className="tag is-blue">
														{this.displayValueFromOptions(
															positionType,
															candidatesStore.preferredPositionTypeOptions
														)}
													</span>
												);
											}
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											What is your time zone?
										</label>
									</div>
									<div className="add-margin-top">
										{candidate.timezone
											? candidate.timezone.text
											: candidate.preferredTimeZone}
									</div>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											Which workshift would you be willing to work in?
										</label>
									</div>
									<div className="add-margin-top">
										<Workshifts
											candidate={candidate}
											showViewerTimezone={true}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-6">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											Are you actively looking for a new job right now ?
										</label>
									</div>
									<div className="add-margin-top">
										{this.displayValueFromOptions(
											candidate.lookingForJob,
											candidatesStore.lookingForJobOptions
										)}
										{candidate.lookingForJobUpdatedAt ? (
											<i>
												{' '}
												(Last updated at{' '}
												{moment(candidate.lookingForJobUpdatedAt).format('lll')}
												)
											</i>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<footer className="modal-card-foot"></footer>
			</div>
		);
	}
}
