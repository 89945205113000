import React from 'react';
import { withRouter } from 'react-router-dom';
import { UserInvite } from './UserInvite';
import { userStore } from './UserStore';
import { UsersTable } from './UsersTable';
import { Dropdown } from '../layout/form-fields/inputs/Dropdown';
import { InputField } from '../layout/form-fields/inputs/InputField';
import { Pagination } from '../layout/pagination/Pagination';
import { UserEdit } from './UserEdit';
import { ListStore } from '../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { ListHeaderComponent } from '../layout/list/ListHeaderComponent';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { dialogStore } from '../layout/modals/DialogStore';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';

@rolesAllowed([USER_ROLES.ADMIN])
@withRouter
@observer
export class Users extends React.Component {
	constructor(props) {
		super(props);
		this.listStore = new ListStore(userStore, {
			entityName: 'User',
			createModalTitle: 'Invite User',
			createModal: <UserInvite handleSuccess={() => this.listStore.search()} />,
			editModal: item => (
				<UserEdit user={item} handleSuccess={() => this.listStore.search()} />
			)
		});
	}

	componentDidMount() {
		const resetQuery = this.preSetQuery();
		this.listStore.search(resetQuery);
	}

	preSetQuery() {
		this.listStore.store.setQuery('sort', [
			{ column: 'name', asc: true },
			{ column: 'email', asc: true, hideIndicator: true }
		]);
		this.listStore.store.setQuery(
			'pagination',
			this.listStore.store.defaultPagination
		);
		return true;
	}

	render() {
		return (
			<div className="Users container">
				<ListHeaderComponent
					title={`${this.listStore.modalsConfigs.entityName}s`}
					createActionTitle={this.listStore.modalsConfigs.createModalTitle}
					handleCreate={() => this.listStore.handleCreate()}
				/>
				<div className="columns">
					<div className="column is-4">
						<InputField
							className="has-text-left no-margin-bottom"
							field="search"
							autoFocus={true}
							type="search"
							placeholder="Name | Email"
							debounce={true}
							debounceTime={400}
							icon="fa-search"
							onValueChange={value => {
								userStore.setQuery('text', value);
								this.listStore.search();
							}}
						/>
					</div>
					<div className="column is-4">
						<Dropdown
							allowClear={true}
							placeholder="Status"
							onValueChange={value => {
								userStore.setQuery('status', value);
								this.listStore.search();
							}}
							options={['Active', 'Invited', 'Disabled']}
						/>
					</div>
					<div className="column is-4">
						<Dropdown
							allowClear={true}
							placeholder="Roles"
							onValueChange={value => {
								userStore.setQuery('role', value !== 'All Roles' ? value : '');
								this.listStore.search();
							}}
							options={[
								USER_ROLES.ADMIN,
								USER_ROLES.MANAGER,
								USER_ROLES.RECRUITER,
								USER_ROLES.CANDIDATE
							]}
						/>
					</div>
				</div>
				<div className="column no-padding add-margin-bottom">
					<Pagination
						loading={this.listStore.loading}
						pagination={userStore.query.pagination}
						onPaginationChange={this.listStore.handlePaginationChange}
					/>
				</div>
				<div className="columns add-margin-top">
					<div className="column is-12 table-wrapper">
						<UsersTable
							data={this.listStore.data}
							sort={this.listStore.store.query.sort}
							onSort={this.listStore.handleSort}
							onEdit={this.listStore.handleEdit}
							onDisable={async item =>
								await this.listStore.handleDisable(item, true)
							}
							onDelete={this.handleDelete}
							onInvite={user => {
								userStore.resendInvite(user.id).then(result => {
									notificationStore.pushNotification({
										type: notificationStore.SUCCESS_NOTIFICATION,
										title: 'Invite User',
										message: 'The user has been successfully invited.'
									});
									this.listStore.search();
								});
							}}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 bottom-pagination">
						<Pagination
							loading={this.listStore.loading}
							pagination={userStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
			</div>
		);
	}

	handleDelete = item => {
		dialogStore.openDialog(
			{
				title: 'Delete User',
				message: `Are you sure you want to delete ${item.name}?`,
				confirmLabel: 'Delete',
				confirmButtonClass: 'is-danger'
			},
			() => {
				this.listStore.deleteFromDialog(item);
			}
		);
	};
}
