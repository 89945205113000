import { withRouter } from 'react-router-dom';
import React from 'react';
import { InputField } from '../../layout/form-fields/inputs/InputField';
import { jobRoleStore } from './JobRoleStore';
import { JobRoleEdit } from './JobRoleEdit';
import { JobRoleCreate } from './JobRoleCreate';
import { ListStore } from '../../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { Loader } from '../../layout/misc/Loader';
import { Icon } from '../../layout/misc/Icon';
import {
	rolesAllowed,
	USER_ROLES
} from '../../../shared/security/RolesAllowed';

@rolesAllowed([USER_ROLES.ADMIN])
@withRouter
@observer
export class JobRoles extends React.Component {
	state = {
		data: [],
		filterText: '',
		loading: true
	};

	constructor(props) {
		super(props);
		this.listStore = new ListStore(jobRoleStore, {
			entityName: 'Job Role',
			createModal: (
				<JobRoleCreate
					handleSuccess={() => {
						this.search();
						this.setState({ filterText: '' });
					}}
				/>
			),
			editModal: item => (
				<JobRoleEdit
					jobRole={item}
					handleSuccess={() => {
						this.search();
						this.setState({ filterText: '' });
					}}
				/>
			)
		});
	}

	componentDidMount() {
		this.search();
	}

	search(value) {
		value = value ? value : '';
		this.setState({ loading: true });
		jobRoleStore.getJobRoles(value).then(result => {
			if (result.status === 'success' && result.data) {
				this.setState({ data: result.data, loading: false });
			}
		});
	}

	render() {
		return (
			<div className="JobRoles container">
				<div className="columns">
					<div className="column is-10">
						<InputField
							className="has-text-left"
							field="title"
							type="search"
							icon="fa-search"
							placeholder="Filter"
							value={this.state.filterText}
							onValueChange={value => {
								this.search(value);
								this.setState({ filterText: value });
							}}
						/>
					</div>
					<div className="column is-2">
						<button
							className="button is-primary is-rounded is-big"
							onClick={() => this.listStore.handleCreate()}>
							{`Create ${this.listStore.modalsConfigs.entityName}`}
						</button>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<table className="table is-fullwidth is-bordered is-hoverable">
							<thead>
								<tr>
									<th>Name</th>
									<th style={{ width: '180px' }} />
								</tr>
							</thead>
							<tbody>
								{this.state.data.map(row => {
									return (
										<tr key={row.id}>
											<td className="has-text-weight-bold">{row.name}</td>
											<td className="actions-column">
												<button
													className="button is-rounded is-circle is-small is-text"
													onClick={() => this.listStore.handleEdit(row)}>
													<Icon className="is-small" icon="fa-pencil-alt" />
												</button>
												<button
													className="button is-rounded is-circle is-small is-text"
													onClick={() =>
														this.listStore.handleDelete(
															row,
															this.onDeleteSuccess,
															true
														)
													}>
													<Icon className="is-small" icon="fa-trash" />
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						{this.state.loading ? <Loader type="ripple" /> : null}
					</div>
				</div>
			</div>
		);
	}

	onDeleteSuccess = item => {
		this.search();
		this.setState({ filterText: '' });
	};
}
