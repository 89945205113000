const fullCircle = 1;
const emptyCircle = 0;

const createEmptyCircle = () => ({ raw: emptyCircle, percent: '0%' });

const createFullCircle = () => ({ raw: fullCircle, percent: '100%' });

const createCircleWithPercentageFilled = roundedOneDecimalPoint => ({
	raw: roundedOneDecimalPoint,
	percent: roundedOneDecimalPoint * 100 + '%'
});

export {
	createEmptyCircle,
	createFullCircle,
	createCircleWithPercentageFilled,
	fullCircle,
	emptyCircle
};
