import React from 'react';
import Async from 'react-select/async';
import { scorecardTemplateStore } from '../ScorecardTemplateStore';

export class ScorecardTemplateDropdown extends React.Component {
	render() {
		return (
			<Async
				field={this.props.field || 'scorecardTemplates'}
				menuPosition={'fixed'}
				className="react-select"
				cacheOptions
				isMulti
				value={this.props.value}
				placeholder={'Scorecard Templates'}
				defaultOptions
				loadOptions={this.loadOptions}
				getOptionValue={option => option.id}
				getOptionLabel={option => option.name}
				noOptionsMessage={this.noOptionsMessage}
				onChange={items => {
					if (this.props.onValueChange) {
						this.props.onValueChange(items);
					}
				}}
				onFocus={() => {
					if (this.props.onFocus) {
						this.props.onFocus();
					}
				}}
			/>
		);
	}

	loadOptions = inputValue => {
		return new Promise(resolve => {
			resolve(this.getScorecardTemplates(inputValue));
		});
	};

	getScorecardTemplates = async inputValue => {
		inputValue = inputValue.replace(/\W/g, '');
		const result =
			await scorecardTemplateStore.getScorecardTemplates(inputValue);
		return result.data || [];
	};

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 1) {
			return 'No matches found';
		} else {
			return 'Enter text to search scorecard templates';
		}
	};
}
