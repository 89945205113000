import React from 'react';

function CandidatesTableFilterList({
	title,
	onClose,
	clearText,
	onClear,
	children
}) {
	return (
		<>
			<div className="filter-list-container">
				<div className="filter-list-header">
					<div className="is-flex is-justify-content-space-between">
						<h3>{title}</h3>
						<button class="button" onClick={onClose}>
							<span class="icon is-small">
								<i class="fas fa-xmark" aria-hidden="true"></i>
							</span>
						</button>
					</div>

					{onClear ? (
						<button className="clear-button" onClick={onClear}>
							{clearText}
						</button>
					) : null}
				</div>
				{children}
			</div>
		</>
	);
}

export default CandidatesTableFilterList;
