import React from 'react';
import { BaseHeader } from '../header/BaseHeader';

export class Farewell extends React.Component {
	componentDidMount() {
		document.body.scrollTo(0, 0);
	}

	render() {
		return (
			<div className="FarewellWrapper is-flex is-flex-direction-column is-justify-content-center">
				<BaseHeader />
				<div className="farewell-content is-flex is-flex-direction-column is-align-items-center">
					<p className="title is-3">We're sorry to see you go!</p>
					<p className="subtitle is-5">
						Your account has been deactivated and will be permanently deleted
						within 60 days. If you ever want to sign up again, please write us
						at{' '}
						<strong>
							<a href="mailto:talent.candidates@prediktive.com">
								talent.candidates@prediktive.com
							</a>
						</strong>
					</p>
				</div>
			</div>
		);
	}
}
