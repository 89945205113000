import { action, observable, makeObservable } from 'mobx';
import { candidatesStore } from '../CandidatesStore';
import { api } from '../../../shared/api/api';

export class CandidateProfileStore {
	@observable candidate;
	endpointRootURL = 'candidateProfiles';

	constructor() {
		makeObservable(this);
	}

	@action
	setCandidate(candidate) {
		this.candidate = candidate;
	}

	@action
	getCandidateCopy() {
		return { ...this.candidate };
	}

	@action
	async reloadCandidate() {
		const result = await candidatesStore.get(this.candidate.id);
		this.setCandidate(result.data);
	}

	@action
	reset() {
		this.candidate = null;
	}

	@action
	async updateProfile(id, request) {
		const result = await api.put(`${this.endpointRootURL}/${id}`, request);
		return result;
	}

	@action
	async publishProfile(id, request) {
		const result = await api.post(`${this.endpointRootURL}/${id}`, request);
		return result;
	}

	@action
	async updateExperience(id, request) {
		const result = await api.put(
			`${this.endpointRootURL}/experience/${id}`,
			request
		);
		return result;
	}

	@action
	async updateEducation(id, request) {
		const result = await api.put(
			`${this.endpointRootURL}/education/${id}`,
			request
		);
		return result;
	}

	@action
	async updateCertification(id, request) {
		const result = await api.put(
			`${this.endpointRootURL}/certification/${id}`,
			request
		);
		return result;
	}

	@action
	async updateTestResult(id, request) {
		const result = await api.put(
			`${this.endpointRootURL}/test-results/${id}`,
			request
		);
		return result;
	}

	@action
	async getPublicProfile(token) {
		return await api.get(`/profiles?token=${token}`);
	}

	@action
	async hireCandidate(id, request) {
		const result = await api.post(`profiles/${id}/hire`, request);
		return result;
	}

	@action
	async requestCandidate(request) {
		return await api.post(`${this.endpointRootURL}/request`, request);
	}
}

const candidateProfileStore = new CandidateProfileStore();
export { candidateProfileStore };
