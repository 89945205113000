import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormField } from '../layout/form-fields/FormField';
import { Dropdown } from '../layout/form-fields/inputs/Dropdown';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { userStore } from './UserStore';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { Switch } from '../layout/form-fields/inputs/Switch';
import { authStore } from '../../shared/security/AuthStore';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	email: Yup.string().required().email().label('Email')
});

export class UserEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					email: this.props.user.email,
					name: this.props.user.name || '',
					role: this.props.user.role,
					disabled: this.props.user.disabled
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.saveUser(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'UserEdit')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							disabled={true}
							className="has-text-left"
							label="Email"
							field="email"
							type="text"
							placeholder="email@prediktive.com"
							icon="fa-envelope"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-10">
						<FormField
							required={true}
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
						/>
					</div>
					<div
						className={`column is-2 ${
							this.props.user.id === authStore.profile.id ? 'is-hidden' : ''
						}`}>
						<div className="field has-text-left">
							<label className="subtitle is-7">
								{!formikBag.values.disabled ? 'Enabled' : 'Disabled'}
							</label>
							<div className="control">
								<Switch
									id="user-disabled"
									value={!formikBag.values.disabled}
									onValueChange={value => {
										formikBag.setFieldValue('disabled', !value);
										return true;
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-10">
						<div className="field has-text-left">
							<label className="subtitle is-7">Role</label>
							<div className="control">
								<Dropdown
									disabled={this.props.user.id === authStore.profile.id}
									enableFixedMode={true}
									value={this.props.user.role}
									onValueChange={value =>
										formikBag.setFieldValue('role', value)
									}
									options={['Admin', 'Manager', 'Recruiter']}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	async saveUser(values) {
		const user = {
			email: values.email,
			name: values.name,
			role: values.role,
			disabled: values.disabled,
			id: this.props.user.id
		};
		userStore.update(this.props.user.id, { user: user }).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'User Updated',
					'The user has been successfully updated.'
				);
				this.closeWithSuccess();
			} else {
				notificationStore.pushErrorNotification(
					'',
					'Failed to update the user.'
				);
			}
		});
	}
}
