import { action, observable, makeObservable } from 'mobx';
import { api } from '../api/api';
import { notificationStore } from '../../components/layout/notifications/NotificationStore';
import localStorage from 'local-storage';

export class EntityStore {
	defaultPagination = {
		page: 1,
		limit: localStorage.get('paginationLimit') || 50
	};
	entityPluralEndpoint = null;

	constructor(entityPluralEndpoint) {
		makeObservable(this);
		this.entityPluralEndpoint = entityPluralEndpoint;
	}

	@observable.ref query = { pagination: this.defaultPagination };

	@action.bound
	setQuery(key, value) {
		this.query[key] = value;
	}

	@action
	resetQuery() {
		this.query = { sort: null, pagination: this.defaultPagination };
	}

	@action
	getQuery(key) {
		return this.query[key];
	}

	@action
	async getAll() {
		const result = await api.get(this.entityPluralEndpoint);
		return result;
	}

	@action
	async search(reset = false) {
		this.query.pagination.limit =
			localStorage.get('paginationLimit') || this.query.pagination.limit;
		if (reset) {
			this.query = { sort: null, pagination: this.defaultPagination };
		}
		const result = await api.post(`${this.entityPluralEndpoint}/query`, {
			query: this.query
		});
		// on fail display error notification
		if (result.status === 'fail') {
			console.error(result.message || result.error);
			notificationStore.pushErrorNotification(
				'Search Error',
				'An error occurred during a search operation'
			);
			return { data: { rows: [] } };
		} else {
			this.setQuery('pagination', result.data.pagination);
			return result;
		}
	}

	@action
	async create(request) {
		const result = await api.post(this.entityPluralEndpoint, request);
		return result;
	}

	@action
	async delete(id) {
		const result = await api.delete(`${this.entityPluralEndpoint}/${id}`);
		return result;
	}

	@action
	async update(id, request) {
		const result = await api.put(`${this.entityPluralEndpoint}/${id}`, request);
		return result;
	}

	@action
	async get(id) {
		const result = await api.get(`${this.entityPluralEndpoint}/${id}`);
		return result;
	}
}
