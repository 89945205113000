import React from 'react';
import AnchorIcons from './AnchorIcons';
import { CandidateProfileImage } from './profile-image/CandidateProfileImage';

const CandidateProfileShortBio = ({
	candidate,
	handleAnchorClick,
	activeSection,
	isIntersecting
}) => {
	return (
		<div
			className={`short-bio-wrapper hidden ${isIntersecting ? '' : 'visible'}`}>
			<div className="short-bio">
				<CandidateProfileImage candidate={candidate} />
				<div className="name-container">
					<h2 onClick={e => handleAnchorClick(e, 'header')}>
						{candidate.name}
					</h2>
					<AnchorIcons
						handleAnchorClick={handleAnchorClick}
						activeSection={activeSection}
					/>
				</div>
			</div>
		</div>
	);
};

export default CandidateProfileShortBio;
