import { NavLink, withRouter } from 'react-router-dom';
import React from 'react';
import moment from 'moment-timezone';
import { scorecardTemplateStore } from './ScorecardTemplateStore';
import { observer } from 'mobx-react';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';
import { Loader } from '../layout/misc/Loader';
import { modalStore } from '../layout/modals/ModalStore';
import { ScorecardTemplateSectionForm } from './components/ScorecardTemplateSectionForm';
import { SectionTable } from './components/SectionTable';
import { Switch } from '../layout/form-fields/inputs/Switch';
import { InputField } from '../layout/form-fields/inputs/InputField';
import { Tooltip } from '../layout/tooltip/Tooltip';
import { dialogStore } from '../layout/modals/DialogStore';
import { ScorecardTemplatePublishModal } from './components/ScorecardTemplatePublishModal';
import { notificationStore } from '../layout/notifications/NotificationStore';
import clsx from 'clsx';
import { Icon } from '../layout/misc/Icon';
import { Dropdown } from 'components/layout/form-fields/inputs/Dropdown';

@rolesAllowed([USER_ROLES.ADMIN])
@withRouter
@observer
export class ScorecardTemplateForm extends React.Component {
	state = {
		data: null,
		loading: true,
		sorting: false,
		draggingId: null,
		percentage: 0,
		percentageSections: {}
	};

	constructor(props) {
		super(props);

		this.store = scorecardTemplateStore;
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const id = this.props.match.params.id;
		const { status, data } = await this.store.get(id);

		await this.store.getScorecardOptions('');

		// FIXME:For some reason even tough we are setting order
		// 	the dependency still comes unsorted
		data.scorecardTemplateSections = data.scorecardTemplateSections.sort(
			(a, b) => {
				return a.ordinal > b.ordinal ? 1 : -1;
			}
		);

		this.setState({
			data: data,
			error: status !== 'success',
			loading: false
		});
	}

	handleDrag(dragEvent, id) {
		this.setState({
			draggingId: id
		});
	}

	handleDrop(dragEvent, id) {
		const templateId = this.props.match.params.id;
		const { draggingId } = this.state;

		scorecardTemplateStore
			.swapSections(templateId, draggingId, id)
			.then(() => {
				return this.setState({
					draggingId: null
				});
			})
			.then(() => {
				this.load();
			});
	}

	handleSectionChange(sectionData, next) {
		const { percentageSections } = this.state;

		// Update that section percentage
		percentageSections[sectionData.id] =
			sectionData.scorecardTemplateItems.reduce((total, item) => {
				return total + Number(item.weight);
			}, 0);

		// Update global percentage
		const globalPercentage = Object.values(percentageSections).reduce(
			(total, value) => total + value,
			0
		);

		this.setState(
			{
				percentageSections: percentageSections,
				percentage: globalPercentage || 0
			},
			next
		);
	}

	handleSectionDelete(sectionData) {
		this.handleSectionChange(
			{
				id: sectionData.id,
				scorecardTemplateItems: []
			},
			() => {
				this.load();
			}
		);
	}

	handlePublish() {
		const templateId = this.props.match.params.id;

		modalStore.openModal(
			{
				title: 'Publish Scorecard Template',
				saveButtonLabel: 'Publish'
			},
			<ScorecardTemplatePublishModal
				templateId={templateId}
				handleSuccess={() => {
					this.load();
				}}
			/>
		);
	}

	handleUnpublish() {
		const templateId = this.props.match.params.id;

		dialogStore.openDialog(
			{
				title: 'Unpublish Scorecard Template',
				message:
					'An unpublished scorecard template cannot be used to create new candidate scorecards. Are you sure you want to continue?',
				confirmLabel: 'Unpublish',
				confirmButtonClass: 'is-danger'
			},
			() => {
				scorecardTemplateStore.unpublish(templateId).then(result => {
					if (result.status === 'fail') {
						notificationStore.pushNotification({
							type: notificationStore.ERROR_NOTIFICATION,
							title: 'Failed to unpublish this Scorecard Template',
							message: result.message
						});
					} else {
						this.load();
					}
				});
			}
		);
	}

	generateColorOptionsIcons(colorOptions) {
		return colorOptions.map(colorOption => ({
			...colorOption,
			text: (
				<>
					{colorOption.text}{' '}
					<div
						className="color-icon"
						style={{
							backgroundColor: this.store.colorOptionsEnum[colorOption.value]
						}}></div>
				</>
			)
		}));
	}

	render() {
		const { data, percentage, loading, sorting, error } = this.state;
		const hasSections =
			data &&
			data.scorecardTemplateSections &&
			data &&
			data.scorecardTemplateSections.length > 0;
		const canSort = hasSections && data.scorecardTemplateSections.length > 1;
		const isDraft = data && data.status === 'draft';
		const inUse = data && data.usageCount > 0;

		const percentageColorClass =
			percentage < 100 ? 'below' : percentage > 100 ? 'above' : 'exact';

		if (loading) {
			return <Loader />;
		}

		if (error) {
			return 'An error has occurred.';
		}

		return (
			<div className="ScorecardTemplateForm container">
				<div className="level ListHeader">
					<div className="level-left">
						<h1 className="title">
							Edit Scorecard Template
							<br />
							<span className="ScorecardStatusLabel">
								{isDraft
									? 'Draft'
									: 'Published at ' + moment(data.publishedAt).format('lll')}
							</span>
						</h1>
					</div>
					<div className="level-right">
						<nav className="breadcrumb">
							<ul>
								<li>
									<NavLink
										className="navigation-link"
										to="/scorecard-templates">
										Scorecard Templates
									</NavLink>
								</li>
								<li className="is-active">
									<NavLink
										className="navigation-link"
										to="/scorecard-templates">
										Edit
									</NavLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className="TemplateHeader columns is-align-items-center">
					<div className="column is-3">
						<InputField
							disabled={!isDraft}
							className="has-text-left"
							field="text"
							type="text"
							debounce={true}
							debounceTime={400}
							placeholder="Name"
							label="Name"
							defaultValue={data.name}
							onValueChange={value => {
								this.setState(
									{
										data: { ...data, name: value }
									},
									() => {
										this.store.update(data.id, {
											scorecardTemplate: {
												name: value
											}
										});
									}
								);
							}}
						/>
					</div>
					<div className="column is-3">
						<div className="control">
							<Switch
								className="config-switch"
								id="disabled"
								value={!data.disabled}
								activeLabel="Enabled"
								inactiveLabel="disabled"
								onValueChange={enabled => {
									this.setState(
										{
											data: { ...data, disabled: !enabled }
										},
										() => {
											this.store.update(data.id, {
												scorecardTemplate: {
													disabled: !enabled
												}
											});
										}
									);
								}}
							/>
						</div>
					</div>
					<div className="column is-3" />
					<div className="column is-3 has-text-right ">
						<label htmlFor="disabled" className="is-6">
							Total Weight
						</label>
						<div className="control">
							<span
								className={`title is-1 TemplatePercentage TemplatePercentage--${percentageColorClass}`}>
								{percentage}%
							</span>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-2 py-0">
						<div className="field has-text-left">
							<label className="subtitle is-7">Type</label>
							<Dropdown
								enableFixedMode={true}
								value={data.type}
								onValueChange={value => {
									this.setState(
										{
											data: { ...data, type: value }
										},
										() => {
											this.store.update(data.id, {
												scorecardTemplate: {
													type: value
												}
											});
										}
									);
								}}
								options={this.store.typeOptions}
								optionValue={'value'}
							/>
						</div>
					</div>
				</div>
				<div className="SectionsList columns">
					<div className="column is-12">
						<div className="columns is-align-items-flex-end">
							<div className="column is-8 is-size-5">Sections and Items</div>
							<div className="column is-4">
								<div className="level gap-4">
									<div className="level-left" />
									<div
										className={clsx('level-right', {
											'is-hidden': !isDraft
										})}>
										<button
											disabled={!isDraft}
											className="button is-primary level-item"
											onClick={() => {
												modalStore.openModal(
													{
														title: 'Add Section',
														saveButtonLabel: 'Save'
													},
													<ScorecardTemplateSectionForm
														templateId={data.id}
														handleSuccess={result => {
															this.load();
														}}
													/>
												);
											}}>
											Add Section
										</button>
										<Tooltip
											className="level-item"
											position="top"
											disabled={canSort}
											message="You need 2 or more sections to sort them.">
											<button
												disabled={!canSort || !isDraft}
												className="button"
												onClick={() => {
													this.setState({
														sorting: !sorting
													});
												}}>
												{sorting ? 'Done Sorting' : 'Sort Sections'}
											</button>
										</Tooltip>
									</div>
								</div>
							</div>
						</div>
						{data.scorecardTemplateSections && (
							<div className="SectionTableList">
								{data.scorecardTemplateSections.map(section => (
									<SectionTable
										disabled={!isDraft}
										templateId={data.id}
										sectionId={section.id}
										key={section.id}
										draggable={sorting}
										position={data.ordinal}
										handleDrag={(event, id) => this.handleDrag(event, id)}
										handleDrop={(event, id) => this.handleDrop(event, id)}
										onRemove={section => this.handleSectionDelete(section)}
										onChange={section => this.handleSectionChange(section)}
									/>
								))}
							</div>
						)}
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 is-flex is-flex-direction-row is-justify-content-end is-align-items-center">
						{isDraft ? (
							<button
								className="button is-primary is-small"
								onClick={() => this.handlePublish()}>
								Publish
							</button>
						) : (
							<>
								<Tooltip
									position="top"
									disabled={!inUse}
									message="This Scorecard Template is currently in use.">
									{inUse && (
										<div className="p-2">
											<Icon icon="fa-info-circle" />
										</div>
									)}
								</Tooltip>
								<button
									disabled={inUse}
									className="button is-danger is-small"
									onClick={() => this.handleUnpublish()}>
									Unpublish
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		);
	}
}
