import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { candidateProfileStore } from '../CandidateProfileStore';
import { Loader } from '../../../components/layout/misc/Loader';
import { candidatesStore } from '../CandidatesStore';
import { CandidateProfileExpectationsEdit } from '../expectations/CandidateProfileExpectationsEdit';
import { CandidateProfileAboutEdit } from '../about/CandidateProfileAboutEdit';
import { CandidateProfileContactInfoEdit } from '../contact-info/CandidateProfileContactInfoEdit';
import { CandidateProfileWizardSuccessful } from './CandidateProfileWizardSuccessful';
import { BaseHeader } from '../../../components/layout/header/BaseHeader';

@withRouter
@observer
export class CandidateProfileWizard extends React.Component {
	state = {
		currentStep: 1
	};
	componentDidMount() {
		void this.loadCandidate();
	}

	componentWillUnmount() {
		candidateProfileStore.reset();
	}

	setCurrentStep(step) {
		this.setState({ currentStep: step });
		document.body.scrollTo(0, 0);
	}

	async loadCandidate() {
		const result = await candidatesStore.get();
		candidateProfileStore.setCandidate(result.data);
		this.setCurrentStep(candidateProfileStore.candidate.wizardCurrentStep);
	}

	render() {
		const { FIRST_STEP, SECOND_STEP, THIRD_STEP, FOURTH_STEP } =
			candidatesStore.wizardSteps;
		if (candidateProfileStore.candidate) {
			return (
				<Fragment>
					<BaseHeader />
					<div className="wizard maxWidth has-height-auto">
						<ul className="progressbar">
							<li
								className={this.state.currentStep >= FIRST_STEP ? 'active' : ''}
							/>
							<li
								className={
									this.state.currentStep >= SECOND_STEP ? 'active' : ''
								}
							/>
							<li
								className={this.state.currentStep >= THIRD_STEP ? 'active' : ''}
							/>
							<li
								className={
									this.state.currentStep >= FOURTH_STEP ? 'active' : ''
								}
							/>
						</ul>
						{this.state.currentStep === FIRST_STEP ? (
							<CandidateProfileExpectationsEdit
								candidate={candidateProfileStore.candidate}
								isWizard={true}
								handleSuccess={async () => {
									await candidateProfileStore.reloadCandidate();
									this.setCurrentStep(SECOND_STEP);
								}}
							/>
						) : null}
						{this.state.currentStep === SECOND_STEP ? (
							<CandidateProfileAboutEdit
								candidate={candidateProfileStore.candidate}
								isWizard={true}
								handleSuccess={() => {
									candidateProfileStore.reloadCandidate();
									this.setCurrentStep(THIRD_STEP);
								}}
								handleBack={() => {
									this.setCurrentStep(FIRST_STEP);
								}}
							/>
						) : null}
						{this.state.currentStep === THIRD_STEP ? (
							<CandidateProfileContactInfoEdit
								candidate={candidateProfileStore.candidate}
								isWizard={true}
								handleSuccess={() => {
									candidateProfileStore.reloadCandidate();
									this.setCurrentStep(FOURTH_STEP);
								}}
								handleBack={() => {
									this.setCurrentStep(SECOND_STEP);
								}}
							/>
						) : null}
						{this.state.currentStep === FOURTH_STEP ? (
							<CandidateProfileWizardSuccessful />
						) : null}
					</div>
				</Fragment>
			);
		} else {
			return <Loader type="page-loader" />;
		}
	}
}
