import React from 'react';
import { Icon } from '../../../layout/misc/Icon';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

@withRouter
@observer
export class CandidateChangelogTable extends React.Component {
	state = {
		currentTime: moment()
	};

	render() {
		return (
			<table className="CandidateChangelogTable table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th
							className="is-sortable is-hidden-mobile"
							style={{ width: '40%' }}>
							Description
						</th>
						<th
							className="is-sortable is-hidden-mobile"
							style={{ width: '20%' }}>
							Performed by{' '}
						</th>
						<th
							style={{ width: '40%' }}
							onClick={() => this.handleHeaderClick('createdAt')}>
							Date {this.isColumnSorted('createdAt')}
						</th>
					</tr>
				</thead>
				<tbody>
					{(this.props.data || []).map(item => {
						return (
							<tr key={item.id} className={item.disabled ? 'disabled' : ''}>
								<td className="has-text-weight-bold is-hidden-mobile">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<span>{item.description}</span>
										</div>
									</div>
								</td>

								<td className="has-text-weight-bold is-hidden-mobile">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<span>{item.user ? item.user.name : 'N/A'}</span>
										</div>
									</div>
								</td>
								<td className="has-text-weight-bold is-hidden-mobile">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<span>
												{moment(item.createdAt).format('lll')} (UTC
												{moment.tz(moment.tz.guess()).format('Z')}{' '}
												{moment.tz.guess()})
											</span>
										</div>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	isColumnSorted(column) {
		if (this.props.sort && this.props.sort.column === column) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}
}
