import { ModalComponent } from 'components/layout/modals/ModalComponent';
import React from 'react';

export class CandidateProfileContactInfo extends ModalComponent {
	displayValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	render() {
		const candidate = this.props.candidate;
		return (
			<div className="CandidateProfileExpectationsModal">
				<section className="modal-card-body">
					{this.props.renderInternalSectionButtons()}
					<div className={'description-section educattion'}>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">Full name</label>
									</div>
									{candidate.name}
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">Contact Email</label>
									</div>
									{candidate.preferredEmail || candidate.email}
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">Location</label>
									</div>
									{`${candidate.location} / ${
										candidate.country ? candidate.country.name : ''
									}`}
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">Linkedin profile</label>
									</div>
									<a
										href={candidate.linkedinUrl}
										rel="noopener noreferrer"
										target="_blank">
										{' '}
										{candidate.linkedinUrl}
									</a>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">Phone Number</label>
									</div>
									{candidate.phoneNumber}
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											Preferred Contact Methods
										</label>
									</div>
									<div className="add-margin-top">
										{(candidate.preferredContactMethod || []).map(
											contactMethod => {
												return (
													<span
														key={contactMethod}
														className="tag is-blue add-margin-right">
														{contactMethod}
													</span>
												);
											}
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="columns">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">Recruiter</label>
									</div>
									<div className="add-margin-top">
										<span>
											{candidate.referrerRecruiter
												? candidate.referrerRecruiter.name
												: 'N/A'}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<footer className="modal-card-foot"></footer>
			</div>
		);
	}
}
