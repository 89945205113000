import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
	isUserInRoles,
	rolesAllowed,
	USER_ROLES
} from '../../shared/security/RolesAllowed';

const settingsMap = [
	{
		section: 'countries',
		label: 'Country Settings',
		icon: 'fas fa-flag',
		rolesAllowed: [USER_ROLES.ADMIN]
	},
	{
		section: 'technologies',
		label: 'Technologies',
		icon: 'fas fa-clipboard-list',
		rolesAllowed: [USER_ROLES.ADMIN]
	},
	{
		section: 'tags',
		label: 'Tags',
		icon: 'fas fa-tag',
		rolesAllowed: [USER_ROLES.ADMIN]
	},
	{
		section: 'job-roles',
		label: 'Job Roles',
		icon: 'fas fa-user-tag',
		rolesAllowed: [USER_ROLES.ADMIN]
	},
	{
		section: 'expertise-areas',
		label: 'Expertise Areas',
		icon: 'fas fa-flask',
		rolesAllowed: [USER_ROLES.ADMIN]
	},
	{
		section: 'recruiters',
		label: 'Recruiters',
		icon: 'fas fa-user-friends',
		rolesAllowed: [USER_ROLES.ADMIN, USER_ROLES.MANAGER]
	},
	{
		section: 'currencies',
		label: 'Currencies',
		icon: 'fas fa-dollar-sign',
		rolesAllowed: [USER_ROLES.ADMIN]
	}
];

@rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER])
@withRouter
export class Settings extends React.Component {
	render() {
		return (
			<div className="Settings container">
				<Fragment>
					<div className="level ListHeader">
						<div className="level-left">
							<h1 className="title">Settings</h1>
						</div>
						<div className="level-right">
							{this.isSubSection() ? (
								<nav className="breadcrumb">
									<ul>
										{this.getPaths().map((item, index) => {
											return (
												<li
													key={index}
													className={
														index === this.getPaths().length - 1
															? 'is-active'
															: ''
													}>
													<NavLink className="navigation-link" to={`/${item}`}>
														{item}
													</NavLink>
												</li>
											);
										})}
									</ul>
								</nav>
							) : null}
						</div>
					</div>
				</Fragment>
				{!this.isSubSection() ? (
					<div className="columns is-multiline is-variable is-2">
						{settingsMap
							.filter(item => isUserInRoles(item.rolesAllowed))
							.map(item => (
								<div
									key={item.section}
									className="column is-half-tablet is-one-third-desktop is-one-quarter-widescreen">
									<div
										className="card"
										onClick={() => this.handleSectionClick(item.section)}>
										<div className="card-content is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
											{item.icon && (
												<span className="is-size-2 mr-4">
													<i className={item.icon} />
												</span>
											)}
											<p className="is-size-4 is-flex-grow-1 has-text-centered">
												{item.label}
											</p>
										</div>
									</div>
								</div>
							))}
					</div>
				) : (
					<div className="section-divider" />
				)}
			</div>
		);
	}

	isSubSection() {
		return this.props.location.pathname.replace('/', '').split('/').length >= 2;
	}

	getPaths() {
		return this.props.location.pathname.replace('/', '').split('/');
	}

	handleSectionClick(section) {
		this.props.history.push(`/settings/${section}`);
	}
}
