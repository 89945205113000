import { action, makeObservable } from 'mobx';
import { api } from '../../shared/api/api';
import { EntityStore } from '../../shared/stores/EntityStore';

class TestStore extends EntityStore {
	constructor() {
		super('tests');
		makeObservable(this);
	}

	@action
	async getTests(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}

	@action
	async getDeduplicatedTests(query) {
		const url = this.entityPluralEndpoint + '/deduplicate?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}

	@action
	async importTestsFromFile(testsFile) {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		const formData = new FormData();
		formData.append('file', testsFile);
		const result = await api.post(
			`${this.entityPluralEndpoint}/import`,
			formData,
			config
		);
		return result;
	}
}

export const testStore = new TestStore();
