import React, { Fragment } from 'react';
import { FieldRequiredIndicator } from './FieldRequiredIndicator';

export class ConditionalContent extends React.Component {
	state = {
		showDropdown: null
	};

	handleShowDropdown() {
		this.setState({ showDropdown: true });
		this.props.onShow && this.props.onShow();
	}

	handleHideDropdown() {
		this.setState({ showDropdown: false });
		this.props.onHide && this.props.onHide();
	}

	componentDidMount() {
		if (this.props.shouldShowContent) {
			this.handleShowDropdown();
		}
	}

	render() {
		return (
			<Fragment>
				<div className="conditional-content">
					<div className="columns">
						<div className="column is-fullwidth flex-top">
							<label className="title is-5 has-text-weight-bold is-pulled-left add-margin-right">
								{this.props.label}{' '}
								{this.props.required ? <FieldRequiredIndicator /> : null}
								<br />
								<label className="title is-6">
									{this.props.extraInfoLabel}
								</label>
							</label>
							<div className="container-radio-right">
								<label className="radio has-text-centered radio-right">
									<input
										type="radio"
										name={`${this.props.entityName}-answer`}
										value={'no'}
										onChange={() => this.handleHideDropdown()}
										defaultChecked={!this.props.shouldShowContent}
										disabled={this.props.shouldShowContent}
									/>
									<br />
									<label>No</label>
								</label>
								<label className="radio has-text-centered radio-right">
									<input
										type="radio"
										name={`${this.props.entityName}-answer`}
										value={'yes'}
										onChange={() => this.handleShowDropdown()}
										defaultChecked={this.props.shouldShowContent}
										disabled={this.props.shouldShowContent}
									/>
									<br />
									<label>Yes</label>
								</label>
							</div>
						</div>
					</div>
					{this.state.showDropdown && this.props.content
						? this.props.content()
						: null}
				</div>
			</Fragment>
		);
	}
}
