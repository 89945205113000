import React from 'react';

export class Loader extends React.Component {
	/* eslint-disable indent */
	render() {
		switch (this.props.type) {
			case 'page-loader':
				return (
					<div className={`lds-page-loader ${this.props.className || ''}`}>
						<span>Loading</span>
						<div className="lds-ripple">
							<div />
							<div />
						</div>
					</div>
				);
			case 'ripple':
				return (
					<div className={`lds-ripple ${this.props.className || ''}`}>
						<div />
						<div />
					</div>
				);
			default:
				return (
					<div className={`lds-ring ${this.props.className || ''}`}>
						<div />
						<div />
						<div />
						<div />
					</div>
				);
		}
	}
}
