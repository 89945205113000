import { action, observable, makeObservable } from 'mobx';
import { api } from '../../../../shared/api/api';
import { notificationStore } from '../../../layout/notifications/NotificationStore';

export const candidateHiringOption = {
	YES: 'yes',
	NO: 'no',
	MAYBE: 'maybe'
};

export const candidateSeniorityOption = {
	JUNIOR: 'junior',
	JUNIOR_PLUS: 'junior_plus',
	MID_LEVEL: 'mid_level',
	MID_LEVEL_PLUS: 'mid_level_plus',
	SENIOR: 'senior',
	SENIOR_PLUS: 'senior_plus',
	LEAD: 'lead'
};

export const candidateSeniorityOptionLabel = {
	[candidateSeniorityOption.JUNIOR]: 'Junior',
	[candidateSeniorityOption.JUNIOR_PLUS]: 'Junior +',
	[candidateSeniorityOption.MID_LEVEL]: 'Mid Level',
	[candidateSeniorityOption.MID_LEVEL_PLUS]: 'Mid Level +',
	[candidateSeniorityOption.SENIOR]: 'Senior',
	[candidateSeniorityOption.SENIOR_PLUS]: 'Senior +',
	[candidateSeniorityOption.LEAD]: 'Lead'
};

export const candidateHiringOptionLabel = {
	[candidateHiringOption.YES]: 'Yes',
	[candidateHiringOption.NO]: 'No',
	[candidateHiringOption.MAYBE]: 'Maybe'
};

export const candidateSeniorityOptions = Object.values(
	candidateSeniorityOption
).map(seniorityKey => ({
	text: candidateSeniorityOptionLabel[seniorityKey],
	value: seniorityKey
}));

export const candidateHiringOptions = Object.values(candidateHiringOption).map(
	hiringKey => ({
		text: candidateHiringOptionLabel[hiringKey],
		value: hiringKey
	})
);

class CandidateScorecardStore {
	@observable scorecards;
	@observable selectedScorecardId;

	constructor() {
		makeObservable(this);
	}

	@action
	selectScorecard(scorecardId) {
		this.selectedScorecardId = scorecardId;
	}

	@action
	setScorecards(scorecards) {
		this.scorecards = scorecards;
	}

	@action
	getSelectedScorecard() {
		if (this.selectedScorecardId) {
			return this.scorecards.find(
				scorecard => scorecard.id === this.selectedScorecardId
			);
		} else {
			return null;
		}
	}

	@action
	async getAll(candidateId) {
		const result = await api.get(`/candidates/${candidateId}/scorecards`);
		// on fail display error notification
		if (result.status === 'fail') {
			console.error(result.message || result.error);
			notificationStore.pushErrorNotification(
				'Search Error',
				'An error occurred trying to get the Scorecards for this Candidate'
			);
			this.setScorecards([]);
		} else {
			this.setScorecards(result?.data ?? []);

			if (!this.selectedScorecardId && result?.data?.length > 0) {
				this.selectScorecard(result?.data[0]?.id ?? null);
			}
		}
	}

	@action('createCandidateScorecard')
	async create(candidateId, scorecardTemplateId) {
		const result = await api.post(`candidates/${candidateId}/scorecards`, {
			scorecardTemplateId
		});

		if (result.status === 'success') {
			await this.getAll(candidateId);

			this.selectScorecard(result.data.candidateScorecard.id);
		}

		return result;
	}

	@action
	async update(candidateId, candidateScorecardId, data) {
		const url = `candidates/${candidateId}/scorecards/${candidateScorecardId}`;

		return await api.put(encodeURI(url), { data });
	}

	@action
	async toggle(candidateId, candidateScorecardId, visible) {
		const url = `candidates/${candidateId}/scorecards/${candidateScorecardId}/show`;

		return await api.put(encodeURI(url), { visible });
	}

	@action
	async remove(candidateId, candidateScorecardId) {
		const url = `candidates/${candidateId}/scorecards/${candidateScorecardId}`;

		const result = await api.delete(encodeURI(url));

		if (result === 'OK') {
			const selectedIndex = this.scorecards.findIndex(
				scorecard => scorecard.id === candidateScorecardId
			);

			const scorecards = [...this.scorecards];

			scorecards.splice(selectedIndex, 1);
			this.setScorecards(scorecards);

			try {
				if (selectedIndex > this.scorecards.length - 1) {
					this.selectScorecard(this.scorecards[selectedIndex - 1].id);
				} else {
					this.selectScorecard(this.scorecards[selectedIndex].id);
				}
			} catch (error) {
				this.selectScorecard(null);
			}
		}

		return result;
	}
}

export const candidateScorecardStore = new CandidateScorecardStore();
