import React from 'react';
import { candidatesStore } from '../CandidatesStore';
import { ModalComponent } from 'components/layout/modals/ModalComponent';

export class CandidateProfileAbout extends ModalComponent {
	displayValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}

	displayYearsText(year) {
		if (!year || year === 'N/A') return;
		return `${year > 1 ? `${year} years` : `${year} year`}`;
	}
	displayRemoteExperiece(candidate) {
		const remoteExperienceText = this.displayYearsText(
			this.displayValueFromOptions(
				candidate.remoteExperienceYears,
				candidatesStore.remoteExperienceYearsOptions
			)
		);
		return remoteExperienceText ? remoteExperienceText : 'No';
	}
	displayUsRemoteExperiece(candidate) {
		const remoteExperienceText = this.displayYearsText(
			this.displayValueFromOptions(
				candidate.usExperienceYears,
				candidatesStore.usExperienceYearsOptions
			)
		);
		return remoteExperienceText ? remoteExperienceText : 'No';
	}
	render() {
		const candidate = this.props.candidate;
		const expertiseAreas = candidate.areaAttributes.map(item => {
			return {
				...item,
				...item.expertiseArea
			};
		});
		const knownSkills = (this.props.candidate.knownSkills || []).map(item => {
			return { ...item, ...item.skill };
		});
		return (
			<div className="CandidateProfileExpectationsModal">
				<section className="modal-card-body">
					{this.props.renderInternalSectionButtons()}
					<div className={'description-section'}>
						<div className="columns  wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											How would you rate your english language level?
										</label>
									</div>
									{this.displayValueFromOptions(
										candidate.englishLevel,
										candidatesStore.englishLevelOptions
									)}
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-7">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											What are you area(s) of expertise, how do you assess your
											expertise level and how many years of experience do you
											have in each one of them?
										</label>
									</div>
									<div className="add-margin-top">
										{expertiseAreas.map((expertiseArea, index) => {
											return (
												<div key={`expertiseArea-${index}`}>
													<span
														key={`name-${index}`}
														className="tag is-blue add-margin-right">
														{expertiseArea.name}
													</span>
													<span
														key={`level-${index}`}
														className="tag is-grey add-margin-right">
														{expertiseArea.level}
													</span>
													<span
														key={`experienceYears-${index}`}
														className="tag is-grey add-margin-right">
														{this.displayYearsText(
															expertiseArea.experienceYears
														)}
													</span>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											What are the technologies you know best?
										</label>
									</div>
									<div className="add-margin-top">
										{knownSkills.map((skill, index) => {
											return (
												<div key={`knownSkill-${index}`}>
													<span
														key={skill.id}
														className="tag is-blue add-margin-right">
														{skill.name}
													</span>
													<span
														key={`level-${index}`}
														className="tag is-grey add-margin-right">
														{skill.level ? skill.level : 'N/A'}
													</span>
													<span
														key={`experienceYears-${index}`}
														className="tag is-grey add-margin-right">
														{skill.experienceYears
															? this.displayYearsText(skill.experienceYears)
															: 'N/A'}
													</span>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											Do you have experience working from home? How many years?
										</label>
									</div>
									<div className="add-margin-top">
										{this.displayRemoteExperiece(candidate)}
									</div>
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											Do you have experience working with teams in the United
											States? How many years?
										</label>
									</div>
									<div className="add-margin-top">
										{this.displayUsRemoteExperiece(candidate)}
									</div>
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											For how many years have you worked with teams in the
											United States?
										</label>
									</div>
									{this.displayValueFromOptions(
										candidate.usExperienceYears,
										candidatesStore.usExperienceYearsOptions
									)}
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											What is your highest level of education?
										</label>
									</div>
									{this.displayValueFromOptions(
										candidate.highestEducationLevel,
										candidatesStore.highestEducationLevelOptions
									)}
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">
											In which stage of the program are you?
										</label>
									</div>
									{this.displayValueFromOptions(
										candidate.educationStage,
										candidatesStore.educationStageOptions
									)}
								</div>
							</div>
						</div>
						<div className="columns wizard-row">
							<div className="column is-5">
								<div className="sub-title is-6">
									<div>
										<label className="subtitle is-7">Resume</label>
									</div>
									{candidate.resumeUrl ? (
										<a
											href={`${process.env.REACT_APP_API}${candidate.resumeUrl}`}
											download>
											{' '}
											Download{' '}
										</a>
									) : (
										'N/A'
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
				<footer className="modal-card-foot"></footer>
			</div>
		);
	}
}
