import React from 'react';
import { observer } from 'mobx-react';
import { candidateScorecardStore } from '../../../profile/scorecards/CandidateScorecardStore';
import { modalStore } from '../../../../layout/modals/ModalStore';
import { CandidateAdminScorecardEditModal } from '../../../profile/scorecards/CandidateAdminScorecardEditModal';
import { Switch } from '../components/Switch';
import { dialogStore } from '../../../../layout/modals/DialogStore';
import { Scorecard } from '../components/Scorecard';
import moment from 'moment';
import { candidateProfileStore } from 'components/candidates/profile/CandidateProfileStore';

@observer
export class CandidateScorecardsEdit extends React.Component {
	async componentDidMount() {
		const { candidate } = this.props;

		await candidateScorecardStore.getAll(candidate.id);
	}

	handleTabClick(clickEvent, scorecardId) {
		clickEvent.preventDefault();

		candidateScorecardStore.selectScorecard(scorecardId);
	}

	async refetch(scorecard) {
		const { candidateId } = scorecard;

		await candidateScorecardStore.getAll(candidateId);
	}

	async handleToggleVisibility(visible, scorecard) {
		const { id, candidateId } = scorecard;

		await candidateScorecardStore.toggle(candidateId, id, visible);
		await candidateScorecardStore.getAll(candidateId);
	}

	handleClickEdit(scorecard) {
		const { candidate } = this.props;

		modalStore.openModal(
			{ title: 'Candidate Scorecard', position: 'center', size: '2xl' },
			<CandidateAdminScorecardEditModal
				candidate={candidate}
				candidateScorecard={scorecard}
				handleSuccess={() => {
					void this.refetch(scorecard);
					candidateProfileStore.reloadCandidate();
				}}
			/>
		);
	}

	async handleClickDelete({ candidateId, id }) {
		await candidateScorecardStore.remove(candidateId, id);
		await candidateScorecardStore.getAll(candidateId);
		await candidateProfileStore.reloadCandidate();
	}

	isScorecardCompleted(scorecard) {
		return !!(
			scorecard.internalNotes &&
			scorecard.publicNotes &&
			scorecard.wouldYouHire
		);
	}

	isScorecardExpired(scorecard) {
		return moment(scorecard.activationDate).isSameOrBefore(
			moment().subtract(18, 'months')
		);
	}

	renderActionButtons(scorecard) {
		return (
			<div
				className="ScorecardContent__Actions"
				onClick={e => e.stopPropagation()}>
				{!this.isScorecardExpired(scorecard) ? (
					<>
						<Switch
							id={scorecard.id}
							className="config-switch"
							value={scorecard.visible}
							activeLabel="Show"
							inactiveLabel="Hide"
							onValueChange={visible => {
								this.handleToggleVisibility(visible, scorecard);
							}}
							disabled={!this.isScorecardCompleted(scorecard)}
						/>

						<button
							className="button is-rounded is-small level-item"
							onClick={() => {
								this.handleClickEdit(scorecard);
							}}>
							Edit
						</button>
					</>
				) : null}
				<button
					className="button is-danger is-rounded is-small level-item"
					onClick={() => {
						dialogStore.openDialog(
							{
								title: 'Delete Candidate Scorecard',
								message:
									'This Candidate Scorecard entry will be deleted. Are you sure?',
								confirmLabel: 'Delete',
								confirmButtonClass: 'is-danger'
							},
							() => this.handleClickDelete(scorecard)
						);
					}}>
					Delete
				</button>
			</div>
		);
	}

	render() {
		const hasScorecards =
			candidateScorecardStore.scorecards?.length > 0 ?? false;

		return (
			<div className="CandidateAdminScorecard">
				{hasScorecards &&
					candidateScorecardStore.scorecards.map(scorecard => (
						<Scorecard
							scorecard={scorecard}
							isExpired={this.isScorecardExpired(scorecard)}
							renderActionButtons={() => this.renderActionButtons(scorecard)}
						/>
					))}
			</div>
		);
	}
}
