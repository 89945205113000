import React from 'react';
import { CandidateProfileExperience } from './experience/CandidateProfileExperience';
import { CandidateProfileEducation } from './education/CandidateProfileEducation';
import { CandidateProfileCertifications } from './certifications/CandidateProfileCertifications';
import { CandidateProfileTestResults } from './test-results/CandidateProfileTestResults';
import { Button } from '../../layout/button/Button';
import { modalStore } from '../../layout/modals/ModalStore';
import { CandidateProfileTestResultEdit } from './test-results/CandidateProfileTestResultEdit';
import { CandidateProfileEducationEdit } from './education/CandidateProfileEducationEdit';
import { CandidateProfileCertificationEdit } from './certifications/CandidateProfileCertificationEdit';
import { candidateProfileStore } from './CandidateProfileStore';
import { CandidateProfileExperienceEdit } from './experience/CandidateProfileExperienceEdit';
import { Switch } from '../../layout/form-fields/inputs/Switch';
import { CandidateAdminScorecardCreateModal } from './scorecards/CandidateAdminScorecardCreateModal';
import { candidateScorecardStore } from './scorecards/CandidateScorecardStore';
import { CandidateScorecardsEdit } from '../shared/scorecards/scorecards-edit/CandidateScorecardsEdit';
import { Icon } from './Icon';

const TABS = {
	EXPERIENCE: 0,
	EDUCATION: 1,
	CERTIFICATIONS: 2,
	TECHNICAL_TESTS: 3,
	SCORECARDS: 4,
	WORK_PREFERENCES: 5,
	SELF_ASSESSMENT: 6,
	CONTACT_INFO: 7,
	COMPENSATION: 8,
	CHANGELOG: 9
};

export class CandidateProfileSections extends React.Component {
	state = {
		activeTab: null
	};
	sectionsConfigurations = {
		[TABS.EXPERIENCE]: 'showExperience',
		[TABS.EDUCATION]: 'showEducation',
		[TABS.CERTIFICATIONS]: 'showCertifications',
		[TABS.TECHNICAL_TESTS]: 'showTestResults',
		[TABS.SCORECARDS]: 'showScorecards'
	};
	componentDidMount() {
		const {
			candidateExperiences,
			candidateEducations,
			candidateCertifications,
			candidateTestResults,
			candidateScorecards
		} = this.props.candidate;

		if (candidateExperiences.length > 0) {
			this.setState({ activeTab: TABS.EXPERIENCE });
		} else if (candidateEducations.length > 0) {
			this.setState({ activeTab: TABS.EDUCATION });
		} else if (candidateCertifications.length > 0) {
			this.setState({ activeTab: TABS.CERTIFICATIONS });
		} else if (candidateTestResults.length > 0) {
			this.setState({ activeTab: TABS.TECHNICAL_TESTS });
		} else if (candidateScorecards.length > 0) {
			this.setState({ activeTab: TABS.SCORECARDS });
		}
	}

	renderSectionButtons(section) {
		const {
			candidate: { candidateProfile }
		} = this.props;

		return (
			<div className="panel-controls">
				<div className="pull-right has-text-centered-mobile">
					<Switch
						className="config-switch"
						id={`ProfileSectionSwitch-${this.sectionsConfigurations[section]}`}
						key={this.sectionsConfigurations[section]}
						value={candidateProfile[this.sectionsConfigurations[section]]}
						activeLabel="Show"
						inactiveLabel="Hide"
						onValueChange={value =>
							this.props.onConfigChange(
								this.sectionsConfigurations[section],
								value
							)
						}
					/>
				</div>
				<div className="tabbed-panel-option add-button">
					<a
						href={'void'}
						onClick={e => {
							this.handleAddClick(e, section);
						}}>
						<Button
							className="is-rounded is-circle is-xsmall is-blue"
							icon="plus"
						/>
						<span>Add</span>
					</a>
				</div>
			</div>
		);
	}

	render() {
		const { candidate } = this.props;
		const {
			candidateExperiences,
			candidateEducations,
			candidateCertifications,
			candidateTestResults
		} = candidate;

		return (
			<div className="panels is-edit">
				<div className="tabbed-panel">
					<div id="tabs-content-wrapper" className="tabs-content-wrapper">
						<div
							className={'box tabbed-panel-content is-active'}
							id="experience">
							<div className="title-container">
								<Icon className="section-icon" icon="fa-solid fa-briefcase" />
								<h2 className="title is-3">Experience</h2>
								{this.renderSectionButtons(TABS.EXPERIENCE)}
							</div>
							<CandidateProfileExperience
								experiences={candidateExperiences}
								isEdit={true}
								onConfigChange={this.props.onExperienceConfigChange}
							/>
						</div>

						<div
							className={'box is-scorecards tabbed-panel-content is-active'}
							id="scorecards">
							<div className="title-container">
								<Icon
									className="section-icon"
									icon="fa-solid fa-gauge-simple-high"
								/>
								<h2 className="title is-3">Scorecards</h2>
								{this.renderSectionButtons(TABS.SCORECARDS)}
							</div>
							<CandidateScorecardsEdit candidate={candidate} />
						</div>

						<div
							className={'box is-test-results tabbed-panel-content is-active'}
							id="technical-tests">
							<div className="title-container">
								<Icon className="section-icon" icon="fa-solid fa-chart-line" />
								<h2 className="title is-3">Technical Tests</h2>
								{this.renderSectionButtons(TABS.TECHNICAL_TESTS)}
							</div>
							<CandidateProfileTestResults
								candidate={candidate}
								testResults={candidateTestResults}
								onConfigChange={this.props.onTestResultConfigChange}
							/>
						</div>

						<div
							className={'box tabbed-panel-content education-section is-active'}
							id="education">
							<div className="title-container">
								<Icon
									className="section-icon"
									icon="fa-solid fa-graduation-cap"
								/>
								<h2 className="title is-3">Education</h2>
								{this.renderSectionButtons(TABS.EDUCATION)}
							</div>
							<CandidateProfileEducation
								educations={candidateEducations}
								onConfigChange={this.props.onEducationConfigChange}
							/>
						</div>

						<div
							className={
								'box tabbed-panel-content certification-section is-active'
							}
							id="certifications">
							<div className="title-container">
								<Icon className="section-icon" icon="fa-solid fa-medal" />
								<h2 className="title is-3">Certifications</h2>
								{this.renderSectionButtons(TABS.CERTIFICATIONS)}
							</div>
							<CandidateProfileCertifications
								certifications={candidateCertifications}
								onConfigChange={this.props.onCertificationConfigChange}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	handleTabClick(e, tabId) {
		e.preventDefault();
		// If already active do nothing.
		if (this.state.activeTab === tabId) {
			return;
		}
		this.setState({ activeTab: tabId });
	}

	handleAddClick(e, section) {
		const { candidate } = this.props;
		e.preventDefault();

		switch (section) {
			case TABS.EXPERIENCE:
				modalStore.openModal(
					{ title: 'New Experience', position: 'center', size: 'XL' },
					<CandidateProfileExperienceEdit
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			case TABS.EDUCATION:
				modalStore.openModal(
					{ title: 'New Education' },
					<CandidateProfileEducationEdit
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			case TABS.CERTIFICATIONS:
				modalStore.openModal(
					{ title: 'New Certification' },
					<CandidateProfileCertificationEdit
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			case TABS.TECHNICAL_TESTS:
				modalStore.openModal(
					{ title: 'New Technical Test' },
					<CandidateProfileTestResultEdit
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			case TABS.SCORECARDS:
				modalStore.openModal(
					{ title: 'Create Scorecard' },
					<CandidateAdminScorecardCreateModal
						candidate={candidate}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
							candidateScorecardStore.getAll(candidate.id);
						}}
					/>
				);
				break;

			default:
				break;
		}
	}
}
