import React from 'react';
import { withRouter } from 'react-router-dom';
import { InputField } from '../layout/form-fields/inputs/InputField';
import { Dropdown } from '../layout/form-fields/inputs/Dropdown';
import { Pagination } from '../layout/pagination/Pagination';
import { clientStore } from './ClientStore';
import { ClientsTable } from './ClientsTable';
import { ClientCreate } from './ClientCreate';
import { ClientEdit } from './ClientEdit';
import { ListStore } from '../../shared/stores/ListStore';
import { ListHeaderComponent } from '../layout/list/ListHeaderComponent';
import { observer } from 'mobx-react';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';

@rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER])
@withRouter
@observer
export class Clients extends React.Component {
	constructor(props) {
		super(props);
		this.listStore = new ListStore(clientStore, {
			entityName: 'Client',
			createModalTitle: 'Create Client',
			createModal: (
				<ClientCreate handleSuccess={() => this.listStore.search()} />
			),
			editModal: item => (
				<ClientEdit
					client={item}
					handleSuccess={() => this.listStore.search()}
				/>
			)
		});
	}

	componentDidMount() {
		this.listStore.search(true);
	}

	render() {
		return (
			<div className="Clients container">
				<ListHeaderComponent
					title={`${this.listStore.modalsConfigs.entityName}s`}
					createActionTitle={this.listStore.modalsConfigs.createModalTitle}
					handleCreate={() => this.listStore.handleCreate()}
				/>
				<div className="columns">
					<div className="column is-3">
						<InputField
							className="has-text-left"
							field="search"
							type="search"
							placeholder="Name | Email"
							icon="fa-search"
							onValueChange={value => {
								clientStore.setQuery('text', value);
								this.listStore.search();
							}}
						/>
					</div>
					<div className="column is-2">
						<Dropdown
							allowClear={true}
							placeholder="Job Status"
							onValueChange={value => {
								clientStore.setQuery('jobs', value);
								this.listStore.search();
							}}
							options={['Active', 'Inactive']}
						/>
					</div>
					<div className="column is-2">
						<Dropdown
							value="Enabled"
							onValueChange={value => {
								clientStore.setQuery('status', value);
								this.listStore.search();
							}}
							options={['Enabled', 'Disabled']}
						/>
					</div>
					<div className="column is-5">
						<Pagination
							loading={this.listStore.loading}
							pagination={clientStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<ClientsTable
							data={this.listStore.data}
							sort={this.listStore.store.query.sort}
							onSort={this.listStore.handleSort}
							onEdit={this.listStore.handleEdit}
							onDisable={item => this.listStore.handleDisable(item, true)}
							onDelete={item => this.listStore.handleDelete(item, null, true)}
							onSearch={() => this.listStore.search()}
							onJobRedirect={this.handleJobRedirect}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 bottom-pagination">
						<Pagination
							loading={this.listStore.loading}
							pagination={clientStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
			</div>
		);
	}

	handleJobRedirect = client => {
		this.props.history.push({
			pathname: '/job-openings',
			state: { query: { client: client.name } }
		});
	};
}
