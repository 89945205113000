import { EntityStore } from 'shared/stores/EntityStore';
import { action, makeObservable } from 'mobx';
import { api } from 'shared/api/api';

class ScorecardTemplateStore extends EntityStore {
	constructor() {
		super('scorecard-templates');
		makeObservable(this);
	}

	typeOptions = [
		{ text: 'Select one option', value: null },
		{
			text: 'Technical',
			value: 'technical'
		},
		{
			text: 'HR',
			value: 'hr'
		}
	];

	colorOptions = [
		{ text: 'Select one option', value: null },
		{
			text: 'Blue',
			value: 'blue'
		},
		{
			text: 'Green',
			value: 'green'
		},
		{
			text: 'Gray',
			value: 'gray'
		}
	];

	colorOptionsEnum = {
		blue: '#314ebd',
		green: '#02CCB8',
		gray: '#50597c'
	};

	@action
	async getScorecardOptions(query = '') {
		const url = this.entityPluralEndpoint + '/options?q=' + query;

		return await api.get(encodeURI(url));
	}

	@action
	async getScorecardTemplates(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;

		return await api.get(encodeURI(url));
	}

	@action
	async getScorecardSections(templateId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections`;

		return await api.get(encodeURI(url));
	}

	@action
	async getScorecardSection(templateId, sectionId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}`;

		return await api.get(encodeURI(url));
	}

	@action
	async getScorecardItems(templateId, sectionId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}/items`;

		return await api.get(encodeURI(url));
	}

	@action
	async updateSection(templateId, sectionId, data) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}`;

		return await api.put(encodeURI(url), data);
	}

	@action
	async updateItem(templateId, sectionId, itemId, data) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}/items/${itemId}`;

		return await api.put(encodeURI(url), data);
	}

	@action
	async createSection(templateId, data) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections`;

		return await api.post(encodeURI(url), data);
	}

	@action
	async createItem(templateId, sectionId, data) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}/items`;

		return await api.post(encodeURI(url), data);
	}

	@action
	async deleteSection(templateId, sectionId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}`;

		return await api.delete(encodeURI(url));
	}

	@action
	async deleteItem(templateId, sectionId, itemId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}/items/${itemId}`;

		return await api.delete(encodeURI(url));
	}

	@action
	async swapSections(templateId, sourceItemId, targetItemId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/swap`;

		return await api.put(encodeURI(url), {
			sourceId: sourceItemId,
			targetId: targetItemId
		});
	}

	@action
	async swapItems(templateId, sectionId, sourceItemId, targetItemId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/sections/${sectionId}/items/swap`;

		return await api.put(encodeURI(url), {
			sourceId: sourceItemId,
			targetId: targetItemId
		});
	}

	@action
	async validate(templateId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/validate`;

		return await api.post(encodeURI(url));
	}

	@action
	async publish(templateId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/publish`;

		return await api.post(encodeURI(url));
	}

	@action
	async unpublish(templateId) {
		const url = `${this.entityPluralEndpoint}/${templateId}/unpublish`;

		return await api.post(encodeURI(url));
	}
}

export const scorecardTemplateStore = new ScorecardTemplateStore();
