import { Button } from '../../../components/layout/button/Button';
import { modalStore } from '../../../components/layout/modals/ModalStore';
import { CandidateProfileReposEdit } from './CandidateProfileReposEdit';
import { candidateProfileStore } from '../CandidateProfileStore';
import React from 'react';
import { CandidateProfileRepo } from './CandidateProfileRepo';
import { observer } from 'mobx-react';
import { Icon } from '../Icon';

@observer
export class CandidateProfileRepos extends React.Component {
	render() {
		const config = this.props.candidate.candidateProfile;
		return (
			<div
				className={`repos-container bio-subsection-wrapper CandidateProfileRepos has-edit-button ${
					!config.showRepos ? 'is-disabled' : ''
				}`}>
				<div className="title is-inline-block">
					<Icon icon="fa-regular fa-brands fa-github" />
					Repos
					{this.props.repos.length > 0 && (
						<div className="edit-button-wrapper repos" title="Edit your repos">
							{!candidateProfileStore.isLocked && (
								<Button
									className="is-rounded  edit-button"
									icon="pencil-alt"
									iconClass="has-text-white"
									onClick={event => {
										modalStore.openModal(
											{ title: 'Edit Repos', position: 'center' },
											<CandidateProfileReposEdit
												candidate={this.props.candidate}
												repos={this.props.repos}
												handleSuccess={() => {
													candidateProfileStore.reloadCandidate();
												}}
											/>
										);
									}}
								/>
							)}
						</div>
					)}
				</div>
				{this.props.repos.length > 0 ? (
					<div className="sub-section">
						<div className="sub-section-content ">
							{this.props.repos.map((repo, index) => {
								return (
									<CandidateProfileRepo
										key={repo.id}
										repo={repo}
										asLink
										showIcon={false}
									/>
								);
							})}
						</div>
					</div>
				) : (
					<div className="sub-section-content has-edit-button">
						<p>
							You have no repos listed in your profile yet, do you want to add
							one?
						</p>
						<div
							className="about-me edit-button-wrapper"
							title="Edit your repos">
							{!candidateProfileStore.isLocked && (
								<Button
									className="is-rounded is-small is-circle add-button"
									icon="plus"
									iconClass="has-text-white"
									onClick={event => {
										modalStore.openModal(
											{ title: 'Edit Repos', position: 'center' },
											<CandidateProfileReposEdit
												candidate={this.props.candidate}
												repos={this.props.repos}
												handleSuccess={() => {
													candidateProfileStore.reloadCandidate();
												}}
											/>
										);
									}}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}
