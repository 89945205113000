import React from 'react';
import { Icon } from '../layout/misc/Icon';
import moment from 'moment-timezone';

export class TestResultsFilesTable extends React.Component {
	isColumnSorted(column) {
		if (this.props.sort && this.props.sort.column === column) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}

	render() {
		return (
			<table className="table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th className="is-sortable" style={{ width: '10%' }}>
							File name
						</th>
						<th
							className="is-sortable"
							style={{ width: '15%' }}
							onClick={() => this.handleHeaderClick('createdAt')}>
							Created at {this.isColumnSorted('createdAt')}
						</th>
						<th className="is-sortable" style={{ width: '10%' }}>
							Updated at
						</th>
						<th className="is-sortable" style={{ width: '10%' }}>
							Current
						</th>
						<th style={{ width: '150px' }} />
					</tr>
				</thead>
				<tbody>
					{this.props.data.map(item => {
						return (
							<tr key={item.id} className={item.disabled ? 'disabled' : ''}>
								<td className="has-text-weight-bold">{item.filename}</td>
								<td className="has-text-weight-medium">
									{moment(item.createdAt).fromNow()}
								</td>
								<td className="has-text-weight-medium">
									{moment(item.updatedAt).fromNow()}
								</td>
								<td className="has-text-weight-medium">
									{item.current ? 'Yes' : 'No'}
								</td>
								<td className="actions-column">
									<button
										title="Pin the file to be processed"
										className="button is-rounded is-circle is-small is-text"
										onClick={() => {
											this.props.onEdit(item);
										}}>
										<Icon className="is-small" icon="fa-thumbtack" />
									</button>
									<button
										title="Download File"
										className="button is-rounded is-circle is-small is-text"
										onClick={() => {
											this.props.onDownload(item);
										}}>
										<Icon className="is-small" icon="fa-download" />
									</button>
									<button
										title="Delete File"
										className="button is-rounded is-circle is-small is-text"
										onClick={() => {
											if (this.props.onEdit) {
												this.props.onDelete(item);
											}
										}}>
										<Icon className="is-small" icon="fa-trash" />
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
}
