import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from 'shared/api/api';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, getElementAtEvent } from 'react-chartjs-2';
import { Loader } from 'components/layout/misc/Loader';

ChartJS.register(ArcElement, Tooltip, Legend);

function CandidatesByScore() {
	const [data, setData] = useState(null);
	const history = useHistory();

	const fetchData = async () => {
		const url = 'dashboard/candidates-by-score';
		const { data } = await api.get(encodeURI(url));

		setData({
			labels: data.labels,
			datasets: [
				{
					label: '# of Candidates',
					data: data.values,
					backgroundColor: [
						'rgba(255, 99, 132, 0.2)',
						'rgba(54, 162, 235, 0.2)',
						'rgba(255, 206, 86, 0.2)',
						'rgba(75, 192, 192, 0.2)',
						'rgba(153, 102, 255, 0.2)',
						'rgba(255, 159, 64, 0.2)',
						'rgba(230, 67, 158, 0.2)',
						'rgba(68, 182, 174, 0.2)',
						'rgba(140, 42, 186, 0.2)',
						'rgba(215, 108, 61, 0.2)',
						'rgba(99, 207, 83, 0.2)'
					],
					borderColor: [
						'rgba(255, 99, 132, 1)',
						'rgba(54, 162, 235, 1)',
						'rgba(255, 206, 86, 1)',
						'rgba(75, 192, 192, 1)',
						'rgba(153, 102, 255, 1)',
						'rgba(255, 159, 64, 1)',
						'rgba(230, 67, 158, 1)',
						'rgba(68, 182, 174, 1)',
						'rgba(140, 42, 186, 1)',
						'rgba(215, 108, 61, 1)',
						'rgba(99, 207, 83, 1)'
					],
					borderWidth: 1
				}
			]
		});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const options = {
		plugins: {
			legend: {
				position: 'chartArea',
				labels: {
					generateLabels: chart => {
						const { config } = chart;
						const { data } = config;
						const { labels, datasets } = data;
						const legendLabels =
							labels?.map((label, index) => {
								const totalCandidates = datasets[0].data.reduce(function (
									a,
									b
								) {
									return a + b;
								}, 0);

								const value = datasets[0].data[index] ?? 0;

								const percentage = value / totalCandidates;

								const formattedPercentage = new Intl.NumberFormat('en-US', {
									style: 'percent',
									minimumFractionDigits: 0,
									maximumFractionDigits: 2
								}).format(percentage);

								return {
									text: `${label}: ${value} (${formattedPercentage})`,
									datasetIndex: 0,
									index,
									fillStyle: datasets[0].backgroundColor[index],
									strokeStyle: datasets[0].borderColor[index]
								};
							}) || [];
						return legendLabels;
					},
					filter: ({ datasetIndex, index }, { datasets }) =>
						datasets[datasetIndex].data[index] > 0
				}
			},
			tooltip: {
				callbacks: {
					label: ({ formattedValue, parsed: parsedValue, label, dataset }) => {
						const totalCandidates = dataset.data.reduce(function (a, b) {
							return a + b;
						}, 0);

						const percentage = parsedValue / totalCandidates;

						const formattedPercentage = new Intl.NumberFormat('en-US', {
							style: 'percent',
							minimumFractionDigits: 0,
							maximumFractionDigits: 2
						}).format(percentage);

						return `${label}: ${formattedValue} candidate(s) (${formattedPercentage})`;
					}
				}
			}
		},
		onHover: (event, chartElement) => {
			event.native.target.style.cursor = chartElement[0]
				? 'pointer'
				: 'default';
		}
	};

	const chartRef = useRef(null);

	const handleOnClickScoreRange = event => {
		const { current: chart } = chartRef;

		if (!chart) {
			return;
		}

		const elements = getElementAtEvent(chart, event);

		if (!elements.length) return;

		const { index } = elements[0];

		const scoreRange = data.labels[index].split('-');

		const formattedScoreRange =
			scoreRange.length === 2
				? scoreRange.join(',')
				: `${scoreRange},${scoreRange}`;

		history.push(`/candidates?scoreRange=${formattedScoreRange}`);
	};

	return (
		<div className="column is-8">
			<h3 className="title has-text-centered is-5">Candidates by Score</h3>
			{data ? (
				<Pie
					ref={chartRef}
					data={data}
					style={{ maxHeight: '300px' }}
					options={options}
					onClick={handleOnClickScoreRange}
				/>
			) : (
				<Loader type="ripple" />
			)}
		</div>
	);
}

export default CandidatesByScore;
