import React, { useCallback, Fragment } from 'react';

// tuple [ value, inputProps, labelProps ]
const options = [
	[5, {}, {}],
	[4, {}, {}],
	[3, {}, {}],
	[2, {}, {}],
	[1, {}, {}],
	[0, { className: 'EditableStars__Clear' }, {}]
];

export const EditableStar = ({ identifier, rating = 0, onChange }) => {
	const handleChange = useCallback(
		inputEvent => {
			onChange && onChange(Number(inputEvent.target.value));
		},
		[onChange]
	);

	return (
		<div className="StarWrapper">
			<div className="EditableStars">
				{options.map(([value, InputProps, LabelProps]) => (
					<Fragment key={`${identifier}-input-${value}`}>
						<input
							type="radio"
							id={`${identifier}-rating-${value}`}
							name={`${identifier}-rating`}
							value={value}
							checked={Number(value) === Number(rating)}
							onChange={handleChange}
							{...InputProps}
						/>
						<label htmlFor={`${identifier}-rating-${value}`} {...LabelProps}>
							{value}
						</label>
					</Fragment>
				))}
			</div>
		</div>
	);
};
