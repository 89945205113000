import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { CandidateProfileTestResultsCanvas } from './CandidateProfileTestResultsCanvas';
import { Icon } from '../Icon';

export class CandidateProfileTestResults extends React.Component {
	getGroupedTestResults(testResults) {
		if (!testResults) return [];
		const testResultsGroup = new Map();
		testResults.forEach(testResult => {
			const testName = testResult.test.name;
			if (!testResultsGroup.has(testName)) {
				testResultsGroup.set(testName, [testResult]);
				return;
			}

			const groupedTests = testResultsGroup.get(testName);
			groupedTests.push(testResult);
		});
		return [].concat(...testResultsGroup.values());
	}
	render() {
		const getNumberWithOrdinal = n => {
			const s = ['th', 'st', 'nd', 'rd'];
			const v = n % 100;
			return n + (s[(v - 20) % 10] || s[v] || s[0]);
		};
		const groupedTestsResults = this.getGroupedTestResults(
			this.props.testResults
		);
		const testResultEntries = groupedTestsResults.map(testResult => {
			const showPercentile = testResult.test.sample_size >= 50;

			return (
				<div
					key={testResult.id}
					className={`content description-section test-result ${
						!testResult.visible ? 'is-disabled' : ''
					}`}>
					<div className="title">{testResult.test.name} </div>
					<div className="test-result-card">
						<div className="test-info-container is-flex">
							<div className="score-container">
								<div className="title is-spaced is-7">Score</div>
								<div className="subtitle is-1">
									<span className="has-medium-weight">
										{Math.round(testResult.score)}/
									</span>
									<span className="has-less-weight">100</span>
								</div>
							</div>
							<div className="percentile-container">
								<div className="title is-spaced is-7">
									Percentile
									<span
										className="tooltip is-tooltip-top"
										data-tooltip="Candidate score relative to average score.">
										<i className="icon fas fa-info-circle" />
									</span>
								</div>
								<span className="subtitle is-1 has-medium-weight">
									{showPercentile && testResult.percentile != null
										? getNumberWithOrdinal(testResult.percentile)
										: 'N/A'}
								</span>
								{showPercentile && testResult.percentile != null ? (
									<div
										className="tooltip is-tooltip-top"
										data-tooltip="Number of candidates that took this test.">
										<div className="subtitle is-4 has-less-weight">
											n=
											{testResult.test.sample_size
												? testResult.test.sample_size
												: 0}
										</div>
									</div>
								) : null}
							</div>
						</div>
						<div className="time-info-container is-flex">
							<div className="date-taken">
								<Icon icon="fa-regular fa-calendar" />
								{moment(testResult.takenAt).format('D MMM YYYY')}
							</div>
							<div className="time-taken">
								<Icon icon="fa-solid fa-stopwatch" />
								{testResult.timeTaken}&apos;
							</div>
						</div>
					</div>
				</div>
			);
		});
		return (
			<Fragment>
				{this.props.testResults.length > 1 ? (
					<Fragment>
						<div className="graph">
							<CandidateProfileTestResultsCanvas
								candidate={this.props.candidate}
								testResults={this.props.testResults}
							/>
						</div>
					</Fragment>
				) : (
					<div className="add-lg-margin-top"></div>
				)}
				<div className="test-results-container">{testResultEntries}</div>
			</Fragment>
		);
	}
}
