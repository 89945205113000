import React, { Fragment, useState } from 'react';
import { api } from '../shared/api/api';
import { notificationStore } from '../components/layout/notifications/NotificationStore';
import { Link, withRouter } from 'react-router-dom';
import { Button } from '../components/layout/button/Button';
import { authStore } from '../shared/security/AuthStore';
import { PublicHeader } from '../components/layout/header/PublicHeader';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { dialogStore } from '../components/layout/modals/DialogStore';

function SignupPage({ history }) {
	const [termsAccepted, setTermsAccepted] = useState(false);
	const handleAcceptTermsConditions = () => {
		setTermsAccepted({ termsAccepted: !termsAccepted });
	};

	const signUpUser = async (email, googleId, imageUrl, name) => {
		const data = await api.post('signup', {
			email: email,
			googleID: googleId,
			imageUrl: imageUrl,
			name: name
		});
		if (data.token && data.profile) {
			authStore.setToken(data.token, data.profile);
		}
		if (data.status === 'fail') {
			notificationStore.pushNotification({
				type: notificationStore.ERROR_NOTIFICATION,
				title: 'Sign Up Error',
				message:
					'There was a problem attempting to sign up. Please contact an administrator.'
			});
		} else if (data.status === 'deactivated-user') {
			dialogStore.openDialog({
				title: 'Your account is inactive',
				message: (
					<>
						Your Prediktive account was deactivated. If you want to reactivate
						your account contact us at{' '}
						<strong>
							<a href="mailto:talent.candidates@prediktive.com">
								talent.candidates@prediktive.com
							</a>
						</strong>
					</>
				),
				confirmLabel: 'Close',
				confirmButtonClass: 'primary-v2',
				hideCancelButton: true
			});
		} else {
			history.push('/');
		}
	};

	const handleSignupSuccess = async tokenResponse => {
		const { data: userInfo } = await axios.get(
			'https://www.googleapis.com/oauth2/v3/userinfo',
			{
				headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
			}
		);

		const { email, sub: googleId, picture: imageUrl, name } = userInfo;

		signUpUser(email, googleId, imageUrl, name);
	};

	const handleSignupFailure = error => {
		/**
		 * 	"idpiframe_initialization_failed":	initialization of the Google Auth API failed (this will occur if a client doesn't have third party cookies enabled)
		 *	"popup_closed":	The user closed the popup before finishing the sign in flow.
		 *	"access_denied":	The user denied the permission to the scopes required
		 *	"immediate_failed":	No user could be automatically selected without prompting the consent flow.
		 */
		const errorMessages = {
			idpiframe_initialization_failed: [
				"Couldn't initialize Authentication API",
				'Please check if you have cookies enabled'
			],
			popup_closed: [
				'Authentication aborted',
				'It seems you accidentally closed the popup before the authentication was done, please try again.'
			],
			access_denied: [
				'Insufficient permissions',
				'Please accept the minimum required permissions'
			],
			immediate_failed: null // This one shouldn't trigger a notification
		};

		const errorMessage = errorMessages[error.error || error.type];

		if (errorMessage) {
			notificationStore.pushErrorNotification(...errorMessage);
		}
	};

	const signup = useGoogleLogin({
		onSuccess: handleSignupSuccess,
		onError: handleSignupFailure,
		onNonOAuthError: handleSignupFailure,
		flow: 'implicit'
	});

	return (
		<Fragment>
			<div className="wizard signup">
				<PublicHeader />
				<section className="hero signup">
					<div className="hero-body">
						<img
							className="is-hidden-mobile"
							src="/newHome/hero/hero-signup-bg.png"
							alt="Hero"
						/>
						<div className="container has-text-centered">
							<h1 className="title is-2">Create Your Account</h1>
							<h2 className="subtitle is-6">
								Use your{' '}
								<span className="is-secondary-night-blue">Google Account</span>{' '}
								to create your Talent Network Account.
							</h2>

							<div className="is-divider"></div>

							<div className="terms-container">
								<label className="subtitle is-6">
									<input
										type="checkbox"
										onClick={() => {
											handleAcceptTermsConditions();
										}}
									/>
									Click to agree to our{' '}
									<a
										className="is-secondary-night-blue"
										href="https://prediktive.com/terms"
										target="_blank"
										rel="noopener noreferrer">
										{' '}
										Terms Of Service
									</a>
									,{' '}
									<a
										className="is-secondary-night-blue"
										href="https://prediktive.com/privacy"
										target="_blank"
										rel="noopener noreferrer">
										Privacy Policy
									</a>{' '}
									and{' '}
									<a
										className="is-secondary-night-blue"
										href="https://prediktive.com/cookie_policy"
										target="_blank"
										rel="noopener noreferrer">
										Cookie Policy
									</a>
								</label>
							</div>
							<div className="add-lg-padding-top">
								<Button
									disabled={!termsAccepted}
									onClick={() => signup()}
									type="submit"
									className="button primary-v2 Button is-medium"
									label={'Connect With Google'}
								/>
							</div>
							<h2 className="redirect-login subtitle is-6">
								Already have an account?{' '}
								<Link className="is-secondary-night-blue" to="/login">
									Login here
								</Link>
							</h2>
						</div>
					</div>
					<div className="is-hidden"></div>
				</section>
			</div>
		</Fragment>
	);
}

export default withRouter(SignupPage);
