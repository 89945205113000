import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { modalStore } from '../layout/modals/ModalStore';
import { FormField } from '../layout/form-fields/FormField';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { testStore } from './TestStore';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { Button } from '../layout/button/Button';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	version: Yup.string().label('Version').nullable(),
	skillmeterTestId: Yup.string().label('Skillmeter ID').nullable()
});

export class TestEdit extends ModalComponent {
	state = {
		saving: false,
		savingAndImporting: false
	};
	savingAndImporting(status) {
		if (status === false || status === true) {
			this.setState({ savingAndImporting: status });
		} else {
			this.setState({ savingAndImporting: true });
		}
	}
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saveTest(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'TestEdit')}
			</Formik>
		);
	}

	renderFooter(formikBag) {
		return (
			<footer className="modal-card-foot">
				<div className="fullwidth">
					<Button
						type="button"
						className="button is-rounded is-light"
						label={'Cancel'}
						onClick={() => this.handleClickCancel()}>
						Cancel
					</Button>
					<Button
						disabled={
							!formikBag.dirty ||
							!formikBag.isValid ||
							this.state.savingAndImporting
						}
						onClick={() => {
							this.saving();
							formikBag.submitForm();
						}}
						type="submit"
						className="button is-primary is-rounded"
						label={modalStore.saveButtonLabel || 'Save'}
						loading={this.state.saving}
					/>
					<Button
						disabled={
							!formikBag.dirty || !formikBag.isValid || this.state.saving
						}
						onClick={() => {
							formikBag.setFieldValue('importTestResults', true, false);
							this.savingAndImporting();
							return formikBag.submitForm();
						}}
						type="submit"
						className="button is-primary is-rounded"
						label={modalStore.saveButtonLabel || 'Save & Import'}
						loading={this.state.savingAndImporting}
					/>
				</div>
			</footer>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Version"
							field="version"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Skillmeter ID"
							field="skillmeterTestId"
							type="text"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		if (this.props.isNew) {
			return {
				name: '',
				skillmeterTestId: '',
				version: ''
			};
		}
		const skillmeterTestId = this.props.test.skillmeterTestId;
		const version = this.props.test.version;
		return {
			name: this.props.test.name,
			version: version ? version : null,
			skillmeterTestId: skillmeterTestId ? skillmeterTestId : null,
			disabled: this.props.test.disabled
		};
	}

	async saveTest(values) {
		if (this.props.isNew) {
			this.createTest(values);
			return;
		}
		this.updateTest(values);
	}

	async updateTest(values) {
		const test = {
			name: values.name,
			version: values.version ? values.version : null,
			skillmeterTestId: values.skillmeterTestId
				? values.skillmeterTestId
				: null,
			importTestResults: values.importTestResults,
			disabled: values.disabled
		};
		testStore.update(this.props.test.id, { test: test }).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'Test Updated',
					'The test has been successfully updated.'
				);
				this.closeWithSuccess();
			} else {
				notificationStore.pushErrorNotification(
					'',
					'Failed to update the test.'
				);
			}
		});
	}

	async createTest(values) {
		const { name, skillmeterTestId, version, importTestResults } = values;
		testStore
			.create({
				name,
				version,
				skillmeterTestId: skillmeterTestId,
				importTestResults
			})
			.then(result => {
				if (result.status === 'success') {
					this.closeWithSuccess();
				} else {
					notificationStore.pushNotification({
						type: notificationStore.ERROR_NOTIFICATION,
						title: 'Create Test',
						message: 'An error has occurred. Please try again.'
					});
					modalStore.closeDialog();
				}
			});
	}
}
