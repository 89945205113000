import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { modalStore } from '../modals/ModalStore';
import { CandidateProfileSendFeedback } from '../../../candidate-portal/profile/feedback/CandidateProfileSendFeedback';
import { authStore } from '../../../shared/security/AuthStore';
import { dialogStore } from '../modals/DialogStore';
import { candidatesStore } from '../../../candidate-portal/profile/CandidatesStore';
import { notificationStore } from '../notifications/NotificationStore';

@withRouter
@observer
export class Footer extends React.Component {
	async deleteCandidateProfile() {
		const result = await candidatesStore.delete();
		if (result.status === 'success') {
			dialogStore.closeDialog();
			authStore.logout();
			this.props.history.push('/farewell');
		} else {
			notificationStore.pushErrorNotification(
				'Delete Failed',
				'Failed to delete your account.'
			);
		}
	}

	render() {
		let footerClassName = this.props.floating ? 'Footer float' : 'Footer';
		footerClassName += this.props.fullScreenLayout ? ' full-screen' : '';
		const nonLoggedUser = authStore.ready && authStore.profile == null;
		const isCandidate = !nonLoggedUser && authStore.isCandidate();
		return (
			<Fragment>
				<div className={footerClassName}>
					<div className="footerSmall">
						<a
							href="https://prediktive.com/terms"
							target="_blank"
							className="link"
							rel="noopener noreferrer">
							Terms Of Service
						</a>
						<div className="dot"></div>
						<a
							href="https://prediktive.com/privacy"
							target="_blank"
							className="link"
							rel="noopener noreferrer">
							Privacy Policy
						</a>
						<div className="dot"></div>
						<a
							href="https://prediktive.com/cookie_policy"
							target="_blank"
							className="link"
							rel="noopener noreferrer">
							Cookie Policy
						</a>
						<div className="dot"></div>
						Copyright © {new Date().getFullYear()} Prediktive LLC. All rights
						reserved.
					</div>
					{isCandidate ? (
						<Fragment>
							<a
								href="/"
								className="footerCta"
								onClick={event => {
									event.preventDefault();
									modalStore.openModal(
										{ title: 'Send Feedback' },
										<CandidateProfileSendFeedback />
									);
								}}>
								Send Feedback
							</a>{' '}
							<a
								href="/"
								className="footerDeleteProfileCta"
								onClick={event => {
									event.preventDefault();
									dialogStore.openDialog(
										{
											title: 'Delete Account',
											message: 'Are you sure you want to delete your account?',
											confirmLabel: 'Delete',
											confirmButtonClass: 'is-danger'
										},
										() => {
											dialogStore.setLoading(true);
											this.deleteCandidateProfile();
										}
									);
								}}>
								Delete my account
							</a>
						</Fragment>
					) : null}
					{this.props.isLogin ? (
						<span>
							<br /> By clicking "Sign in with your Google account", you agree
							to our
							<a href="/terms"> Terms of Service</a> and{' '}
							<a href="/privacy-policy">Privacy Statement</a>. We'll
							occasionally send you account related emails.
						</span>
					) : (
						''
					)}
				</div>
			</Fragment>
		);
	}
}
