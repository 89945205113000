import React from 'react';
import { ErrorMessage } from 'formik';

export class FormFieldError extends React.Component {
	render() {
		return (
			<ErrorMessage name={this.props.field}>
				{msg => <p className="help is-danger">{msg}</p>}
			</ErrorMessage>
		);
	}
}
