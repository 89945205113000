import { EntityStore } from '../../../shared/stores/EntityStore';
import { action, makeObservable } from 'mobx';
import { api } from '../../../shared/api/api';

class SkillStore extends EntityStore {
	constructor() {
		super('skills');
		makeObservable(this);
	}

	@action
	async getSkills(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}
}

export const skillStore = new SkillStore();
