import React from 'react';
import DatePicker from 'react-datepicker';

export class DateInput extends React.Component {
	render() {
		return (
			<DatePicker
				className="input"
				popperPlacement={this.props.popperPlacement}
				popperModifiers={
					this.props.popperModifiers
						? this.props.popperModifiers
						: DatePicker.popperModifiers
				}
				selected={this.props.value}
				onChange={newDate => this.props.onChange(newDate)}
				isClearable={true}
				showMonthYearPicker={this.props.isMonthYear ? true : false}
				dateFormat={this.props.isMonthYear ? 'MMM yyyy' : 'dd/MM/yyyy'}
			/>
		);
	}
}
