import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { FormField } from '../../layout/form-fields/FormField';
import { jobRoleStore } from './JobRoleStore';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name')
});

export class JobRoleEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					name: this.props.jobRole.name
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'JobRoleEdit')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<div className="columns">
				<div className="column is-full">
					<FormField
						required
						className="has-text-left"
						label="Name"
						field="name"
						type="text"
						placeholder="Name"
					/>
				</div>
			</div>
		);
	}

	async save(values) {
		jobRoleStore
			.update(this.props.jobRole.id, {
				jobRole: values
			})
			.then(result => {
				this.resultNotification(result, 'Job Role', 'Edit');
			});
	}
}
