import { Dropdown } from '../../../components/layout/form-fields/inputs/Dropdown';
import {
	candidatesStore,
	GITHUB_REPO_KEY,
	GITHUB_URL
} from '../CandidatesStore';
import { InputField } from '../../../components/layout/form-fields/inputs/InputField';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Icon } from '../../../components/layout/misc/Icon';

const ValidationSchema = Yup.object().shape({
	url: Yup.string().required().url().label('Url')
});

export class CandidateProfileRepoEditForm extends React.Component {
	componentDidMount() {
		if (this.props.editRepo) {
			let prefix = GITHUB_URL;
			if (this.props.editRepo.repoKey !== GITHUB_REPO_KEY) {
				prefix = '';
			}
			this.setState({
				url: this.props.editRepo.url || GITHUB_URL,
				prefix: prefix,
				repoKey: this.props.editRepo.repoKey || GITHUB_REPO_KEY
			});
		}
	}

	getInitialValues() {
		let url = GITHUB_URL;
		let prefix = GITHUB_URL;
		let repoKey = GITHUB_REPO_KEY;
		if (this.props.editRepo) {
			if (this.props.editRepo.repoKey !== GITHUB_REPO_KEY) {
				prefix = '';
			}
			url = this.props.editRepo.url;
			repoKey = this.props.editRepo.repoKey;
		}
		return {
			url: url,
			prefix: prefix,
			repoKey: repoKey
		};
	}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					delete values.prefix;
					this.props.onSave(values);
				}}>
				{formikBag => (
					<Fragment>
						<div className="columns form-container">
							<div className="column is-3">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.repoKey}
									onValueChange={value => {
										let prefix = GITHUB_URL;
										let url = GITHUB_URL;
										if (value !== GITHUB_REPO_KEY) {
											prefix = '';
											url = '';
										}
										formikBag.setFieldValue('repoKey', value);
										formikBag.setFieldValue('prefix', prefix);
										formikBag.setFieldValue('url', url);
									}}
									options={candidatesStore.repoKeyOptions}
									optionValue={'value'}
								/>
							</div>
							<div className="column is-9">
								<InputField
									prefix={formikBag.values.prefix}
									value={formikBag.values.url}
									className="has-text-left"
									field="url"
									type="text"
									placeholder="Url"
									onValueChange={value => {
										formikBag.setFieldValue('url', value);
									}}
								/>
								{!formikBag.errors.url ? null : (
									<p className="help is-danger">{formikBag.errors.url}</p>
								)}
							</div>
						</div>
						<div className="columns">
							<div className="column is-full has-text-right form-confirm-buttons">
								<button
									title="Cancel"
									type="button"
									className="button add-sm-margin-right action-button is-danger is-rounded is-circle is-small"
									onClick={event => {
										this.props.onCancel();
									}}>
									<Icon icon="fa-times" />
								</button>
								<button
									title="Save changes"
									disabled={!formikBag.dirty || !formikBag.isValid}
									type="button"
									className="button action-button is-primary is-rounded is-circle is-small"
									onClick={event => {
										formikBag.submitForm();
									}}>
									<Icon icon="fa-check" />
								</button>
							</div>
						</div>
					</Fragment>
				)}
			</Formik>
		);
	}
}
