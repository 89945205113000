import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../../../layout/form-fields/FormField';
import { DateInput } from '../../../layout/form-fields/inputs/DateInput';
import { candidatesStore } from '../../CandidatesStore';
import { SkillDropdown } from '../../../layout/form-fields/inputs/SkillDropdown';
import { InputField } from '../../../layout/form-fields/inputs/InputField';
import ProjectDraggableItem from './ProjectDraggableItem';
import { utils } from '../../../../shared/stores/Utils';
import { Button } from '../../../layout/button/Button';
import { FieldRequiredIndicator } from '../../../layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({
	jobTitle: Yup.string().required().label('Job Title'),
	company: Yup.string().required().label('Company'),
	from: Yup.mixed().nonNullable('From is a required field').label('From'),
	to: Yup.mixed()
		.label('To')
		.nullable()
		.test(
			'end-date-less-than-from-date',
			'The experience end date is required',
			function (value) {
				if (this.parent.isCurrentExperience && value === null) {
					return true;
				}
				if (!this.parent.isCurrentExperience && value) {
					return true;
				}
				return false;
			}
		)
		.test(
			'end-date-less-than-from-date',
			'The experience end date must occur after the start date',
			function (value) {
				if (value && this.parent.from) {
					const from = new Date(this.parent.from);
					const valueDate = new Date(value);
					if (valueDate.getTime() < from.getTime()) {
						return false;
					}
				}
				return true;
			}
		)
});

export class CandidateProfileExperienceEdit extends ModalComponent {
	state = {
		projects: [],
		isAddingEditingProject: false,
		isCurrentExperience: null,
		projectTitle: '',
		projectDescription: '',
		projectLinkUrls: [''],
		projectIndex: null
	};
	currentIndex = 0;

	constructor() {
		super();
		this.projectNameRef = React.createRef();
	}

	componentDidMount() {
		if (
			this.props.experience &&
			this.props.experience.candidateExperienceProjects.length > 0
		) {
			const projects = this.props.experience.candidateExperienceProjects.map(
				(item, index) => {
					return {
						id: item.id,
						title: item.title,
						description: item.description,
						linkUrls: item.linkUrls || [''],
						index: index
					};
				}
			);
			this.currentIndex = projects.length;
			this.setState({ projects: projects });
		}
		if (!this.props.experience) {
			this.setState({
				isCurrentExperience: false
			});
			return;
		}
		this.setState({
			isCurrentExperience:
				this.props.experience && this.props.experience.to ? false : true
		});
	}

	getInitialValues() {
		if (this.props.isNew) {
			return {
				jobTitle: '',
				company: '',
				location: '',
				description: '',
				from: new Date(),
				isCurrentExperience: false,
				to: null,
				skills: []
			};
		} else {
			return {
				jobTitle: this.props.experience.jobTitle,
				company: this.props.experience.company,
				location: this.props.experience.location || '',
				description: this.props.experience.description || '',
				from: new Date(this.props.experience.from),
				to: this.props.experience.to
					? new Date(this.props.experience.to)
					: null,
				isCurrentExperience: this.props.experience.to ? false : true,
				skills: this.props.experience.skills
			};
		}
	}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileExperienceEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-6">
						<FormField
							required
							className="has-text-left"
							label="Job Title"
							field="jobTitle"
							type="text"
						/>
					</div>
					<div className="column is-3">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								From <FieldRequiredIndicator />
							</label>
							<div className="control">
								<DateInput
									value={formikBag.values.from}
									onBlur={() => formikBag.setFieldTouched('from')}
									onChange={newDate => {
										formikBag.setFieldValue('from', newDate);
									}}
									isMonthYear={true}
								/>
								<p className="help is-danger">{formikBag.errors.from}</p>
							</div>
						</div>
					</div>
					<div className="column is-3">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								To{' '}
								{!this.state.isCurrentExperience ? (
									<FieldRequiredIndicator />
								) : null}
							</label>
							<div className="control">
								{!this.state.isCurrentExperience ? (
									<Fragment>
										<DateInput
											value={formikBag.values.to}
											onBlur={() => formikBag.setFieldTouched('from')}
											onChange={newDate =>
												formikBag.setFieldValue('to', newDate)
											}
											isMonthYear={true}
										/>
										<p className="help is-danger to-date-error">
											{formikBag.errors.to}
										</p>
									</Fragment>
								) : (
									<div className="add-margin-top">
										<span>Present</span>
									</div>
								)}
							</div>
						</div>
					</div>{' '}
				</div>
				<div className="columns">
					<div className="column field is-fullwidth has-text-left">
						<div className="control">
							<label className="checkbox subtitle is-7">
								<input
									type="checkbox"
									defaultChecked={this.state.isCurrentExperience}
									onClick={event => {
										this.setState({
											isCurrentExperience: event.target.checked
										});
										formikBag.setFieldValue('to', null);
										formikBag.setFieldValue(
											'isCurrentExperience',
											event.target.checked
										);
									}}
								/>{' '}
								I'm currently working in this role
							</label>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<FormField
							required
							className="has-text-left"
							label="Company"
							field="company"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Location"
							field="location"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Description"
							field="description"
							type="textarea"
							maxlength={1000}
							placeholder="Description"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">Technologies</label>
							<SkillDropdown
								value={formikBag.values.skills}
								onValueChange={values =>
									formikBag.setFieldValue('skills', values)
								}
							/>
						</div>
					</div>
				</div>
				<hr />
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left remove-margin-bottom">
							<label className="subtitle project is-7">Projects</label>
						</div>
					</div>
				</div>
				{this.renderProjectList(formikBag)}
			</Fragment>
		);
	}

	renderProjectList(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full has-text-left">
						<div className="tabbed-panel add-margin-top">
							<div className="box small">
								<div className="description-section">
									<div className="sub-section has-text-left sub-section-projects">
										<div className="sub-section-content ">
											{this.state.projects.map((project, index) => {
												return (
													<ProjectDraggableItem
														key={project.index}
														project={project}
														index={index}
														isEditing={
															this.state.projectIndex === project.index
														}
														isAddEditFormActive={
															this.state.isAddingEditingProject
														}
														onMove={(dragIndex, hoverIndex) => {
															const projects = utils.arrangeArray(
																this.state.projects,
																dragIndex,
																hoverIndex
															);
															formikBag.setFieldValue('projects', projects);
															this.setState({ projects: projects });
														}}
														onEdit={project => {
															this.setState(
																{
																	projectIndex: project.index,
																	projectTitle: project.title,
																	projectLinkUrls: project.linkUrls,
																	projectDescription: project.description,
																	isAddingEditingProject: true
																},
																() =>
																	this.projectNameRef.current.focus &&
																	this.projectNameRef.current.focus()
															);
														}}
														onDelete={project => {
															const projects = this.state.projects.filter(
																item => item.index !== project.index
															);
															this.setState({ projects: projects });
															formikBag.setFieldValue('projects', projects);
														}}
													/>
												);
											})}
											{this.state.projects.length === 0 ? (
												<div className="has-text-centered">No projects yet</div>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={
						'columns ' + (this.state.isAddingEditingProject ? 'is-hidden' : '')
					}>
					<div className="column is-full has-text-right">
						<button
							type="button"
							className="button project-btn is-primary is-rounded is-small add-button"
							onClick={event => {
								this.setState(
									{ isAddingEditingProject: true },
									() =>
										this.projectNameRef.current.focus &&
										this.projectNameRef.current.focus()
								);
							}}>
							Add New
						</button>
					</div>
				</div>
				<div
					className={!this.state.isAddingEditingProject ? 'is-invisible' : ''}>
					<div className="columns form-container">
						<div className="column is-full">
							<InputField
								inputRef={this.projectNameRef}
								value={this.state.projectTitle}
								className="has-text-left"
								field="projectTitle"
								type="text"
								placeholder="Project Title"
								onValueChange={value => {
									this.setState({ projectTitle: value });
								}}
							/>
							{!this.state.projectTitle && (
								<p className="help is-danger has-text-left">
									Project Title is a required field.
								</p>
							)}
						</div>
					</div>
					<div className="columns form-container">
						<div className="column is-full">
							<InputField
								value={this.state.projectDescription}
								className="has-text-left"
								field="projectDescription"
								type="textarea"
								placeholder="Project Description"
								onValueChange={value => {
									this.setState({ projectDescription: value });
								}}
							/>
						</div>
					</div>
					{(this.state.projectLinkUrls || ['']).map((linkUrl, index) => (
						<div className="columns form-container">
							<div className="column is-11">
								<InputField
									value={linkUrl}
									className="has-text-left"
									field="projectLinkUrl"
									type="text"
									placeholder="Project Link Url"
									onValueChange={value => {
										const projectLinks = [...this.state.projectLinkUrls];
										projectLinks[index] = value;
										this.setState({
											projectLinkUrls: projectLinks
										});
									}}
								/>
								{!(
									!this.state.projectLinkUrls[index] ||
									this.isValidURL(this.state.projectLinkUrls[index])
								) && (
									<p className="help is-danger has-text-left">
										Project URL must be a valid link.
									</p>
								)}
							</div>
						</div>
					))}
					<div className="column is-3">
						<Fragment>
							<div className="is-flex">
								<a
									href={'void'}
									className="add-margin-right"
									onClick={e => {
										e.preventDefault();
										this.setState({
											projectLinkUrls: this.state.projectLinkUrls.concat([''])
										});
									}}>
									<Button
										className="is-rounded is-circle is-blue"
										icon={'plus'}
									/>
								</a>
								{(this.state.projectLinkUrls || []).length > 1 ? (
									<a
										href={'void'}
										onClick={e => {
											e.preventDefault();
											this.setState({
												projectLinkUrls: this.state.projectLinkUrls.slice(0, -1)
											});
										}}>
										<Button
											className="is-rounded is-circle is-danger"
											icon={'minus'}
										/>
									</a>
								) : null}
							</div>
						</Fragment>
					</div>
					<div className="columns">
						<div className="column is-full has-text-right form-confirm-buttons">
							<Button
								title="Cancel"
								type="button"
								className="button add-sm-margin-right project-btn is-danger is-rounded is-small"
								onClick={event => this.clearProjectInputs()}
								icon="times"
							/>
							<Button
								title="Save changes"
								disabled={
									!this.state.projectTitle.length ||
									!(this.state.projectLinkUrls || []).every(
										projectLinkUrl =>
											projectLinkUrl === '' ||
											(projectLinkUrl && this.isValidURL(projectLinkUrl))
									)
								}
								type="button"
								className="button project-btn is-primary is-rounded is-small"
								icon="check"
								onClick={event => {
									let projects = this.state.projects;
									// is edit
									if (this.state.projectIndex !== null) {
										const match = projects.find(
											item => item.index === this.state.projectIndex
										);
										match.title = this.state.projectTitle;
										match.description = this.state.projectDescription;
										match.linkUrls = this.state.projectLinkUrls;
										// is create
									} else {
										this.currentIndex += 1;
										const project = {
											title: this.state.projectTitle,
											description: this.state.projectDescription,
											linkUrls: this.state.projectLinkUrls,
											index: this.currentIndex
										};
										projects.push(project);
									}
									this.setState({
										projects: projects
									});
									formikBag.setFieldValue('projects', projects);
									this.clearProjectInputs();
								}}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	async save(values) {
		const request = {
			experience: {
				jobTitle: values.jobTitle,
				company: values.company,
				location: values.location,
				description: values.description,
				from: values.from,
				to: values.to
			},
			skills: values.skills.map(item => item.id),
			projects: this.state.projects
		};
		if (this.props.isNew) {
			candidatesStore
				.createExperience(this.props.candidate.id, request)
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Experience',
						'The Experience has been saved successfully.'
					);
				});
		} else {
			candidatesStore
				.updateExperience(this.props.experience.id, request)
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Experience',
						'The Experience has been saved successfully.'
					);
				});
		}
	}

	clearProjectInputs() {
		this.setState({
			projectTitle: '',
			projectDescription: '',
			projectLinkUrl: '',
			projectIndex: null,
			isAddingEditingProject: false
		});
	}

	isValidURL(string) {
		if (!string) return false;
		const res = string.match(
			/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g
		);
		return res !== null;
	}
}
