import { action, observable, makeObservable } from 'mobx';

export class ModalStore {
	@observable isOpen = false;
	@observable title;
	@observable className = '';
	modalComponent;

	constructor() {
		makeObservable(this);
	}

	@action
	openModal(options, modalComponent) {
		this.isOpen = true;
		this.title = options.title;
		this.saveButtonLabel = options.saveButtonLabel;
		this.position = this.getPositionClass(options.position);
		this.size = this.getSizeClass(options.size);
		this.modalComponent = modalComponent;
		document.body.classList.add('no-scroll');
	}

	getPositionClass(position) {
		if (
			position === 'Center' ||
			position === 'centered' ||
			position === 'center'
		) {
			return 'is-centered';
		}
		return '';
	}

	getSizeClass(size) {
		if (size === 'XL' || size === 'xl') {
			return 'is-xl';
		}

		if (size === '2xl') {
			return 'is-2xl';
		}
		return '';
	}

	@action
	closeDialog() {
		this.isOpen = false;
		this.title = null;
		this.className = '';
		this.saveButtonLabel = null;
		setTimeout(() => {
			this.modalComponent = null;
			document.body.classList.remove('no-scroll');
		}, 100);
	}

	getModalComponent() {
		return this.isOpen ? modalStore.modalComponent : null;
	}
}

const modalStore = new ModalStore();
export { modalStore };
