import React from 'react';

export class CandidateProfileExperienceProjects extends React.Component {
	renderProject(project) {
		return (
			<>
				<div className="title is-blue-text is-7">{project.title}</div>
				<div>{project.description}</div>
				{(project.linkUrls || []).map(linkUrl => (
					<div>
						<a href={linkUrl} target="_blank" rel="noopener noreferrer">
							{linkUrl}
						</a>
					</div>
				))}
			</>
		);
	}

	render() {
		return (
			<div className="sub-section sub-section-projects">
				<div className="subtitle is-7">
					<i className="fa-solid fa-pencil" />
					Projects
				</div>
				<div className="sub-section-content">
					{this.props.projects.map((project, index) => {
						return (
							<>
								<div className="project-content" key={project.id}>
									<i class="fa-solid fa-caret-right"></i>
									<div>{this.renderProject(project)}</div>
								</div>
								<div
									className={
										index < this.props.projects.length - 1 ? 'is-divider' : ''
									}
								/>
							</>
						);
					})}
				</div>
			</div>
		);
	}
}
