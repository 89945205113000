import React, { Fragment } from 'react';
import { modalStore } from '../layout/modals/ModalStore';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from '../layout/form-fields/inputs/Dropdown';
import { FormField } from '../layout/form-fields/FormField';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { userStore } from './UserStore';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { FieldRequiredIndicator } from '../layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({
	email: Yup.string().required().email().label('Email')
});

export class UserInvite extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					email: '',
					name: '',
					role: 'Admin'
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.inviteUser(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'UserInvite')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Email (Google Account)"
							field="email"
							type="text"
							placeholder="email@example.com"
							icon="fa-envelope"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Role <FieldRequiredIndicator />
							</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value="Admin"
									onValueChange={value =>
										formikBag.setFieldValue('role', value)
									}
									options={['Admin', 'Manager', 'Recruiter']}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	async inviteUser(values) {
		const { name, email, role } = values;
		userStore.create({ email: email, role: role, name: name }).then(result => {
			if (result.status === 'success') {
				userStore.invite(email, name, role).then(inviteR => {
					notificationStore.pushNotification({
						type: notificationStore.SUCCESS_NOTIFICATION,
						title: 'Invite User',
						message: 'The user has been successfully invited.'
					});
					this.closeWithSuccess();
				});
			} else {
				notificationStore.pushNotification({
					type: notificationStore.ERROR_NOTIFICATION,
					title: 'Invite User',
					message: 'An error has occurred. Please try again.'
				});
				modalStore.closeDialog();
			}
		});
	}
}
