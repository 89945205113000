import React from 'react';
import { withRouter } from 'react-router-dom';
import { jobOpeningStore } from './JobOpeningStore';
import { InputField } from '../layout/form-fields/inputs/InputField';
import { Dropdown } from '../layout/form-fields/inputs/Dropdown';
import { Pagination } from '../layout/pagination/Pagination';
import { JobOpeningCreate } from './JobOpeningCreate';
import { JobOpeningEdit } from './JobOpeningEdit';
import { JobOpeningsTable } from './JobOpeningsTable';
import { ListStore } from '../../shared/stores/ListStore';
import { ListHeaderComponent } from '../layout/list/ListHeaderComponent';
import { observer } from 'mobx-react';
import { modalStore } from '../layout/modals/ModalStore';
import { JobOpeningCandidateAdd } from './JobOpeningCandidateAdd';
import { dialogStore } from '../layout/modals/DialogStore';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';

@rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER])
@withRouter
@observer
export class JobOpenings extends React.Component {
	constructor(props) {
		super(props);
		this.listStore = new ListStore(jobOpeningStore, {
			entityName: 'Job Opening',
			apiObjectName: 'job',
			createModal: (
				<JobOpeningCreate handleSuccess={() => this.listStore.search()} />
			),
			createModalTitle: 'Create Job Opening',
			editModal: item => (
				<JobOpeningEdit
					job={item}
					handleSuccess={() => this.listStore.search()}
				/>
			),
			editModalTitle: 'Edit Job Opening'
		});
	}

	componentDidMount() {
		const resetQuery = this.preSetQuery();
		this.listStore.search(resetQuery);
	}

	componentWillUnmount() {
		this.listStore.store.resetQuery();
	}

	preSetQuery() {
		// search for default sort on url params
		const query =
			this.props.location.state && this.props.location.state.query
				? this.props.location.state.query
				: null;
		if (query) {
			if (query.client) {
				jobOpeningStore.setQuery('client', query.client);
				delete query.client;
			}
			// apply default sort by created date
		} else {
			jobOpeningStore.setQuery('sort', { column: 'createdAt', asc: false });
		}
		return false;
	}

	render() {
		return (
			<div className="Jobs container">
				<ListHeaderComponent
					title={'Job Openings'}
					createActionTitle={this.listStore.modalsConfigs.createModalTitle}
					handleCreate={() => this.listStore.handleCreate()}
				/>
				<div className="columns">
					<div className="column is-2">
						<InputField
							className="has-text-left"
							field="title"
							placeholder="Job Title"
							type="search"
							icon="fa-search"
							onValueChange={value => {
								jobOpeningStore.setQuery('title', value);
								this.listStore.search();
							}}
						/>
					</div>
					<div className="column is-2">
						<InputField
							value={jobOpeningStore.getQuery('client') || ''}
							className="has-text-left"
							field="client"
							placeholder="Client"
							type="search"
							icon="fa-search"
							onValueChange={value => {
								jobOpeningStore.setQuery('client', value);
								this.listStore.search();
							}}
						/>
					</div>
					<div className="column is-2">
						<Dropdown
							allowClear={true}
							placeholder="Status"
							onValueChange={value => {
								jobOpeningStore.setQuery('status', value);
								this.listStore.search();
							}}
							options={['Enabled', 'Disabled']}
						/>
					</div>
					<div className="column is-6">
						<Pagination
							loading={this.listStore.loading}
							pagination={jobOpeningStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<JobOpeningsTable
							data={this.listStore.data}
							sort={jobOpeningStore.query.sort}
							onSort={this.listStore.handleSort}
							onEdit={this.listStore.handleEdit}
							onDisable={item => this.listStore.handleDisable(item, true)}
							onDelete={item => this.listStore.handleDelete(item, null, true)}
							onAddCandidate={this.handleAddCandidate}
							onDeleteCandidate={this.handleDeleteCandidate}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 bottom-pagination">
						<Pagination
							loading={this.listStore.loading}
							pagination={jobOpeningStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
			</div>
		);
	}

	handleAddCandidate = job => {
		modalStore.openModal(
			{
				title: 'Add Candidates',
				saveButtonLabel: 'Add'
			},
			<JobOpeningCandidateAdd
				job={job}
				handleSuccess={() => {
					this.listStore.search();
				}}
			/>
		);
	};

	handleDeleteCandidate = (job, candidate) => {
		dialogStore.openDialog(
			{
				title: 'Remove Candidate',
				message: 'Are you sure you want to remove this candidate?'
			},
			() => {
				jobOpeningStore.deleteCandidate(job.id, candidate.id).then(result => {
					if (result.status === 'success') {
						notificationStore.pushSuccessNotification(
							'Remove Candidate',
							'The candidate has been successfully removed.'
						);
						this.listStore.search();
					} else {
						notificationStore.pushErrorNotification(
							'Remove Candidate',
							'Failed to remove the candidate.'
						);
					}
				});
			}
		);
	};
}
