import { FieldRequiredIndicator } from '../../../components/layout/form-fields/inputs/FieldRequiredIndicator';
import React, { Fragment } from 'react';
import { Dropdown } from '../../../components/layout/form-fields/inputs/Dropdown';
import { ExpertiseAreaDropdown } from '../../../components/layout/form-fields/inputs/ExpertiseAreaDropdown';
import { candidatesStore } from '../CandidatesStore';

export class CandidateProfileAboutExpertiseAreas extends React.Component {
	state = {
		value: []
	};
	componentDidMount() {
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
	}

	updateExpertiseAreaFormData(expertiseArea, updatedData) {
		const expertiseAreas = this.props.formikBag.values.expertiseAreas;
		const newExpertiseAreas = expertiseAreas.map(area => {
			if (expertiseArea.id === area.id) {
				const updatedExperienceArea = { ...area, ...updatedData };
				return updatedExperienceArea;
			}
			return area;
		});
		this.props.formikBag.setFieldValue('expertiseAreas', newExpertiseAreas);
	}

	getExperienceAndExpertiseFromForm(expertiseArea) {
		const expertiseAreas = this.props.formikBag.values.expertiseAreas || [];
		return expertiseAreas.find(area => {
			return expertiseArea.id === area.id ? area : null;
		});
	}
	render() {
		const errorsEnum = {
			fieldsError: 'Please complete all fields for every Expertise Area',
			expertiseAreasError: 'Please select at least one Expertise Area'
		};

		return (
			<Fragment>
				<div className="expertise-areas">
					<div className="columns">
						<div className="column is-full">
							<div className="field has-text-left">
								<label
									className={
										this.props.styleVersion === 'v2'
											? 'title is-5 has-text-weight-bold'
											: 'subtitle is-7'
									}>
									What is your <strong>area of expertise?</strong>{' '}
									{this.props.required ? <FieldRequiredIndicator /> : null}
								</label>
								<ExpertiseAreaDropdown
									value={this.props.formikBag.values.expertiseAreas}
									styleVersion={this.props.styleVersion}
									onValueChange={values => {
										const formValues = (values || []).map(value => {
											const existingExpertiseArea =
												this.getExperienceAndExpertiseFromForm(value);
											return {
												name: value.name,
												id: value.id,
												experienceYears: existingExpertiseArea
													? existingExpertiseArea.experienceYears
													: null,
												level: existingExpertiseArea
													? existingExpertiseArea.level
													: null
											};
										});
										this.props.formikBag.setFieldValue(
											'expertiseAreas',
											formValues
										);
										this.props.formikBag.setFieldTouched(
											'expertiseAreas',
											true,
											false
										);
										this.setState({ value: values });
									}}
									optionValue={'value'}
									isInvalid={
										this.props.formikBag.touched.expertiseAreas &&
										this.props.formikBag.errors.expertiseAreas ===
											errorsEnum.expertiseAreasError
									}
								/>
							</div>
						</div>
					</div>
					{(this.props.formikBag.values.expertiseAreas || []).map(
						(expertiseArea, index) => {
							return (
								<Fragment key={index}>
									<div
										className={`columns ${
											this.props.styleVersion === 'v2'
												? ''
												: 'is-multiline add-margin-top'
										} `}>
										<div
											className={`column ${
												this.props.styleVersion === 'v2' ? 'is-5' : 'is-12'
											} `}>
											<div className="selector-tag is-marginless">
												<div className="tag-content">{expertiseArea.name}</div>
											</div>
										</div>
										<div
											className={`column ${
												this.props.styleVersion === 'v2'
													? 'is-3 is-marginless'
													: 'is-12'
											} `}>
											<div className="control">
												<Dropdown
													enableFixedMode={true}
													value={expertiseArea.level}
													styleVersion={this.props.styleVersion}
													placeholder={'Expertise Level'}
													onValueChange={value => {
														expertiseArea.level = value;

														this.props.formikBag.setFieldValue(
															'expertiseAreas',
															this.props.formikBag.values.expertiseAreas
														);
													}}
													options={candidatesStore.expertiseAreaLevelOptions}
													optionValue={'value'}
													isInvalid={
														this.props.formikBag.errors.expertiseAreas ===
															errorsEnum.fieldsError && !expertiseArea.level
													}
												/>
											</div>
										</div>
										<div
											className={`column ${
												this.props.styleVersion === 'v2'
													? 'is-4 is-marginless'
													: 'is-12'
											} `}>
											<div className="control">
												<Dropdown
													enableFixedMode={true}
													value={expertiseArea.experienceYears}
													styleVersion={this.props.styleVersion}
													placeholder={'Years of experience'}
													onValueChange={value => {
														expertiseArea.experienceYears = value;

														this.props.formikBag.setFieldValue(
															'expertiseAreas',
															this.props.formikBag.values.expertiseAreas
														);
													}}
													options={candidatesStore.expertiseAreaYearsOptions}
													optionValue={'value'}
													isInvalid={
														this.props.formikBag.errors.expertiseAreas ===
															errorsEnum.fieldsError &&
														!expertiseArea.experienceYears
													}
												/>
											</div>
										</div>
									</div>
								</Fragment>
							);
						}
					)}
				</div>
			</Fragment>
		);
	}
}
