import React, { Fragment } from 'react';
import { Button } from '../../../components/layout/button/Button';
import { dialogStore } from '../../../components/layout/modals/DialogStore';
import { modalStore } from '../../../components/layout/modals/ModalStore';
import { CandidateProfileCertificationEdit } from './CandidateProfileCertificationEdit';
import { candidateProfileStore } from '../CandidateProfileStore';
import { candidatesStore } from '../CandidatesStore';
import { notificationStore } from '../../../components/layout/notifications/NotificationStore';
import { observer } from 'mobx-react';

@observer
export class CandidateProfileCertifications extends React.Component {
	getDisplayEducationStatus(value) {
		const educationStatus = candidatesStore.educationStatusOptions.find(
			status => status.value === value
		);
		return educationStatus.text;
	}
	render() {
		return (
			<div className="content-container">
				{this.props.certifications.map((certification, index) => {
					return (
						<Fragment key={certification.id}>
							<div className="description-content">
								<div
									className={`description-section education ${
										!certification.visible ? 'is-disabled' : ''
									}`}>
									<div className="is-pulled-right button-group">
										{!candidateProfileStore.isLocked && (
											<>
												<Button
													className="is-rounded is-small edit-button"
													icon="trash"
													iconClass="has-text-danger"
													onClick={event => {
														dialogStore.openDialog(
															{
																title: 'Delete Certification',
																message:
																	'This certification entry will be deleted. Are you sure?',
																confirmLabel: 'Delete',
																confirmButtonClass: 'is-danger'
															},
															() => this.delete(certification)
														);
													}}
												/>
												<Button
													className="is-rounded is-small edit-button"
													icon="pencil-alt"
													iconClass="has-text-grey"
													onClick={event => {
														modalStore.openModal(
															{ title: 'Edit Certification' },
															<CandidateProfileCertificationEdit
																certification={certification}
																handleSuccess={() => {
																	candidateProfileStore.reloadCandidate();
																}}
															/>
														);
													}}
												/>
											</>
										)}
									</div>
									<div className="title is-5">{certification.degree}</div>
									<div className="subtitle is-blue-text is-6">
										{certification.institution}
									</div>
									<div className="additional-information-section">
										<div className="subtitle is-7 location">
											{certification.location && (
												<span>
													<i className="fas fa-map-marker-alt" />
													<span>{certification.location}</span>
												</span>
											)}
										</div>
										<div className="subtitle is-7 status">
											<span className="status-text">
												{this.getDisplayEducationStatus(certification.status)}
											</span>
											{certification.status === 'graduated' &&
											certification.graduationYear ? (
												<span>
													Completion Year: {certification.graduationYear}
												</span>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					);
				})}
			</div>
		);
	}

	async delete(item) {
		candidatesStore.deleteCertification(item.id).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'Certification Deleted',
					'The Certification has been successfully deleted.'
				);
				candidateProfileStore.reloadCandidate();
			} else {
				notificationStore.pushErrorNotification(
					'Delete Failed',
					'Failed to delete the Certification'
				);
			}
		});
	}
}
