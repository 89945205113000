import React from 'react';
import { withRouter } from 'react-router-dom';
import { authStore } from '../../../../shared/security/AuthStore';

@withRouter
export class CandidatePublicProfileRedirect extends React.Component {
	componentDidMount() {
		const loggedIn = authStore.isUserLogged();
		const { history, location, match } = this.props;
		const queryParameters = new URLSearchParams(location.search);
		const token = queryParameters.get('token');

		if (token) {
			history.push(`/profile?token=${token}`);
		} else if (match.params.slug) {
			history.push('/profile', { slug: match.params.slug });
		} else {
			history.push(`${loggedIn ? '/' : '/login'}${location.hash}`);
		}
	}

	render() {
		return <></>;
	}
}
