import React from 'react';
import { ResponsiveLine } from '@nivo/line';

export class CandidatePublicProfileTestResultsCanvas extends React.Component {
	generateResultTestPoint = () => {
		const candidateLineData = {
			id: this.props.candidate.name,
			color: '#02E6B5',
			data: []
		};
		const averageLineData = {
			id: 'Average',
			color: '#7E859E',
			data: []
		};
		this.props.testResults.forEach((testResult, index) => {
			if (!testResult.visible) return;
			const candidatePoint = {
				x: testResult.test.name,
				y: testResult.score
			};
			const averagePoint = {
				x: testResult.test.name,
				y: testResult.test.average_score
			};
			candidateLineData.data.push(candidatePoint);
			averageLineData.data.push(averagePoint);
		});
		const resultTestPoints = [{ ...candidateLineData }, { ...averageLineData }];
		return resultTestPoints;
	};

	generateSampleSizePerTest = () => {
		const sampleSizePerTest = {};
		this.props.testResults.forEach((testResult, index) => {
			sampleSizePerTest[testResult.test.name] = testResult.test.sample_size;
		});
		return sampleSizePerTest;
	};

	render() {
		const resultTestPoints = this.generateResultTestPoint();
		const format = (value, index) => {
			const MAX_VALUES_TO_SHOW = 22;
			if (value.length > MAX_VALUES_TO_SHOW) {
				value = value.substring(0, MAX_VALUES_TO_SHOW) + '...';
			}
			return value;
		};
		const stylesChart = {
			fontFamily: 'Work Sans',
			position: 'absolute',
			width: '100%',
			height: 250
		};

		const MyResponsiveLine = (
			<ResponsiveLine
				data={resultTestPoints}
				margin={{ top: 50, right: 20, bottom: 50, left: 50 }}
				xScale={{ type: 'point' }}
				yScale={{ type: 'linear', stacked: false, min: 0, max: 100 }}
				curve="monotoneX"
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					format: format,
					legend: '',
					legendOffset: 36,
					legendPosition: 'middle'
				}}
				axisLeft={{
					tickValues: [0, 50, 100],
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'TEST SCORE',
					legendOffset: -40,
					legendPosition: 'middle'
				}}
				enableGridX={true}
				enableGridY={false}
				lineWidth={2}
				pointSize={4}
				pointColor={d => d.color}
				pointBorderWidth={1}
				pointBorderColor={d => d.color}
				theme={{
					fontFamily: 'Work Sans',
					axis: {
						ticks: {
							text: {
								fontSize: 12
							}
						}
					}
				}}
				pointLabelYOffset={-12}
				useMesh={true}
				gridYValues={[0, 50, 100]}
				colors={d => d.color}
				isInteractive={true}
				enableSlices="x"
				legends={[
					{
						anchor: 'top',
						direction: 'row',
						justify: false,
						translateX: 0,
						translateY: -20,
						itemsSpacing: 2,
						itemDirection: 'left-to-right',
						itemWidth: 80,
						itemHeight: 15,
						symbolSize: 12,
						symbolShape: 'circle',
						symbolBorderColor: 'rgba(0, 0, 0, .5)'
					}
				]}
			/>
		);
		return (
			<div key="chart" style={stylesChart}>
				{MyResponsiveLine}
			</div>
		);
	}
}
