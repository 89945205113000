import React from 'react';
import { authStore } from './AuthStore';
import { Unauthorized } from '../../components/layout/security/Unauthorized';

const USER_ROLES = Object.freeze({
	ADMIN: 'Admin',
	MANAGER: 'Manager',
	CANDIDATE: 'Candidate',
	RECRUITER: 'Recruiter'
});

const rolesAllowed = roles => WrappedComponent => {
	return class extends React.Component {
		render() {
			if (isUserDisabled()) {
				console.error(
					`Unauthorized access to /${WrappedComponent.displayName}`
				);
				return <Unauthorized />;
			}
			if (isUserInRoles(roles)) {
				return <WrappedComponent roles={roles} {...this.props} />;
			} else {
				console.error(
					`Unauthorized access to /${WrappedComponent.displayName}`
				);
				return <Unauthorized />;
			}
		}
	};
};

function isUserDisabled() {
	return authStore.isUserDisabled();
}

function isUserInRoles(roles) {
	if (!roles.length) {
		return true;
	}
	let authorized = false;
	roles.forEach(role => {
		if (authStore.isUserInRole(role)) {
			authorized = true;
			return;
		}
	});
	return authorized;
}

export { rolesAllowed, USER_ROLES, isUserInRoles };
