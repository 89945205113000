import React, { Component } from 'react';
import { Icon } from 'components/layout/misc/Icon';
import { ScorecardSection } from './ScorecardSection';
import { ScorecardCircle } from './ScorecardCircle';
import { isMobile } from 'shared/utils';
import {
	candidateHiringOptionLabel,
	candidateSeniorityOptionLabel
} from 'components/candidates/profile/scorecards/CandidateScorecardStore';
import moment from 'moment';

export class Scorecard extends Component {
	state = {
		open: false
	};

	constructor() {
		super();
		this.toggleContainer = this.toggleContainer.bind(this);
	}

	toggleContainer(event) {
		event.preventDefault();

		this.setState({ open: !this.state.open });
	}

	render() {
		const { scorecard, renderActionButtons, isExpired } = this.props;

		return (
			<div
				className={`ScorecardContainer is-flex is-flex-direction-row gap-4 ${
					this.state.open ? 'is-open' : ''
				}`}>
				<div className="Tab tabbed-panel">
					<div
						className="Tab__Title is-flex is-justify-content-space-between"
						onClick={this.toggleContainer}>
						<h3 className="title is-5">{scorecard.name}</h3>
						{renderActionButtons()}
						<div className="horizontal-divider"></div>
						{isExpired ? <span className="tag is-red">Expired</span> : null}
						<div className="is-flex is-align-items-center gap-5">
							<ScorecardCircle
								key={scorecard.overallScore}
								rating={Number(scorecard.overallScore)}
								width={25}
								height={25}
								fullCircleColor="#19E1D0"
								emptyCircleColor="#314EBD"
								type="circle"
								hasTooltip={!isMobile()}
							/>
						</div>
						<button
							className="ToggleScorecardButton button level-item"
							onClick={this.toggleContainer}>
							<Icon
								className="is-big"
								icon={this.state.open ? 'fa-chevron-down' : 'fa-chevron-up'}
							/>
						</button>
					</div>
					<div className="ScorecardAdminInfo is-flex">
						<div>
							<span className="is-grey is-5 mr-2">Created by:</span>
							<strong className="is-5">{scorecard.createdByUser.name}</strong>
						</div>
						<div>
							<span className="is-grey is-5 mr-2">Creation date:</span>
							<strong className="is-5">
								{moment(scorecard.createdAt).format('ll')}
							</strong>
						</div>
					</div>
					{this.state.open ? (
						<>
							<div className="Tab__Content">
								{scorecard && (
									<div className="ScorecardContent">
										<div className="ScorecardContent__SectionList">
											{scorecard.sections.map((section, index) => (
												<ScorecardSection
													key={`ScorecardSection-${index}-${section.score}`}
													section={section}
													collapsible
												/>
											))}
										</div>
									</div>
								)}
							</div>
							<div className="ScorecardInfoBox__content">
								<p className="title is-7">Comments</p>
								<p className="is-text-pre-wrap is-text-word-break">
									{scorecard.publicNotes}
								</p>
							</div>
							<div className="ScorecardInfoBox__content scorecard-internal-box">
								<div className="ScorecardInfoBox__title is-size-6 has-text-weight-bold is-uppercase">
									INTERNAL USE ONLY
								</div>

								<div className="ScorecardInfoBox__rating">
									<span className="title is-7">
										Would you hire this candidate?
									</span>
									<span>
										{candidateHiringOptionLabel[scorecard.wouldYouHire]}
									</span>
								</div>
								<div className="ScorecardInfoBox__rating">
									<span className="title is-7">Seniority</span>
									<span>
										{candidateSeniorityOptionLabel[scorecard.seniority]}
									</span>
								</div>
								<div className="ScorecardInfoBox__internal_comments">
									<p className="title is-7">Additional Comments</p>
									<p className="is-text-pre-wrap is-text-word-break">
										{scorecard.internalNotes}
									</p>
								</div>
							</div>
						</>
					) : null}
				</div>
			</div>
		);
	}
}
