import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import SettingsApp from './SettingsApp';
import { AdminRoute } from '../components/layout/routes/AdminRoute';
import { authStore } from '../shared/security/AuthStore';
import { Users } from '../components/users/Users';
import { Tests } from '../components/tests/Tests';
import { TestResultsFiles } from '../components/test-results-files/TestResultsFiles';
import { Clients } from '../components/clients/Clients';
import { Candidates } from '../components/candidates/Candidates';
import { CandidateProfile } from '../components/candidates/profile/CandidateProfile';
import { JobOpenings } from '../components/job-openings/JobOpenings';
import { Notifications } from '../components/layout/notifications/Notifications';
import { Dialog } from '../components/layout/modals/Dialog';
import { Modal } from '../components/layout/modals/Modal';
import { ServiceTerms } from 'components/documents/ServiceTerms';
import { PrivacyPolicy } from 'components/documents/PrivacyPolicy';
import { CandidatePublicProfileRedirect } from '../components/candidates/profile/public/CandidatePublicProfileRedirect';
import { ScorecardTemplates } from '../components/scorecard-templates/ScorecardTemplates';
import { ScorecardTemplateForm } from '../components/scorecard-templates/ScorecardTemplateForm';
import { Dashboard } from 'components/dashboard/Dashboard';
import { CandidatePublicProfile } from 'candidate-public-profile/CandidatePublicProfile';

@withRouter
@observer
export class AdminApp extends React.Component {
	render() {
		return (
			<div className="App">
				<Notifications />
				<Dialog />
				<Modal />
				<Switch>
					<Redirect exact from="/" to="/candidates" />
					<AdminRoute
						fullScreenLayout={true}
						path="/profile"
						exact
						hiddenFooter>
						<CandidatePublicProfile />
					</AdminRoute>
					<AdminRoute fullScreenLayout={false} path="/users" exact>
						{auth(<Users />)}
					</AdminRoute>
					<AdminRoute fullScreenLayout={false} path="/tests" exact>
						{auth(<Tests />)}
					</AdminRoute>
					<AdminRoute fullScreenLayout={false} path="/test-results-files" exact>
						{auth(<TestResultsFiles />)}
					</AdminRoute>
					<AdminRoute fullScreenLayout={false} path="/clients" exact>
						{auth(<Clients />)}
					</AdminRoute>
					<AdminRoute path="/candidates" exact fullScreenLayout={false}>
						{auth(<Candidates />)}
					</AdminRoute>
					<AdminRoute
						fullScreenLayout={false}
						path="/candidates/profile/:id/edit"
						exact>
						{auth(<CandidateProfile isEdit={true} />)}
					</AdminRoute>
					<AdminRoute fullScreenLayout={false} path="/job-openings" exact>
						{auth(<JobOpenings />)}
					</AdminRoute>
					<AdminRoute path="/terms" exact fullScreenLayout={true}>
						<ServiceTerms />
					</AdminRoute>
					<AdminRoute fullScreenLayout={true} path="/privacy-policy" exact>
						<PrivacyPolicy />
					</AdminRoute>
					<AdminRoute path="/settings" fullScreenLayout={false}>
						<SettingsApp />
					</AdminRoute>
					<AdminRoute path="/scorecard-templates/:id" fullScreenLayout={false}>
						<ScorecardTemplateForm />
					</AdminRoute>
					<AdminRoute path="/scorecard-templates" fullScreenLayout={false}>
						<ScorecardTemplates />
					</AdminRoute>
					<AdminRoute path="/dashboard" fullScreenLayout={false}>
						<Dashboard />
					</AdminRoute>
					{/* Legacy redirect */}
					<AdminRoute fullScreenLayout={true} path="/p/:slug" exact>
						<CandidatePublicProfileRedirect />
					</AdminRoute>
					{/* Default */}
					<Redirect to="/" />
				</Switch>
			</div>
		);
	}
}

function auth(component) {
	if (!authStore.isUserLogged()) {
		return <Redirect to="/login" />;
	} else {
		return component;
	}
}
