import React from 'react';
import { Icon } from './Icon';
import { modalStore } from 'components/layout/modals/ModalStore';
import { CandidateProfileExpectations } from 'candidate-portal/profile/expectations/CandidateProfileExpectations';
import { CandidateProfileExpectationsEditAdmin } from 'candidate-portal/profile/expectations/CandidateProfileExpectationsEditAdmin';
import { candidateProfileStore } from './CandidateProfileStore';
import { CandidateProfileAboutEditAdmin } from 'candidate-portal/profile/about/CandidateProfileAboutEditAdmin';
import { CandidateProfileContactInfoEditAdmin } from 'candidate-portal/profile/contact-info/CandidateProfileContactInfoEditAdmin';
import { CandidateCompensationCreate } from './compensations/CandidateCompensationCreate';
import { candidateCompensationsStore } from './compensations/CandidateCompensationsStore';
import { Button } from 'components/layout/button/Button';
import { CandidateProfileAbout } from 'candidate-portal/profile/about/CandidateProfileAbout';
import { CandidateProfileContactInfo } from 'candidate-portal/profile/contact-info/CandidateProfileContactInfo';
import { CandidateCompensations } from './compensations/CandidateCompensations';
import { CandidateChangelog } from './changelogs/CandidateChangelog';

export default function AnchorIcons({
	candidate,
	handleAnchorClick,
	activeSection
}) {
	const handleActionClick = (e, section) => {
		e.preventDefault();
		if (section === 'work_preferences') {
			modalStore.openModal(
				{ title: 'Edit Work Preferences' },
				<CandidateProfileExpectationsEditAdmin
					candidate={candidate}
					isNew={true}
					handleSuccess={() => {
						candidateProfileStore.reloadCandidate();
					}}
				/>
			);
		} else if (section === 'self_assessment') {
			modalStore.openModal(
				{ title: 'Edit Self Assesment' },
				<CandidateProfileAboutEditAdmin
					candidate={candidate}
					isNew={true}
					handleSuccess={() => {
						candidateProfileStore.reloadCandidate();
					}}
				/>
			);
		} else if (section === 'contact_info') {
			modalStore.openModal(
				{ title: 'Edit Contact Info' },
				<CandidateProfileContactInfoEditAdmin
					candidate={candidate}
					isNew={true}
					handleSuccess={() => {
						candidateProfileStore.reloadCandidate();
					}}
				/>
			);
		} else if (section === 'compensation') {
			modalStore.openModal(
				{ title: 'Add Compensation' },
				<CandidateCompensationCreate
					candidate={candidate}
					handleSuccess={() => {
						candidateCompensationsStore.getCompensations(candidate.id);
					}}
					handleSave={values =>
						candidateCompensationsStore.create(candidate.id, {
							data: values
						})
					}
				/>
			);
		}
	};

	const renderInternalSectionButtons = section => {
		return (
			<div className="internal-section-buttons">
				<a
					href={'void'}
					onClick={e => {
						handleActionClick(e, section);
					}}>
					<Button
						className="is-rounded is-circle is-xsmall is-blue"
						icon="edit"
					/>
					<span>{section === 'compensation' ? 'Add' : 'Edit'}</span>
				</a>
			</div>
		);
	};

	const handleInternalAnchorClick = (e, section) => {
		e.preventDefault();
		if (section === 'work_preferences') {
			modalStore.openModal(
				{ title: 'Work Preferences', position: 'centered', size: '2xl' },
				<CandidateProfileExpectations
					candidate={candidate}
					renderInternalSectionButtons={() =>
						renderInternalSectionButtons('work_preferences')
					}
				/>
			);
		} else if (section === 'self_assessment') {
			modalStore.openModal(
				{ title: 'Self Assesment', position: 'centered', size: '2xl' },
				<CandidateProfileAbout
					candidate={candidate}
					renderInternalSectionButtons={() =>
						renderInternalSectionButtons('self_assessment')
					}
				/>
			);
		} else if (section === 'contact_info') {
			modalStore.openModal(
				{ title: 'Contact Info', position: 'centered', size: '2xl' },
				<CandidateProfileContactInfo
					candidate={candidate}
					renderInternalSectionButtons={() =>
						renderInternalSectionButtons('contact_info')
					}
				/>
			);
		} else if (section === 'compensation') {
			modalStore.openModal(
				{ title: 'Compensation', position: 'centered', size: '2xl' },
				<CandidateCompensations
					candidate={candidate}
					renderInternalSectionButtons={() =>
						renderInternalSectionButtons('compensation')
					}
				/>
			);
		} else if (section === 'changelog') {
			modalStore.openModal(
				{ title: 'Changelog', position: 'centered', size: '2xl' },
				<CandidateChangelog candidate={candidate} />
			);
		}
	};

	return (
		<div className="anchor-icons">
			<div className="icons-container">
				<button
					onClick={e => handleAnchorClick(e, 'experience')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'experience' ? 'active' : ''
					}`}
					data-tooltip="Experience">
					<Icon icon="fa-solid fa-briefcase" />
				</button>
				<button
					onClick={e => handleAnchorClick(e, 'scorecards')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'scorecards' ? 'active' : ''
					}`}
					data-tooltip="Scorecards">
					<Icon icon="fa-solid fa-gauge-simple-high" />
				</button>
				<button
					onClick={e => handleAnchorClick(e, 'technical-tests')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'technical-tests' ? 'active' : ''
					}`}
					data-tooltip="Technical Tests">
					<Icon icon="fa-solid fa-chart-line" />
				</button>
				<button
					onClick={e => handleAnchorClick(e, 'education')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'education' ? 'active' : ''
					}`}
					data-tooltip="Education">
					<Icon icon="fa-solid fa-graduation-cap" />
				</button>
				<button
					onClick={e => handleAnchorClick(e, 'certifications')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'certifications' ? 'active' : ''
					}`}
					data-tooltip="Certifications">
					<Icon icon="fa-solid fa-medal" />
				</button>
			</div>
			<div className="icons-container internal">
				<button
					onClick={e => handleInternalAnchorClick(e, 'work_preferences')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'work_preferences' ? 'active' : ''
					}`}
					data-tooltip="Work Preferences">
					<Icon icon="fa-solid fa-screwdriver-wrench" />
				</button>
				<button
					onClick={e => handleInternalAnchorClick(e, 'self_assessment')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'self_assessment' ? 'active' : ''
					}`}
					data-tooltip="Self Assessment">
					<Icon icon="fa-solid fa-clipboard-list" />
				</button>
				<button
					onClick={e => handleInternalAnchorClick(e, 'contact_info')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'contact_info' ? 'active' : ''
					}`}
					data-tooltip="Contact Info">
					<Icon icon="fa-solid fa-address-card" />
				</button>
				<button
					onClick={e => handleInternalAnchorClick(e, 'compensation')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'compensation' ? 'active' : ''
					}`}
					data-tooltip="Compensation">
					<Icon icon="fa-solid fa-dollar-sign" />
				</button>
				<button
					onClick={e => handleInternalAnchorClick(e, 'changelog')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'changelog' ? 'active' : ''
					}`}
					data-tooltip="Changelog">
					<Icon icon="fa-solid fa-book" />
				</button>
			</div>
		</div>
	);
}
