import React from 'react';
import { Icon } from './Icon';

export default function AnchorIcons({
	candidate,
	handleAnchorClick,
	activeSection
}) {
	const {
		candidateExperiences,
		candidateEducations,
		candidateCertifications,
		candidateTestResults,
		candidateScorecards
	} = candidate;

	return (
		<div className="anchor-icons">
			{candidateExperiences.length > 0 ? (
				<button
					onClick={e => handleAnchorClick(e, 'experience')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'experience' ? 'active' : ''
					}`}
					data-tooltip="Experience">
					<Icon icon="fa-solid fa-briefcase" />
				</button>
			) : null}
			{candidateScorecards.length > 0 ? (
				<button
					onClick={e => handleAnchorClick(e, 'scorecards')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'scorecards' ? 'active' : ''
					}`}
					data-tooltip="Scorecards">
					<Icon icon="fa-solid fa-gauge-simple-high" />
				</button>
			) : null}
			{candidateTestResults.length > 0 ? (
				<button
					onClick={e => handleAnchorClick(e, 'technical-tests')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'technical-tests' ? 'active' : ''
					}`}
					data-tooltip="Technical Tests">
					<Icon icon="fa-solid fa-chart-line" />
				</button>
			) : null}
			{candidateEducations.length > 0 ? (
				<button
					onClick={e => handleAnchorClick(e, 'education')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'education' ? 'active' : ''
					}`}
					data-tooltip="Education">
					<Icon icon="fa-solid fa-graduation-cap" />
				</button>
			) : null}
			{candidateCertifications.length > 0 ? (
				<button
					onClick={e => handleAnchorClick(e, 'certifications')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'certifications' ? 'active' : ''
					}`}
					data-tooltip="Certifications">
					<Icon icon="fa-solid fa-medal" />
				</button>
			) : null}
		</div>
	);
}
