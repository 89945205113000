import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import React from 'react';
import { scorecardTemplateStore } from './ScorecardTemplateStore';
import { ScorecardTemplateCreateDialog } from './components/ScorecardTemplateCreateDialog';
import { ListStore } from '../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { Loader } from '../layout/misc/Loader';
import { Icon } from '../layout/misc/Icon';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';
import { InputField } from '../layout/form-fields/inputs/InputField';
import { STATE_LABELS, TEMPLATE_STATES } from './constants';
import { Switch } from '../layout/form-fields/inputs/Switch';
import { dialogStore } from '../layout/modals/DialogStore';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { Tooltip } from '../layout/tooltip/Tooltip';

@rolesAllowed([USER_ROLES.ADMIN])
@withRouter
@observer
export class ScorecardTemplates extends React.Component {
	state = {
		data: [],
		filterText: '',
		loading: true
	};

	constructor(props) {
		super(props);

		this.listStore = new ListStore(scorecardTemplateStore, {
			entityName: 'Scorecard Template',
			createModal: (
				<ScorecardTemplateCreateDialog
					key="create"
					handleSuccess={({ data }) => {
						this.props.history.push(`/scorecard-templates/${data.id}`);
					}}
				/>
			)
		});
	}

	componentDidMount() {
		this.search();
	}

	search(value = '') {
		this.setState({ loading: true });

		scorecardTemplateStore.getScorecardTemplates(value).then(result => {
			if (result.status === 'success' && result.data) {
				this.setState({ data: result.data, loading: false });
			}
		});
	}

	handleToggleEnable(id, enabled) {
		dialogStore.openDialog(
			{
				title: enabled
					? 'Enable Scorecard Template'
					: 'Disable Scorecard Template',
				message: enabled
					? 'Enabled scorecard templates can be used when adding a candidate scorecard. Are you sure you want to enable this scorecard template?'
					: 'Disabled scorecard templates cannot be used when adding a candidate scorecard. Are you sure you want to disable this scorecard template?',
				confirmLabel: enabled ? 'Enable' : 'Disable',
				confirmButtonClass: enabled ? 'is-success' : 'is-danger'
			},
			() => {
				scorecardTemplateStore
					.update(id, {
						scorecardTemplate: {
							disabled: !enabled
						}
					})
					.then(() => {
						this.search();
					});
			}
		);
	}

	render() {
		return (
			<div className="ScorecardTemplates container">
				<div className="level ListHeader">
					<div className="level-left">
						<h1 className="title">Scorecard Templates</h1>
					</div>
				</div>

				<div className="columns">
					<div className="column is-10">
						<InputField
							className="has-text-left"
							field="title"
							type="search"
							icon="fa-search"
							placeholder="Filter"
							value={this.state.filterText}
							onValueChange={value => {
								this.search(value);
								this.setState({ filterText: value });
							}}
						/>
					</div>
					<div className="column is-2">
						<button
							className="button is-primary is-rounded is-big"
							onClick={() => this.listStore.handleCreate()}>
							{`Create ${this.listStore.modalsConfigs.entityName}`}
						</button>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<table className="table is-fullwidth is-bordered is-hoverable">
							<thead>
								<tr>
									<th className="column-header-name">Name</th>
									<th className="column-header-name">Status</th>
									<th className="column-header-name">Published At</th>
									<th style={{ width: '240px' }} />
								</tr>
							</thead>
							<tbody>
								{this.state.data.map(row => {
									const isPublished = row.status === TEMPLATE_STATES.PUBLISHED;
									const isInUse = row.usageCount > 0;

									return (
										<tr key={row.id}>
											<td className="has-text-weight-bold column-name">
												{row.name}
											</td>

											<td className="has-text-weight-bold column-status">
												{STATE_LABELS[row.status]}
											</td>

											<td className="has-text-weight-bold column-publishedAt">
												{isPublished
													? moment(row.publishedAt).format('lll')
													: '-'}
											</td>

											<td className="actions-column">
												<button
													className="button is-rounded is-circle is-small is-text"
													onClick={() =>
														this.props.history.push(
															`/scorecard-templates/${row.id}`
														)
													}>
													<Icon className="is-small" icon="fa-pencil-alt" />
												</button>
												<Tooltip
													disabled={!isInUse}
													message="This Scorecard Template is currently in use.">
													<button
														className="button is-rounded is-circle is-small is-text"
														disabled={isInUse}
														onClick={() =>
															dialogStore.openDialog(
																{
																	title: 'Delete Scorecard Template',
																	message:
																		'Are you sure you want to delete this Scorecard Template?'
																},
																() =>
																	this.listStore.delete(
																		row,
																		this.onDeleteSuccess,
																		this.onDeleteFail,
																		true
																	)
															)
														}>
														<Icon className="is-small" icon="fa-trash" />
													</button>
												</Tooltip>
												<Switch
													key={`disable-${row.id}`}
													id={`disable-${row.id}`}
													value={!row.disabled}
													activeLabel="Enabled"
													inactiveLabel="disabled"
													onValueChange={enabled => {
														this.handleToggleEnable(row.id, enabled);
													}}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						{this.state.loading ? <Loader type="ripple" /> : null}
					</div>
				</div>
			</div>
		);
	}

	onDeleteSuccess = item => {
		this.search();
		this.setState({ filterText: '' });
	};

	onDeleteFail = (item, result) => {
		notificationStore.pushErrorNotification(
			'An error has occurred deleting this Scorecard Template.',
			result.message
		);
	};
}
