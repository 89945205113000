import { EntityStore } from '../../shared/stores/EntityStore';
import { action, makeObservable, override } from 'mobx';
import { api } from '../../shared/api/api';

export const GITHUB_URL = 'https://github.com/';
export const GITHUB_REPO_KEY = 'github';

class CandidatesStore extends EntityStore {
	seniorityOptions = [
		{ text: 'Junior', value: 'jr' },
		{ text: 'Junior+', value: 'jr+' },
		{ text: 'Mid-Level', value: 'md' },
		{ text: 'Mid-Level+', value: 'md+' },
		{ text: 'Senior', value: 'sr' },
		{ text: 'Senior+', value: 'sr+' },
		{ text: 'Lead', value: 'ld' }
	];

	englishLevelOptions = [
		{ text: 'None', value: 'none' },
		{ text: 'Beginner', value: 'beginner' },
		{ text: 'Intermediate', value: 'intermediate' },
		{ text: 'Upper Intermediate', value: 'upper_intermediate' },
		{ text: 'Advanced', value: 'advanced' },
		{ text: 'Native', value: 'native' }
	];

	educationStatusOptions = [
		{ text: 'Complete', value: 'graduated' },
		{ text: 'In Progress', value: 'attending' },
		{ text: 'Incomplete', value: 'incomplete' }
	];

	repoKeyOptions = [
		{ text: 'Github', value: 'github' },
		{ text: 'Other', value: 'other' }
	];

	preferredWorkLocationOptions = [
		{ text: 'Home', value: 'home' },
		{ text: 'Office', value: 'office' },
		{ text: 'Mixed', value: 'mixed' }
	];

	preferredPositionTypeOptions = [
		{ text: 'Full Time', value: 'full_time' },
		{ text: 'Part Time', value: 'part_time' },
		{ text: 'On Demand', value: 'on_demand' }
	];
	preferredWorkingEnvironmentOptions = [
		{ text: 'Steady with little changes', value: 'fixed' },
		{ text: 'Dynamic with changing priorities', value: 'dynamic' }
	];
	remoteExperienceYearsOptions = [
		{ text: 'Less than 2 years of experience', value: 1 },
		{ text: 'Between 2 and 3 years', value: 3 },
		{ text: 'Between 3 and 4 years', value: 4 },
		{ text: 'Between 5 and 10 years', value: 10 },
		{ text: 'More than 10 years', value: 11 }
	];
	remoteExperienceYearsAdminOptions = [
		{ text: 'None', value: 0 },
		{ text: 'Less than 2 years of experience', value: 1 },
		{ text: 'Between 2 and 3 years', value: 3 },
		{ text: 'Between 3 and 4 years', value: 4 },
		{ text: 'Between 5 and 10 years', value: 10 },
		{ text: 'More than 10 years', value: 11 }
	];
	preferredWorkshiftsOptions = [
		{ text: '5am to 2pm ', value: '5am-2pm' },
		{ text: '6am to 3pm ', value: '6am-3pm' },
		{ text: '7am to 4pm ', value: '7am-4pm' },
		{ text: '8am to 5pm ', value: '8am-5pm' },
		{ text: '9am to 6pm ', value: '9am-6pm' },
		{ text: '10am to 7pm ', value: '10am-7pm' },
		{ text: '11am to 8pm ', value: '11am-8pm' },
		{ text: '12pm to 9pm ', value: '12pm-9pm' },
		{ text: '1pm to 10pm ', value: '1pm-10pm' },
		{ text: '2pm to 11pm ', value: '2pm-11pm' },
		{ text: '3pm to 12am ', value: '3pm-12am' },
		{ text: 'Anytime', value: 'anytime' }
	];
	expertiseAreaLevelOptions = [
		{ text: 'Basic', value: 'basic' },
		{ text: 'Intermediate', value: 'intermediate' },
		{ text: 'Advanced', value: 'advanced' },
		{ text: 'Expert', value: 'expert' }
	];
	expertiseAreaYearsOptions = [
		{ text: 'Less than 2 years of experience', value: 1 },
		{ text: 'Between 2 and 3 years', value: 3 },
		{ text: 'Between 3 and 4 years', value: 4 },
		{ text: 'Between 5 and 10 years', value: 10 },
		{ text: 'More than 10 years', value: 11 }
	];
	knownSkillLevelOptions = [
		{ text: 'Basic', value: 'basic' },
		{ text: 'Intermediate', value: 'intermediate' },
		{ text: 'Advanced', value: 'advanced' },
		{ text: 'Expert', value: 'expert' }
	];
	knownSkillYearsOptions = [
		{ text: 'Less than 2 years of experience', value: 1 },
		{ text: 'Between 2 and 3 years', value: 3 },
		{ text: 'Between 3 and 4 years', value: 4 },
		{ text: 'Between 5 and 10 years', value: 10 },
		{ text: 'More than 10 years', value: 11 }
	];
	usExperienceYearsAdminOptions = [
		{ text: 'None', value: 0 },
		{ text: 'Less than 2 years of experience', value: 1 },
		{ text: 'Between 2 and 3 years', value: 3 },
		{ text: 'Between 3 and 4 years', value: 4 },
		{ text: 'Between 5 and 10 years', value: 10 },
		{ text: 'More than 10 years', value: 11 }
	];
	usExperienceYearsOptions = [
		{ text: 'Less than 2 years of experience', value: 1 },
		{ text: 'Between 2 and 3 years', value: 3 },
		{ text: 'Between 3 and 4 years', value: 4 },
		{ text: 'Between 5 and 10 years', value: 10 },
		{ text: 'More than 10 years', value: 11 }
	];
	highestEducationLevelOptions = [
		{ text: 'High School Degree', value: 'high_school' },
		{
			text: 'Associate’s Degree (<4 years duration)',
			value: 'associate'
		},
		{
			text: 'Bachelor’s Degree (>=4 years duration)',
			value: 'bachelor'
		},
		{ text: 'Master’s Degree', value: 'master' },
		{ text: 'PhD', value: 'phd' }
	];
	educationStageOptions = [
		{ text: 'In Progress', value: 'in_progress' },
		{ text: 'Incomplete', value: 'abandoned' },
		{ text: 'Complete', value: 'graduated' }
	];
	preferredContactMethodOptions = [
		{ text: 'Email', value: 'email' },
		{ text: 'Phone', value: 'phone' },
		{ text: 'Whatsapp', value: 'whatsapp' }
	];
	talentNetworkSourceOptions = [
		{ text: 'Found it through search engine', value: 'search_engine' },
		{ text: 'Saw a company post on LinkedIn', value: 'linkedin' },
		{
			text: 'Referred by a recruiter (select recruiter name below)',
			value: 'referred_prediktive'
		},
		{ text: 'Referred by an acquaintance', value: 'referred_acquaintance' },
		{ text: 'I received an invitation email', value: 'invitation_email' },
		{ text: 'Other', value: 'other' }
	];
	lookingForJobOptions = [
		{ text: "I'm actively looking for a job", value: 'yes' },
		{ text: "I'm not looking for a job", value: 'no' },
		{ text: "I'm passively looking for a job", value: 'maybe' }
	];

	wizardSteps = {
		FIRST_STEP: 1,
		SECOND_STEP: 2,
		THIRD_STEP: 3,
		FOURTH_STEP: 4,
		FINAL_STEP: 4
	};

	constructor() {
		super('candidate-portal');
		makeObservable(this);
	}

	@action
	async createEducation(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/educations`,
			request
		);
		return result;
	}

	@action
	async updateEducation(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/educations/${id}`,
			request
		);
		return result;
	}

	@action
	async deleteEducation(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/educations/${id}`
		);
		return result;
	}

	@action
	async createCertification(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/certifications`,
			request
		);
		return result;
	}

	@action
	async updateCertification(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/certifications/${id}`,
			request
		);
		return result;
	}

	@action
	async deleteCertification(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/certifications/${id}`
		);
		return result;
	}

	@action
	async createTestResult(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/test-results`,
			request
		);
		return result;
	}

	@action
	async deleteTestResult(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/test-results/${id}`
		);
		return result;
	}

	@action
	async updateTestResult(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/test-results/${id}`,
			request
		);
		return result;
	}

	@action
	async createExperience(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/experiences`,
			request
		);
		return result;
	}

	@action
	async updateExperience(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/experiences/${id}`,
			request
		);
		return result;
	}

	@action
	async deleteExperience(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/experiences/${id}`
		);
		return result;
	}

	@action
	async updateExpectations(request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/expectations`,
			request
		);
		return result;
	}

	@action
	async createCompensation(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/compensations`,
			request
		);

		return result;
	}

	@action
	async updateAbout(request) {
		const result = await api.put(`${this.entityPluralEndpoint}/about`, request);
		return result;
	}

	@action
	async updateContactInfo(request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/contact-info`,
			request
		);
		return result;
	}

	@action
	async createRepos(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/repos`,
			request
		);
		return result;
	}

	@action
	async sendFeedback(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/feedback`,
			request
		);
		return result;
	}

	@action
	async createTestRequest(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/test-requests/`,
			request
		);
		return result;
	}

	@action
	async uploadCandidateImage(imageFile) {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		const formData = new FormData();
		formData.append('image', imageFile);
		const result = await api.put(
			`${this.entityPluralEndpoint}/image`,
			formData,
			config
		);
		return result;
	}

	@action
	async uploadCandidateResume(resumeFile) {
		const config = {
			headers: {
				'content-type': 'application/pdf'
			}
		};
		const formData = new FormData();
		formData.append('resume', resumeFile);
		const result = await api.put(
			`${this.entityPluralEndpoint}/resume`,
			formData,
			config
		);
		return result;
	}

	@action
	async publishProfile(request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/publish`,
			request
		);
		return result;
	}

	@action
	async getCandidates(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}

	@override
	async get() {
		const result = await api.get(`${this.entityPluralEndpoint}`);
		return result;
	}

	@override
	async update(request) {
		const result = await api.put(`${this.entityPluralEndpoint}`, request);
		return result;
	}

	@override
	async delete() {
		const result = await api.delete(`${this.entityPluralEndpoint}`);
		return result;
	}

	@action
	async verifyEmail(request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/verify-email`,
			request
		);
		return result;
	}

	@action
	async checkLinkToken(request) {
		const result = await api.get(
			`${this.entityPluralEndpoint}/is-locked`,
			request
		);
		return result;
	}
}

export const candidatesStore = new CandidatesStore();
