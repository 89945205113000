import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { candidatesStore } from '../../CandidatesStore';
import { TagsDropdown } from '../../../layout/form-fields/inputs/TagsDropdown';
import { FieldRequiredIndicator } from '../../../layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({});

export class CandidateProfileTagsEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileTagsEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								You can add up to 5 tags to this candidate
							</label>
							<FieldRequiredIndicator />
							<div className="control">
								<TagsDropdown
									value={formikBag.values.tags}
									onValueChange={values =>
										formikBag.setFieldValue('tags', values)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		return {
			tags: this.props.tags
		};
	}

	async save(values) {
		candidatesStore.createTags(this.props.candidate.id, values).then(result => {
			this.resultNotification(
				result,
				null,
				null,
				'Tag',
				'The tags has been saved successfully.'
			);
		});
	}
}
