import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AdminRoute } from '../components/layout/routes/AdminRoute';
import { Modal } from '../components/layout/modals/Modal';
import { CookiePolicy } from 'components/documents/CookiePolicy';
import { ServiceTerms } from 'components/documents/ServiceTerms';
import { PrivacyPolicy } from 'components/documents/PrivacyPolicy';
import { Dialog } from 'components/layout/modals/Dialog';
import { CandidatePublicProfileRedirect } from '../components/candidates/profile/public/CandidatePublicProfileRedirect';
import { LoginPage } from '../pages/LoginPage';
import SignupPage from '../pages/SignupPage';
import { Farewell } from 'components/layout/security/Farewell';
import { CandidatePublicProfile } from 'candidate-public-profile/CandidatePublicProfile';

@withRouter
@observer
export class PublicApp extends React.Component {
	render() {
		const { location } = this.props;

		return (
			<div className="Public">
				<Modal />
				<Dialog />
				<Switch>
					<AdminRoute path="/login" fullScreenLayout={true} exact>
						<LoginPage />
					</AdminRoute>
					<AdminRoute path="/signup" fullScreenLayout={true} exact>
						<SignupPage />
					</AdminRoute>
					<AdminRoute path="/farewell" fullScreenLayout={true} exact>
						<Farewell />
					</AdminRoute>
					<AdminRoute path="/terms" exact fullScreenLayout={true}>
						<ServiceTerms />
					</AdminRoute>
					<AdminRoute path="/cookie-policy" exact fullScreenLayout={true}>
						<CookiePolicy />
					</AdminRoute>
					<AdminRoute
						path="/profile"
						fullScreenLayout={true}
						exact
						hiddenFooter>
						<CandidatePublicProfile />
					</AdminRoute>
					<AdminRoute path="/privacy-policy" exact fullScreenLayout={true}>
						<PrivacyPolicy />
					</AdminRoute>
					<AdminRoute path="/p/:slug" fullScreenLayout={true}>
						<CandidatePublicProfileRedirect />
					</AdminRoute>
					<Redirect to={`/login${location.search}${location.hash}`} />
				</Switch>
			</div>
		);
	}
}
