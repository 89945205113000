import React from 'react';
import { ProfileImage } from '../layout/misc/ProfileImage';
import { Button } from '../layout/button/Button';

export class JobCandidateAvatar extends React.Component {
	render() {
		return (
			<div
				className="avatar candidate-avatar"
				key={this.props.candidate.id}
				title={this.props.candidate.name}>
				<figure className="avatar-image image is-24x24">
					<ProfileImage
						className="is-rounded"
						src={this.props.candidate.imageUrl}
					/>
				</figure>
				<div className="avatar-label">
					<Button
						title={`Delete ${this.props.candidate.name}`}
						className="button is-small is-danger is-rounded"
						icon="times"
						onClick={() =>
							this.props.onDeleteCandidate(this.props.job, this.props.candidate)
						}
					/>
				</div>
			</div>
		);
	}
}
