import React from 'react';
import { observer } from 'mobx-react';
import { authStore } from '../../../../shared/security/AuthStore';
import { NavLink, withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { ProfileImage } from '../../../../components/layout/misc/ProfileImage';

@withRouter
@onClickOutside
@observer
export class UserMenu extends React.Component {
	state = {
		openMenu: false
	};

	handleClickOutside() {
		this.handleOnUserMenuClick(true);
	}

	render() {
		return this.renderMenu();
	}

	handleOnLogoutClick() {
		authStore.logout();
		this.props.history.push('/login');
	}

	handleOnUserMenuClick(close = false) {
		if (close) {
			this.setState({
				openMenu: false
			});
		} else {
			this.setState({
				openMenu: !this.state.openMenu
			});
		}
	}

	renderPrefenrenceMenuItem(menuItem) {
		const { route, text, icon } = menuItem;
		return (
			<NavLink
				key={text}
				to={route}
				className="dropdown-item"
				activeClassName="is-active"
				onClick={() => this.handleOnUserMenuClick()}>
				{text}
				<div className="submenu-icon">
					<i className={icon}></i>
				</div>
			</NavLink>
		);
	}

	renderMenu() {
		let preferencesMenuItems = [];
		// 1. Expectations
		preferencesMenuItems.push({
			route: '/candidate-portal/expectations',
			text: 'Work Preferences',
			icon: 'fas fa-briefcase'
		});
		// 2. About
		preferencesMenuItems.push({
			route: '/candidate-portal/about',
			text: 'Self Assessment',
			icon: 'fas fa-male'
		});
		// 3. Contact Info
		preferencesMenuItems.push({
			route: '/candidate-portal/contact-info',
			text: 'Contact Info',
			icon: 'fas fa-id-card-alt'
		});
		return (
			<div className="UserMenu">
				<div
					className={
						'dropdown is-icon-dropdown ' +
						(this.state.openMenu ? 'is-active' : '')
					}>
					<div
						className="dropdown-trigger"
						onClick={() => this.handleOnUserMenuClick()}>
						<span className="caret icon is-small">
							<i className="fas fa-angle-down" />
						</span>
						<figure
							className="avatar-image image is-48x48"
							title={`${authStore.fullName}, ${authStore.email}`}>
							<ProfileImage
								className="is-rounded"
								src={authStore.profileImage}
							/>
						</figure>
					</div>
					<div className="dropdown-menu" role="menu">
						<div className="dropdown-content">
							{preferencesMenuItems.map((menuItem, index) => {
								return this.renderPrefenrenceMenuItem(menuItem, index);
							})}
							<NavLink
								to={''}
								className="dropdown-item"
								onClick={() => this.handleOnLogoutClick()}>
								Logout{' '}
								<div className="submenu-icon">
									<i className="fas fa-sign-out-alt"></i>
								</div>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
