import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { Notifications } from '../components/layout/notifications/Notifications';
import { Loader } from '../components/layout/misc/Loader';
import { Dialog } from '../components/layout/modals/Dialog';
import { Modal } from '../components/layout/modals/Modal';
import { CandidatePortalRoute } from './layout/routes/CandidatePortalRoute';
import { CandidateProfile } from './profile/CandidateProfile';
import { CandidateProfileExpectationsMainPage } from './profile/expectations/CandidateProfileExpectationsMainPage';
import { CandidateProfileAboutMainPage } from './profile/about/CandidateProfileAboutMainPage';
import { CandidateProfileEmailVerification } from './profile/email-verification/CandidateProfileEmailVerification';
import { candidateProfileStore } from './profile/CandidateProfileStore';
import { candidatesStore } from './profile/CandidatesStore';
import { CandidateProfileContactInfoMainPage } from './profile/contact-info/CandidateProfileContactInfoMainPage';
import { CandidateProfileWizard } from './profile/wizard/CandidateProfileWizard';
import { observer } from 'mobx-react';

@withRouter
@observer
export class CandidatePortal extends React.Component {
	state = {
		wizardCompleted: null,
		candidate: null,
		candidateLoaded: null
	};
	baseURL = '/candidate-portal';
	componentDidMount() {
		this.loadCandidate();
	}

	componentWillUnmount() {
		candidateProfileStore.reset();
	}

	async loadCandidate() {
		const result = await candidatesStore.get();
		const candidate = result.data;
		const wizardCompleted =
			candidate.wizardCurrentStep === candidatesStore.wizardSteps.FINAL_STEP;
		this.setState({
			candidateLoaded: true,
			wizardCompleted: wizardCompleted,
			candidate
		});
	}
	render() {
		if (!this.state.candidateLoaded) return <Loader type="page-loader" />;
		return (
			<div className="CandidatePortal ">
				<Notifications />
				<Dialog />
				<Modal />
				<div>
					<Switch>
						{/* Added redirect for now to avoid breaking user browse history or even open tabs */}
						{/*		this route only exists for the user itself, he can't access other user profiles anyway */}
						<Redirect
							path={`/p/${this.state.candidate.slug}`}
							to={`${this.baseURL}/my-profile`}
						/>
						<CandidatePortalRoute
							path={`${this.baseURL}/my-profile`}
							fullScreenLayout={true}
							showFooter={true}>
							{this.wizardCompletedRequired(<CandidateProfile isEdit={true} />)}
						</CandidatePortalRoute>
						<CandidatePortalRoute
							path={`${this.baseURL}/verify-email/:token`}
							fullScreenLayout={true}
							exact>
							{this.wizardCompletedRequired(
								<CandidateProfileEmailVerification />
							)}
						</CandidatePortalRoute>
						<CandidatePortalRoute
							path={`${this.baseURL}/expectations`}
							fullScreenLayout={true}>
							{this.wizardCompletedRequired(
								<CandidateProfileExpectationsMainPage />
							)}
						</CandidatePortalRoute>
						<CandidatePortalRoute
							path={`${this.baseURL}/about`}
							fullScreenLayout={true}>
							{this.wizardCompletedRequired(<CandidateProfileAboutMainPage />)}
						</CandidatePortalRoute>
						<CandidatePortalRoute
							path={`${this.baseURL}/contact-info`}
							fullScreenLayout={true}>
							{this.wizardCompletedRequired(
								<CandidateProfileContactInfoMainPage />
							)}
						</CandidatePortalRoute>
						<CandidatePortalRoute
							path={`${this.baseURL}/wizard`}
							fullScreenLayout={false}>
							{this.wizardNoCompletedRequired(<CandidateProfileWizard />)}
						</CandidatePortalRoute>
						<Redirect to={`${this.baseURL}/my-profile`} />
					</Switch>
				</div>
			</div>
		);
	}

	wizardCompletedRequired(component) {
		return this.state.wizardCompleted ? (
			component
		) : (
			<Redirect to={`${this.baseURL}/wizard`} />
		);
	}

	wizardNoCompletedRequired(component) {
		return !this.state.wizardCompleted ? (
			component
		) : (
			<Redirect to={`${this.baseURL}/my-profile`} />
		);
	}
}
