import React from 'react';
import { withRouter } from 'react-router-dom';
import { modalStore } from '../../../layout/modals/ModalStore';
import { CandidateProfileHireCandidate } from './CandidateProfileHireCandidate';
import { candidatesStore } from '../../CandidatesStore';
import { CandidateProfileLinkToken } from '../link-token/CandidateProfileLinkToken';
import { BaseHeader } from '../../../layout/header/BaseHeader';

@withRouter
export class CandidateProfileHeader extends React.Component {
	handleViewProfileClick = async () => {
		const { candidate } = this.props;
		let tokenResponse = await candidatesStore.getLinkToken(candidate.id);

		if (!tokenResponse.data) {
			tokenResponse = await candidatesStore.generateLinkToken(candidate.id);
		}

		modalStore.openModal(
			{ title: 'Private URL', saveButtonLabel: 'Extend' },
			<CandidateProfileLinkToken
				token={tokenResponse.data}
				candidate={candidate}
				isNew={true}
				handleSuccess={() => {}}
			/>
		);
	};

	handleMeetCandidateClick() {
		modalStore.openModal(
			{
				title: 'Meet Candidate',
				saveButtonLabel: 'Submit'
			},
			<CandidateProfileHireCandidate
				PublicLinkId={this.props.candidate.PublicLinkId}
			/>
		);
	}

	meetCandidateButton() {
		const buttonLabel = 'Meet this candidate';

		const { pathname, search } = this.props.history.location;

		return (
			<>
				<button
					className="button is-ghost is-fullwidth is-hidden-desktop"
					onClick={this.handleMeetCandidateClick.bind(this)}>
					{buttonLabel}
				</button>
				<button
					className="button is-primary is-rounded is-capitalized is-hidden-touch"
					onClick={this.handleMeetCandidateClick.bind(this)}>
					{buttonLabel}
				</button>
				<a
					className="anchor-button-pdf"
					href={`${pathname}${search}&source=pdf`}
					target="_blank"
					rel="noopener noreferrer">
					{buttonLabel}
				</a>
			</>
		);
	}

	viewProfileButton() {
		return (
			<div className="is-6 has-text-right">
				<button
					className="button is-primary is-rounded is-capitalized"
					onClick={this.handleViewProfileClick}>
					View Profile
				</button>
			</div>
		);
	}

	componentDidMount() {
		const params = new URLSearchParams(this.props.history?.location?.search);

		if (params.get('source') === 'pdf') {
			this.handleMeetCandidateClick();
		}
	}

	render() {
		const { standalone } = this.props;

		return standalone ? (
			<BaseHeader
				actions={
					<div className="navbar-end">
						<div className="navbar-item">
							<div className="hire-button is-6 has-text-right">
								{this.props.isEdit
									? this.viewProfileButton()
									: this.meetCandidateButton()}
							</div>
						</div>
					</div>
				}
			/>
		) : (
			<div className="mt-6 is-flex is-flex-direction-row is-justify-content-end is-align-items-center">
				{this.props.isEdit && this.viewProfileButton()}
			</div>
		);
	}
}
