import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
	candidateScorecardStore,
	candidateHiringOptions,
	candidateSeniorityOptions
} from './CandidateScorecardStore';
import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { FormField } from '../../../layout/form-fields/FormField';
import { Dropdown } from '../../../layout/form-fields/inputs/Dropdown';
import { ScorecardSection } from './ScorecardSection';

const ValidationSchema = Yup.object().shape({
	publicNotes: Yup.string().nullable().label('Public Notes'),
	internalNotes: Yup.string().nullable().label('Internal Notes'),
	wouldYouHire: Yup.string().nullable().label('Would you hire this candidate?'),
	scores: Yup.object().label('Scores')
});

export class CandidateAdminScorecardEditModal extends ModalComponent {
	componentDidMount() {}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateAdminScorecardEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		const { candidateScorecard } = this.props;
		const { setFieldValue, values } = formikBag;

		return (
			<div className="is-flex is-flex-direction-column gap-4">
				<div className="is-flex is-flex-direction-row gap-4">
					<div className="is-flex-grow-1">
						{candidateScorecard.sections.map(section => (
							<ScorecardSection
								key={section.name}
								section={section}
								editable={true}
								onChange={(sectionId, itemId, score) => {
									setFieldValue('scores', {
										...values.scores,
										[itemId]: score
									});
								}}
							/>
						))}
					</div>
					<div>
						<div className="is-flex is-flex-direction-column gap-4">
							<div className="ScorecardInfoBox ScorecardInfoBox--green">
								<div className="ScorecardInfoBox__content ScorecardInfoBox__content--headless">
									<p className="is-size-7 has-text-weight-bold is-uppercase pb-4">
										Additional Comments
									</p>
									<FormField
										className="has-text-left"
										label="Notes"
										field="publicNotes"
										type="textarea"
										maxlength={700}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="ScorecardInfoBox ScorecardInfoBox--blue scorecard-internal-box">
					<div className="ScorecardInfoBox__title is-size-6 has-text-weight-bold is-uppercase py-5">
						INTERNAL USE ONLY
					</div>
					<div className="ScorecardInfoBox__rating">
						<div className="p-4 is-fullwidth">
							<div className="field has-text-left">
								<label className="subtitle is-7">
									Would you hire this candidate?
								</label>
								<div className="control">
									<Dropdown
										enableFixedMode={true}
										value={candidateScorecard.wouldYouHire}
										onValueChange={value =>
											formikBag.setFieldValue('wouldYouHire', value)
										}
										options={candidateHiringOptions}
										optionValue={'value'}
									/>
								</div>
							</div>
							<div className="field has-text-left">
								<label className="subtitle is-7">Seniority</label>
								<div className="control">
									<Dropdown
										enableFixedMode={true}
										value={candidateScorecard.seniority}
										onValueChange={value =>
											formikBag.setFieldValue('seniority', value)
										}
										options={candidateSeniorityOptions}
										optionValue={'value'}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="ScorecardInfoBox__content">
						<p className="is-size-7 has-text-weight-bold is-uppercase pb-4">
							Additional Comments
						</p>
						<FormField
							className="has-text-left"
							label="Notes"
							field="internalNotes"
							type="textarea"
							maxlength={5000}
						/>
					</div>
				</div>
			</div>
		);
	}

	getInitialValues() {
		const { candidateScorecard } = this.props;

		return {
			internalNotes: candidateScorecard.internalNotes || '',
			publicNotes: candidateScorecard.publicNotes || '',
			wouldYouHire: candidateScorecard.wouldYouHire,
			scores: {}
		};
	}

	async save(values) {
		const { candidateScorecard } = this.props;

		candidateScorecardStore
			.update(candidateScorecard.candidateId, candidateScorecard.id, values)
			.then(result => {
				this.resultNotification(
					result,
					null,
					null,
					'Candidate Scorecard',
					'The candidate scorecard has been saved successfully.'
				);
			});
	}
}
