import React from 'react';
import { withRouter } from 'react-router-dom';

@withRouter
export class BaseHeader extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			menuOpen: false
		};
	}

	toggleMenu(clickEvent) {
		clickEvent.preventDefault();

		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	render() {
		const { logoPath, actions } = this.props;
		const { menuOpen } = this.state;

		return (
			<nav className="navbar is-info is-fixed-top public-header">
				<div className="container">
					<div className="navbar-brand is-align-items-center">
						{/*Logo*/}
						<a href="https://prediktive.com" className="logo">
							<img alt="Prediktive" src={logoPath ? logoPath : '/logo.svg'} />
						</a>
						{/*Mobile Menu*/}
						{actions && (
							<a
								role="button"
								className={`navbar-burger ${menuOpen ? 'is-active' : ''}`}
								aria-label="menu"
								aria-expanded="false"
								data-target="navbarBasicExample"
								href="#menu"
								onClick={this.toggleMenu.bind(this)}>
								<span aria-hidden="true" />
								<span aria-hidden="true" />
								<span aria-hidden="true" />
							</a>
						)}
					</div>
					{/*Menu*/}
					<div className={`navbar-menu ${menuOpen ? 'is-active' : ''}`}>
						{actions}
					</div>
				</div>
			</nav>
		);
	}
}
