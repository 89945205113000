import React from 'react';
import { Icon } from '../layout/misc/Icon';
import moment from 'moment-timezone';
import { ProfileImage } from '../layout/misc/ProfileImage';
import { Switch } from '../layout/form-fields/inputs/Switch';

export class TestsTable extends React.Component {
	getCreatedByDisplay(test) {
		return (
			<div className="thumb-table">
				<div className="thumb-label">
					<figure className="image is-24x24 is-inline-block">
						<ProfileImage
							className="is-rounded"
							src={test.createdByUser.imageUrl}
						/>
					</figure>
					<div className="is-image-label">
						{test.createdByUser.name ? test.createdByUser.name : ''}
					</div>
				</div>
			</div>
		);
	}

	isColumnSorted(column) {
		if (this.props.sort && this.props.sort.column === column) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}

	render() {
		// Helper for Sortable Table Headers
		const SortableTableHeader = ({
			children,
			className,
			identifier,
			...props
		}) => {
			return (
				<th
					className={`is-sortable ${className}`}
					onClick={() => this.handleHeaderClick(identifier)}
					{...props}>
					{children} {this.isColumnSorted(identifier)}
				</th>
			);
		};

		return (
			<table className="table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<SortableTableHeader style={{ width: '10%' }} identifier="name">
							Name
						</SortableTableHeader>
						<SortableTableHeader style={{ width: '10%' }} identifier="version">
							Version
						</SortableTableHeader>
						<SortableTableHeader
							style={{ width: '15%' }}
							identifier="skillmeterTestId">
							Skillmeter Test Id
						</SortableTableHeader>
						<SortableTableHeader
							style={{ width: '10%' }}
							identifier="sampleSize">
							Sample Size
						</SortableTableHeader>
						<SortableTableHeader
							style={{ width: '10%' }}
							identifier="averageScore">
							Average Score
						</SortableTableHeader>
						<SortableTableHeader
							style={{ width: '17%' }}
							identifier={'createdBy'}>
							Created by
						</SortableTableHeader>
						<SortableTableHeader
							style={{ width: '10%' }}
							identifier="createdAt">
							Created at
						</SortableTableHeader>
						<th style={{ width: '150px' }} />
					</tr>
				</thead>
				<tbody>
					{this.props.data.map(item => {
						return (
							<tr key={item.id} className={item.disabled ? 'disabled' : ''}>
								<td className="has-text-weight-bold">{item.name}</td>
								<td className="has-text-weight-medium">{item.version}</td>
								<td className="has-text-weight-medium">
									{item.skillmeterTestId}
								</td>
								<td className="has-text-weight-medium">{item.sampleSize}</td>
								<td className="has-text-weight-medium">{item.averageScore}</td>
								<td className="has-text-weight-medium">
									{this.getCreatedByDisplay(item)}
								</td>
								<td className="has-text-weight-medium">
									{moment(item.createdAt).fromNow()}
								</td>
								<td className="actions-column">
									<button
										title="Delete Test"
										className="button is-rounded is-circle is-small is-text"
										onClick={() => {
											if (this.props.onDelete) {
												this.props.onDelete(item);
											}
										}}>
										<Icon className="is-small" icon="fa-trash" />
									</button>
									<button
										title="Edit Test"
										className="button is-rounded is-circle is-small is-text"
										onClick={() => {
											if (this.props.onEdit) {
												this.props.onEdit(item);
											}
										}}>
										<Icon className="is-small" icon="fa-pencil-alt" />
									</button>
									<span>
										<Switch
											id={item.id}
											key={Math.random()}
											value={!item.disabled}
											activeLabel="Enabled"
											inactiveLabel="Disabled"
											onValueChange={value => {
												if (this.props.onDisable) {
													this.props.onDisable(item);
												}
											}}
										/>
									</span>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
}
