import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { scorecardTemplateStore } from '../../../scorecard-templates/ScorecardTemplateStore';
import { candidateScorecardStore } from './CandidateScorecardStore';
import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Dropdown } from '../../../layout/form-fields/inputs/Dropdown';

const ValidationSchema = Yup.object().shape({
	scorecardTemplateId: Yup.number().required().label('Scorecard Template')
});

export class CandidateAdminScorecardCreateModal extends ModalComponent {
	componentDidMount() {
		scorecardTemplateStore.getScorecardOptions().then(({ status, data }) => {
			if (status === 'success') {
				this.setState({
					options: data.map(({ id, name }) => ({ text: name, value: id }))
				});
			} else {
				this.setState({
					loadError: true
				});
			}
		});
	}

	render() {
		return (
			<>
				<Formik
					initialValues={this.getInitialValues()}
					validationSchema={ValidationSchema}
					onSubmit={values => {
						this.saving();
						this.save(values);
					}}>
					{formikBag =>
						this.renderModalContainer(
							formikBag,
							'CandidateAdminScorecardCreateModal'
						)
					}
				</Formik>
			</>
		);
	}

	renderForm(formikBag) {
		const { options } = this.state;

		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<label className="subtitle is-7">Scorecard Template</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.scorecardTemplateId}
									onValueChange={value =>
										formikBag.setFieldValue('scorecardTemplateId', value)
									}
									options={options ?? []}
									optionValue={'value'}
									disabled={!options}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		return {
			scorecardTemplateId: null
		};
	}

	async save(values) {
		const { candidate } = this.props;

		candidateScorecardStore
			.create(candidate.id, values.scorecardTemplateId)
			.then(result => {
				this.resultNotification(
					result,
					null,
					null,
					'Candidate Scorecard',
					'The candidate scorecard has been created successfully.'
				);
			});
	}
}
