import { action, observable, makeObservable } from 'mobx';
import { candidatesStore } from './CandidatesStore';
import { api } from '../../shared/api/api';

export class CandidateProfileStore {
	@observable candidate;
	@observable isLocked;
	endpointRootURL = 'candidateProfiles';

	constructor() {
		makeObservable(this);
	}

	@action
	setCandidate(candidate) {
		this.candidate = candidate;
	}

	@action
	setIsLocked(value) {
		this.isLocked = value;
	}

	@action
	getCandidateCopy() {
		return { ...this.candidate };
	}

	@action
	async reloadCandidate() {
		const result = await candidatesStore.get(this.candidate.id);
		this.setCandidate(result.data);
	}

	@action
	async loadCandidateLinkToken() {
		const { data } = await candidatesStore.checkLinkToken(this.candidate.id);
		this.setIsLocked(data);
	}

	@action
	reset() {
		this.candidate = null;
		this.isLocked = null;
	}

	@action
	async updateProfile(id, request) {
		const result = await api.put(`${this.endpointRootURL}/${id}`, request);
		return result;
	}

	@action
	async updateExperience(id, request) {
		const result = await api.put(
			`${this.endpointRootURL}/experience/${id}`,
			request
		);
		return result;
	}

	@action
	async updateEducation(id, request) {
		const result = await api.put(
			`${this.endpointRootURL}/education/${id}`,
			request
		);
		return result;
	}

	@action
	async updateCertification(id, request) {
		const result = await api.put(
			`${this.endpointRootURL}/certification/${id}`,
			request
		);
		return result;
	}

	@action
	async getPublicProfile(token) {
		return await api.get(`/profiles?token=${token}`);
	}
}

const candidateProfileStore = new CandidateProfileStore();
export { candidateProfileStore };
