import React, { Fragment } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { countryStore } from './CountryStore';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { FormField } from '../../layout/form-fields/FormField';

const ValidationSchema = Yup.object().shape({
	id: Yup.string().required().label('id'),
	name: Yup.string().required().label('name')
});

export class CountryEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					id: this.props.country.id,
					name: this.props.country.name
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'CountryEdit')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							disabled={true}
							className="has-text-left"
							label="Id"
							field="id"
							placeholder="Id"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
							placeholder="Name"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	async save(values) {
		countryStore
			.update(this.props.country.id, {
				country: values
			})
			.then(result => {
				this.resultNotification(result, 'Country', 'Edit');
			});
	}
}
