import React from 'react';
import localStorage from 'local-storage';
import { Loader } from '../misc/Loader';

export class Pagination extends React.Component {
	render() {
		if (!this.props.pagination) {
			return null;
		} else if (this.props.loading) {
			return <Loader type="ripple" className="search-loading is-hidden" />;
		} else if (
			this.props.pagination &&
			(this.props.pagination.totalResults === null ||
				this.props.pagination.totalResults === 0)
		) {
			return (
				<div className="has-text-centered subtitle is-6 pagination-no-results is-hidden">
					No results
				</div>
			);
		}
		let totalPages = 1;
		let pages = [];
		let currentPage = 1;
		let currentLimit = 50;
		let showOptions = [10, 25, 50, 100];
		const step = 5;
		if (this.props.pagination.totalResults > this.props.pagination.limit) {
			currentPage = this.props.pagination.page;
			currentLimit = this.props.pagination.limit;
			totalPages = Math.ceil(this.props.pagination.totalResults / currentLimit);
		}
		// show all pages
		if (totalPages < step) {
			pages = this.addPages(1, totalPages);
		} else if (currentPage - step / 2 <= 0) {
			// FIRST - step
			pages = this.addPages(1, step);
		} else if (currentPage + step / 2 >= totalPages) {
			// step - LAST
			pages = this.addPages(totalPages + 1 - step, totalPages);
		} else {
			// step (page in middle)
			pages = this.addPages(
				Math.ceil(currentPage - step / 2),
				Math.ceil(currentPage + step / 2) - 1
			);
		}
		return (
			<div className="Pagination pf-pagination">
				<ul className="pf-pagination-list">
					<div className="pf-size-controls">
						<p className="page-size"> SHOW:</p>
						<div className="select">
							<select
								value={this.props.pagination.limit}
								onChange={event => {
									if (event.target) {
										this.handlePaginationChange(
											currentPage,
											event.target.value
										);
									}
								}}>
								{showOptions.map((option, index) => {
									return <option key={index}>{option}</option>;
								})}
							</select>
						</div>
					</div>
					<div className="pf-page-controls">
						<p
							onClick={() => this.handlePaginationChange(1, currentLimit)}
							className={
								'go-back go-first ' +
								(pages.length > 1 && currentPage !== 1 ? '' : 'is-invisible')
							}>
							<i className="fas fa-angle-double-left" />
						</p>
						<p
							onClick={() => {
								if (pages.length > 1 && currentPage !== 1) {
									this.handlePaginationChange(currentPage - 1, currentLimit);
								}
							}}
							className={
								'go-back ' +
								(pages.length > 1 && currentPage !== 1 ? '' : 'is-invisible')
							}>
							<i className="fas fa-angle-left" />
						</p>
						{pages.map(page => {
							return (
								<p
									key={page}
									onClick={() =>
										this.handlePaginationChange(page, currentLimit)
									}
									className={'page ' + (currentPage === page ? 'active' : '')}>
									{page}
								</p>
							);
						})}
						<p
							onClick={() => {
								if (pages.length > 1 && currentPage < totalPages) {
									this.handlePaginationChange(currentPage + 1, currentLimit);
								}
							}}
							className={
								'go-forward go-last ' +
								(pages.length > 1 && currentPage < totalPages
									? ''
									: 'is-invisible')
							}>
							<i className="fas fa-angle-right" />
						</p>
						<p
							onClick={() =>
								this.handlePaginationChange(totalPages, currentLimit)
							}
							className={
								'go-forward go-last ' +
								(pages.length > 1 && currentPage < totalPages
									? ''
									: 'is-invisible')
							}>
							<i className="fas fa-angle-double-right" />
						</p>
					</div>
				</ul>
			</div>
		);
	}

	addPages(from, to) {
		let pages = [];
		for (let i = from; i <= to; i++) {
			pages.push(i);
		}
		return pages;
	}

	handlePaginationChange(page, limit) {
		if (this.props.pagination.totalResults <= limit) {
			page = 1;
		}
		if (this.props.onPaginationChange) {
			localStorage.set('paginationLimit', limit);
			this.props.onPaginationChange(page, limit);
		}
	}
}
