import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { candidatesStore } from '../CandidatesStore';
import { FormField } from '../../../components/layout/form-fields/FormField';
import { FieldRequiredIndicator } from '../../../components/layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({
	text: Yup.string().required().label('feedback')
});

export class CandidateProfileSendFeedback extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(
						formikBag,
						'CandidateProfileTestResultRequest'
					)
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Feedback <FieldRequiredIndicator />
							</label>
							<div className="control">
								<FormField
									className="has-text-left"
									field="text"
									type="textarea"
									maxlength={5000}
									placeholder="Feedback"
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		return {
			text: ''
		};
	}

	async save(values) {
		candidatesStore.sendFeedback(values).then(result => {
			this.resultNotification(
				result,
				null,
				null,
				'Feedback',
				'Your feedback has been received.'
			);
		});
	}
}
