import React from 'react';
import { Fragment } from 'react';
import { CandidateProfileTestResultProcess } from '../../candidates/profile/test-results/CandidateProfileTestResultProcess';

export class ListHeaderTestComponent extends React.Component {
	render() {
		return (
			<Fragment>
				<div className="level ListHeader">
					<div className="level-left">
						<h1 className="title">{this.props.title}</h1>
					</div>
					<div className="level-right">
						<CandidateProfileTestResultProcess
							handleSuccess={() => this.props.handleProcessResults()}
						/>
						<button
							className="button is-primary is-rounded main-action-button"
							onClick={() => this.props.handleCreate()}>
							{this.props.createActionTitle || `Create ${this.props.title}`}
						</button>
					</div>
				</div>
			</Fragment>
		);
	}
}
