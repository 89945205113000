import { EntityStore } from '../../shared/stores/EntityStore';
import { action, makeObservable } from 'mobx';
import { api } from '../../shared/api/api';

class JobOpeningStore extends EntityStore {
	constructor() {
		super('jobs');
		makeObservable(this);
	}

	@action
	async addCandidates(id, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${id}/candidates`,
			request
		);
		return result;
	}

	@action
	async deleteCandidate(id, candidateId) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/${id}/candidates/${candidateId}`
		);
		return result;
	}
}

export const jobOpeningStore = new JobOpeningStore();
