import _ from 'lodash';
import { DragSource, DropTarget } from 'react-dnd';
import React from 'react';
import { Button } from '../../../layout/button/Button';

export class ProjectDraggableItem extends React.Component {
	render() {
		const {
			project,
			isDragging,
			connectDragSource,
			connectDropTarget,
			isEditing,
			isAddEditFormActive
		} = this.props;

		const projectWrapperControls =
			!isAddEditFormActive || isEditing ? (
				<div className="is-pulled-right button-group">
					<Button
						disabled={isEditing}
						className="is-rounded is-small edit-button"
						icon="trash"
						iconClass="has-text-danger"
						onClick={event => {
							this.props.onDelete(project);
						}}
					/>
					<Button
						disabled={isEditing}
						className="is-middle is-small edit-button"
						icon="pencil-alt"
						iconClass="has-text-grey"
						onClick={event => {
							this.props.onEdit(project);
						}}
					/>
					<Button
						disabled={isEditing}
						title="Drag & Drop to sort"
						className="is-rounded is-small edit-button is-move-cursor"
						icon="bars"
					/>
				</div>
			) : (
				''
			);
		return connectDragSource(
			connectDropTarget(
				<div
					className={`project-wrapper ${isDragging ? 'is-dragging' : ''} ${
						isEditing ? 'is-editing' : ''
					}`}
					key={project.index}>
					{projectWrapperControls}
					<div className="title is-blue-text is-7">{project.title}</div>
					<div>{project.description}</div>
					{(project.linkUrls || []).map(linkUrl => (
						<div>
							<a href={linkUrl} target="_blank" rel="noopener noreferrer">
								{linkUrl}
							</a>
						</div>
					))}
				</div>
			)
		);
	}
}

const source = {
	beginDrag(props) {
		// props.onDrag();
		return {
			index: props.index
		};
	}
};

const target = {
	hover(props, monitor) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;
		if (dragIndex === hoverIndex) {
			return;
		}
		props.onMove(dragIndex, hoverIndex);
		monitor.getItem().index = hoverIndex;
	}
};

// The name "item" should be replaced by each type of draggable item.
// For example, it could be "project", "skill", "profile-section", etc.
export default _.flow(
	DropTarget('item', target, connect => ({
		connectDropTarget: connect.dropTarget()
	})),
	DragSource('item', source, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	}))
)(ProjectDraggableItem);
