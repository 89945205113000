import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { dialogStore } from './DialogStore';
import { Button } from '../button/Button';

@withRouter
@observer
export class Dialog extends React.Component {
	render() {
		return <div className="Dialog">{this.renderModal()}</div>;
	}

	renderModal() {
		return (
			<div className={'modal ' + this.isModalOpen()}>
				<div className="modal-background" />
				<div className="modal-card">
					<header className="modal-card-head">
						<p className="modal-card-title title is-2">{this.getTitle()}</p>
						<a href={'void'} onClick={e => this.handleClickClose(e)}>
							<i className="fas fa-times" />
						</a>
						<div className="border-line" />
					</header>
					<section className="modal-card-body has-text-centered">
						{this.getMessage()}
					</section>
					<footer className="modal-card-foot">
						<div className="fullwidth">
							{this.getHideCancelButton() ? null : (
								<button
									className="button is-rounded is-light"
									onClick={() => this.handleClickCancel()}>
									{this.getCancelLabel()}
								</button>
							)}
							<Button
								onClick={() => this.handleClickConfirm()}
								type="button"
								className={`button is-rounded ${
									dialogStore.confirmButtonClass || 'is-primary'
								}`}
								label={this.getConfirmLabel()}
								loading={dialogStore.loading}
							/>
						</div>
					</footer>
				</div>
			</div>
		);
	}

	getTitle() {
		return dialogStore.title ? dialogStore.title : '';
	}

	getMessage() {
		return dialogStore.message ? dialogStore.message : '';
	}

	getCancelLabel() {
		return dialogStore.cancelLabel ? dialogStore.cancelLabel : 'Cancel';
	}

	getConfirmLabel() {
		return dialogStore.confirmLabel ? dialogStore.confirmLabel : 'Confirm';
	}

	getHideCancelButton() {
		return dialogStore.hideCancelButton ? dialogStore.hideCancelButton : false;
	}

	isModalOpen() {
		return dialogStore.isOpen === true ? 'is-active ' : '';
	}

	handleClickClose(e) {
		e.preventDefault();
		if (dialogStore.handleCancelClose) {
			dialogStore.handleCancelClose('close');
		}
		dialogStore.closeDialog();
	}

	handleClickCancel() {
		if (dialogStore.handleCancelClose) {
			dialogStore.handleCancelClose('cancel');
		}
		dialogStore.closeDialog();
	}

	handleClickConfirm() {
		if (dialogStore.handleConfirm) {
			dialogStore.handleConfirm();
		}
		dialogStore.closeDialog();
	}
}
