import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { CandidateProfileTestResultRequest } from '../../profile/test-results/CandidateProfileTestResultRequest';
import { modalStore } from '../../../components/layout/modals/ModalStore';
import { candidateProfileStore } from 'candidate-portal/profile/CandidateProfileStore';
import { observer } from 'mobx-react';

@withRouter
@observer
export class SideNavigation extends React.Component {
	render() {
		let menuItems = [];
		// 1. Profile
		menuItems.push({
			route: '/p/',
			icon: 'fa fa-user',
			tooltip: 'My Profile'
		});
		// 2. Request a test
		menuItems.push({
			route: '/candidate-portal/my-profile',
			icon: 'fal fa-clipboard-list',
			modalComponent: (
				<Fragment>
					<CandidateProfileTestResultRequest
						isNew={true}
						handleSuccess={() => {}}
					/>
				</Fragment>
			),
			tooltip: 'Request Tests'
		});

		const isLocked = candidateProfileStore.isLocked;

		return (
			<div className="SideNavigation">
				<nav className={`menu  app-left-menu ${isLocked ? 'is-locked' : ''}`}>
					<ul className="menu-list">
						{menuItems.map((menuItem, index) => {
							return this.renderMenuItem(menuItem, index);
						})}
					</ul>
				</nav>
			</div>
		);
	}

	renderMenuItem(menuItem, index) {
		const { route, icon, tooltip, disabled, modalComponent } = menuItem;
		return (
			<li
				key={`sidebar-element-${index}`}
				className="tooltip is-tooltip-right"
				data-tooltip={tooltip}>
				{modalComponent ? (
					<NavLink
						className="dropdown-item"
						to={''}
						onClick={() => {
							modalStore.openModal(
								{ title: tooltip, saveButtonLabel: 'Request Tests' },
								modalComponent
							);
						}}>
						<i className={'fas ' + icon} />
					</NavLink>
				) : disabled ? (
					<NavLink
						to={''}
						onClick={this.disableNavLinkClick}
						className="disabled">
						<i className={'fas ' + icon} />
						<span className="extra-label">soon</span>
					</NavLink>
				) : (
					<NavLink to={route} activeClassName="is-active">
						<i className={'fas ' + icon} />
					</NavLink>
				)}
			</li>
		);
	}

	disableNavLinkClick(event) {
		event.preventDefault();
	}
}
