import React from 'react';
import moment from 'moment-timezone';
import Select from 'react-select';

export class YearDropdown extends React.Component {
	state = {
		value: null
	};
	componentDidMount() {
		if (this.props.value) {
			this.setState({
				value: { value: this.props.value, label: this.props.value }
			});
		}
	}

	render() {
		let options = [];
		const currentYear = moment().year();
		for (let i = currentYear; i >= 1960; i--) {
			options.push({ value: i, label: i });
		}

		const { placeholder = '', field = 'year' } = this.props;

		return (
			<Select
				name={field}
				value={this.state.value}
				className="react-select"
				menuPosition="fixed"
				placeholder={placeholder}
				isClearable={true}
				isSearchable={true}
				options={options}
				onChange={item => {
					if (this.props.onValueChange) {
						this.props.onValueChange(item);
					}
					this.setState({ value: item });
				}}
			/>
		);
	}
}
