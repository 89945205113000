import React from 'react';
import { Fragment } from 'react';

export class ListHeaderComponent extends React.Component {
	render() {
		return (
			<Fragment>
				<div className="level ListHeader">
					<div className="level-left">
						<h1 className="title">{this.props.title}</h1>
					</div>
					{this.props.disableCreation ? null : (
						<div className="level-right">
							<button
								className="button is-primary is-rounded main-action-button"
								onClick={() => this.props.handleCreate()}>
								{this.props.createActionTitle || `Create ${this.props.title}`}
							</button>
						</div>
					)}
					{this.props.actions ? (
						<div className="level-right">{this.props.actions}</div>
					) : null}
				</div>
			</Fragment>
		);
	}
}
