import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { notificationStore } from '../../../components/layout/notifications/NotificationStore';
import { Button } from '../../../components/layout/button/Button';
import { candidatesStore } from '../CandidatesStore';
import { JobRoleDropdown } from '../../../components/layout/form-fields/inputs/JobRoleDropdown';
import { MultiDropdown } from '../../../components/layout/form-fields/inputs/MultiDropdown';
import { SkillDropdown } from '../../../components/layout/form-fields/inputs/SkillDropdown';
import { TimezoneDropdown } from '../../../components/timezones/shared/TimezoneDropdown';
import { WorkLocationInput } from '../../../components/layout/form-fields/inputs/WorkLocationInput';
import { FieldRequiredIndicator } from '../../../components/layout/form-fields/inputs/FieldRequiredIndicator';
import { FormFieldError } from '../../../components/layout/form-fields/FormFieldError';
import DropdownField from '../../../components/layout/form-fields/DropdownField';
import { currencyStore } from '../../../components/settings/currencies/CurrencyStore';
import { FormField } from 'components/layout/form-fields/FormField';
import { Dropdown } from 'components/layout/form-fields/inputs/Dropdown';
import { modalStore } from 'components/layout/modals/ModalStore';
import { CandidateCompensationCreate } from 'components/candidates/profile/compensations/CandidateCompensationCreate';
import { candidateProfileStore } from '../CandidateProfileStore';
import { ConditionalContent } from 'components/layout/form-fields/inputs/ConditionalContent';
import { Loader } from 'components/layout/misc/Loader';

const ValidationSchema = Yup.object().shape({
	preferredJobRoles: Yup.array()
		.min(1, 'Please select at least one Role')
		.required()
		.label('Roles'),
	preferredSkills: Yup.array()
		.min(1, 'Please select at least one Skill')
		.required()
		.label('Technologies'),
	preferredWorkLocation: Yup.string()
		.nullable()
		.required()
		.label('Work Location'),
	preferredPositionType: Yup.array()
		.min(1, 'Please select at least one Position Type')
		.required()
		.label('Position Types'),
	preferredWorkshifts: Yup.array()
		.min(1, 'Please select at least one Workshift')
		.required()
		.label('Workshifts'),
	preferredTimeZone: Yup.object().nullable().required().label('Time Zone'),
	showingCompensationFields: Yup.boolean(),
	currentCurrency: Yup.mixed()
		.label('Current Currency')
		.when('showingCompensationFields', {
			is: true,
			then: schema =>
				schema
					.test(
						'is-special-required',
						'Current Currency is required',
						value => !!value
					)
					.required()
		}),
	expectedCurrency: Yup.mixed()
		.label('Expected Currency')
		.when('showingCompensationFields', {
			is: true,
			then: schema =>
				schema
					.test(
						'is-special-required',
						'Expected Currency is required',
						value => !!value
					)
					.required()
		}),
	currentCompensation: Yup.number()
		.label('Current Compensation')
		.when('showingCompensationFields', {
			is: true,
			then: schema => schema.required()
		}),
	expectedCompensation: Yup.number()
		.label('Expected Compensation')
		.when('showingCompensationFields', {
			is: true,
			then: schema => schema.required()
		})
});

export class CandidateProfileExpectationsEdit extends React.Component {
	state = {
		saving: false,
		loaded: false,
		currencies: []
	};
	getValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	isInitialValid(props) {
		if (!props.validationSchema) return true;
		return props.validationSchema.isValidSync(props.initialValues);
	}

	findCurrencyObject(currencyId) {
		return this.state.currencies.find(currency => currency.id === currencyId);
	}

	async componentDidMount() {
		const { data: currencies } = await currencyStore.getCurrencies('');

		this.setState({ currencies, loaded: true });
	}

	renderCompensationFields(formikBag, currencyMap) {
		if (!this.state.loaded) {
			return <Loader />;
		}

		return (
			<div className="compensations columns wizard-row">
				<div className="column is-6">
					<div className="has-text-left">
						<label className="title is-5 has-text-weight-bold">
							Current <strong>Compensation</strong>
						</label>
						<div className="columns">
							<p className="control column is-4">
								<div className="field">
									<Dropdown
										styleVersion="v2"
										value={formikBag.values.currentCurrency}
										onValueChange={value => {
											formikBag.setFieldValue('currentCurrency', value);
										}}
										options={currencyMap}
										optionValue={'value'}
										placeholder="Currency"
										position="top"
									/>
									<FormFieldError field="currentCurrency" />
								</div>
							</p>
							<p className="control column">
								<FormField
									className="has-text-left"
									styleVersion="v2"
									field="currentCompensation"
									type="number"
									placeholder="Current Compensation"
								/>
							</p>
						</div>
					</div>
				</div>
				<div className="column is-6">
					<div className=" has-text-left">
						<label className="title is-5 has-text-weight-bold">
							Expected <strong>Compensation</strong>
						</label>
						<div className="columns">
							<p className="control column is-4">
								<div className="field">
									<Dropdown
										styleVersion="v2"
										value={formikBag.values.expectedCurrency}
										onValueChange={value => {
											formikBag.setFieldValue('expectedCurrency', value);
										}}
										options={currencyMap}
										optionValue={'value'}
										placeholder="Currency"
										position="top"
									/>
									<FormFieldError field="expectedCurrency" />
								</div>
							</p>
							<p className="control column">
								<FormField
									className="has-text-left"
									styleVersion="v2"
									field="expectedCompensation"
									type="number"
									placeholder="Expected Compensation"
								/>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderCompensations() {
		const latestCompensation =
			this.props.candidate.candidateCompensationLedgers[0];

		return (
			<div className="compensations columns wizard-row">
				<div className="column is-5">
					<div className="has-text-left">
						<label className="title is-5 has-text-weight-bold">
							Current Compensation
						</label>
						<div className="columns">
							<p className="control column">
								{latestCompensation
									? `${latestCompensation.currentCurrency.code} ${latestCompensation.currentCompensation}`
									: '-'}
							</p>
						</div>
					</div>
				</div>
				<div className="column is-5">
					<div className=" has-text-left">
						<label className="title is-5 has-text-weight-bold">
							Expected Compensation
						</label>
						<div className="columns">
							<p className="control column">
								{latestCompensation
									? `${latestCompensation.expectedCurrency.code} ${latestCompensation.expectedCompensation}`
									: '-'}
							</p>
						</div>
					</div>
				</div>
				<div className="column">
					<Button
						onClick={() => {
							modalStore.openModal(
								{ title: 'Add Compensation' },
								<CandidateCompensationCreate
									isCandidate
									candidate={this.props.candidate}
									handleSuccess={() => {
										candidateProfileStore.reloadCandidate();
									}}
									handleSave={values =>
										candidatesStore.createCompensation({
											data: {
												...values,
												currentCurrency: this.findCurrencyObject(
													values.currentCurrency
												),
												expectedCurrency: this.findCurrencyObject(
													values.expectedCurrency
												)
											}
										})
									}
								/>
							);
						}}
						type="submit"
						className="button is-primary is-big is-rounded"
						label="Update Compensation"
					/>
				</div>
			</div>
		);
	}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				isInitialValid={this.isInitialValid}
				validateOnChange
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderForm(formikBag)}
			</Formik>
		);
	}

	renderForm(formikBag) {
		const currencyMap = [
			{ text: 'Currency', value: '' },
			...this.state.currencies.map(currency => ({
				text: `${currency.code} ${currency.symbol}`,
				value: currency.id
			}))
		];

		return (
			<Fragment>
				<div className="expectations-edit form-content maxWidth">
					<section className="hero">
						<div className="hero-body">
							<div className="container has-text-centered">
								<h1 className="title is-2 title-edit">Work Preferences</h1>
							</div>
						</div>
					</section>
					<section className="section">
						<div className="container">
							<div className="columns wizard-row">
								<div className="column is-full">
									<div className="field has-text-left">
										<label className="title is-6 ">
											What type of <strong>roles</strong> are you interested in?{' '}
											<FieldRequiredIndicator />
										</label>
										<DropdownField
											component={JobRoleDropdown}
											name="preferredJobRoles"
											styleVersion="v2"
										/>
										<FormFieldError field="preferredJobRoles" />
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-full">
									<div className="field has-text-left">
										<label className="title is-6">
											What are the <strong>technologies</strong> you would like
											to work with? <FieldRequiredIndicator />
										</label>
										<DropdownField
											component={SkillDropdown}
											name="preferredSkills"
											placeholder="You can choose more than one option"
											styleVersion="v2"
										/>
										<FormFieldError field="preferredSkills" />
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-full">
									<WorkLocationInput
										value={formikBag.values.preferredWorkLocation}
										onValueChange={value => {
											formikBag.setFieldValue('preferredWorkLocation', value);
											formikBag.setFieldTouched(
												'preferredWorkLocation',
												true,
												false
											);
										}}
										required
									/>
									<FormFieldError field="preferredWorkLocation" />
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-full">
									<div className="field has-text-left">
										<label className="title is-6">
											What type of <strong>positions</strong> are you interested
											in? <FieldRequiredIndicator />
										</label>
										<DropdownField
											component={MultiDropdown}
											name="preferredPositionType"
											placeholder="You can choose more than one option"
											styleVersion="v2"
											options={candidatesStore.preferredPositionTypeOptions}
										/>
										<FormFieldError field="preferredPositionType" />
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-8">
									<div className=" has-text-left">
										<label className="title is-6">
											Which <strong>workshifts</strong> would you be willing to
											work in? <FieldRequiredIndicator />
										</label>
										<DropdownField
											component={MultiDropdown}
											name="preferredWorkshifts"
											styleVersion="v2"
											options={candidatesStore.preferredWorkshiftsOptions}
											onValueChange={values => {
												const sortedWorkshifts = values
													? values.sort(this.orderWorkshift)
													: values;
												formikBag.setFieldValue(
													'preferredWorkshifts',
													sortedWorkshifts
												);
												formikBag.setFieldTouched(
													'preferredWorkshifts',
													true,
													false
												);
											}}
										/>
										<FormFieldError field="preferredWorkshifts" />
									</div>
								</div>
								<div className="column is-4">
									<div className=" has-text-left">
										<label className="title is-5 has-text-weight-bold">
											What is your <strong>time zone</strong>?{' '}
											<FieldRequiredIndicator />
										</label>
										<DropdownField
											component={TimezoneDropdown}
											name="preferredTimeZone"
											styleVersion="v2"
										/>
										<FormFieldError field="preferredTimeZone" />
									</div>
								</div>
							</div>
							{this.props.isWizard ? (
								<div className="columns wizard-row">
									<div className="column is-fullwidth">
										<ConditionalContent
											entityName="compensations"
											styleVersion="v2"
											noSelectDefaultValue={0}
											shouldShowContent={this.areCompensationsFilled(formikBag)}
											label={
												<>
													Would you like to enter your{' '}
													<strong>compensation</strong> details?
												</>
											}
											required
											onShow={() => {
												formikBag.setFieldValue(
													'showingCompensationFields',
													true
												);
											}}
											onHide={() => {
												formikBag.setFieldValue(
													'showingCompensationFields',
													false
												);
												this.clearCompensationFields(formikBag);
											}}
											content={() =>
												this.renderCompensationFields(formikBag, currencyMap)
											}
										/>
									</div>
								</div>
							) : (
								this.renderCompensations()
							)}
						</div>
					</section>
					<footer className="footer">
						<div className="container">
							<div className="content has-text-centered">
								{this.props.isWizard ? (
									<div>
										<Button
											onClick={() => {
												formikBag.submitForm();
											}}
											type="submit"
											className="button primary-v2 is-large"
											label={'Continue'}
											loading={this.state.saving}
										/>
									</div>
								) : (
									<Button
										onClick={() => {
											formikBag.submitForm();
										}}
										type="submit"
										className="button primary-v2 is-large"
										label={'Save'}
										loading={this.state.saving}
									/>
								)}
							</div>
						</div>
					</footer>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		const preferredPositionType = (
			this.props.candidate.preferredPositionType || []
		).map(item => {
			return {
				text: this.getValueFromOptions(
					item,
					candidatesStore.preferredPositionTypeOptions
				),
				value: item
			};
		});
		const preferredWorkshifts = (
			this.props.candidate.preferredWorkshifts || []
		).map(item => {
			return {
				text: this.getValueFromOptions(
					item,
					candidatesStore.preferredWorkshiftsOptions
				),
				value: item
			};
		});
		const candidateTimeZone = this.props.candidate.timezone
			? this.props.candidate.timezone
			: null;
		const preferredSkills = (this.props.candidate.preferredSkills || []).map(
			item => {
				return item.skill;
			}
		);
		const preferredJobRoles = (
			this.props.candidate.preferredJobRoles || []
		).map(item => {
			return { ...item.jobRole };
		});

		const latestCompensation =
			this.props.candidate.candidateCompensationLedgers?.[0] || {};

		return {
			preferredJobRoles: preferredJobRoles,
			preferredWorkLocation: this.props.candidate.preferredWorkLocation,
			preferredSkills: preferredSkills,
			lookingForJob: this.props.candidate.lookingForJob,
			preferredTimeZone: candidateTimeZone,
			preferredPositionType,
			preferredWorkshifts,
			currentCurrency: latestCompensation.currentCurrencyId || '',
			currentCompensation: latestCompensation.currentCompensation || '',
			expectedCurrency: latestCompensation.expectedCurrencyId || '',
			expectedCompensation: latestCompensation.expectedCompensation || ''
		};
	}

	saving(status) {
		if (status === false || status === true) {
			this.setState({ saving: status });
		} else {
			this.setState({ saving: true });
		}
	}

	async save(values) {
		const compensationFields = {
			currentCurrency: this.findCurrencyObject(values.currentCurrency),
			expectedCurrency: this.findCurrencyObject(values.expectedCurrency),
			currentCompensation: values.currentCompensation,
			expectedCompensation: values.expectedCompensation
		};

		const valuesToSave = {
			candidate: {
				...values,
				preferredPositionType: values.preferredPositionType.map(
					item => item.value
				),
				preferredWorkshifts: values.preferredWorkshifts.map(item => item.value),
				preferredTimeZone: values.preferredTimeZone.id
			},
			preferredJobRoles: values.preferredJobRoles,
			preferredSkills: values.preferredSkills,
			...(this.props.isWizard &&
			this.hasCompensationsChanged(compensationFields)
				? compensationFields
				: {})
		};
		candidatesStore.updateExpectations(valuesToSave).then(result => {
			this.setState({ saving: false });
			if (result.status === 'success') {
				notificationStore.pushNotification({
					type: notificationStore.SUCCESS_NOTIFICATION,
					title: 'Work preferences saved',
					message: 'Your work preferences were saved successfully.'
				});
				this.props.handleSuccess();
			} else {
				notificationStore.pushNotification({
					type: notificationStore.ERROR_NOTIFICATION,
					title: 'Work preferences error',
					message: 'An error has occurred. Please try again.'
				});
			}
		});
	}

	orderWorkshift(workshiftA, workshiftB) {
		if (workshiftA === 'anytime') {
			return 1;
		}
		const shiftListA = workshiftA.value.split('-');
		const shiftStartsA = shiftListA[0];
		const militaryShiftTimeA = parseInt(
			moment(shiftStartsA, 'ha').format('HH')
		);
		const shiftListB = workshiftB.value.split('-');
		const shiftStartsB = shiftListB[0];
		const militaryShiftTimeB = parseInt(
			moment(shiftStartsB, 'ha').format('HH')
		);
		return militaryShiftTimeA > militaryShiftTimeB ? 1 : -1;
	}

	areCompensationsFilled(formikBag) {
		return (
			!!formikBag.values.currentCurrency &&
			!!formikBag.values.currentCompensation &&
			!!formikBag.values.expectedCurrency &&
			!!formikBag.values.expectedCompensation
		);
	}

	clearCompensationFields(formikBag) {
		formikBag.setFieldValue('currentCurrency', '');
		formikBag.setFieldValue('currentCompensation', '');
		formikBag.setFieldValue('expectedCurrency', '');
		formikBag.setFieldValue('expectedCompensation', '');
	}

	hasCompensationsChanged(newCompensation) {
		if (!newCompensation) {
			return;
		}
		const latestCompensation =
			this.props.candidate.candidateCompensationLedgers[0];

		return (
			!latestCompensation ||
			(latestCompensation &&
				(latestCompensation.currentCurrency.id !==
					newCompensation.currentCurrency.id ||
					latestCompensation.currentCompensation !==
						newCompensation.currentCompensation ||
					latestCompensation.expectedCurrency.id !==
						newCompensation.expectedCurrency.id ||
					latestCompensation.expectedCompensation !==
						newCompensation.expectedCompensation))
		);
	}
}
