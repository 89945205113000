import React from 'react';
import { CandidateProfileRepo } from 'components/candidates/profile/repos/CandidateProfileRepo';
import { Icon } from './Icon';
export class CandidatePublicProfileRepos extends React.Component {
	render() {
		return (
			<div className="repos-container bio-subsection-wrapper CandidatePublicProfileRepos">
				<div className="title is-inline-block">
					<Icon icon="fa-regular fa-brands fa-github" />
					Repos
				</div>
				{this.props.repos.length > 0 ? (
					<div className="sub-section">
						<div className="sub-section-content">
							{this.props.repos.map((repo, index) => {
								return (
									<CandidateProfileRepo
										key={repo.id}
										repo={repo}
										asLink
										showIcon={false}
									/>
								);
							})}
						</div>
					</div>
				) : (
					<div className="sub-section-content">
						<p>There are no repos listed in this candidate's profile yet.</p>
					</div>
				)}
			</div>
		);
	}
}
