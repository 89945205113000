import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Field, Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../../../layout/form-fields/FormField';
import { Dropdown } from '../../../layout/form-fields/inputs/Dropdown';
import { candidateCompensationsStore } from './CandidateCompensationsStore';
import { FieldRequiredIndicator } from '../../../layout/form-fields/inputs/FieldRequiredIndicator';
import { DateInput } from '../../../layout/form-fields/inputs/DateInput';
import { currencyStore } from '../../../settings/currencies/CurrencyStore';
import { Loader } from '../../../layout/misc/Loader';
import { shift } from '@floating-ui/dom';

const ValidationSchema = Yup.object().shape({
	currentCurrency: Yup.number().required().label('Current Currency'),
	expectedCurrency: Yup.number().required().label('Expected Currency'),
	date: Yup.string().required().label('Date'),
	currentCompensation: Yup.number().required().label('Current Compensation'),
	expectedCompensation: Yup.number().required().label('Expected Compensation'),
	notes: Yup.string().label('Notes')
});

export class CandidateCompensationCreate extends ModalComponent {
	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			loaded: false
		};
	}

	async componentDidMount() {
		await currencyStore
			.getCurrencies('')
			.then(this.onLoadCurrencies.bind(this));
	}

	onLoadCurrencies({ data }) {
		this.setState({
			currencies: data,
			loaded: true
		});
	}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateCompensationEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		const { loaded, currencies } = this.state;

		if (!loaded) {
			return <Loader />;
		}

		const currencyMap = [
			{ text: 'Currency', value: '' },
			...currencies.map(currency => ({
				text: `${currency.code} ${currency.symbol}`,
				value: currency.id
			}))
		];

		return (
			<Fragment>
				{this.props.isCandidate ? null : (
					<div className="columns">
						<div className="column is-6">
							<div className="field has-text-left">
								<label className="subtitle is-7">
									Date <FieldRequiredIndicator />
								</label>
								<div className="control">
									<DateInput
										value={formikBag.values.date}
										popperModifiers={[
											shift({
												padding: 5
											})
										]}
										onBlur={() => formikBag.setFieldTouched('date')}
										onChange={newDate => {
											formikBag.setFieldValue('date', newDate);
										}}
									/>
									<p className="help is-danger">{formikBag.errors.date}</p>
								</div>
							</div>
						</div>
					</div>
				)}
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7 is-left">
								Current Compensation
							</label>
							<div className="columns">
								<p className="control column is-3">
									<Dropdown
										enableFixedMode={true}
										value={formikBag.values.currentCurrency}
										onValueChange={value => {
											formikBag.setFieldValue('currentCurrency', value);
											formikBag.setFieldValue('currentCompensation', '');
										}}
										options={currencyMap}
										optionValue={'value'}
									/>
								</p>
								<p className="control column">
									<Field
										name="currentCompensation"
										className="input"
										type="number"
									/>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label htmlFor="" className="subtitle is-7">
								Expected Compensation
							</label>
							<div className="columns">
								<div className="control column is-3">
									<Dropdown
										enableFixedMode={true}
										value={formikBag.values.expectedCurrency}
										onValueChange={value => {
											formikBag.setFieldValue('expectedCurrency', value);
											formikBag.setFieldValue('expectedCompensation', '');
										}}
										options={currencyMap}
										optionValue={'value'}
									/>
								</div>
								<div className="control column">
									<Field
										name="expectedCompensation"
										className="input"
										type="number"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Notes"
							field="notes"
							type="textarea"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		const candidateCompensations =
			candidateCompensationsStore.compensations ||
			this.props.candidate.candidateCompensationLedgers;

		const latestCompensation =
			candidateCompensations.length > 0 ? candidateCompensations[0] : {};

		return {
			currentCurrency: latestCompensation.currentCurrencyId || '',
			expectedCurrency: latestCompensation.expectedCurrencyId || '',
			date: Date.now(),
			currentCompensation: latestCompensation.currentCompensation || '',
			expectedCompensation: latestCompensation.expectedCompensation || '',
			notes: ''
		};
	}

	async save(values) {
		const { handleSave } = this.props;

		const result = await handleSave(values);
		this.resultNotification(
			result,
			null,
			null,
			'Compensation',
			'The Compensation Entry has been saved successfully.'
		);
	}
}
