import { ModalComponent } from 'components/layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { candidateProfileStore } from 'components/candidates/profile/CandidateProfileStore';
import { FormField } from 'components/layout/form-fields/FormField';

const ValidationSchema = Yup.object().shape({
	fullName: Yup.string().required().label('Full Name'),
	company: Yup.string().required().label('Company'),
	email: Yup.string().required().email().label('Email')
});

export class RequestProfileModal extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					fullName: '',
					company: '',
					email: ''
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'RequestProfileModal')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Full Name"
							field="fullName"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Company"
							field="company"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Email"
							field="email"
							type="email"
							icon="fa-envelope"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	async save(values) {
		const { candidateId, candidateSlug } = this.props;

		candidateProfileStore
			.requestCandidate({ ...values, id: candidateId, slug: candidateSlug })
			.then(result => {
				this.resultNotification(
					result,
					null,
					null,
					'Request a new candidate profile link',
					"Your request to re-activate this candidate's profile was sent."
				);
			});
	}
}
