import React, { useEffect } from 'react';
import { candidateCompensationsStore } from './CandidateCompensationsStore';
import { CandidateCompensationsTable } from './CandidateCompensationsTable';
import { observer } from 'mobx-react';
import { notificationStore } from '../../../layout/notifications/NotificationStore';

export const CandidateCompensations = observer(
	({ candidate, renderInternalSectionButtons }) => {
		useEffect(() => {
			const getCompensations = async () => {
				await candidateCompensationsStore.getCompensations(candidate.id);
			};

			void getCompensations();
		}, [candidate.id]);

		const handleDeleteCompensation = async id => {
			const result = await candidateCompensationsStore.delete(candidate.id, id);

			if (result.status === 'success') {
				notificationStore.pushNotification({
					type: notificationStore.SUCCESS_NOTIFICATION,
					title: 'Delete compensation',
					message: 'The compensation entry has been successfully removed.'
				});
			} else {
				notificationStore.pushNotification({
					type: notificationStore.ERROR_NOTIFICATION,
					title: 'Delete compensation',
					message: 'An error has occurred. Please try again.'
				});
			}

			await candidateCompensationsStore.getCompensations(candidate.id);
		};

		return (
			<div className="CandidateProfileExpectationsModal">
				<section className="modal-card-body">
					{renderInternalSectionButtons()}
					<div className="columns">
						<CandidateCompensationsTable
							data={candidateCompensationsStore.compensations}
							onClickDelete={handleDeleteCompensation}
						/>
					</div>
				</section>
				<footer className="modal-card-foot"></footer>
			</div>
		);
	}
);
