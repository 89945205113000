import { action, observable, makeObservable } from 'mobx';

export class DialogStore {
	@observable isOpen = false;
	@observable title;
	@observable message;
	@observable cancelLabel;
	@observable confirmLabel;
	@observable confirmButtonClass;
	@observable handleConfirm;
	@observable handleCancelClose;
	@observable hideCancelButton;
	@observable isAsyncOperation = false;
	@observable loading = false;

	constructor() {
		makeObservable(this);
	}

	@action
	openDialog(options, handleConfirm, handleCancelClose) {
		this.isOpen = true;
		this.title = options.title;
		this.message = options.message;
		this.cancelLabel = options.cancelLabel;
		this.confirmLabel = options.confirmLabel;
		this.hideCancelButton = options.hideCancelButton;
		this.confirmButtonClass = options.confirmButtonClass;
		this.handleConfirm = handleConfirm;
		this.handleCancelClose = handleCancelClose;
		this.loading = false;
	}

	@action
	closeDialog() {
		this.isOpen = false;
		this.title = null;
		this.message = null;
		this.cancelLabel = null;
		this.confirmLabel = null;
		this.confirmButtonClass = null;
		this.hideCancelButton = null;
		this.handleConfirm = null;
		this.handleCancelClose = null;
		this.loading = false;
	}

	@action
	setLoading(value) {
		this.loading = value;
	}
}

const dialogStore = new DialogStore();
export { dialogStore };
