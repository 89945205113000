import React, { Fragment } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormField } from '../layout/form-fields/FormField';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { candidatesStore } from './CandidatesStore';
import { Dropdown } from '../layout/form-fields/inputs/Dropdown';
import { CountryDropdown } from '../layout/form-fields/inputs/CountryDropdown';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	email: Yup.string().required().email().label('Email')
});

export class CandidateCreate extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					name: '',
					email: '',
					location: '',
					seniority: 'jr',
					englishLevel: 'none',
					countryId: '',
					aboutMe: ''
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.create(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'CandidateCreate')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
							required
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Email"
							field="email"
							type="text"
							placeholder="email@domain.com"
							icon="fa-envelope"
							required
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">Country</label>
							<div className="control">
								<CountryDropdown
									value={this.state.selectedCountry}
									onValueChange={item => {
										formikBag.setFieldValue('countryId', item.id);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="City/State"
							field="location"
							type="text"
							placeholder="City/State"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">English Level</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value="none"
									onValueChange={value =>
										formikBag.setFieldValue('englishLevel', value)
									}
									options={candidatesStore.englishLevelOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Bio"
							field="aboutMe"
							type="textarea"
							placeholder="Bio"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	async create(values) {
		const {
			name,
			email,
			location,
			seniority,
			englishLevel,
			countryId,
			aboutMe
		} = values;
		candidatesStore
			.create({
				name: name,
				email: email,
				location: location,
				seniority: seniority,
				englishLevel: englishLevel,
				countryId: countryId,
				aboutMe: aboutMe
			})
			.then(result => {
				this.resultNotification(result, 'Candidate', 'Create');
			});
	}
}
