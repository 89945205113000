import React from 'react';
import { withRouter } from 'react-router-dom';
import { BaseHeader } from './BaseHeader';
import LoginButton from 'components/login/LoginButton';

@withRouter
export class PublicHeader extends React.Component {
	constructor(props) {
		super(props);

		this.handleSignupClick = this.handleSignupClick.bind(this);
	}

	handleSignupClick() {
		this.props.history.push('/signup');
	}

	render() {
		return (
			<BaseHeader
				logoPath="/logo-blue.svg"
				actions={
					<div className="navbar-end">
						{/*Google Login*/}
						<LoginButton
							render={({ onClick }) => {
								return (
									<>
										<div className="navbar-item">
											<button
												className="button is-secondary is-hidden-touch"
												onClick={onClick}>
												Login
											</button>
											<button
												className="button is-ghost is-underlined is-fullwidth is-hidden-desktop"
												onClick={onClick}>
												Login
											</button>
										</div>
									</>
								);
							}}
						/>
						{/*Sign up*/}
						<div className="navbar-item">
							<button
								className="button is-primary is-hidden-touch"
								onClick={this.handleSignupClick}>
								Sign Up
							</button>
							<button
								className="button is-ghost is-fullwidth is-hidden-desktop"
								onClick={this.handleSignupClick}>
								Sign Up
							</button>
						</div>
					</div>
				}
			/>
		);
	}
}
