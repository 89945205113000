import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../../../layout/form-fields/FormField';
import { Dropdown } from '../../../layout/form-fields/inputs/Dropdown';
import { candidatesStore } from '../../CandidatesStore';
import { YearDropdown } from '../../../layout/form-fields/inputs/YearDropdown';

const ValidationSchema = Yup.object().shape({
	degree: Yup.string().required().label('Degree'),
	institution: Yup.string().required().label('Institution')
});

export class CandidateProfileCertificationEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(
						formikBag,
						'CandidateProfileCertificationEdit'
					)
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Degree"
							field="degree"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Institution"
							field="institution"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Location"
							field="location"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<label className="subtitle is-7">Status</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.status}
									onValueChange={value => {
										formikBag.setFieldValue('status', value);
									}}
									options={candidatesStore.educationStatusOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`columns ${
						formikBag.values.status !== 'graduated' ? 'is-hidden' : ''
					}`}>
					<div className="column is-8">
						<div className="field has-text-left">
							<label className="subtitle is-7">Completion Year</label>
							<div className="control">
								<Fragment>
									<YearDropdown
										field="graduationYear"
										value={formikBag.values.graduationYear}
										placeholder="Completion Year"
										name="graduationYear"
										onValueChange={item => {
											formikBag.setFieldValue(
												'graduationYear',
												item ? item.value : ''
											);
										}}
									/>
								</Fragment>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		if (this.props.isNew) {
			return {
				degree: '',
				institution: '',
				location: '',
				status: 'attending',
				graduationYear: ''
			};
		} else {
			return {
				degree: this.props.certification.degree,
				institution: this.props.certification.institution,
				location: this.props.certification.location || '',
				status: this.props.certification.status,
				graduationYear: this.props.certification.graduationYear
			};
		}
	}

	async save(values) {
		if (!values.graduationYear) {
			values.graduationYear = null;
		}
		if (this.props.isNew) {
			candidatesStore
				.createCertification(this.props.candidate.id, {
					certification: values
				})
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Certification',
						'The Certification Entry has been saved successfully.'
					);
				});
		} else {
			candidatesStore
				.updateCertification(this.props.certification.id, {
					certification: values
				})
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Certification',
						'The Certification Entry has been saved successfully.'
					);
				});
		}
	}
}
