import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import { Dropdown } from '../../../components/layout/form-fields/inputs/Dropdown';
import { candidatesStore } from '../CandidatesStore';
import { candidatesStore as candidateStoreAdmin } from '../../../components/candidates/CandidatesStore';
import { CandidateProfileResumeEdit } from './CandidateProfileResumeEdit';
import { CandidateProfileAboutExpertiseAreas } from './CandidateProfileAboutExpertiseAreas';
import { SkillDropdown } from '../../../components/layout/form-fields/inputs/SkillDropdown';
import { CandidateProfileAboutKnownSkills } from './CandidateProfileAboutKnownSkills';

const SUPPORTED_FORMATS = ['application/pdf'];
const MAX_FILE_SIZE_LIMIT = process.env.REACT_APP_RESUME_MAX_FILE_SIZE;
const ValidationSchema = Yup.object().shape({
	englishLevel: Yup.string().required(),
	expertiseAreas: Yup.array()
		.min(1, 'Please select at least one Expertise Area')
		.label('expertiseAreas')
		.required()
		.test(
			'expertise-area-completed',
			'Please complete all fields for every Expertise Area',
			function (value) {
				if (!(value.length > 0)) {
					return false;
				}
				const valid = value.every(item => {
					return item.experienceYears && item.level;
				});
				return valid;
			}
		),
	knownSkills: Yup.array()
		.min(1, 'Please select at least one Technology')
		.label('knownSkills')
		.required()
		.test(
			'known-skills-completed',
			'Please complete all fields for every Technology',
			function (value) {
				if (!(value.length > 0)) {
					return false;
				}
				const valid = value.every(item => {
					return item.experienceYears && item.level;
				});
				return valid;
			}
		),
	remoteExperienceYears: Yup.string().required(),
	usExperienceYears: Yup.string().required(),
	highestEducationLevel: Yup.string().required(),
	educationStage: Yup.string().required(),
	resume: Yup.mixed()
		.test(
			'only-pdf-files',
			'File type not supported. Only .pdf files are allowed.',
			value => {
				if (!value) return true;
				return value && value.type && SUPPORTED_FORMATS.includes(value.type);
			}
		)
		.test('file-size-limit', 'The file size is too large (max 1MB)', value => {
			if (!value) return true;
			return value && value.size <= MAX_FILE_SIZE_LIMIT;
		})
});

export class CandidateProfileAboutEditAdmin extends ModalComponent {
	state = {
		saving: false
	};
	getValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	isInitialValid(props) {
		if (!props.validationSchema) return true;
		return props.validationSchema.isValidSync(props.initialValues);
	}
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				isInitialValid={this.isInitialValid}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileAboutEditAdmin')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								How would you rate your english language level?
							</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.englishLevel}
									onValueChange={value =>
										formikBag.setFieldValue('englishLevel', value)
									}
									options={candidatesStore.englishLevelOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<CandidateProfileAboutExpertiseAreas formikBag={formikBag} />
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<CandidateProfileAboutKnownSkills formikBag={formikBag} />
					</div>
				</div>
				<div className="columns add-margin-top">
					<div className="column is-full">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								What are the technologies you know best?
							</label>
							<SkillDropdown
								value={formikBag.values.knownSkills}
								onValueChange={values =>
									formikBag.setFieldValue('knownSkills', values)
								}
							/>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								Do you have experience working remotely?
							</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.remoteExperienceYears}
									onValueChange={value =>
										formikBag.setFieldValue('remoteExperienceYears', value)
									}
									options={candidatesStore.remoteExperienceYearsAdminOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								Do you have experience working with teams in the United States?
							</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.usExperienceYears}
									onValueChange={value =>
										formikBag.setFieldValue('usExperienceYears', value)
									}
									options={candidatesStore.usExperienceYearsAdminOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<div>
								<label className="subtitle is-7">Resume</label>
							</div>
							<a
								href={`${process.env.REACT_APP_API}${formikBag.values.resumeUrl}`}
								download>
								{' '}
								Download{' '}
							</a>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								<CandidateProfileResumeEdit formikBag={formikBag} />
								{!formikBag.errors.resume ? null : (
									<p className="help is-danger">{formikBag.errors.resume}</p>
								)}
							</label>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className=" has-text-left">
							<label className="subtitle is-7">
								What is your highest level of education?
							</label>
							<div className="columns">
								<div className="column is-7">
									<div className="control">
										<Dropdown
											enableFixedMode={true}
											value={formikBag.values.highestEducationLevel}
											placeholder={'Education Level'}
											onValueChange={value => {
												formikBag.setFieldValue('highestEducationLevel', value);
											}}
											options={candidatesStore.highestEducationLevelOptions}
											optionValue={'value'}
										/>
									</div>
								</div>
								<div className="column is-5">
									<div className=" has-text-left">
										<label className="title is-5 has-text-weight-bold"></label>
										<div className="control">
											<Dropdown
												enableFixedMode={true}
												value={formikBag.values.educationStage}
												placeholder={'Stage of Progress'}
												onValueChange={value => {
													formikBag.setFieldValue('educationStage', value);
												}}
												options={candidatesStore.educationStageOptions}
												optionValue={'value'}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		const expertiseAreas = this.props.candidate.areaAttributes.map(item => {
			return {
				...item,
				...item.expertiseArea
			};
		});
		const knownSkills = (this.props.candidate.knownSkills || []).map(item => {
			return { ...item, ...item.skill };
		});
		return {
			englishLevel: this.props.candidate.englishLevel,
			remoteExperienceYears: this.props.candidate.remoteExperienceYears,
			expertiseAreas: expertiseAreas,
			knownSkills: knownSkills,
			resumeUrl: this.props.candidate.resumeUrl,
			usExperienceYears: this.props.candidate.usExperienceYears,
			highestEducationLevel: this.props.candidate.highestEducationLevel,
			educationStage: this.props.candidate.educationStage
		};
	}

	saving(status) {
		if (status === false || status === true) {
			this.setState({ saving: status });
		} else {
			this.setState({ saving: true });
		}
	}

	async save(values) {
		const valuesToSave = {
			candidate: {
				...values
			},
			expertiseAreas: values.expertiseAreas,
			knownSkills: values.knownSkills
		};
		if (values.resume) {
			candidateStoreAdmin
				.uploadCandidateResume(this.props.candidate.id, values.resume)
				.then(result => {
					this.resultNotification(
						result,
						'Self Assessment',
						null,
						'Resume',
						'Your resume was uploaded successfully.'
					);
					this.props.handleSuccess();
				});
		}
		candidateStoreAdmin
			.updateAboutAdmin(this.props.candidate.id, valuesToSave)
			.then(result => {
				this.setState({ saving: false });
				this.resultNotification(
					result,
					'Self Assessment',
					null,
					'Self Assessment',
					'Your self assessment info were saved successfully.'
				);
			});
	}
}
