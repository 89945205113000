import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { authStore } from '../shared/security/AuthStore';
import { Settings } from '../components/settings/Settings';
import { Countries } from '../components/settings/countries/Countries';
import { Skills } from '../components/settings/skills/Skills';
import { Tags } from '../components/settings/tags/Tags';
import { JobRoles } from '../components/settings/job-roles/JobRoles';
import { ExpertiseAreas } from '../components/settings/expertise-areas/ExpertiseAreas';
import { Recruiters } from '../components/settings/recruiters/Recruiters';
import { Currencies } from '../components/settings/currencies/Currencies';

const SettingsRoutes = ({ match }) => {
	return (
		<div>
			<Route exact fullScreenLayout={false}>
				{auth(<Settings />)}
			</Route>
			<Route fullScreenLayout={false} path={`${match.path}/countries`}>
				{auth(<Countries />)}
			</Route>
			<Route fullScreenLayout={false} path={`${match.path}/technologies`}>
				{auth(<Skills />)}
			</Route>
			<Route fullScreenLayout={false} path={`${match.path}/tags`}>
				{auth(<Tags />)}
			</Route>
			<Route fullScreenLayout={false} path={`${match.path}/job-roles`}>
				{auth(<JobRoles />)}
			</Route>
			<Route fullScreenLayout={false} path={`${match.path}/expertise-areas`}>
				{auth(<ExpertiseAreas />)}
			</Route>
			<Route fullScreenLayout={false} path={`${match.path}/recruiters`}>
				{auth(<Recruiters />)}
			</Route>
			<Route fullScreenLayout={false} path={`${match.path}/currencies`}>
				{auth(<Currencies />)}
			</Route>
		</div>
	);
};

function auth(component) {
	if (!authStore.isUserLogged()) {
		return <Redirect to="/login" />;
	} else {
		return component;
	}
}

export default withRouter(SettingsRoutes);
