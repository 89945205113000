import React from 'react';
import { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Header } from '../header/Header';
import { Footer } from '../../../components/layout/footer/Footer';
import { SideNavigation } from '../side-navigation/SideNavigation';
import { observer } from 'mobx-react';

@withRouter
@observer
export class CandidatePortalRoute extends React.Component {
	render() {
		return (
			<Fragment>
				{this.props.fullScreenLayout ? (
					<Fragment>
						<SideNavigation />
						<Header />
					</Fragment>
				) : null}
				<Route exact={this.props.exact ? true : false} path={this.props.path}>
					{this.props.children}
				</Route>
				{this.props.showFooter ? <Footer /> : null}
			</Fragment>
		);
	}
}
