import React, { useState } from 'react';
import { Icon } from 'components/layout/misc/Icon';
import { ScorecardCircle } from './ScorecardCircle';

export const ScorecardItem = ({ item: externalItem = {} }) => {
	const [item] = useState({ ...externalItem });

	return (
		<div className="ScorecardContent__Item">
			<div className="ScorecardContent__ItemContent is-vcentered has-text-left">
				<div className="ScorecardContent__ItemTitle level is-justify-content-flex-start">
					<span className="pl-4">{item.name}</span>
					{item.description ? (
						<span
							className="ScorecardContent__ItemDescription pl-3 tooltip has-tooltip-multiline is-tooltip-top"
							data-tooltip={item.description}>
							<Icon icon="fa-info-circle" />
						</span>
					) : null}
				</div>
			</div>
			<div className="horizontal-divider"></div>
			<div style={{ width: '95px' }} className="is-vcentered">
				<div className="level">
					<div className="StarWrapper">
						<ScorecardCircle
							key={item.id}
							width={15}
							height={15}
							fullCircleColor="#19E1D0"
							emptyCircleColor="#314EBD"
							rating={item.score}
							tooltipMessage={`${item.relativeScore.toFixed(2)} out of 5`}
							hasTooltip={false}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
