import React from 'react';
import { Icon } from '../layout/misc/Icon';
import { Switch } from '../layout/form-fields/inputs/Switch';
import moment from 'moment-timezone';
import { Button } from '../layout/button/Button';
import { JobCandidateAvatar } from './JobCandidateAvatar';

export class JobOpeningsTable extends React.Component {
	state = {
		currentTime: moment()
	};

	componentDidMount() {
		this.timerID = setInterval(() => this.tick(), 60000);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	tick() {
		this.setState({ currentTime: moment() });
	}

	render() {
		return (
			<table className="table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th
							className="is-sortable"
							style={{ width: '15%' }}
							onClick={() => this.handleHeaderClick('title')}>
							Job {this.isColumnSorted('title')}
						</th>
						<th
							className="is-sortable"
							style={{ width: '9%' }}
							onClick={() => this.handleHeaderClick('createdAt')}>
							Created {this.isColumnSorted('createdAt')}
						</th>
						<th
							className="is-sortable"
							style={{ width: '10%' }}
							onClick={() => this.handleHeaderClick('client')}>
							Client {this.isColumnSorted('client')}
						</th>
						<th style={{ width: '20%' }}>
							Candidates {this.isColumnSorted('candidates')}
						</th>
						<th style={{ width: '12%' }} />
					</tr>
				</thead>
				<tbody>
					{this.props.data.map(item => {
						return (
							<tr key={item.id} className={item.disabled ? 'disabled' : ''}>
								<td className="has-text-weight-bold">
									<a
										target="_blank"
										rel="noopener noreferrer"
										className="tag is-blue is-small button"
										href={item.atsLink}>
										<small>ATS</small>
									</a>
									&nbsp;
									{item.title}
								</td>
								<td className="has-text-weight-bold">
									<div className="has-text-grey-light subtitle is-6">
										{moment(item.createdAt).fromNow()}
									</div>
								</td>
								<td className="has-text-weight-bold">
									{item.client ? item.client.name : ''}
								</td>
								<td className="has-text-weight-bold">
									<div className="candidate-avatar-container">
										{item.candidates.map(association => {
											return (
												<JobCandidateAvatar
													key={association.candidate.id}
													job={item}
													candidate={association.candidate}
													onDeleteCandidate={this.props.onDeleteCandidate}
												/>
											);
										})}
									</div>
									<Button
										className="is-rounded is-circle is-small is-light is-pulled-right"
										icon="plus"
										onClick={() => this.props.onAddCandidate(item)}
									/>
								</td>
								<td className="actions-column">
									<div>
										<button
											className="button is-rounded is-circle is-small is-text"
											onClick={() => {
												if (this.props.onEdit) {
													this.props.onEdit(item);
												}
											}}>
											<Icon className="is-small" icon="fa-pencil-alt" />
										</button>
										<button
											className="button is-rounded is-circle is-small is-text"
											onClick={() => {
												if (this.props.onDelete) {
													this.props.onDelete(item);
												}
											}}>
											<Icon className="is-small" icon="fa-trash" />
										</button>
										<Switch
											id={item.id}
											value={!item.disabled}
											activeLabel="Enabled"
											inactiveLabel="Disabled"
											onValueChange={value => {
												if (this.props.onDisable) {
													this.props.onDisable(item);
												}
											}}
										/>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	isColumnSorted(column) {
		if (this.props.sort && this.props.sort.column === column) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}
}
