import { action, makeObservable } from 'mobx';
import { api } from '../../shared/api/api';
import { EntityStore } from '../../shared/stores/EntityStore';

class TimezoneStore extends EntityStore {
	constructor() {
		super('timezones');
		makeObservable(this);
	}

	@action
	async getTimezones(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}
}

export const timezoneStore = new TimezoneStore();
