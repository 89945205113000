import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/styles.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_USE_SENTRY === 'true') {
	const ENVIRONMENTS_MAPPING = {
		'dev.talent.prediktive.com': 'development',
		'staging.talent.prediktive.com': 'staging',
		'talent.prediktive.com': 'production'
	};

	const environment =
		ENVIRONMENTS_MAPPING[window.location.hostname] || 'development';

	Sentry.init({
		dsn: 'https://84a00a15fe254b9f38ced3fc01b4aa15@o877773.ingest.sentry.io/4505706761355264',
		environment,
		integrations: [new Sentry.Replay()],
		// Performance Monitoring
		tracesSampleRate: 0.2, // If set to 1.0, it will capture 100% of the transactions, reduce in production!
		// Session Replay
		replaysSessionSampleRate: 0.2, // If set to 0.1, it will set the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

configure({ enforceActions: 'always' });

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
