import { withRouter } from 'react-router-dom';
import React from 'react';
import { InputField } from '../../layout/form-fields/inputs/InputField';
import { currencyStore } from './CurrencyStore';
import { CurrencyForm } from './CurrencyForm';
import { ListStore } from '../../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { Loader } from '../../layout/misc/Loader';
import { Icon } from '../../layout/misc/Icon';
import {
	rolesAllowed,
	USER_ROLES
} from '../../../shared/security/RolesAllowed';
import moment from 'moment';

@rolesAllowed([USER_ROLES.ADMIN])
@withRouter
@observer
export class Currencies extends React.Component {
	state = {
		data: [],
		filterText: '',
		loading: true
	};

	constructor(props) {
		super(props);

		this.listStore = new ListStore(currencyStore, {
			entityName: 'Currency',
			createModal: (
				<CurrencyForm
					key="create"
					handleSuccess={() => {
						this.search();
						this.setState({ filterText: '' });
					}}
				/>
			),
			editModal: item => (
				<CurrencyForm
					key={item.id}
					data={item}
					handleSuccess={() => {
						this.search();
						this.setState({ filterText: '' });
					}}
				/>
			)
		});
	}

	componentDidMount() {
		this.search();
	}

	search(value = '') {
		this.setState({ loading: true });

		currencyStore.getCurrencies(value).then(result => {
			if (result.status === 'success' && result.data) {
				this.setState({ data: result.data, loading: false });
			}
		});
	}

	render() {
		return (
			<div className="Currencies container">
				<div className="columns">
					<div className="column is-10">
						<InputField
							className="has-text-left"
							field="title"
							type="search"
							icon="fa-search"
							placeholder="Filter"
							value={this.state.filterText}
							onValueChange={value => {
								this.search(value);
								this.setState({ filterText: value });
							}}
						/>
					</div>
					<div className="column is-2">
						<button
							className="button is-primary is-rounded is-big"
							onClick={() => this.listStore.handleCreate()}>
							{`Create ${this.listStore.modalsConfigs.entityName}`}
						</button>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<table className="table is-fullwidth is-bordered is-hoverable">
							<thead>
								<tr>
									<th className="column-header-name">Name</th>
									<th className="column-header-symbol">Symbol</th>
									<th className="column-header-code">Code</th>
									<th className="column-header-USDExchangeRate">
										USD Exchange Rate
									</th>
									<th className="column-header-createdAt">Created At</th>
									<th className="column-header-updatedAt">Updated At</th>
									<th style={{ width: '180px' }} />
								</tr>
							</thead>
							<tbody>
								{this.state.data.map(row => {
									return (
										<tr key={row.id}>
											<td className="has-text-weight-bold column-name">
												{row.name}
											</td>
											<td className="has-text-weight-bold column-symbol">
												{row.symbol}
											</td>
											<td className="has-text-weight-bold column-code">
												{row.code}
											</td>
											<td className="has-text-weight-bold column-USDExchangeRate">
												{row.USDExchangeRate}
											</td>
											<td
												className="has-text-weight-bold column-createdAt tooltip is-tooltip-top"
												data-tooltip={moment(row.createdAt).format('lll')}>
												{moment(row.createdAt).format('ll')}
											</td>
											<td
												className="has-text-weight-bold column-updatedAt tooltip is-tooltip-top"
												data-tooltip={moment(row.updatedAt).format('lll')}>
												{moment(row.updatedAt).format('ll')}
											</td>

											<td className="actions-column">
												<button
													className="button is-rounded is-circle is-small is-text"
													onClick={() => this.listStore.handleEdit(row)}>
													<Icon className="is-small" icon="fa-pencil-alt" />
												</button>
												<button
													className="button is-rounded is-circle is-small is-text"
													onClick={() =>
														this.listStore.handleDelete(
															row,
															this.onDeleteSuccess,
															true
														)
													}>
													<Icon className="is-small" icon="fa-trash" />
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						{this.state.loading ? <Loader type="ripple" /> : null}
					</div>
				</div>
			</div>
		);
	}

	onDeleteSuccess = item => {
		this.search();
		this.setState({ filterText: '' });
	};
}
