import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { notificationStore } from '../../../components/layout/notifications/NotificationStore';
import { Button } from '../../../components/layout/button/Button';
import { Dropdown } from '../../../components/layout/form-fields/inputs/Dropdown';
import { candidatesStore } from '../CandidatesStore';
import { CandidateProfileAboutExpertiseAreas } from './CandidateProfileAboutExpertiseAreas';
import { CandidateProfileAboutKnownSkills } from './CandidateProfileAboutKnownSkills';
import { CandidateProfileResumeEdit } from './CandidateProfileResumeEdit';
import { ConditionalDropdown } from '../../../components/layout/form-fields/inputs/ConditionalDropdown';
import { FieldRequiredIndicator } from '../../../components/layout/form-fields/inputs/FieldRequiredIndicator';
import { FormFieldError } from '../../../components/layout/form-fields/FormFieldError';
import DropdownField from '../../../components/layout/form-fields/DropdownField';

const SUPPORTED_FORMATS = ['application/pdf'];
const MAX_FILE_SIZE_LIMIT = process.env.REACT_APP_RESUME_MAX_FILE_SIZE;
const ValidationSchema = Yup.object().shape({
	englishLevel: Yup.string().required().label('English Level'),
	expertiseAreas: Yup.array()
		.min(1, 'Please select at least one Expertise Area')
		.label('Expertise Areas')
		.required()
		.test(
			'expertise-area-completed',
			'Please complete all fields for every Expertise Area',
			function (value) {
				if (!(value.length > 0)) {
					return false;
				}
				const valid = value.every(item => {
					return item.experienceYears && item.level;
				});
				return valid;
			}
		),
	knownSkills: Yup.array()
		.min(1, 'Please select at least one Technology')
		.label('Technologies')
		.required()
		.test(
			'known-skills-completed',
			'Please complete all fields for every Technology',
			function (value) {
				if (!(value.length > 0)) {
					return false;
				}
				const valid = value.every(item => {
					return item.experienceYears && item.level;
				});
				return valid;
			}
		),
	remoteExperienceYears: Yup.string()
		.required()
		.label('Remote Experience Years'),
	usExperienceYears: Yup.string().required().label('US Experience Years'),
	highestEducationLevel: Yup.string()
		.required()
		.label('Highest Education Level'),
	educationStage: Yup.string().required().label('Education Stage'),
	resume: Yup.mixed()
		.test('only-pdf-files', 'File type not supported. Use .pdf.', value => {
			if (!value) return true;
			return value && value.type && SUPPORTED_FORMATS.includes(value.type);
		})
		.test('file-size-limit', 'The file size is too large (max 1MB)', value => {
			if (!value) return true;
			return value && value.size <= MAX_FILE_SIZE_LIMIT;
		})
});

export class CandidateProfileAboutEdit extends React.Component {
	state = {
		saving: false
	};
	getValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	isInitialValid(props) {
		if (!props.validationSchema) return true;
		return props.validationSchema.isValidSync(props.initialValues);
	}
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				isInitialValid={this.isInitialValid}
				validationSchema={ValidationSchema}
				validateOnChange
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderForm(formikBag)}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<div className="about-edit maxWidth">
				<Fragment>
					<section className="hero">
						<div className="hero-body">
							<div className="container has-text-centered">
								<h1 className="title is-2 title-edit">Self Assessment</h1>
							</div>
						</div>
					</section>
					<section className="section">
						<div className="container">
							<div className="columns wizard-row">
								<div className="column is-fullwidth">
									<div className="field has-text-left">
										<label className="title is-5 has-text-weight-bold">
											How would you rate your{' '}
											<strong>English language level?</strong>{' '}
											<FieldRequiredIndicator />
										</label>
										<div className="english-level control is-fullwidth add-lg-margin-top">
											{candidatesStore.englishLevelOptions.map(
												(option, index) => {
													return (
														<label
															className="radio has-text-centered"
															key={index}>
															<input
																type="radio"
																name="answer"
																key={option.value}
																value={option.value}
																onChange={() => {
																	formikBag.setFieldValue(
																		'englishLevel',
																		option.value
																	);
																	formikBag.setFieldTouched(
																		'englishLevel',
																		true,
																		false
																	);
																}}
																defaultChecked={
																	option.value === formikBag.values.englishLevel
																		? true
																		: false
																}
															/>
															<br />
															<label>{option.text}</label>
														</label>
													);
												}
											)}
										</div>
										<br />
										<FormFieldError field="englishLevel" />
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-full">
									<CandidateProfileAboutExpertiseAreas
										formikBag={formikBag}
										styleVersion={'v2'}
										required
									/>
									<FormFieldError field="expertiseAreas" />
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-full">
									<CandidateProfileAboutKnownSkills
										formikBag={formikBag}
										value={formikBag.values.knownSkills}
										styleVersion={'v2'}
										required
									/>
									<FormFieldError field="knownSkills" />
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-fullwidth">
									<ConditionalDropdown
										value={formikBag.values.remoteExperienceYears}
										styleVersion={'v2'}
										entityName={'remoteExperienceYears'}
										extraInfoLabel={'Working remotely means 100% from home.'}
										noSelectDefaultValue={0}
										dropdownLabel={'How many years?'}
										label={
											<>
												Do you have experience{' '}
												<strong>working remotely?</strong>
											</>
										}
										switchLabel={'Yes I have experience working remotely'}
										handleChange={value => {
											formikBag.setFieldValue('remoteExperienceYears', value);
											formikBag.setFieldTouched(
												'remoteExperienceYears',
												true,
												false
											);
										}}
										options={candidatesStore.remoteExperienceYearsOptions}
										optionValue={'value'}
										required
									/>
									<FormFieldError field="remoteExperienceYears" />
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-fullwidth">
									<ConditionalDropdown
										value={formikBag.values.usExperienceYears}
										entityName={'usExperienceYears'}
										styleVersion={'v2'}
										dropdownLabel={'How many years?'}
										noSelectDefaultValue={0}
										label={
											<>
												Do you have experience working with teams{' '}
												<strong>in the United States</strong>?
											</>
										}
										switchLabel={
											'Yes I have worked with teams in the United States'
										}
										handleChange={value => {
											formikBag.setFieldValue('usExperienceYears', value);
											formikBag.setFieldTouched(
												'usExperienceYears',
												true,
												false
											);
										}}
										options={candidatesStore.usExperienceYearsOptions}
										optionValue={'value'}
										required
									/>
									<FormFieldError field="usExperienceYears" />
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-fullwidth">
									<div className=" has-text-left">
										<label className="title is-5 has-text-weight-bold">
											What is your <strong>highest level of education</strong>?{' '}
											<FieldRequiredIndicator />
										</label>
										<div className="columns">
											<div className="column is-7">
												<div className="control">
													<DropdownField
														component={Dropdown}
														name="highestEducationLevel"
														enableFixedMode={true}
														styleVersion="v2"
														placeholder="Education Level"
														options={
															candidatesStore.highestEducationLevelOptions
														}
														optionValue="value"
													/>
												</div>
											</div>
											<div className="column is-5">
												<div className=" has-text-left">
													<label className="title is-5 has-text-weight-bold"></label>
													<div className="control">
														<DropdownField
															component={Dropdown}
															name="educationStage"
															enableFixedMode={true}
															styleVersion="v2"
															placeholder="Stage of Progress"
															options={candidatesStore.educationStageOptions}
															optionValue="value"
														/>
													</div>
												</div>
											</div>
										</div>
										<FormFieldError field="highestEducationLevel" />
										<FormFieldError field="educationStage" />
									</div>
								</div>
							</div>
							<div className="columns wizard-row">
								<div className="column is-fullwidth">
									<CandidateProfileResumeEdit
										formikBag={formikBag}
										label={
											<>
												<strong>Resume</strong> (Optional, Max. 1MB)
											</>
										}
										styleVersion={'v2'}
									/>
									{!formikBag.errors.resume ? null : (
										<p className="help is-danger">{formikBag.errors.resume}</p>
									)}
									{this.props.candidate.resumeUrl && !this.props.isWizard ? (
										<a
											href={`${process.env.REACT_APP_API}${this.props.candidate.resumeUrl}`}
											download>
											{' '}
											Download resume{' '}
										</a>
									) : null}
								</div>
							</div>
						</div>
					</section>
					<footer className="footer">
						<div className="container">
							<div className="content has-text-centered">
								{this.props.isWizard ? (
									<Button
										type="button"
										className="button is-light is-large"
										onClick={() => this.props.handleBack()}
										label={'Back'}></Button>
								) : null}
								<Button
									onClick={() => {
										formikBag.submitForm();
									}}
									type="submit"
									className="button primary-v2 is-large"
									label={this.props.isWizard ? 'Continue' : 'Save'}
									loading={this.state.saving}
								/>
							</div>
						</div>
					</footer>
				</Fragment>
			</div>
		);
	}

	getInitialValues() {
		const expertiseAreas = this.props.candidate.areaAttributes.map(item => {
			return {
				...item,
				...item.expertiseArea
			};
		});
		const knownSkills = (this.props.candidate.knownSkills || []).map(item => {
			return { ...item, ...item.skill };
		});
		return {
			englishLevel: this.props.candidate.englishLevel,
			remoteExperienceYears: this.props.candidate.remoteExperienceYears,
			expertiseAreas: expertiseAreas,
			knownSkills: knownSkills,
			usExperienceYears: this.props.candidate.usExperienceYears,
			highestEducationLevel: this.props.candidate.highestEducationLevel,
			educationStage: this.props.candidate.educationStage,
			resume: this.props.candidate.resume
		};
	}

	saving(status) {
		if (status === false || status === true) {
			this.setState({ saving: status });
		} else {
			this.setState({ saving: true });
		}
	}

	async save(values) {
		const valuesToSave = {
			candidate: {
				...values
			},
			expertiseAreas: values.expertiseAreas,
			knownSkills: values.knownSkills
		};

		if (values.resume) {
			candidatesStore.uploadCandidateResume(values.resume).then(result => {
				notificationStore.pushNotification({
					type: notificationStore.SUCCESS_NOTIFICATION,
					title: 'Resume saved',
					message: 'Your resume was uploaded successfully.'
				});
				this.props.handleSuccess();
			});
		}
		candidatesStore.updateAbout(valuesToSave).then(result => {
			this.setState({ saving: false });
			if (result.status === 'success') {
				notificationStore.pushNotification({
					type: notificationStore.SUCCESS_NOTIFICATION,
					title: 'Self Assessment saved',
					message: 'Your self assessment was saved successfully.'
				});
				this.props.handleSuccess();
			} else {
				notificationStore.pushNotification({
					type: notificationStore.ERROR_NOTIFICATION,
					title: 'Self Assessment error',
					message: 'An error has occurred. Please try again.'
				});
			}
		});
	}
}
