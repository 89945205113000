import { skillStore } from 'components/settings/skills/SkillStore';
import React from 'react';
import Async from 'react-select/async';

export class SkillDropdown extends React.Component {
	state = {
		value: null,
		loaded: false
	};
	async componentDidMount() {
		if (this.props.filterByNames) {
			const skillsNames = this.props.filterByNames;
			const filteredSkills = await Promise.all(
				skillsNames.map(async skillName =>
					(await this.getSkills(skillName)).find(
						skill => skill.name === skillName
					)
				)
			);
			this.setState({
				value: filteredSkills
			});
			this.setState({ loaded: true });
			return;
		}

		if (this.props.value) {
			this.setState({
				value: this.props.value
			});
		}
		this.setState({ loaded: true });
	}

	render() {
		if (this.state.loaded) {
			const { placeholder = 'Technologies' } = this.props;

			return (
				<Async
					menuPosition={'fixed'}
					maxMenuHeight="300"
					className={
						'react-select ' +
						(this.props.styleVersion ? this.props.styleVersion : '') +
						(this.props.isInvalid ? ' has-error' : '')
					}
					cacheOptions
					isMulti
					value={this.props.value}
					placeholder={placeholder}
					defaultOptions
					loadOptions={this.loadOptions}
					getOptionValue={this.getOptionValue}
					getOptionLabel={this.getOptionLabel}
					noOptionsMessage={this.noOptionsMessage}
					onChange={item => {
						if (this.props.onValueChange) {
							this.props.onValueChange(item);
						}

						this.setState({ value: item });
					}}
				/>
			);
		} else {
			return null;
		}
	}

	loadOptions = inputValue => {
		return new Promise(resolve => {
			resolve(this.getSkills(inputValue));
		});
	};

	getSkills = async inputValue => {
		inputValue = inputValue.trim();
		const result = await skillStore.getSkills(inputValue);
		return result.data;
	};

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 1) {
			return 'No matches found';
		} else {
			return 'Enter text to search skills';
		}
	};

	getOptionValue = option => option.id;

	getOptionLabel = option => `${option.name}`;
}
