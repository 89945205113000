import React from 'react';
import clsx from 'clsx';
import { DynamicStar } from 'components/DynamicStar';

export const ReadOnlyStar = ({
	tooltipMessage,
	hasTooltip = true,
	tooltipDirection = 'top',
	...StarProps
}) => {
	const { rating } = StarProps;
	const roundedRating = rating.toFixed(1);

	return (
		<span
			className={clsx(`is-tooltip-${tooltipDirection}`, {
				tooltip: hasTooltip
			})}
			data-tooltip={tooltipMessage ?? `${roundedRating} out of 5`}>
			<DynamicStar {...StarProps} rating={Number(roundedRating)} />
		</span>
	);
};
