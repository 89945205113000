import React from 'react';
import { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Header } from '../header/Header';
import { SideNavigation } from '../side-navigation/SideNavigation';
import { PublicFooter } from '../footer/PublicFooter';
import { authStore } from '../../../shared/security/AuthStore';
import { Footer } from '../footer/Footer';

@withRouter
export class AdminRoute extends React.Component {
	render() {
		const loggedIn = authStore.isUserLogged();

		return (
			<Fragment>
				{!this.props.fullScreenLayout ? (
					<Fragment>
						<SideNavigation />
						<Header />
					</Fragment>
				) : null}
				<div className="Grid">
					<div
						className={`Content ${
							!this.props.fullScreenLayout ? 'is-user-logged' : ''
						}`}>
						<Route exact={!!this.props.exact} path={this.props.path}>
							{this.props.children}
						</Route>
					</div>
					{this.props.hiddenFooter ? null : loggedIn ? (
						<Footer
							fullScreenLayout={this.props.fullScreenLayout}
							floating={false}
						/>
					) : (
						<PublicFooter />
					)}
				</div>
			</Fragment>
		);
	}
}
