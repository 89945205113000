import {
	createEmptyStar,
	createFullStar,
	createStarWithPercentageFilled
} from './utils';

const reducer = (state, action) => {
	if (action.type === 'ADD_STAR') {
		if (action.payload === undefined) {
			return state;
		}

		return [...state, ...Array(action.payload).fill(createEmptyStar())];
	} else if (action.type === 'FILL_STAR') {
		if (action.payload === undefined) {
			return state;
		}

		const fullStarsCounter = Math.floor(action.payload);

		const surplus = Math.round((action.payload % 1) * 10) / 10;
		const roundedOneDecimalPoint = Math.round(surplus * 10) / 10;

		return state.map((_, index) => {
			if (fullStarsCounter >= index + 1) {
				return createFullStar();
			} else if (action.payload === index + roundedOneDecimalPoint) {
				return createStarWithPercentageFilled(roundedOneDecimalPoint);
			} else {
				return createEmptyStar();
			}
		});
	} else if (action.type === 'REMOVE_STAR') {
		return [...state.slice(0, action.payload)];
	} else {
		return state;
	}
};

export { reducer };
