import { useField } from 'formik';
import React from 'react';

function DropdownField({ component: DropdownComponent, name, ...props }) {
	const [, meta, helpers] = useField(name);

	const { setValue, setTouched } = helpers;

	return (
		<DropdownComponent
			{...props}
			value={meta.value}
			onValueChange={value => {
				if (props.onValueChange) {
					props.onValueChange(value);
				} else {
					setValue(value);
					setTouched(true, false);
				}
			}}
			isInvalid={meta.touched && meta.error}
		/>
	);
}

export default DropdownField;
