import React from 'react';
import { Icon } from '../layout/misc/Icon';
import { authStore } from '../../shared/security/AuthStore';
import moment from 'moment-timezone';
import { Switch } from '../layout/form-fields/inputs/Switch';
import { ProfileImage } from '../layout/misc/ProfileImage';

export class UsersTable extends React.Component {
	state = {
		currentTime: moment()
	};

	componentDidMount() {
		this.timerID = setInterval(() => this.tick(), 60000);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	tick() {
		this.setState({ currentTime: moment() });
	}

	getStatusText(user) {
		const status = user.status;
		if (status === 'Disabled') {
			return (
				<div>
					<span className="has-text-grey-light">Disabled</span>
					<div className="has-text-grey-light subtitle is-7">
						Since {moment(user.disabledAt).fromNow()}
					</div>
				</div>
			);
		} else if (status === 'Invited') {
			return (
				<div>
					<button
						className="button is-text"
						onClick={() => this.props.onInvite(user)}>
						Resend Invite
					</button>
					<div className="has-text-grey-light subtitle is-7">
						Invited {moment(user.invitedAt).fromNow()}
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<span>Active</span>
					<div className="has-text-grey-light subtitle is-7">
						Since {moment(user.firstAccessAt).fromNow()}
					</div>
				</div>
			);
		}
	}

	getLastAccessText(user) {
		if (user.email === authStore.email) {
			return <span className="has-text-primary">Active Now</span>;
		} else if (!user.lastAccessAt) {
			return <span className="has-text-danger">Never</span>;
		} else {
			return moment(user.lastAccessAt).fromNow();
		}
	}

	getNameDisplay(user) {
		return (
			<div className="thumb-table">
				<div className="thumb-label">
					<figure className="image is-24x24 is-inline-block">
						<ProfileImage className="is-rounded" src={user.imageUrl} />
					</figure>
					<div className="is-image-label">{user.name ? user.name : ''}</div>
				</div>
			</div>
		);
	}

	isColumnSorted(column) {
		if (this.props.sort && Array.isArray(this.props.sort)) {
			let match = this.props.sort.find(item => item.column === column);
			if (match && !match.hideIndicator) {
				const icon = match.asc ? 'fas fa-chevron-up' : 'fas fa-chevron-down';
				return <Icon icon={icon} />;
			}
		} else if (
			this.props.sort &&
			this.props.sort.column === column &&
			!this.props.sort.column.hideIndicator
		) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}

	render() {
		return (
			<table className="table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th
							className="is-sortable"
							style={{ width: '10%' }}
							onClick={() => this.handleHeaderClick('name')}>
							Name {this.isColumnSorted('name')}
						</th>
						<th
							className="is-sortable"
							style={{ width: '25%' }}
							onClick={() => this.handleHeaderClick('email')}>
							Email {this.isColumnSorted('email')}
						</th>
						<th style={{ width: '10%' }}>Status</th>
						<th
							className="is-sortable is-last-access"
							onClick={() => this.handleHeaderClick('lastAccessAt')}>
							Last Access {this.isColumnSorted('lastAccessAt')}
						</th>
						<th
							className="is-sortable"
							style={{ width: '10%' }}
							onClick={() => this.handleHeaderClick('role')}>
							Role {this.isColumnSorted('role')}
						</th>
						<th style={{ width: '170px' }} />
					</tr>
				</thead>
				<tbody>
					{this.props.data.map(item => {
						return (
							<tr key={item.id} className={item.disabled ? 'disabled' : ''}>
								<td className="has-text-weight-bold">
									{this.getNameDisplay(item)}
								</td>
								<td className="has-text-weight-medium">{item.email}</td>
								<td className="has-text-weight-medium status-column">
									{this.getStatusText(item)}
								</td>
								<td className="has-text-weight-medium">
									{this.getLastAccessText(item)}
								</td>
								<td className="has-text-weight-medium">{item.role}</td>
								<td className="actions-column">
									{item.email !== authStore.profile.email && (
										<button
											title="Delete User"
											className="button is-rounded is-circle is-small is-text"
											onClick={() => {
												if (
													this.props.onDelete &&
													item.email !== authStore.profile.email
												) {
													this.props.onDelete(item);
												}
											}}>
											<Icon className="is-small" icon="fa-trash" />
										</button>
									)}
									<button
										title="Edit User"
										className="button is-rounded is-circle is-small is-text"
										onClick={() => {
											if (this.props.onEdit) {
												this.props.onEdit(item);
											}
										}}>
										<Icon className="is-small" icon="fa-pencil-alt" />
									</button>
									<span
										className={
											item.email === authStore.profile.email
												? 'is-invisible'
												: ''
										}>
										<Switch
											id={item.id}
											key={Math.random()}
											value={!item.disabled}
											activeLabel="Enabled"
											inactiveLabel="Disabled"
											onValueChange={async value => {
												if (
													this.props.onDisable &&
													item.email !== authStore.profile.email
												) {
													return await this.props.onDisable(item);
												}
											}}
										/>
									</span>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
}
