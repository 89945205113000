import {
	createEmptyCircle,
	createFullCircle,
	createCircleWithPercentageFilled
} from './utils';

const reducer = (state, action) => {
	if (action.type === 'ADD_CIRCLE') {
		if (action.payload === undefined) {
			return state;
		}

		return [...state, ...Array(action.payload).fill(createEmptyCircle())];
	} else if (action.type === 'FILL_CIRCLE') {
		if (action.payload === undefined) {
			return state;
		}

		const fullCirclesCounter = Math.floor(action.payload);

		const surplus = Math.round((action.payload % 1) * 10) / 10;
		const roundedOneDecimalPoint = Math.round(surplus * 10) / 10;

		return state.map((_, index) => {
			if (fullCirclesCounter >= index + 1) {
				return createFullCircle();
			} else if (action.payload === index + roundedOneDecimalPoint) {
				return createCircleWithPercentageFilled(roundedOneDecimalPoint);
			} else {
				return createEmptyCircle();
			}
		});
	} else if (action.type === 'REMOVE_CIRCLE') {
		return [...state.slice(0, action.payload)];
	} else {
		return state;
	}
};

export { reducer };
