import React from 'react';
import { scorecardTemplateStore } from 'components/scorecard-templates/ScorecardTemplateStore';
import { observer } from 'mobx-react';
import { Loader } from 'components/layout/misc/Loader';
import { Icon } from 'components/layout/misc/Icon';
import { modalStore } from 'components/layout/modals/ModalStore';
import { ScorecardTemplateSectionForm } from 'components/scorecard-templates/components/ScorecardTemplateSectionForm';
import { ScorecardTemplateItemForm } from 'components/scorecard-templates/components/ScorecardTemplateItemForm';
import { dialogStore } from 'components/layout/modals/DialogStore';

@observer
export class SectionTable extends React.Component {
	state = {
		data: [],
		loading: true,
		open: false,
		error: false,
		dragging: false,
		draggingId: null,
		dragOver: null
	};

	constructor(props) {
		super(props);

		this.store = scorecardTemplateStore;
	}

	componentDidMount() {
		this.load({ triggerChange: true });
	}

	async load({ triggerChange }) {
		const { onChange, templateId, sectionId } = this.props;
		const { status, data } = await this.store.getScorecardSection(
			templateId,
			sectionId
		);

		// FIXME:For some reason even tough we are setting order
		// 	the dependency still comes unsorted
		data.scorecardTemplateItems = data.scorecardTemplateItems.sort((a, b) => {
			return a.ordinal > b.ordinal ? 1 : -1;
		});

		this.setState({
			data: data,
			error: status !== 'success',
			loading: false
		});

		if (triggerChange) {
			onChange && onChange(data);
		}
	}

	handleEdit() {
		const { data } = this.state;
		const { templateId, disabled } = this.props;

		modalStore.openModal(
			{
				title: 'Create Section',
				saveButtonLabel: 'Save'
			},
			<ScorecardTemplateSectionForm
				templateId={templateId}
				data={data}
				disabled={disabled}
				handleSuccess={result => {
					this.load({ triggerChange: true });
				}}
			/>
		);
	}

	handleRemove() {
		const { data } = this.state;
		const { templateId, onRemove } = this.props;

		dialogStore.openDialog(
			{
				title: 'Delete Section',
				message:
					'This section and every item within it will be deleted. Are you sure?',
				confirmLabel: 'Delete',
				confirmButtonClass: 'is-danger'
			},
			() => {
				scorecardTemplateStore.deleteSection(templateId, data.id).then(() => {
					onRemove && onRemove(data);
				});
			}
		);
	}

	handleRemoveItem(id) {
		const { data } = this.state;
		const { templateId } = this.props;

		dialogStore.openDialog(
			{
				title: 'Delete Item',
				message: 'This item will be deleted. Are you sure?',
				confirmLabel: 'Delete',
				confirmButtonClass: 'is-danger'
			},
			() => {
				scorecardTemplateStore.deleteItem(templateId, data.id, id).then(() => {
					this.load({ triggerChange: true });
				});
			}
		);
	}

	handleDragSection(dragEvent) {
		const { handleDrag } = this.props;
		const { data } = this.state;

		this.setState({ dragging: true });

		handleDrag && handleDrag(dragEvent, data.id);
	}

	handleDragEnterSection(dragEvent) {
		const { dragging } = this.state;

		if (dragging) {
			return;
		}

		dragEvent.currentTarget.classList.toggle('DragState--Over', true);
	}

	handleDragLeaveSection(dragEvent) {
		const goingOut = !dragEvent.currentTarget.contains(dragEvent.relatedTarget);

		if (goingOut) {
			dragEvent.currentTarget.classList.toggle('DragState--Over', false);
		}
	}

	handleDropSection(dragEvent) {
		const { handleDrop } = this.props;
		const { data } = this.state;

		dragEvent.currentTarget.classList.toggle('DragState--Over', false);
		this.setState({ dragging: false });

		handleDrop && handleDrop(dragEvent, data.id);
	}

	handleDragItem(dragEvent, id) {
		this.setState({
			draggingId: id
		});
	}

	handleDragEnterItem(dragEvent, id) {
		const { draggingId } = this.state;

		if (draggingId === id) {
			return;
		}

		dragEvent.currentTarget.classList.toggle('DragState--Over', true);
	}

	handleDragLeaveItem(dragEvent, id) {
		const goingOut =
			!dragEvent.relatedTarget?.contains(dragEvent.currentTarget) &&
			!dragEvent.currentTarget?.contains(dragEvent.relatedTarget);

		if (goingOut) {
			dragEvent.currentTarget.classList.toggle('DragState--Over', false);
		}
	}

	handleDropItem(dragEvent, id) {
		const { templateId } = this.props;
		const { data, draggingId } = this.state;

		dragEvent.currentTarget.classList.toggle('DragState--Over', false);

		scorecardTemplateStore
			.swapItems(templateId, data.id, draggingId, id)
			.then(() => {
				return this.setState({
					draggingId: null
				});
			})
			.then(() => {
				this.load({ triggerChange: true });
			});
	}

	handleToggleOpen() {
		const { open } = this.state;

		this.setState({ open: !open });
	}

	getTotalWeight() {
		const { data } = this.state;

		if (data && data.scorecardTemplateItems.length > 0) {
			return data.scorecardTemplateItems.reduce((total, item) => {
				return total + Number(item.weight);
			}, 0);
		}

		return 0;
	}

	getDragAndDropProps() {
		const { disabled, draggable } = this.props;

		if (!draggable || disabled) {
			return {};
		}

		return {
			draggable,
			onDragOver: dragEvent => dragEvent.preventDefault(),
			onDrop: dragEvent => this.handleDropSection(dragEvent),
			onDragStart: dragEvent => this.handleDragSection(dragEvent),
			onDragEnter: dragEvent => this.handleDragEnterSection(dragEvent),
			onDragLeave: dragEvent => this.handleDragLeaveSection(dragEvent)
		};
	}

	render() {
		const { data, loading, open, error } = this.state;
		const { disabled, templateId, draggable } = this.props;

		const hasItems =
			data &&
			data.scorecardTemplateItems &&
			data.scorecardTemplateItems.length > 0;

		if (loading) {
			return <Loader />;
		}

		if (error) {
			return 'An error has occurred.';
		}

		return (
			<div
				className="SectionTable DragItem columns is-gapless"
				{...this.getDragAndDropProps()}>
				<div className="column is-12">
					<table className="table is-fullwidth is-bordered is-hoverable">
						<thead>
							<tr>
								<th style={{ width: '5%' }} className="is-vcentered">
									<div className="level">
										<Icon
											className={`dragHandler ${!draggable ? 'disabled' : ''}`}
											icon="fa-grip-lines"
										/>
									</div>
								</th>
								<th
									style={{ width: '40%' }}
									className="is-vcentered is-uppercase">
									{data.name}
								</th>
								<th style={{ width: '50%' }} className="is-vcentered">
									{data.description}
								</th>
								<th
									style={{ width: '80px' }}
									className="is-vcentered has-text-centered">
									{this.getTotalWeight()}%
								</th>
								<th
									style={{ width: disabled ? '100px' : '290px' }}
									className="is-vcentered buttons-column">
									<div className="level gap-4">
										<div className="level-left" />
										<div className="level-right">
											{!disabled && (
												<button
													disabled={disabled}
													className="button is-primary level-item"
													onClick={event => {
														event.preventDefault();

														modalStore.openModal(
															{
																title: 'Create Item',
																saveButtonLabel: 'Save'
															},
															<ScorecardTemplateItemForm
																templateId={templateId}
																sectionId={data.id}
																handleSuccess={result => {
																	if (!open) {
																		this.setState({ open: true }, () => {
																			this.load({ triggerChange: true });
																		});
																	} else {
																		this.load({ triggerChange: true });
																	}
																}}
															/>
														);
													}}>
													Add Item
												</button>
											)}
											<button
												className="button is-rounded is-small level-item"
												onClick={() => {
													this.handleEdit();
												}}>
												Edit
											</button>
											{!disabled && (
												<button
													disabled={disabled}
													className="button is-danger is-rounded is-small level-item"
													onClick={() => this.handleRemove()}>
													Remove
												</button>
											)}
										</div>
									</div>
								</th>
								<th style={{ width: '60px' }} className="is-vcentered">
									{hasItems && (
										<button
											className="ToggleSectionButton button level-item"
											onClick={() => this.handleToggleOpen()}>
											<Icon
												className="is-big"
												icon={open ? 'fa-chevron-up' : 'fa-chevron-down'}
											/>
										</button>
									)}
								</th>
							</tr>
						</thead>
						<tbody
							className={`SectionTable SectionTable--${
								!draggable && open ? 'open' : 'closed'
							}`}>
							{data.scorecardTemplateItems.map(item => (
								<tr
									className="ScorecardTemplateItem DragItem"
									key={item.id}
									draggable={
										!disabled && data.scorecardTemplateItems.length > 1
									}
									onDragOver={dragEvent => dragEvent.preventDefault()}
									onDragEnter={dragEvent =>
										this.handleDragEnterItem(dragEvent, item.id)
									}
									onDragLeave={dragEvent =>
										this.handleDragLeaveItem(dragEvent, item.id)
									}
									onDrop={dragEvent => this.handleDropItem(dragEvent, item.id)}
									onDragStart={dragEvent =>
										this.handleDragItem(dragEvent, item.id)
									}>
									<td className="is-vcentered drag-button">
										<Icon
											className={`dragHandler ${
												disabled ? 'dragHandler--disabled' : ''
											}`}
											icon="fa-grip-lines"
										/>
									</td>
									<td className="is-vcentered">{item.name}</td>
									<td className="is-vcentered">{item.description}</td>
									<td className="is-vcentered has-text-centered">
										{item.weight}%
									</td>
									<td className="is-vcentered">
										<div className="level gap-4">
											<div className="level-left" />
											<div className="level-right">
												<button
													className="button is-rounded is-small level-item"
													onClick={() => {
														modalStore.openModal(
															{
																title: 'Edit Item',
																saveButtonLabel: 'Save'
															},
															<ScorecardTemplateItemForm
																templateId={templateId}
																sectionId={data.id}
																disabled={disabled}
																data={item}
																handleSuccess={result => {
																	this.load({ triggerChange: true });
																}}
															/>
														);
													}}>
													Edit
												</button>
												{!disabled && (
													<button
														disabled={disabled}
														className="button is-danger is-rounded is-small level-item"
														onClick={() => this.handleRemoveItem(item.id)}>
														Remove
													</button>
												)}
											</div>
										</div>
									</td>
									<td className="is-vcentered" />
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}
