import React from 'react';
import { notificationStore } from './NotificationStore';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

@withRouter
@observer
export class Notifications extends React.Component {
	render() {
		return (
			<div className="Notifications">
				<div className="notifications-wrapper">
					{notificationStore.notifications.map(notification => {
						return this.renderNotification(notification);
					})}
				</div>
			</div>
		);
	}

	renderNotification(notification) {
		return (
			<div
				className={'notification is-' + notification.type}
				ref={notification.key}
				key={notification.key}>
				<h1 className="title is-5">{notification.title}</h1>
				<button
					className="delete"
					onClick={() =>
						notificationStore.onCloseNotification(notification.key)
					}
				/>
				<p>{notification.message}</p>
			</div>
		);
	}
}
