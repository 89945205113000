import React from 'react';
import { Icon } from '../layout/misc/Icon';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { ProfileImage } from '../layout/misc/ProfileImage';
import { CandidateProfileTopSkills } from './profile/top-skills/CandidateProfileTopSkills';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { observer } from 'mobx-react';
import CandidatesTableActions from './shared/CandidatesTableActions';

@withRouter
@observer
export class CandidatesTable extends React.Component {
	state = {
		currentTime: moment()
	};

	componentDidMount() {
		this.timerID = setInterval(() => this.tick(), 60000);
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	tick() {
		this.setState({ currentTime: moment() });
	}

	shouldShowColumn(column) {
		return this.props.visibleColumns.some(
			visibleColumn => visibleColumn.value === column
		);
	}

	render() {
		return (
			<table className="table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th
							className="is-sortable name-column"
							onClick={() => this.handleHeaderClick('name')}>
							Name {this.isColumnSorted('name')}
						</th>
						{this.shouldShowColumn('country') && (
							<th className="date-column is-hidden-mobile">Country</th>
						)}
						{this.shouldShowColumn('last_modified') && (
							<th
								className="date-column is-sortable is-hidden-mobile"
								onClick={() => this.handleHeaderClick('updatedAt')}>
								Last Modified {this.isColumnSorted('updatedAt')}
							</th>
						)}
						{this.shouldShowColumn('top_technologies') && (
							<th className="techs-column">Top Technologies</th>
						)}
						{this.shouldShowColumn('tags') && (
							<th className="techs-column">Tags</th>
						)}
						{this.shouldShowColumn('profile_completion') && (
							<th
								className="is-sortable is-hidden-mobile is-score is-right-align"
								onClick={() => this.handleHeaderClick('profileScore')}>
								<span
									className="tooltip is-tooltip-top"
									data-tooltip={'Profile Completion Score'}>
									Profile Completion
								</span>
								{this.isColumnSorted('profileScore')}
							</th>
						)}
						{this.props.scorecardTemplates.map(scorecardTemplate => (
							<th className="is-hidden-mobile is-score is-right-align">
								<span
									className="tooltip is-tooltip-top"
									data-tooltip={scorecardTemplate.name}>
									{scorecardTemplate.name}
								</span>
							</th>
						))}
						<th style={{ width: '5%' }} />
					</tr>
				</thead>
				<tbody>
					{this.props.data.map(item => {
						return (
							<tr
								key={item.id}
								className={item.recordStatus === 'inactive' ? 'disabled' : ''}>
								<td className="has-text-weight-bold name-row">
									<div className="thumb-table">
										<div className="thumb-label">
											<figure className="image is-24x24 is-inline-block is-hidden-mobile">
												<span
													className="tooltip is-tooltip-right"
													data-tooltip={item.email}>
													<ProfileImage
														className="is-rounded"
														src={item.imageUrl ? `${item.imageUrl}` : null}
													/>
												</span>
											</figure>

											<div className="is-inline-block thumb-text">
												<a href={`/candidates/profile/${item.id}/edit`}>
													<span>{item.name ? item.name : ''}</span>
												</a>
											</div>
										</div>
									</div>
								</td>
								{this.shouldShowColumn('country') && (
									<td className="has-text-weight-bold is-hidden-mobile">
										<div className="thumb-label">
											<div className="subtitle is-7 thumb-text is-paddingless">
												<span
													className="tooltip is-tooltip-top"
													data-tooltip={item.country ? item.country.name : ''}>
													{item.country ? item.country.name : ''}
												</span>
											</div>
										</div>
									</td>
								)}
								{this.shouldShowColumn('last_modified') && (
									<td className="has-text-weight-bold is-hidden-mobile">
										<div className="thumb-label">
											<div className="subtitle is-7 thumb-text is-paddingless">
												<span
													className="tooltip is-tooltip-top"
													data-tooltip={moment(item.updatedAt).format('lll')}>
													{moment(item.updatedAt).format('ll')}
												</span>
											</div>
										</div>
									</td>
								)}
								{this.shouldShowColumn('top_technologies') && (
									<td className="has-text-weight-bold">
										<CandidateProfileTopSkills skills={item.topSkills} />
									</td>
								)}
								{this.shouldShowColumn('tags') && (
									<td className="has-text-weight-bold">
										{item.candidateTags.map(candidateTag => {
											return (
												<span
													key={candidateTag.tagId}
													className="tag is-blue add-margin-right">
													{candidateTag.tag.name}
												</span>
											);
										})}
									</td>
								)}
								{this.shouldShowColumn('profile_completion') && (
									<td className="has-text-weight-bold is-hidden-mobile score-row">
										<div
											className={`tag ${
												item.profileScore !== null &&
												(item.profileScore > 80
													? 'is-green'
													: item.profileScore > 40
														? 'is-yellow'
														: 'is-red')
											} subtitle is-7`}>
											{item.profileScore !== null
												? `${item.profileScore}%`
												: 'N/A'}
										</div>
									</td>
								)}
								{this.props.scorecardTemplates.map(scorecardTemplate => {
									const scorecard = item.scorecards.find(
										scorecard => scorecard.name === scorecardTemplate.name
									);

									const overallScore = Number(scorecard?.overallScore);
									return (
										<td className="has-text-weight-bold is-hidden-mobile score-row">
											<div
												className={`tag ${
													!!scorecard?.overallScore &&
													(overallScore >= 4
														? 'is-green'
														: overallScore >= 2.5
															? 'is-yellow'
															: 'is-red')
												} subtitle is-7`}>
												{!!scorecard?.overallScore
													? overallScore.toFixed(1)
													: 'N/A'}
											</div>
										</td>
									);
								})}
								<td className="actions-row">
									<div className="center-align is-flex-desktop">
										<CandidatesTableActions
											actions={[
												{
													label: 'Edit',
													title: 'Edit Profile',
													onClickHandler: () => {
														this.props.history.push(
															`/candidates/profile/${item.id}/edit`
														);
													},
													icon: 'pencil-alt'
												},
												{
													label: 'View Link',
													title: "View secret candidate's link",
													onClickHandler: async () => {
														if (this.props.onGenerateLinkToken) {
															await this.props.onGenerateLinkToken(item);
														}
													},
													icon: 'eye'
												},
												item.recordStatus === 'active'
													? {
															label: 'Deactivate',
															title: 'Deactivate Candidate',
															onClickHandler: () => {
																if (this.props.onDelete) {
																	this.props.onDelete(item);
																}
															},
															icon: 'user-times'
														}
													: {
															label: 'Activate',
															title: 'Activate Candidate',
															onClickHandler: () => {
																if (this.props.onActivate) {
																	this.props.onActivate(item);
																}
															},
															icon: 'user-check'
														}
											]}
										/>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	isColumnSorted(column) {
		if (this.props.sort && this.props.sort.column === column) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}

	/* eslint-disable quotes */
	async copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(text);
		} else {
			navigator.clipboard.writeText(text).then(
				() => {
					notificationStore.pushSuccessNotification(
						'Copy public profile link',
						`The candidate's public profile link was copied to your clipboard`
					);
				},
				error => {
					console.error(error);
					this.onCopyTextToClipboardError();
				}
			);
		}
	}

	fallbackCopyTextToClipboard(text) {
		var textArea = document.createElement('textarea');
		textArea.value = text;
		textArea.style.position = 'fixed'; //avoid scrolling to bottom
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			let result = document.execCommand('copy');
			if (result) {
				notificationStore.pushSuccessNotification(
					'Copy public profile link',
					`The candidate's public profile link was copied to your clipboard`
				);
			} else {
				this.onCopyTextToClipboardError();
			}
		} catch (err) {
			console.error(err);
			this.onCopyTextToClipboardError();
		}
		document.body.removeChild(textArea);
	}

	onCopyTextToClipboardError() {
		notificationStore.pushErrorNotification(
			'Copy public profile link',
			`The candidate's public profile link failed to copy on clipboard.`
		);
	}
}
