import { withRouter } from 'react-router-dom';
import React from 'react';
import { candidatesStore } from '../CandidatesStore';
import { CandidateProfileBio } from './CandidateProfileBio';
import { Loader } from '../../layout/misc/Loader';
import { CandidateProfileSections } from './CandidateProfileSections';
import { candidateProfileStore } from './CandidateProfileStore';
import { observer } from 'mobx-react';
import { notificationStore } from '../../layout/notifications/NotificationStore';

@withRouter
@observer
export class CandidateProfile extends React.Component {
	componentDidMount() {
		this.loadCandidate();
	}

	componentWillUnmount() {
		candidateProfileStore.reset();
	}

	async loadCandidate() {
		const candidateId = this.props.match.params.id;
		const result = await candidatesStore.get(candidateId);
		candidateProfileStore.setCandidate(result.data);
	}

	render() {
		if (candidateProfileStore.candidate) {
			return (
				<div className="AdminPortal">
					<div className="CandidateProfile">
						<CandidateProfileBio
							candidate={candidateProfileStore.candidate}
							isEdit={this.props.isEdit}
							onConfigChange={this.handleConfigChange}
							showOnlyOptimalWorkshift={true}
						/>
						{this.renderCandidateProfileInfo()}
					</div>
				</div>
			);
		} else {
			return <Loader type="page-loader" />;
		}
	}

	renderCandidateProfileInfo() {
		if (
			candidateProfileStore.candidate.candidateExperiences.length > 0 ||
			candidateProfileStore.candidate.candidateEducations.length > 0 ||
			candidateProfileStore.candidate.candidateCertifications.length > 0 ||
			candidateProfileStore.candidate.candidateTestResults.length > 0 ||
			this.props.isEdit
		) {
			return (
				<CandidateProfileSections
					onConfigChange={this.handleConfigChange}
					onExperienceConfigChange={this.handleExperienceConfigChange}
					onEducationConfigChange={this.handleEducationConfigChange}
					onCertificationConfigChange={this.handleCertificationConfigChange}
					onTestResultConfigChange={this.handleTestResultConfigChange}
					candidate={candidateProfileStore.candidate}
					isEdit={this.props.isEdit}
				/>
			);
		}
		return null;
	}

	handleConfigChange = async (property, newValue) => {
		const { id } = candidateProfileStore.candidate.candidateProfile;
		const request = {
			update: { config: property, newValue: newValue },
			candidateId: candidateProfileStore.candidate.id
		};
		const result = await candidateProfileStore.updateProfile(id, request);
		return this.handleSuccessFailure(result);
	};

	handlePublish = async status => {
		const { id } = candidateProfileStore.candidate.candidateProfile;
		const request = { status: status };
		const result = await candidateProfileStore.publishProfile(id, request);
		return this.handleSuccessFailure(result);
	};

	handleExperienceConfigChange = async (id, newValue) => {
		const request = {
			update: { newValue: newValue }
		};
		const result = await candidateProfileStore.updateExperience(id, request);
		return this.handleSuccessFailure(result);
	};

	handleEducationConfigChange = async (id, newValue) => {
		const request = {
			update: { newValue: newValue }
		};
		const result = await candidateProfileStore.updateEducation(id, request);
		return this.handleSuccessFailure(result);
	};

	handleCertificationConfigChange = async (id, newValue) => {
		const request = {
			update: { newValue: newValue }
		};
		const result = await candidateProfileStore.updateCertification(id, request);
		return this.handleSuccessFailure(result);
	};

	handleTestResultConfigChange = async (id, newValue) => {
		const request = {
			update: { newValue: newValue }
		};
		const result = await candidateProfileStore.updateTestResult(id, request);
		return this.handleSuccessFailure(result);
	};

	async handleSuccessFailure(result) {
		if (result.status === 'success') {
			notificationStore.pushNotification({
				type: notificationStore.SUCCESS_NOTIFICATION,
				title: 'Candidate Profile',
				message: 'The candidate profile has been saved.'
			});
			await candidateProfileStore.reloadCandidate();
			return true;
		} else {
			notificationStore.pushNotification({
				type: notificationStore.ERROR_NOTIFICATION,
				title: 'Candidate Profile',
				message:
					'An error occurred updating candidate profile. Please try again.'
			});
			await candidateProfileStore.reloadCandidate();
			return false;
		}
	}
}
