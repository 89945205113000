import { EntityStore } from '../../../shared/stores/EntityStore';
import { action, makeObservable, override } from 'mobx';
import { api } from '../../../shared/api/api';

class RecruiterStore extends EntityStore {
	constructor() {
		super('recruiters');
		makeObservable(this);
	}

	@action
	async getRecruiters({ query, status }) {
		const url = this.entityPluralEndpoint + '?q=' + query + '&st=' + status;
		const result = await api.get(encodeURI(url));

		return result;
	}

	@override
	async update(recruiterId, data) {
		const url = `${this.entityPluralEndpoint}/${recruiterId}`;

		const result = await api.put(encodeURI(url), { recruiter: data });

		return result;
	}
}

export const recruiterStore = new RecruiterStore();
