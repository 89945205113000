import React from 'react';
import { Icon } from '../Icon';
import ProgressBar from './ProgressBar';

export class CandidateProfileScore extends React.Component {
	render() {
		const { profileScore } = this.props.candidate;
		return (
			<div
				className={`bio-subsection-wrapper CandidateProfileScore ${
					profileScore !== null &&
					(profileScore > 80
						? 'is-green'
						: profileScore > 40
							? 'is-yellow'
							: 'is-red')
				}`}>
				<div className="title is-inline-block">
					<Icon icon="fa-solid fa-compact-disc" />
					Profile Completion Score
				</div>
				<ProgressBar percentage={profileScore} />
				<span>{profileScore}%</span>
			</div>
		);
	}
}
