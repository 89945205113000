import React from 'react';
import { candidatesStore } from '../CandidatesStore';
import Async from 'react-select/async';

export class CandidatesDropdown extends React.Component {
	state = {
		value: null
	};

	componentDidMount() {
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
	}

	render() {
		return (
			<Async
				field={this.props.field || 'candidates'}
				menuPosition={'fixed'}
				className="react-select"
				cacheOptions
				isMulti
				value={this.state.value}
				placeholder={'Candidates'}
				defaultOptions
				loadOptions={this.loadOptions}
				getOptionValue={option => option.id}
				getOptionLabel={option => `${option.name}, ${option.email}`}
				noOptionsMessage={this.noOptionsMessage}
				onChange={items => {
					this.setState({ value: items });
					if (this.props.onValueChange) {
						this.props.onValueChange(items);
					}
				}}
				onFocus={() => {
					if (this.props.onFocus) {
						this.props.onFocus();
					}
				}}
			/>
		);
	}

	loadOptions = inputValue => {
		return new Promise(resolve => {
			resolve(this.getCandidates(inputValue));
		});
	};

	getCandidates = async inputValue => {
		inputValue = inputValue.replace(/\W/g, '');
		if (inputValue && inputValue.length >= 1) {
			const result = await candidatesStore.getCandidates(inputValue);
			return result.data;
		} else {
			return [];
		}
	};

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 1) {
			return 'No matches found';
		} else {
			return 'Enter text to search candidates';
		}
	};
}
