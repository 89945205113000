import React, { Fragment } from 'react';
import { FormField } from '../FormField';
import { FieldRequiredIndicator } from './FieldRequiredIndicator';
import { RecruitersDropdown } from './RecruitersDropdown';

export class ReferrerInput extends React.Component {
	state = {
		showOtherInput: null,
		showRecruitersDropdown: null,
		value: null,
		currentRadioValue: null,
		currentInputValue: null
	};
	componentDidMount() {
		if (this.props.optionsToShowInput.includes(this.props.radioValue)) {
			this.setState({
				showOtherInput: true,
				currentRadioValue: this.props.radioValue
			});
			return;
		}
		if (this.props.optionRecruiterDropdown.includes(this.props.radioValue)) {
			this.setState({
				showRecruitersDropdown: true,
				currentRadioValue: this.props.radioValue
			});
			return;
		}
		this.setState({
			currentRadioValue: this.props.radioValue
		});
	}

	render() {
		return (
			<Fragment>
				<div className="radio-input-other">
					<div className="columns is-marginless">
						<div className="column is-fullwidth">
							<div className="field has-text-left">
								<label className="title is-5 has-text-weight-bold">
									{this.props.label}{' '}
									{this.props.required ? <FieldRequiredIndicator /> : null}
								</label>
								<div className="control is-fullwidth add-lg-margin-top options">
									{this.props.options.map(option => {
										return (
											<label
												className="radio add-margin-top"
												key={`label-${option.value}`}>
												<input
													type="radio"
													name="answer"
													key={option.value}
													value={option.value}
													onChange={event => {
														const selectedOption = event.target.value;
														if (
															this.props.optionsToShowInput.includes(
																selectedOption
															)
														) {
															this.props.handleOnChangeRadio(null);
															this.props.handleOnChangeInput('');
															this.setState({
																showOtherInput: true,
																showRecruitersDropdown: false,
																currentRadioValue: selectedOption
															});
															return;
														}
														if (
															this.props.optionRecruiterDropdown.includes(
																selectedOption
															)
														) {
															this.props.handleOnChangeRecruiterDropdown(null);
															this.props.handleOnChangeRadio(selectedOption);
															this.setState({
																showOtherInput: false,
																showRecruitersDropdown: true,
																currentRadioValue: selectedOption
															});
															return;
														}
														this.props.handleOnChangeRadio(selectedOption);
														this.setState({
															showOtherInput: false,
															showRecruitersDropdown: false,
															currentRadioValue: selectedOption
														});
													}}
													defaultChecked={
														this.props.radioValue === option.value
													}
												/>{' '}
												<label>{option.text}</label>
											</label>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					{this.state.showOtherInput ? (
						<div className="columns add-margin-top">
							<div className="column is-6">
								<div className="field has-text-left">
									<FormField
										className="has-text-left"
										styleVersion="v2"
										field={this.props.fieldInput}
										value={this.props.inputValue || ''}
										type="text"
										key={'radioWithInput'}
										placeholder={this.props.inputPlaceholder}
										onChange={event => {
											this.props.handleOnChangeRadio(
												this.state.currentRadioValue
											);
											this.props.handleOnChangeInput(event.target.value);
										}}
									/>
								</div>
							</div>
						</div>
					) : null}
					{this.state.showRecruitersDropdown ? (
						<div className="columns add-margin-top">
							<div className="column is-6">
								<div className="field has-text-left">
									<RecruitersDropdown
										styleVersion="v2"
										placeholder={this.props.inputPlaceholder}
										onValueChange={item => {
											this.props.handleOnChangeRadio(
												this.state.currentRadioValue
											);
											this.props.handleOnChangeRecruiterDropdown(item.id);
										}}
									/>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</Fragment>
		);
	}
}
