import { ProfileImage } from '../../../layout/misc/ProfileImage';
import { Icon } from '../../../layout/misc/Icon';
import React from 'react';
import { modalStore } from '../../../layout/modals/ModalStore';
import { CandidateProfileImageEdit } from './CandidateProfileImageEdit';
import { candidateProfileStore } from '../CandidateProfileStore';

export class CandidateProfileImage extends React.Component {
	render() {
		return (
			<div
				className={
					'profile-pic-wrapper' +
					(this.props.isEdit ? ' has-pointer is-edit' : '')
				}
				onClick={() => {
					if (this.props.isEdit) {
						modalStore.openModal(
							{ title: 'Upload Profile Picture', position: 'center' },
							<CandidateProfileImageEdit
								candidate={this.props.candidate}
								handleSuccess={() => {
									candidateProfileStore.reloadCandidate();
								}}
							/>
						);
					}
				}}>
				<ProfileImage
					className="profile-picture"
					src={
						this.props.candidate.imageUrl ? this.props.candidate.imageUrl : null
					}
				/>
				{!this.props.isEdit ? null : <Icon icon="fa-pencil-alt" />}
			</div>
		);
	}
}
