import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { FormField, FormFieldTypes } from '../../layout/form-fields/FormField';
import { currencyStore } from './CurrencyStore';

const defaultValues = {
	name: '',
	symbol: '',
	code: '',
	USDExchangeRate: 1.0,
	autoRefresh: false
};

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	symbol: Yup.string().min(1).max(10).required().label('Symbol'),
	code: Yup.string().min(1).max(10).required().label('Code'),
	USDExchangeRate: Yup.number().positive().required().label('Exchange Rate'),
	autoRefresh: Yup.boolean().label('Auto Refresh')
});

export class CurrencyForm extends ModalComponent {
	get isEdit() {
		return this.props.data !== undefined;
	}

	render() {
		return (
			<Formik
				initialValues={{
					...defaultValues,
					...this.props.data
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'CurrencyForm')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<div className="columns">
				<div className="column is-full">
					<FormField
						key="name"
						required
						className="has-text-left"
						label="Name"
						field="name"
						type={FormFieldTypes.TEXT}
						placeholder=""
					/>
					<FormField
						key="symbol"
						required
						className="has-text-left"
						label="Symbol"
						field="symbol"
						type={FormFieldTypes.TEXT}
						placeholder=""
					/>
					<FormField
						key="code"
						required
						className="has-text-left"
						label="Code"
						field="code"
						type={FormFieldTypes.TEXT}
						placeholder=""
					/>
					<FormField
						key="USDExchangeRate"
						required
						className="has-text-left"
						label="USD Exchange Rate"
						field="USDExchangeRate"
						type={FormFieldTypes.NUMBER}
						placeholder=""
					/>
					<div className="columns">
						<div className="column field is-fullwidth has-text-left">
							<div className="control">
								<label className="checkbox subtitle is-7">
									<input
										type="checkbox"
										defaultChecked={formikBag.values.autoRefresh}
										onClick={event => {
											formikBag.setFieldValue(
												'autoRefresh',
												event.target.checked
											);
										}}
									/>
									Auto Refresh
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	async save(values) {
		if (this.isEdit) {
			return currencyStore
				.update(this.props.data.id, {
					currency: values
				})
				.then(result => {
					this.resultNotification(result, 'Currency', 'Update');

					return result;
				});
		} else {
			return currencyStore.create(values).then(result => {
				this.resultNotification(result, 'Currency', 'Create');

				return result;
			});
		}
	}
}
