import React from 'react';
import { authStore } from '../../../../shared/security/AuthStore';
import { NavLink, withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import { ProfileImage } from '../../misc/ProfileImage';

@withRouter
@onClickOutside
export class UserMenu extends React.Component {
	state = {
		openMenu: false
	};

	handleClickOutside() {
		this.handleOnUserMenuClick(true);
	}

	render() {
		return this.renderMenu();
	}

	handleOnLogoutClick() {
		authStore.logout();
		this.props.history.push('/login');
	}

	handleOnUserMenuClick(close = false) {
		if (close) {
			this.setState({
				openMenu: false
			});
		} else {
			this.setState({
				openMenu: !this.state.openMenu
			});
		}
	}

	renderMenu() {
		return (
			<div className="UserMenu">
				<div
					className={
						'dropdown is-icon-dropdown ' +
						(this.state.openMenu ? 'is-active' : '')
					}>
					<div
						className="dropdown-trigger"
						onClick={() => this.handleOnUserMenuClick()}>
						<span className="caret icon is-small">
							<i className="fas fa-angle-down" />
						</span>
						<figure
							className="avatar-image image is-48x48"
							title={`${authStore.fullName}, ${authStore.email}`}>
							<ProfileImage
								className="is-rounded"
								src={authStore.profileImage}
							/>
						</figure>
					</div>
					<div className="dropdown-menu" role="menu">
						<div className="dropdown-content">
							<NavLink
								className="dropdown-item"
								to={''}
								onClick={() => this.handleOnLogoutClick()}>
								Logout
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
