import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { FormField } from '../../layout/form-fields/FormField';
import { recruiterStore } from './RecruiterStore';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name')
});

export class RecruiterEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					name: this.props.recruiter.name,
					email: this.props.recruiter.email,
					disabled: this.props.recruiter.disabled
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'RecruiterEdit')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<div className="columns is-multiline">
				<div className="column is-full">
					<FormField
						required
						className="has-text-left"
						label="Name"
						field="name"
						type="text"
						placeholder="Name"
					/>
				</div>
				<div className="column is-full">
					<FormField
						required
						className="has-text-left"
						label="Email"
						field="email"
						type="text"
						placeholder="Email"
					/>
				</div>
				<div className="column is-full has-text-left">
					<div className="control">
						<label className="checkbox subtitle is-7">
							<input
								type="checkbox"
								checked={formikBag.values.disabled}
								onChange={clickEvent => {
									formikBag.setFieldValue(
										'disabled',
										clickEvent.target.checked
									);
								}}
							/>{' '}
							Inactive
						</label>
					</div>
				</div>
			</div>
		);
	}

	async save(values) {
		recruiterStore.update(this.props.recruiter.id, values).then(result => {
			this.resultNotification(result, 'Recruiter', 'Edit');
		});
	}
}
