import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../../../layout/form-fields/FormField';
import { Dropdown } from '../../../layout/form-fields/inputs/Dropdown';
import { candidatesStore } from '../../CandidatesStore';
import { YearDropdown } from '../../../layout/form-fields/inputs/YearDropdown';
import { FieldRequiredIndicator } from '../../../layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({
	degree: Yup.string().required().label('Title'),
	institution: Yup.string().required().label('Institution')
});

export class CandidateProfileEducationEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				validate={values => {
					let errors = {};
					if (values.status === 'graduated' && !values.graduationYear) {
						errors.graduationYear = 'Completion Year is a required field';
						return errors;
					}
				}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileEducationEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Career/Degree"
							field="degree"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Institution"
							field="institution"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Location"
							field="location"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<label className="subtitle is-7">Status</label>
							<div className="control">
								<Dropdown
									enableFixedMode={true}
									value={formikBag.values.status}
									onValueChange={value =>
										formikBag.setFieldValue('status', value)
									}
									options={candidatesStore.educationStatusOptions}
									optionValue={'value'}
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`columns ${
						formikBag.values.status !== 'graduated' ? 'is-hidden' : ''
					}`}>
					<div className="column is-8">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Completion Year <FieldRequiredIndicator />
							</label>
							<div className="control">
								<YearDropdown
									field="graduationYear"
									value={formikBag.values.graduationYear}
									placeholder="Completion Year"
									name="graduationYear"
									onValueChange={item => {
										formikBag.setFieldValue(
											'graduationYear',
											item ? item.value : ''
										);
									}}
								/>
								<p className="help is-danger">
									{formikBag.errors.graduationYear}
								</p>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		if (this.props.isNew) {
			return {
				degree: '',
				institution: '',
				location: '',
				status: 'attending',
				graduationYear: ''
			};
		} else {
			return {
				degree: this.props.education.degree,
				institution: this.props.education.institution,
				location: this.props.education.location || '',
				status: this.props.education.status,
				graduationYear: this.props.education.graduationYear
			};
		}
	}

	async save(values) {
		if (!values.graduationYear) {
			values.graduationYear = null;
		}
		if (this.props.isNew) {
			candidatesStore
				.createEducation(this.props.candidate.id, {
					education: values
				})
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Education',
						'The education entry has been saved successfully.'
					);
				});
		} else {
			candidatesStore
				.updateEducation(this.props.education.id, {
					education: values
				})
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Education',
						'The education entry has been saved successfully.'
					);
				});
		}
	}
}
