import { action, observable, makeObservable } from 'mobx';

export class NotificationStore {
	SUCCESS_NOTIFICATION = 'primary';
	INFO_NOTIFICATION = 'info';
	WARNING_NOTIFICATION = 'warning';
	ERROR_NOTIFICATION = 'danger';
	AUTO_CLOSE_TIME = 5000;

	@observable notifications = [];

	constructor() {
		makeObservable(this);
	}

	@action
	pushNotification(notification) {
		const key = `n${Math.random()}`;
		notification.key = key;
		this.notifications.push(notification);
		setTimeout(() => {
			this.onCloseNotification(key);
		}, this.AUTO_CLOSE_TIME);
	}

	@action
	pushSuccessNotification(title, message) {
		const notification = this.createNotification(
			this.SUCCESS_NOTIFICATION,
			title,
			message
		);
		this.pushNotification(notification);
	}

	@action
	pushErrorNotification(title, message) {
		const notification = this.createNotification(
			this.ERROR_NOTIFICATION,
			title,
			message
		);
		this.pushNotification(notification);
	}

	@action
	onCloseNotification(key) {
		this.notifications = this.notifications.filter(
			notification => notification.key !== key
		);
	}

	createNotification(type, title, message) {
		return {
			type: type,
			title: title,
			message: message
		};
	}
}

const notificationStore = new NotificationStore();
export { notificationStore };
