import { tagStore } from 'components/settings/tags/TagStore';
import React from 'react';
import Async from 'react-select/async';

export class TagsDropdown extends React.Component {
	MAX_TAGS_ALLOWED = 5;
	state = {
		value: null,
		loaded: false
	};
	async componentDidMount() {
		if (this.props.filterByNames) {
			const tagsNames = this.props.filterByNames;
			const filteredTags = await Promise.all(
				tagsNames.map(async tagName =>
					(await this.getTags(tagName)).find(tag => tag.name === tagName)
				)
			);
			this.setState({
				value: filteredTags
			});
			this.setState({ loaded: true });
			return;
		}
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
		this.setState({ loaded: true });
	}

	render() {
		if (this.state.loaded) {
			return (
				<Async
					menuPosition={'fixed'}
					maxMenuHeight="300"
					className={
						'react-select ' +
						(this.props.styleVersion ? this.props.styleVersion : '')
					}
					cacheOptions
					isMulti
					value={this.props.value}
					placeholder={this.props.placeholder || 'Tags'}
					defaultOptions
					loadOptions={this.loadOptions}
					getOptionValue={this.getOptionValue}
					getOptionLabel={this.getOptionLabel}
					noOptionsMessage={this.noOptionsMessage}
					onChange={item => {
						if (item && item.length > this.MAX_TAGS_ALLOWED) {
							return;
						}
						if (this.props.onValueChange) {
							this.props.onValueChange(item);
						}

						this.setState({ value: item });
					}}
				/>
			);
		} else {
			return null;
		}
	}

	loadOptions = inputValue => {
		return new Promise(resolve => {
			resolve(this.getTags(inputValue));
		});
	};

	getTags = async inputValue => {
		inputValue = inputValue.replace(/\W/g, '');
		const result = await tagStore.getTags(inputValue);
		return result.data;
	};

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 1) {
			return 'No matches found';
		} else {
			return 'Enter text to search tags';
		}
	};

	getOptionValue = option => option.id;

	getOptionLabel = option => `${option.name}`;
}
