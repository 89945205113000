import React from 'react';
import { Icon } from '../layout/misc/Icon';
import { Switch } from '../layout/form-fields/inputs/Switch';
import { modalStore } from '../layout/modals/ModalStore';
import { JobOpeningCreate } from '../job-openings/JobOpeningCreate';
import { withRouter } from 'react-router-dom';
import { authStore } from '../../shared/security/AuthStore';

@withRouter
export class ClientsTable extends React.Component {
	render() {
		return (
			<table className="table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th
							className="is-sortable"
							style={{ width: '15%' }}
							onClick={() => this.handleHeaderClick('name')}>
							Name {this.isColumnSorted('name')}
						</th>
						<th
							className="is-sortable"
							style={{ width: '22%' }}
							onClick={() => this.handleHeaderClick('email')}>
							Email {this.isColumnSorted('email')}
						</th>
						<th style={{ width: '31%' }}>Jobs {this.isColumnSorted('jobs')}</th>
						<th style={{ width: '14%' }} />
					</tr>
				</thead>
				<tbody>
					{this.props.data.map(item => {
						return (
							<tr key={item.id} className={item.disabled ? 'disabled' : ''}>
								<td className="has-text-weight-bold">{item.name}</td>
								<td className="has-text-weight-bold">{item.email}</td>
								<td className="has-text-weight-bold">
									{item.jobs.map(job => {
										return (
											<button
												key={job.id}
												className="button is-text is-blue"
												onClick={() => {
													this.props.onJobRedirect(item);
												}}>
												<small>{job.title}</small>
											</button>
										);
									})}
									{item.jobs.length === 0 ? (
										<span className="has-text-grey-light subtitle is-6 no-job-legend">
											<small>Add job openings</small>
										</span>
									) : null}
									{(authStore.isAdmin() || authStore.isManager()) && (
										<button
											className="button is-rounded is-circle is-small is-light is-pulled-right"
											onClick={() => this.handleAddJob(item)}>
											<Icon className="is-small" icon="fa-plus" />
										</button>
									)}
								</td>
								<td className="actions-column">
									<div>
										<button
											className="button is-rounded is-circle is-small is-text"
											onClick={() => {
												if (this.props.onEdit) {
													this.props.onEdit(item);
												}
											}}>
											<Icon className="is-small" icon="fa-pencil-alt" />
										</button>
										<button
											className="button is-rounded is-circle is-small is-text"
											onClick={() => {
												if (this.props.onDelete) {
													this.props.onDelete(item);
												}
											}}>
											<Icon className="is-small" icon="fa-trash" />
										</button>
										<Switch
											id={item.id}
											value={!item.disabled}
											activeLabel="Enabled"
											inactiveLabel="Disabled"
											onValueChange={value => {
												if (this.props.onDisable) {
													this.props.onDisable(item);
												}
											}}
										/>
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	isColumnSorted(column) {
		if (this.props.sort && this.props.sort.column === column) {
			const icon = this.props.sort.asc ? 'fa-chevron-up' : 'fa-chevron-down';
			return <Icon icon={icon} />;
		} else {
			return null;
		}
	}

	handleHeaderClick(column) {
		if (this.props.onSort) {
			this.props.onSort(column);
		}
	}

	handleAddJob(client) {
		modalStore.openModal(
			{
				title: 'Create Job Opening'
			},
			<JobOpeningCreate
				job={{ clientId: client.id, client: client }}
				handleSuccess={() => {
					if (this.props.onSearch) {
						this.props.onSearch();
					}
				}}
			/>
		);
	}
}
