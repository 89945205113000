import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../../../components/layout/form-fields/FormField';
import { candidatesStore } from '../CandidatesStore';
import CandidateProfileImageCrop from './CandidateProfileImageCrop';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];
const MAX_FILE_SIZE_LIMIT = 1000 * 1024;

const ValidationSchema = Yup.object().shape({
	image: Yup.mixed()
		.required()
		.test(
			'only-image-files',
			'File type not supported. Use .png, .jpg, .jpeg or .gif.',
			value => value && SUPPORTED_FORMATS.includes(value.type)
		)
		.test(
			'file-size-limit',
			'The image file size is too large (max 1MB)',
			value => value && value.size <= MAX_FILE_SIZE_LIMIT
		),

	croppedImage: Yup.mixed()
});

export class CandidateProfileImageEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					image: undefined,
					croppedImage: undefined
				}}
				validationSchema={ValidationSchema}
				validateOnChange
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileImageEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="Image"
							field="image"
							type="file"
							formikBag={{
								...formikBag,
								setFieldValue: (_, value) => {
									formikBag.setFieldTouched('image', true, false);
									formikBag.setFieldValue('image', value, true);
									formikBag.setFieldValue('croppedImage', null, false);
								}
							}}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<CandidateProfileImageCrop
							image={formikBag.values.croppedImage || formikBag.values.image}
							onCropImage={croppedImage => {
								formikBag.setFieldValue('croppedImage', croppedImage);
							}}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full add-md-padding-top">
						<p className="subtitle is-6 has-text-left">
							Please upload a .png, .jpg, .jpeg or .gif image file up to 1MB. It
							should ideally be a square image with the candidate's face in the
							center.
						</p>
					</div>
				</div>
			</Fragment>
		);
	}

	async save(values) {
		candidatesStore
			.uploadCandidateImage(values.croppedImage || values.image)
			.then(result => {
				this.resultNotification(result, 'Candidate Profile Image', 'Edit');
			});
	}
}
