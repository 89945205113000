import React from 'react';
import localStorage from 'local-storage';
import { Loader } from '../../layout/misc/Loader';
import onClickOutside from 'react-onclickoutside';

@onClickOutside
export class CandidatesTableLimitSelect extends React.Component {
	state = {
		openDropdown: false
	};

	handleClickOutside() {
		this.handleOnDropdownClick(true);
	}

	handleOnDropdownClick = (close = false) => {
		this.setState({ openDropdown: close ? false : !this.state.openDropdown });
	};

	render() {
		if (!this.props.pagination) {
			return null;
		} else if (this.props.loading) {
			return <Loader type="ripple" className="search-loading is-hidden" />;
		} else if (
			this.props.pagination &&
			(this.props.pagination.totalResults === null ||
				this.props.pagination.totalResults === 0)
		) {
			return (
				<div className="has-text-centered subtitle is-6 pagination-no-results is-hidden">
					No results
				</div>
			);
		}
		let currentPage = 1;
		let showOptions = [10, 25, 50, 100];
		if (this.props.pagination.totalResults > this.props.pagination.limit) {
			currentPage = this.props.pagination.page;
		}

		return (
			<div className="Pagination pf-pagination">
				<ul className="pf-pagination-list">
					<div className="pf-size-controls">
						<div
							class={`dropdown is-right ${
								this.state.openDropdown ? 'is-active' : ''
							}`}>
							<div
								class="dropdown-trigger"
								onClick={() => this.handleOnDropdownClick()}>
								<button
									class="button"
									aria-haspopup="true"
									aria-controls="dropdown-menu">
									Show: {this.props.pagination.limit}
									<span class="icon is-small">
										<i class="fas fa-angle-down" aria-hidden="true"></i>
									</span>
								</button>
							</div>
							<div class="dropdown-menu" id="dropdown-menu" role="menu">
								<div class="dropdown-content">
									{showOptions.map((option, index) => {
										return (
											<div
												className={`dropdown-item ${
													this.props.pagination.limit === option
														? 'is-active'
														: ''
												}`}
												key={index}
												onClick={() => {
													this.handlePaginationChange(currentPage, option);
													this.handleOnDropdownClick(true);
												}}>
												{option}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</ul>
			</div>
		);
	}

	handlePaginationChange(page, limit) {
		if (this.props.pagination.totalResults <= limit) {
			page = 1;
		}
		if (this.props.onPaginationChange) {
			localStorage.set('paginationLimit', limit);
			this.props.onPaginationChange(page, limit);
		}
	}
}
