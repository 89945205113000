import React from 'react';
import Async from 'react-select/async';
import { expertiseAreaStore } from '../../../settings/expertise-areas/ExpertiseAreaStore';

export class ExpertiseAreaDropdown extends React.Component {
	state = {
		value: null,
		loaded: false
	};
	componentDidMount() {
		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
		this.setState({ loaded: true });
	}

	render() {
		if (this.state.loaded) {
			return (
				<Async
					menuPosition={'fixed'}
					maxMenuHeight="300"
					className={
						'react-select ' +
						(this.props.styleVersion ? this.props.styleVersion : '') +
						(this.props.isInvalid ? ' has-error' : '')
					}
					cacheOptions
					isMulti
					value={this.state.value}
					placeholder="You can choose more than one option"
					defaultOptions
					loadOptions={this.loadOptions}
					getOptionValue={this.getOptionValue}
					getOptionLabel={this.getOptionLabel}
					noOptionsMessage={this.noOptionsMessage}
					onChange={item => {
						if (this.props.onValueChange) {
							this.props.onValueChange(item);
						}
						this.setState({ value: item });
					}}
				/>
			);
		} else {
			return null;
		}
	}

	loadOptions = inputValue => {
		return new Promise(resolve => {
			resolve(this.getExpertiseAreas(inputValue));
		});
	};

	getExpertiseAreas = async inputValue => {
		inputValue = inputValue.replace(/\W/g, '');
		const result = await expertiseAreaStore.getExpertiseAreas(inputValue);
		return result.data;
	};

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 1) {
			return 'No matches found';
		} else {
			return 'Enter text to search an experise area';
		}
	};

	getOptionValue = option => option.id;

	getOptionLabel = option => `${option.name}`;
}
