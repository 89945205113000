import React, { Fragment } from 'react';
import { Button } from '../../../layout/button/Button';
import { dialogStore } from '../../../layout/modals/DialogStore';
import { modalStore } from '../../../layout/modals/ModalStore';
import { CandidateProfileCertificationEdit } from './CandidateProfileCertificationEdit';
import { candidateProfileStore } from '../CandidateProfileStore';
import { candidatesStore } from '../../CandidatesStore';
import { notificationStore } from '../../../layout/notifications/NotificationStore';
import { Switch } from '../../../layout/form-fields/inputs/Switch';

/* eslint-disable indent */
export class CandidateProfileCertifications extends React.Component {
	getDisplayEducationStatus(value) {
		const educationStatus = candidatesStore.educationStatusOptions.find(
			status => status.value === value
		);
		return educationStatus.text;
	}

	renderCertificationStatus(certification) {
		return (
			<div className="subtitle is-7 status">
				<span className="status-text">
					{this.getDisplayEducationStatus(certification.status)}
				</span>
				{certification.status === 'graduated' &&
				certification.graduationYear ? (
					<span>Completion Year: {certification.graduationYear}</span>
				) : null}
			</div>
		);
	}

	render() {
		return (
			<div className="content-container">
				{this.props.certifications.map((certification, index) => {
					return (
						<Fragment key={certification.id}>
							<div className="description-content">
								<div
									className={`description-section education ${
										!certification.visible ? 'is-disabled' : ''
									}`}>
									<div className="is-pulled-right button-group">
										<Button
											className="is-rounded is-small edit-button"
											icon="trash"
											iconClass="has-text-danger"
											onClick={event => {
												dialogStore.openDialog(
													{
														title: 'Delete Certification',
														message:
															'This certification entry will be deleted. Are you sure?',
														confirmLabel: 'Delete',
														confirmButtonClass: 'is-danger'
													},
													() => this.delete(certification)
												);
											}}
										/>
										<Button
											className="is-rounded is-small edit-button"
											icon="pencil-alt"
											iconClass="has-text-grey"
											onClick={event => {
												modalStore.openModal(
													{ title: 'Edit Certification' },
													<CandidateProfileCertificationEdit
														certification={certification}
														handleSuccess={() => {
															candidateProfileStore.reloadCandidate();
														}}
													/>
												);
											}}
										/>
									</div>
									<div className="is-pulled-right">
										<Switch
											className="config-switch"
											id={`certification${certification.id}Switch`}
											value={certification.visible}
											activeLabel="Show"
											inactiveLabel="Hide"
											onValueChange={value =>
												this.props.onConfigChange(certification.id, value)
											}
										/>
									</div>
									<div className="title is-5">{certification.degree}</div>
									<div className="subtitle is-blue-text is-6">
										{certification.institution}
									</div>
									<div className="additional-information-section">
										<div className="subtitle is-7 location">
											{certification.location && (
												<span>
													<i className="fas fa-map-marker-alt" />
													<span>{certification.location}</span>
												</span>
											)}
										</div>
										{this.renderCertificationStatus(certification)}
									</div>
								</div>
							</div>
						</Fragment>
					);
				})}
			</div>
		);
	}

	async delete(item) {
		candidatesStore.deleteCertification(item.id).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'Certification Deleted',
					'The Certification has been successfully deleted.'
				);
				candidateProfileStore.reloadCandidate();
			} else {
				notificationStore.pushErrorNotification(
					'Delete Failed',
					'Failed to delete the Certification'
				);
			}
		});
	}
}
