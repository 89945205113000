import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '../../../components/layout/button/Button';

@withRouter
export class CandidateProfileWizardSuccessful extends React.Component {
	render() {
		return (
			<Fragment>
				<div className="form-content">
					<section className="hero">
						<div className="hero-body">
							<div className="container has-text-centered successfull-signup">
								<h1 className="title is-2 is-marginless">
									Thanks for registering
								</h1>
								<h2 className="subtitle is-6">We'll reach out to you soon!</h2>
								<Button
									onClick={() => (window.location.href = '/')}
									type="submit"
									className="button primary-v2 is-large"
									label={'Continue'}
								/>
							</div>
						</div>
					</section>
				</div>
			</Fragment>
		);
	}
}
