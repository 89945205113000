import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../../../components/layout/form-fields/FormField';
import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import { candidatesStore } from '../CandidatesStore';
import { candidatesStore as candidateStoreAdmin } from '../../../components/candidates/CandidatesStore';
import { CountryDropdown } from '../../../components/layout/form-fields/inputs/CountryDropdown';
import { MultiDropdown } from '../../../components/layout/form-fields/inputs/MultiDropdown';
import { RecruitersDropdown } from '../../../components/layout/form-fields/inputs/RecruitersDropdown';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required(),
	phoneNumber: Yup.string().required().label('Phone'),
	location: Yup.string().required().label('Location'),
	linkedinUrl: Yup.string().required().label('Linked profile').url(),
	country: Yup.mixed().required().label('Country'),
	preferredContactMethod: Yup.array()
		.min(1, 'Please select at least one Contact Method')
		.required()
		.label('Contact Method'),
	email: Yup.string().required().email().label('Email')
});

export class CandidateProfileContactInfoEditAdmin extends ModalComponent {
	state = {
		saving: false
	};
	getValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	isInitialValid(props) {
		if (!props.validationSchema) return true;
		return props.validationSchema.isValidSync(props.initialValues);
	}
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				isInitialValid={this.isInitialValid}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(
						formikBag,
						'CandidateProfileContactInfoEditAdmin'
					)
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<FormField
								className="has-text-left"
								field="name"
								type="text"
								placeholder="Name"
							/>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<div className="field has-text-left">
							<FormField
								className="has-text-left"
								field="email"
								type="email"
								placeholder="Email"
							/>
						</div>
					</div>
					<div className="column is-6">
						<div className="field has-text-left">
							<FormField
								className="has-text-left"
								field="phoneNumber"
								type="text"
								placeholder="Phone"
							/>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<div className="field has-text-left">
							<FormField
								className="has-text-left"
								field="location"
								type="text"
								placeholder="City/State"
							/>
						</div>
					</div>
					<div className="column is-6">
						<div className="field has-text-left">
							<CountryDropdown
								value={formikBag.values.country}
								onValueChange={item => {
									formikBag.setFieldValue('country', item.id);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<FormField
								className="has-text-left"
								field="linkedinUrl"
								type="text"
								placeholder="Linkedin profile"
							/>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<MultiDropdown
								value={formikBag.values.preferredContactMethod}
								placeholder={'What`s your preferred contact method'}
								options={candidatesStore.preferredContactMethodOptions}
								onValueChange={values =>
									formikBag.setFieldValue('preferredContactMethod', values)
								}
							/>
						</div>
					</div>
				</div>
				<div className="columns add-margin-top">
					<div className="column is-full">
						<div className="field has-text-left">
							<RecruitersDropdown
								value={formikBag.values.talentNetworkSourceRecruiter}
								onValueChange={item => {
									formikBag.setFieldValue(
										'talentNetworkSourceRecruiter',
										item.id
									);
								}}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		const preferredContactMethod = (
			this.props.candidate.preferredContactMethod || []
		).map(item => {
			return {
				text: this.getValueFromOptions(
					item,
					candidatesStore.preferredContactMethodOptions
				),
				value: item
			};
		});
		return {
			name: this.props.candidate.name,
			email: this.props.candidate.contactEmail
				? this.props.candidate.contactEmail
				: this.props.candidate.email,
			phoneNumber: this.props.candidate.phoneNumber,
			country: this.props.candidate.country,
			linkedinUrl: this.props.candidate.linkedinUrl,
			location: this.props.candidate.location || '',
			talentNetworkSourceRecruiter: this.props.candidate.referrerRecruiter,
			preferredContactMethod
		};
	}

	saving(status) {
		if (status === false || status === true) {
			this.setState({ saving: status });
		} else {
			this.setState({ saving: true });
		}
	}

	async save(values) {
		const valuesToSave = {
			candidate: {
				...values,
				country: values.country.id ? values.country.id : values.country,
				preferredContactMethod: values.preferredContactMethod.map(
					item => item.value
				)
			}
		};
		candidateStoreAdmin
			.updateContactInfoAdmin(this.props.candidate.id, valuesToSave)
			.then(result => {
				this.setState({ saving: false });
				this.resultNotification(
					result,
					'Contact Info',
					null,
					'Contact Info Error',
					'Your contact info were saved successfully.'
				);
			});
	}
}
