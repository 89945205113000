import React, { Fragment } from 'react';
import { Button } from '../../../components/layout/button/Button';
import { modalStore } from '../../../components/layout/modals/ModalStore';
import { candidateProfileStore } from '../CandidateProfileStore';
import { CandidateProfileEducationEdit } from './CandidateProfileEducationEdit';
import { dialogStore } from '../../../components/layout/modals/DialogStore';
import { notificationStore } from '../../../components/layout/notifications/NotificationStore';
import { candidatesStore } from '../CandidatesStore';
import { observer } from 'mobx-react';

@observer
export class CandidateProfileEducation extends React.Component {
	displayValueFromOptions(value, options) {
		const option = options.find(option => {
			return option.value === value;
		});
		return option ? option.text : 'N/A';
	}
	render() {
		return (
			<div className="content-container">
				{this.props.educations.map(education => {
					return (
						<Fragment key={education.id}>
							<div className="description-content">
								<div
									className={`description-section education ${
										!education.visible ? 'is-disabled' : ''
									}`}>
									<div className="is-pulled-right button-group">
										{!candidateProfileStore.isLocked && (
											<>
												<Button
													className="is-rounded is-small edit-button"
													icon="trash"
													iconClass="has-text-danger"
													onClick={event => {
														dialogStore.openDialog(
															{
																title: 'Delete Education',
																message:
																	'This education entry will be deleted. Are you sure?',
																confirmLabel: 'Delete',
																confirmButtonClass: 'is-danger'
															},
															() => this.delete(education)
														);
													}}
												/>
												<Button
													className="is-rounded is-small edit-button"
													icon="pencil-alt"
													iconClass="has-text-grey"
													onClick={event => {
														modalStore.openModal(
															{ title: 'Edit Education' },
															<CandidateProfileEducationEdit
																education={education}
																handleSuccess={() => {
																	candidateProfileStore.reloadCandidate();
																}}
															/>
														);
													}}
												/>
											</>
										)}
									</div>
									<div className="title is-5">{education.degree}</div>
									<div className="subtitle is-blue-text is-6">
										{education.institution}
									</div>
									<div className="additional-information-section">
										<div className="subtitle is-7 location">
											{education.location && (
												<span>
													<i className="fas fa-map-marker-alt" />
													<span>{education.location}</span>
												</span>
											)}
										</div>
										<div className="subtitle is-7 status">
											<span className="status-text">
												{this.displayValueFromOptions(
													education.status,
													candidatesStore.educationStatusOptions
												)}
											</span>
											{education.status === 'graduated' &&
											education.graduationYear ? (
												<span>Completion Year: {education.graduationYear}</span>
											) : null}
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					);
				})}
			</div>
		);
	}

	async delete(item) {
		candidatesStore.deleteEducation(item.id).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'Education Deleted',
					'The Education has been successfully deleted.'
				);
				candidateProfileStore.reloadCandidate();
			} else {
				notificationStore.pushErrorNotification(
					'Delete Failed',
					'Failed to delete the Education'
				);
			}
		});
	}
}
