import { Button } from '../../../layout/button/Button';
import { modalStore } from '../../../layout/modals/ModalStore';
import { CandidateProfileReposEdit } from './CandidateProfileReposEdit';
import { candidateProfileStore } from '../CandidateProfileStore';
import React from 'react';
import { CandidateProfileRepo } from './CandidateProfileRepo';
import { Switch } from '../../../layout/form-fields/inputs/Switch';
import { Icon } from '../Icon';

export class CandidateProfileRepos extends React.Component {
	render() {
		const config = this.props.candidate.candidateProfile;
		return (
			<div
				className={`repos-container bio-subsection-wrapper CandidateProfileRepos ${
					this.props.isEdit && !config.showRepos ? 'is-disabled' : ''
				}`}>
				<div className="title is-inline-block has-edit-button">
					<Icon icon="fa-regular fa-brands fa-github" />
					Repos
					<div className="edit-button-wrapper" title="Edit your repos">
						{this.props.isEdit && (
							<Button
								className="is-rounded is-small edit-button"
								icon="pencil-alt"
								iconClass="has-text-white"
								onClick={event => {
									modalStore.openModal(
										{ title: 'Edit Repos', position: 'center' },
										<CandidateProfileReposEdit
											candidate={this.props.candidate}
											repos={this.props.repos}
											handleSuccess={() => {
												candidateProfileStore.reloadCandidate();
											}}
										/>
									);
								}}
							/>
						)}
						{this.props.isEdit && (
							<Switch
								className="config-switch"
								id="repoSwitch"
								value={config.showRepos}
								activeLabel="Show"
								inactiveLabel="Hide"
								onValueChange={value =>
									this.props.onConfigChange('showRepos', value)
								}
							/>
						)}
					</div>
				</div>
				{this.props.repos.length > 0 ? (
					<div className="sub-section">
						<div className="sub-section-content">
							{this.props.repos.map((repo, index) => {
								return (
									<CandidateProfileRepo
										key={repo.id}
										repo={repo}
										asLink
										showIcon={false}
									/>
								);
							})}
						</div>
					</div>
				) : (
					<div className="sub-section-content">
						<p>There are no repos listed in this candidate's profile yet.</p>
					</div>
				)}
			</div>
		);
	}
}
