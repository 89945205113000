import React from 'react';

export class ProfileImage extends React.Component {
	isURLAbsolute(url) {
		return url.indexOf('://') > 0 || url.indexOf('//') === 0;
	}
	buildAbsoluteURL(url) {
		return `${process.env.REACT_APP_API}${url}`;
	}
	render() {
		const hasProfileImage = !!this.props.src;

		return (
			<img
				alt="avatar"
				className={`ProfileImage ${
					!hasProfileImage ? 'PlaceholderProfileImage' : ''
				} ${this.props.className}`}
				src={
					this.props.src
						? !this.isURLAbsolute(this.props.src)
							? this.buildAbsoluteURL(this.props.src)
							: this.props.src
						: null || '/avatar_placeholder.png'
				}
			/>
		);
	}
}
