import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import React, { Fragment } from 'react';
import { FormField } from '../../../components/layout/form-fields/FormField';

export class CandidateProfileResumeEdit extends ModalComponent {
	render() {
		return (
			<Fragment>
				<FormField
					className="has-text-left"
					label={
						this.props.label ? this.props.label : 'Resume (Optional, Max. 1MB)'
					}
					acceptedFormats={'application/pdf'}
					field="resume"
					styleVersion={this.props.styleVersion}
					type="file"
					formikBag={this.props.formikBag}
				/>
			</Fragment>
		);
	}
}
