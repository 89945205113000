import React from 'react';
import { withRouter } from 'react-router-dom';
import { modalStore } from 'components/layout/modals/ModalStore';
import { RequestProfileModal } from './RequestProfileModal';
import { BaseHeader } from 'components/layout/header/BaseHeader';

@withRouter
export class RequestProfileForm extends React.Component {
	handleRequestClick = async () => {
		const { candidateId, candidateSlug } = this.props;

		modalStore.openModal(
			{
				title: 'Request a new candidate profile link',
				saveButtonLabel: 'Send'
			},
			<RequestProfileModal
				candidateId={candidateId}
				candidateSlug={candidateSlug}
			/>
		);
	};

	render() {
		return (
			<div className="requestProfileForm">
				<BaseHeader />
				{/*Content*/}
				<div className="content">
					<p className="title is-3">Oops!</p>
					<p className="subtitle is-4">
						This candidate's profile link has expired.
						<br />
						Please click below to request re-activating the link.
					</p>
					<button
						className="button is-primary is-rounded"
						onClick={this.handleRequestClick}>
						Request a new candidate profile link
					</button>
				</div>
			</div>
		);
	}
}
