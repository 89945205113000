import React from 'react';
import { Icon } from '../misc/Icon';
import { BaseHeader } from '../header/BaseHeader';

export class NotFound extends React.Component {
	render() {
		return (
			<div className="NotFoundWrapper is-flex is-flex-direction-column is-justify-content-center">
				<BaseHeader />
				<div className="is-flex is-flex-direction-column is-align-items-center">
					<p>
						<Icon icon="fa-exclamation-circle" />
					</p>
					<p className="title is-3">404 - Not Found</p>
					<p className="subtitle is-4">The requested page doesn't exist</p>
				</div>
			</div>
		);
	}
}
