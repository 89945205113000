import { EntityStore } from '../../shared/stores/EntityStore';
import { action, makeObservable, override } from 'mobx';
import { api } from '../../shared/api/api';
import { notificationStore } from 'components/layout/notifications/NotificationStore';

export const GITHUB_URL = 'https://github.com/';
export const GITHUB_REPO_KEY = 'github';

class CandidatesStore extends EntityStore {
	englishLevelOptions = [
		{ text: 'None', value: 'none' },
		{ text: 'Beginner', value: 'beginner' },
		{ text: 'Intermediate', value: 'intermediate' },
		{ text: 'Upper Intermediate', value: 'upper_intermediate' },
		{ text: 'Advanced', value: 'advanced' },
		{ text: 'Native', value: 'native' }
	];

	educationStatusOptions = [
		{ text: 'Complete', value: 'graduated' },
		{ text: 'In Progress', value: 'attending' },
		{ text: 'Incomplete', value: 'incomplete' }
	];

	recordStatusOptions = [
		{ text: 'Inactive', value: 'inactive' },
		{ text: 'Active', value: 'active' },
		{ text: 'All Candidates', value: '' }
	];

	lookingForJobOptions = [
		{ text: 'Job Status', value: '' },
		{ text: 'Actively Looking for a Job', value: 'yes' },
		{ text: 'Passively Looking for a Job', value: 'maybe' },
		{ text: 'Not Looking for a Job', value: 'no' }
	];

	repoKeyOptions = [
		{ text: 'Github', value: 'github' },
		{ text: 'Other', value: 'other' }
	];

	tableColumnsOptions = [
		{ text: 'Country', label: 'Country', value: 'country' },
		{ text: 'Last Modified', label: 'Last Modified', value: 'last_modified' },
		{
			text: 'Top Technologies',
			label: 'Top Technologies',
			value: 'top_technologies'
		},
		{ text: 'Tags', label: 'Tags', value: 'tags' },
		{
			text: 'Profile Completion',
			label: 'Profile Completion',
			value: 'profile_completion'
		}
	];

	constructor() {
		super('candidates');
		makeObservable(this);
	}

	@action
	async createEducation(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/educations`,
			request
		);
		return result;
	}

	@action
	async updateEducation(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/educations/${id}`,
			request
		);
		return result;
	}

	@action
	async deleteEducation(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/educations/${id}`
		);
		return result;
	}

	@action
	async createCertification(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/certifications`,
			request
		);
		return result;
	}

	@action
	async updateCertification(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/certifications/${id}`,
			request
		);
		return result;
	}

	@action
	async deleteCertification(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/certifications/${id}`
		);
		return result;
	}

	@action
	async createTestResult(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/test-results`,
			request
		);
		return result;
	}

	@action
	async deleteTestResult(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/test-results/${id}`
		);
		return result;
	}

	@action
	async updateTestResult(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/test-results/${id}`,
			request
		);
		return result;
	}

	@action
	async createExperience(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/experiences`,
			request
		);
		return result;
	}

	@action
	async updateExperience(id, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/experiences/${id}`,
			request
		);
		return result;
	}

	@action
	async deleteExperience(id) {
		const result = await api.delete(
			`${this.entityPluralEndpoint}/experiences/${id}`
		);
		return result;
	}

	@action
	async createRepos(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/repos`,
			request
		);
		return result;
	}

	@action
	async createTags(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/tags`,
			request
		);
		return result;
	}

	@action
	async updateExpectationsAdmin(candidateId, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/${candidateId}/expectations`,
			request
		);
		return result;
	}

	@action
	async updateAboutAdmin(candidateId, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/${candidateId}/about`,
			request
		);
		return result;
	}

	@action
	async updateContactInfoAdmin(candidateId, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/${candidateId}/contact-info`,
			request
		);
		return result;
	}

	@action
	async setLockStatus(candidateId, request) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/${candidateId}/lock-end-date`,
			request
		);
		return result;
	}

	@action
	async generateLinkToken(candidateId, request) {
		const result = await api.post(
			`${this.entityPluralEndpoint}/${candidateId}/link-token`,
			request
		);
		return result;
	}

	@action
	async getLinkToken(candidateId, request) {
		return await api.get(
			`${this.entityPluralEndpoint}/${candidateId}/link-token`,
			request
		);
	}

	@action
	async activate(candidateId) {
		const result = await api.put(
			`${this.entityPluralEndpoint}/${candidateId}/activate`
		);
		return result;
	}

	@action
	async uploadCandidateImage(candidateId, imageFile) {
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
		const formData = new FormData();
		formData.append('image', imageFile);
		const result = await api.put(
			`${this.entityPluralEndpoint}/${candidateId}/image`,
			formData,
			config
		);
		return result;
	}

	@action
	async uploadCandidateResume(candidateId, resumeImage) {
		const config = {
			headers: {
				'content-type': 'application/pdf'
			}
		};
		const formData = new FormData();
		formData.append('resume', resumeImage);
		const result = await api.put(
			`${this.entityPluralEndpoint}/${candidateId}/resume`,
			formData,
			config
		);
		return result;
	}

	@action
	async getCandidates(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;
		const result = await api.get(encodeURI(url));
		return result;
	}

	@override
	async get(id) {
		const result = await api.get(`${this.entityPluralEndpoint}/${id}`);
		return result;
	}

	@action
	async getOpenPositions() {
		const result = await api.get('/open-positions');
		return result;
	}

	getCandidateProfileLink({ token, absolute = false }) {
		let url = '/profile';

		if (token) {
			url = `${url}?token=${token}`;
		}

		if (absolute) {
			url = `${window.location.origin}${url}`;
		}

		return url;
	}

	@action
	async exportCandidates() {
		const result = await api.post(
			`${this.entityPluralEndpoint}/export`,
			{
				query: this.query
			},
			{
				responseType: 'blob'
			}
		);

		if (result.status === 'fail') {
			notificationStore.pushErrorNotification(
				'Export Error',
				'An error occurred trying to export the candidate list'
			);
		} else {
			var blob = new Blob([result], {
				type: 'text/csv'
			});

			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = 'candidates_list.csv';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			return result;
		}
	}
}

export const candidatesStore = new CandidatesStore();
