import React from 'react';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { scorecardTemplateStore } from '../ScorecardTemplateStore';
import { Button } from '../../layout/button/Button';

export class ScorecardTemplatePublishModal extends ModalComponent {
	componentDidMount() {
		this.validate();
	}

	render() {
		const { errorMessages, saving } = this.state;

		return (
			<>
				<section className="modal-card-body">
					<article className="message is-info">
						<div className="message-body">
							Remember to check all your data before publishing.
							<br />
							Once published, this scorecard template cannot be modified.
						</div>
					</article>

					{errorMessages && (
						<article className="message is-danger">
							<div className="message-body">
								Please fix the following errors to publish this scorecard
								template:
								<br />
								<br />
								<ol style={{ listStylePosition: 'inside' }}>
									{errorMessages.map(message => (
										<li key={message}>{message}</li>
									))}
								</ol>
							</div>
						</article>
					)}
				</section>
				<footer className="modal-card-foot">
					<div className="fullwidth">
						<button
							type="button"
							className="button is-rounded is-light"
							onClick={() => this.handleClickCancel()}>
							Close
						</button>
						<Button
							disabled={errorMessages}
							type="submit"
							className="button is-primary is-rounded"
							label="Publish"
							loading={saving}
							onClick={() => {
								void this.publish();
							}}
						/>
					</div>
				</footer>
			</>
		);
	}

	async validate() {
		const { templateId } = this.props;

		this.setState({
			errorMessages: null,
			saving: true
		});

		return scorecardTemplateStore.validate(templateId).then(result => {
			if (result.status === 'fail') {
				this.setState({
					errorMessages: result.message,
					saving: false
				});
			} else {
				this.setState({
					saving: false
				});
			}
		});
	}

	async publish() {
		const { templateId } = this.props;

		this.setState({
			errorMessages: null
		});

		return scorecardTemplateStore.publish(templateId).then(result => {
			if (result.status === 'fail') {
				this.setState({
					errorMessages: result.message
				});
			} else {
				this.resultNotification(
					result,
					null,
					null,
					'Scorecard Template Published',
					'This Scorecard Template has been published!'
				);
			}
			return result;
		});
	}
}
