import React, { useState } from 'react';
import { Icon } from '../../../../layout/misc/Icon';
import { ScorecardItem } from './ScorecardItem';
import { ScorecardCircle } from './ScorecardCircle';
import { isMobile } from 'shared/utils';

export const ScorecardSection = ({ section, collapsible = true }) => {
	const [open, setOpen] = useState(true);

	const toggleContainer = event => {
		event.stopPropagation();
		event.preventDefault();

		setOpen(!open);
	};

	return (
		<div
			className="ScorecardContent__SectionContainer"
			onClick={toggleContainer}>
			<div className="ScorecardContent__Section table is-fullwidth mb-0">
				<div className="ScorecardContent__SectionHeader">
					<div className="ScorecardContent__SectionTitle is-justify-content-flex-start">
						<span className="is-grey is-5 is-uppercase">{section.name}</span>
						{section.description ? (
							<span
								className="ScorecardContent__SectionDescription pl-3 tooltip is-tooltip-top has-tooltip-multiline"
								data-tooltip={section.description}>
								<Icon icon="fa-info-circle" />
							</span>
						) : null}
					</div>
					<div className="horizontal-divider"></div>
					<div>
						<ScorecardCircle
							rating={Number(section.relativeScore)}
							width={20}
							height={20}
							fullCircleColor="#19E1D0"
							emptyCircleColor="#314EBD"
							type="circle"
							hasTooltip={!isMobile()}
						/>
					</div>
					{collapsible ? (
						<button
							className="ToggleSectionButton button level-item"
							onClick={toggleContainer}>
							<Icon className="is-big" icon={open ? 'fa-minus' : 'fa-plus'} />
						</button>
					) : null}
				</div>
				<div className="ScorecardContent__SectionItems">
					{open &&
						section.items.map(item => (
							<ScorecardItem key={`${section.id}-${item.id}`} item={item} />
						))}
				</div>
			</div>
		</div>
	);
};
