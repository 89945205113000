import axios from 'axios';
import { authStore } from '../security/AuthStore';

// Default Axios Configuration
const _axios = axios.create({
	baseURL: process.env.REACT_APP_API
});

export const HTTP_ERROR = {
	ERROR: 400,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	SERVER_ERROR: 500
};

// API
export const api = {
	get: (url, options = null) => {
		setToken();
		return _axios
			.get(url, options)
			.then(response => response.data)
			.catch(error => handleErrorResponse(error));
	},
	post: (url, data, options = null) => {
		setToken();
		return _axios
			.post(url, data, options)
			.then(response => response.data)
			.catch(error => handleErrorResponse(error));
	},
	put: (url, data, options = null) => {
		setToken();
		return _axios
			.put(url, data, options)
			.then(response => response.data)
			.catch(error => handleErrorResponse(error));
	},
	patch: (url, data, options = null) => {
		setToken();
		return _axios
			.patch(url, data, options)
			.then(response => response.data)
			.catch(error => handleErrorResponse(error));
	},
	delete: (url, options = null) => {
		setToken();
		return _axios
			.delete(url, options)
			.then(response => response.data)
			.catch(error => handleErrorResponse(error));
	}
};

function handleErrorResponse(error) {
	const errorRequest = error.request;
	const UNAUTHORIZED_CODE = 401;
	if (errorRequest) {
		try {
			let errorResponse = JSON.parse(errorRequest.response);
			console.error(errorResponse.message);
			errorResponse.statusCode = errorRequest.status;
			const responseUnauthorized = errorRequest.status === UNAUTHORIZED_CODE;
			if (responseUnauthorized) {
				authStore.logout();
			}
			errorResponse.error = errorRequest.message;
			return errorResponse;
		} catch (e) {
			console.error(error.toString());
			return { status: 'fail', error: error.toString() };
		}
	} else {
		console.error(error);
		return { status: 'fail', error: error.toString() };
	}
}

function setToken() {
	if (authStore.token) {
		_axios.defaults.headers.common['Authorization'] =
			`Bearer ${authStore.token}`;
	}
}
