import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { authStore } from '../../../shared/security/AuthStore';

@withRouter
export class SideNavigation extends React.Component {
	render() {
		let menuItems = [];
		// 1. Dashboard
		if (authStore.isAdmin() || authStore.isManager()) {
			menuItems.push({
				route: '/dashboard',
				icon: 'fa-chart-pie',
				tooltip: 'Dashboard'
			});
		}
		// 2. Candidates
		if (
			authStore.isAdmin() ||
			authStore.isManager() ||
			authStore.isRecruiter()
		) {
			menuItems.push({
				route: '/candidates',
				icon: 'fa-male',
				tooltip: 'Candidates'
			});
		}
		// 3. Users
		if (authStore.isAdmin()) {
			menuItems.push({
				route: '/users',
				icon: 'fa-users',
				tooltip: 'Users'
			});
		}
		// 4. Tests
		if (authStore.isAdmin() || authStore.isManager()) {
			menuItems.push({
				route: '/tests',
				icon: 'fa-clipboard-list',
				tooltip: 'Tests'
			});
		}
		// 5. Tests
		if (authStore.isAdmin() || authStore.isManager()) {
			menuItems.push({
				route: '/test-results-files',
				icon: 'fa-table',
				tooltip: 'Test Results Files'
			});
		}
		// 6. Tests
		if (authStore.isAdmin() || authStore.isManager()) {
			menuItems.push({
				route: '/scorecard-templates',
				icon: 'fa-star',
				tooltip: 'Scorecard Templates'
			});
		}
		// 7. Settings
		if (authStore.isAdmin() || authStore.isManager()) {
			menuItems.push({
				route: '/settings',
				icon: 'fa-cog',
				tooltip: 'Settings'
			});
		}
		return (
			<div className="SideNavigation">
				<nav className="menu app-left-menu">
					<ul className="menu-list">
						{menuItems.map((menuItem, index) => {
							return this.renderMenuItem(menuItem, index);
						})}
					</ul>
				</nav>
			</div>
		);
	}

	renderMenuItem(menuItem, index) {
		const { route, icon, tooltip } = menuItem;
		return (
			<li
				key={index}
				className="tooltip is-tooltip-right"
				data-tooltip={tooltip}>
				<NavLink to={route} activeClassName="is-active">
					<i className={'fas ' + icon} />
				</NavLink>
			</li>
		);
	}
}
