import React, { Fragment } from 'react';
import { candidateProfileStore } from '../CandidateProfileStore';
import { Loader } from '../../../components/layout/misc/Loader';
import { candidatesStore } from '../CandidatesStore';
import { CandidateProfileAboutEdit } from './CandidateProfileAboutEdit';
import { observer } from 'mobx-react';

@observer
export class CandidateProfileAboutMainPage extends React.Component {
	componentDidMount() {
		this.loadCandidate();
	}

	componentWillUnmount() {
		candidateProfileStore.reset();
	}

	async loadCandidate() {
		const result = await candidatesStore.get();
		candidateProfileStore.setCandidate(result.data);
	}
	render() {
		if (candidateProfileStore.candidate) {
			return (
				<Fragment>
					<div className="main-page">
						<CandidateProfileAboutEdit
							candidate={candidateProfileStore.candidate}
							handleSuccess={() => {
								candidateProfileStore.reloadCandidate();
							}}
						/>
					</div>
				</Fragment>
			);
		} else {
			return <Loader type="page-loader" />;
		}
	}
}
