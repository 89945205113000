import { modalStore } from './ModalStore';
import React, { Fragment } from 'react';
import { notificationStore } from '../notifications/NotificationStore';
import { Button } from '../button/Button';
import { Form } from 'formik';

export class ModalComponent extends React.Component {
	state = {
		saving: false
	};
	closeWithSuccess(result) {
		if (this.props.handleSuccess) {
			this.props.handleSuccess(result);
		}
		this.setState({ saving: false });
		modalStore.closeDialog();
	}
	handleClickCancel() {
		if (this.props.handleCancelClose) {
			this.props.handleCancelClose('cancel');
		}
		modalStore.closeDialog();
	}
	saving(status) {
		if (status === false || status === true) {
			this.setState({ saving: status });
		} else {
			this.setState({ saving: true });
		}
	}
	resultNotification(
		result,
		entityName,
		mode = 'Create',
		customTitle = null,
		customMessage = null
	) {
		if (result.status === 'success') {
			notificationStore.pushNotification({
				type: notificationStore.SUCCESS_NOTIFICATION,
				title: customTitle || `${mode} ${entityName}`,
				message:
					customMessage ||
					`The ${entityName} has been ${
						mode !== 'Create' ? 'saved' : ''
					} successfully${mode === 'Create' ? ' created' : ''}.`
			});
			this.closeWithSuccess(result);
		} else {
			notificationStore.pushNotification({
				type: notificationStore.ERROR_NOTIFICATION,
				title: customTitle || `${mode} ${entityName}`,
				message: 'An error has occurred. Please try again.'
			});
			// Unblock submit button so user can retry
			this.saving(false);
		}
	}

	renderModalContainer(formikBag, className = '') {
		return (
			<Fragment>
				<section className="modal-card-body has-text-centered">
					<div className={`${className} create-edit-form`}>
						<Form>{this.renderForm(formikBag)}</Form>
					</div>
				</section>
				{this.renderFooter(formikBag)}
			</Fragment>
		);
	}

	renderForm() {
		return '';
	}

	renderFooter(formikBag) {
		return (
			<footer className="modal-card-foot">
				<div className="fullwidth">
					<button
						type="button"
						className="button is-rounded is-light"
						onClick={() => this.handleClickCancel()}>
						Cancel
					</button>
					<Button
						disabled={!formikBag.dirty || !formikBag.isValid}
						onClick={() => formikBag.submitForm()}
						type="submit"
						className="button is-primary is-rounded"
						label={modalStore.saveButtonLabel || 'Save'}
						loading={this.state.saving}
					/>
				</div>
			</footer>
		);
	}
}
