import React from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Currency } from '../../shared/Currency';
import { Icon } from '../../../layout/misc/Icon';
import { dialogStore } from '../../../layout/modals/DialogStore';

@withRouter
@observer
export class CandidateCompensationsTable extends React.Component {
	state = {};

	render() {
		const { data, onClickDelete } = this.props;

		return (
			<table className="CandidateCompensationsTable table is-fullwidth is-bordered is-hoverable">
				<thead>
					<tr>
						<th style={{ width: '15%' }}>Current Compensation</th>
						<th style={{ width: '15%' }}>Expected Compensation</th>
						<th className="is-hidden-mobile" style={{ width: '15%' }}>
							Updated by
						</th>
						<th style={{ width: '15%' }}>Date</th>
						<th className="is-hidden-mobile" style={{ width: '30%' }}>
							Notes
						</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{(data ?? []).map(item => {
						return (
							<tr key={item.id} className={item.disabled ? 'disabled' : ''}>
								<td className="has-text-weight-bold">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<Currency
												value={item.currentCompensation}
												code={item.currentCurrency.code}
											/>
										</div>
									</div>
								</td>

								<td className="has-text-weight-bold">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<Currency
												value={item.expectedCompensation}
												code={item.expectedCurrency.code}
											/>
										</div>
									</div>
								</td>

								<td className="has-text-weight-bold is-hidden-mobile">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<span>{item.createdByUser.name}</span>
										</div>
									</div>
								</td>

								<td className="has-text-weight-bold">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<span>{moment(item.date).format('ll')}</span>
										</div>
									</div>
								</td>

								<td className="has-text-weight-bold is-hidden-mobile">
									<div className="thumb-label">
										<div className="subtitle is-7 thumb-text is-paddingless">
											<span>{item.notes}</span>
										</div>
									</div>
								</td>

								<td>
									<button
										className="button is-rounded is-circle is-small is-text"
										onClick={event => {
											dialogStore.openDialog(
												{
													title: 'Delete Compensation',
													message:
														'This compensation entry will be deleted. Are you sure?',
													confirmLabel: 'Delete',
													confirmButtonClass: 'is-danger'
												},
												() => onClickDelete(item.id)
											);
										}}>
										<Icon className="is-small" icon="fa-trash" />
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
}
