import React from 'react';
import { Icon } from '../../misc/Icon';
import onClickOutside from 'react-onclickoutside';

@onClickOutside
export class CountryFlagDropdown extends React.Component {
	state = {
		isOpen: false,
		value: null
	};

	constructor(props) {
		super(props);
		this.dropdownTriggerRef = React.createRef();
		this.dropdownMenuRef = React.createRef();
	}

	componentDidMount() {
		if (![null, undefined].includes(this.props.value)) {
			if (this.props.optionValue) {
				const match = this.props.options.find(item => {
					return item[this.props.optionValue] === this.props.value;
				});
				this.setState({ value: match[this.props.optionValue] });
			} else {
				this.setState({ value: this.props.value });
			}
		}
		if (this.props.isOpen === true) {
			this.setState({ isOpen: true });
		}
	}

	render() {
		return (
			<div
				className={
					'country-flag-dropdown dropdown ' +
					(this.state.isOpen ? 'is-active ' : '') +
					(this.props.styleVersion ? this.props.styleVersion : '')
				}>
				<div className="dropdown-trigger" ref={this.dropdownTriggerRef}>
					<button
						disabled={this.props.disabled}
						type="button"
						className="button"
						onClick={event => {
							if (
								event.target &&
								(event.target.classList.contains('fa-times') ||
									event.target.parentElement.classList.contains('fa-times'))
							) {
								this.clearValue();
							} else {
								this.handleOpenClick();
							}
						}}>
						<span className={!this.state.value ? 'is-placeholder' : ''}>
							{this.props.optionValueRenderSelection
								? this.getValueTextSelectedItem()
								: this.getValueText()}
						</span>
						{this.props.allowClear && this.state.value !== null ? (
							<Icon className="is-small" icon="fa-times" />
						) : null}
						<Icon className="is-small" icon="fa-angle-down" />
					</button>
				</div>
				<div className="dropdown-menu" ref={this.dropdownMenuRef}>
					<div className="dropdown-content">
						<div className="search-container control">
							<input
								type="search"
								className="search-options"
								placeholder="Search"
								onChange={e => {
									const input = e.target.value;

									const filteredCountries = this.props.allCountries.filter(
										country =>
											country.name
												.toLocaleUpperCase()
												.includes(input.toLocaleUpperCase())
									);

									this.props.filterCountries(filteredCountries);
								}}
							/>
						</div>
						{this.props.options.map((option, index) => {
							return (
								<a
									key={index}
									href={'void'}
									onClick={e => this.handleClickValue(e, option)}
									className={
										'dropdown-item ' +
										(this.isOptionActive(option) ? 'is-active' : '')
									}>
									{this.props.optionValue ? option.text : option}
								</a>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	getValueTextSelectedItem() {
		if (this.state.value !== null && this.props.optionValue) {
			const match = this.props.allCountries.find(
				item => item[this.props.optionValue] === this.state.value
			);
			return match ? match[this.props.optionValueRenderSelection] : null;
		} else {
			return this.state.value
				? this.state.value
				: this.props.placeholder || 'Please select one option.';
		}
	}

	getValueText() {
		if (this.state.value !== null && this.props.optionValue) {
			const match = this.props.allCountries.find(
				item => item[this.props.optionValue] === this.state.value
			);
			return match.text;
		} else {
			return this.state.value
				? this.state.value
				: this.props.placeholder || 'Please select one option';
		}
	}

	handleClickOutside(event) {
		this.handleOpenClick(true);
	}

	isOptionActive(value) {
		if (this.props.optionValue) {
			return this.state.value === value[this.props.optionValue];
		}
		return this.state.value === value ? true : false;
	}

	handleOpenClick(close = false) {
		if (close) {
			this.setState({
				isOpen: false
			});
		} else {
			// is going to show the menu
			if (this.state.isOpen === false && this.props.enableFixedMode) {
				const menuCoords =
					this.dropdownTriggerRef.current.getBoundingClientRect();
				this.dropdownMenuRef.current.style.paddingTop = '0';
				this.dropdownMenuRef.current.style.position = 'fixed';
				this.dropdownMenuRef.current.style.top = `${
					menuCoords.top + menuCoords.height
				}px`;
				this.dropdownMenuRef.current.style.left = `${menuCoords.left}px`;
				this.dropdownMenuRef.current.style.width = `${menuCoords.width}px`;
			}
			this.setState({
				isOpen: !this.state.isOpen
			});
		}
	}

	clearValue() {
		this.setState({ value: null });
		this.handleOpenClick(true);
		this.props.onValueChange(null);
	}

	handleClickValue(e, value) {
		e.preventDefault();
		if (this.props.optionValue) {
			value = value[this.props.optionValue];
		}
		this.setState({ value: value });
		this.props.onValueChange(value);
		this.handleOpenClick();
	}
}
