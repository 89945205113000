import React, { Fragment } from 'react';
import { candidatesStore } from './../../../../candidate-portal/profile/CandidatesStore';
import { FieldRequiredIndicator } from './FieldRequiredIndicator';

export class WorkLocationInput extends React.Component {
	state = {
		optionSelected: null
	};
	componentDidMount() {
		if (this.props.value !== null) {
			this.setState({ optionSelected: this.props.value });
		}
	}
	handleClick(option) {
		this.setState({ optionSelected: option });
		this.props.onValueChange(option);
	}

	getDisplayText(value) {
		const texts = {
			home: 'From home all time',
			office: 'In an office all time',
			mixed: 'A mix of the two options'
		};
		return texts[value];
	}

	render() {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="work-location-input">
							<div className="field has-text-left">
								<label className="title is-5 has-text-weight-bold">
									Where do you prefer <strong>working from?</strong>{' '}
									{this.props.required ? <FieldRequiredIndicator /> : null}
								</label>
								<div className="english-level icons-container control is-fullwidth add-lg-margin-top">
									{candidatesStore.preferredWorkLocationOptions.map(option => {
										return (
											<div
												className="icon-wrapper"
												onClick={() => this.handleClick(option.value)}>
												<div
													className={`radio has-text-centered ${
														this.state.optionSelected === option.value
															? 'active'
															: ''
													}`}>
													<img
														alt={'option'}
														className={`icon ${option.value}`}
														src={`../${option.value}.svg`}></img>
													<span className="text">
														{this.getDisplayText(option.value)}
													</span>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
