import React from 'react';
import { observer } from 'mobx-react';
import { candidateScorecardStore } from '../../../../components/candidates/profile/scorecards/CandidateScorecardStore';
import { Scorecard } from './Scorecard';

@observer
export class CandidateScorecardsView extends React.Component {
	state = {
		open: false
	};

	async componentDidMount() {
		const { scorecards } = this.props;
		candidateScorecardStore.setScorecards(scorecards);

		if (!candidateScorecardStore.selectedScorecardId) {
			candidateScorecardStore.selectScorecard(scorecards[0].id);
		}
	}

	handleTabClick(clickEvent, scorecardId) {
		clickEvent.preventDefault();

		candidateScorecardStore.selectScorecard(scorecardId);
	}

	render() {
		const hasScorecards =
			candidateScorecardStore.scorecards?.length > 0 ?? false;

		return (
			<div className="CandidateAdminScorecard">
				{hasScorecards &&
					candidateScorecardStore.scorecards.map(scorecard => (
						<Scorecard scorecard={scorecard} />
					))}
			</div>
		);
	}
}
