import { EntityStore } from '../../../../shared/stores/EntityStore';

class CandidateChangelogsStore extends EntityStore {
	actionOptions = [
		{ text: 'Add Compensation', value: 'add_compensation' },
		{ text: 'Update Candidate', value: 'update_candidate' },
		{ text: 'Add Candidate', value: 'add_candidate' },
		{ text: 'Deactivate Candidate', value: 'deactivate_candidate' },
		{ text: 'Update About', value: 'update_about' },
		{ text: 'Update Expectations', value: 'update_expectations' },
		{
			text: 'Updated "Looking for a job"',
			value: 'update_looking_for_a_job'
		},
		{ text: 'Update Contact Info', value: 'update_contact_info' },
		{ text: 'Add Education', value: 'add_education' },
		{ text: 'Update Education', value: 'update_education' },
		{ text: 'Delete Education', value: 'delete_education' },
		{ text: 'Add Certification', value: 'add_certification' },
		{ text: 'Update Certification', value: 'update_certification' },
		{ text: 'Delete Certification', value: 'delete_certification' },
		{ text: 'Add Experience', value: 'add_experience' },
		{ text: 'Update Experience', value: 'update_experience' },
		{ text: 'Delete Experience', value: 'delete_experience' },
		{ text: 'Add Repo', value: 'add_repo' },
		{ text: 'Add Feedback', value: 'add_feedback' },
		{ text: 'Upload Image', value: 'upload_image' },
		{ text: 'Add Test Request', value: 'add_test_request' },
		{ text: 'Verify Email', value: 'verify_email' },
		{ text: 'Create Candidate Scorecard', value: 'create_candidate_scorecard' },
		{ text: 'Update Candidate Scorecard', value: 'update_candidate_scorecard' },
		{ text: 'Delete Candidate Scorecard', value: 'delete_candidate_scorecard' },
		{ text: 'Add Test Result', value: 'add_test_result' },
		{ text: 'Update Test Result', value: 'update_test_result' },
		{ text: 'Delete Test Result', value: 'delete_test_result' },
		{ text: 'Update Tag', value: 'update_tag' },
		{ text: 'Generate Token', value: 'generate_token' }
	];

	entityPluralEndpoint = 'candidates/changelog';
	constructor() {
		super('candidate-changelog');
	}
}

export const candidateChangelogsStore = new CandidateChangelogsStore();
