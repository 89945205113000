import React, { Fragment } from 'react';
import moment from 'moment-timezone';

export class Workshifts extends React.Component {
	displayWorkshift(workshift, candidate) {
		if (workshift === 'anytime' || !candidate.timezone) {
			return workshift;
		}
		const shiftList = workshift.split('-');
		const shiftStarts = shiftList[0];
		const shiftEnds = shiftList[1];
		const shiftStartsViewerTimeZone = this.getCandidateShiftInViewerTimeZone(
			shiftStarts,
			candidate
		);
		const shiftEndsViewerTimeZone = this.getCandidateShiftInViewerTimeZone(
			shiftEnds,
			candidate
		);
		return `${shiftStartsViewerTimeZone}-${shiftEndsViewerTimeZone}`;
	}
	getBestShiftBasedOnViewerTimezone() {
		const candidate = this.props.candidate;
		const workshifts = this.props.candidate.preferredWorkshifts;
		const optimalWorkshiftStart = 8;
		const sortedByOptimalWorkshift = workshifts
			.slice()
			.sort((workshift1, workshift2) => {
				const convertedWorkshift1 = this.getCandidateShiftInViewerTimeZone(
					workshift1,
					candidate
				);
				const militaryShiftTime1 = parseInt(
					moment(convertedWorkshift1, 'ha').format('HH')
				);
				const convertedWorkshift2 = this.getCandidateShiftInViewerTimeZone(
					workshift2,
					candidate
				);
				const militaryShiftTime2 = parseInt(
					moment(convertedWorkshift2, 'ha').format('HH')
				);
				if (
					Math.abs(militaryShiftTime1 - optimalWorkshiftStart) <=
					Math.abs(militaryShiftTime2 - optimalWorkshiftStart)
				) {
					return 1;
				}
				return -1;
			});
		const optimalWorkshift = sortedByOptimalWorkshift.pop();
		return this.displayWorkshift(optimalWorkshift, candidate);
	}
	getCandidateShiftInViewerTimeZone(time, candidate) {
		const candidateHasTimeZoneOldFormat = !candidate.timezone;
		if (candidateHasTimeZoneOldFormat)
			return this.getCandidateShiftInViewerOldTimeZone(
				time,
				candidate.preferredTimeZone || 'America/New_York'
			);
		return this.getCandidateShiftInViewerNewTimeZone(time, candidate.timezone);
	}
	getCandidateShiftInViewerOldTimeZone(time, candidateTimeZone) {
		const today = new Date();
		const militaryShiftTime = parseInt(moment(time, 'ha').format('HH'));
		const now = moment.utc();

		const candidateTimeZoneUtcOffset = moment.tz
			.zone(candidateTimeZone)
			.utcOffset(now);
		const viewerTimeZoneutcOffset = moment.tz
			.zone(moment.tz.guess())
			.utcOffset(now);
		const differenceInHours =
			(candidateTimeZoneUtcOffset - viewerTimeZoneutcOffset) / 60;
		today.setHours(militaryShiftTime + differenceInHours);
		return moment(today).format('ha');
	}
	getCandidateShiftInViewerNewTimeZone(time, candidateTimeZone) {
		const today = new Date();
		const militaryShiftTime = parseInt(moment(time, 'ha').format('HH'));
		const now = moment.utc();
		const candidateTimeZoneUtcOffset =
			parseFloat(candidateTimeZone.offset) * 60 * -1;
		const viewerTimeZoneutcOffset = moment.tz
			.zone(moment.tz.guess())
			.utcOffset(now);
		const differenceInHours =
			(candidateTimeZoneUtcOffset - viewerTimeZoneutcOffset) / 60;
		today.setHours(militaryShiftTime + differenceInHours);
		return moment(today).format('ha');
	}

	render() {
		return (
			<Fragment>
				{this.props.showOnlyOptimalWorkshift ? (
					this.props.candidate.preferredWorkshifts ? (
						<span className="workshifts tag">
							{this.getBestShiftBasedOnViewerTimezone()}
						</span>
					) : (
						''
					)
				) : (
					(this.props.candidate.preferredWorkshifts || []).map(
						(workshift, index) => {
							return (
								<span key={index} className="workshifts tag">
									{this.displayWorkshift(workshift, this.props.candidate)}
								</span>
							);
						}
					)
				)}{' '}
				{this.props.showViewerTimezone ? moment.tz.guess() : null}
			</Fragment>
		);
	}
}
