import { Formik } from 'formik';
import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { candidatesStore } from '../../CandidatesStore';
import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Button } from '../../../layout/button/Button';
import { FormField } from '../../../layout/form-fields/FormField';
import { notificationStore } from '../../../layout/notifications/NotificationStore';
import { Switch } from 'components/layout/form-fields/inputs/Switch';

const ValidationSchema = Yup.object().shape({
	linkToken: Yup.string()
});

export class CandidateProfileLinkToken extends ModalComponent {
	state = {
		token: null,
		lockEndDate: null
	};

	componentDidMount() {
		this.setState({
			token: this.props.token,
			lockEndDate: this.props.token.lockEndDate
		});
	}

	render() {
		if (!this.state.token) {
			return this.renderEmptyForm();
		}

		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}
				enableReinitialize>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileLinkToken')
				}
			</Formik>
		);
	}

	renderEmptyForm() {
		const { saving } = this.state;

		return (
			<>
				<section className="modal-card-body has-text-centered">
					<div className="CandidateProfileLinkToken create-edit-form">
						<div className="columns">
							<div className="column is-flex is-flex-direction-column	 center-align">
								<label className="is-size-5 mb-4">
									This candidate does not have a profile token yet.
									<br />
									Click below to generate one.
								</label>
								<Button
									className="button is-primary is-rounded add-margin-left"
									label="Generate token"
									onClick={() => {
										this.saving();
										void this.save();
									}}
									loading={saving}
								/>
							</div>
						</div>
					</div>
				</section>
				<footer className="modal-card-foot" />
			</>
		);
	}

	renderForm(formikBag) {
		const {
			lockEndDate,
			token: { linkToken, expirationDate, isExpired }
		} = this.state;

		const candidateUrl = candidatesStore.getCandidateProfileLink({
			token: linkToken,
			absolute: true
		});

		const isLocked =
			!!lockEndDate && new Date(lockEndDate).valueOf() > Date.now();

		return (
			<Fragment>
				<div className="columns">
					<div className="column is-9">
						<FormField
							className="has-text-left"
							label="Private URL"
							field="linkToken"
							type="text"
							disabled={true}
							placeholder="City/State"
						/>
					</div>
					<div className="column is-3 is-flex">
						<div className="buttons">
							<Button
								title="Copy Private URL"
								className="button is-rounded is-circle is-small is-text"
								onClick={() => {
									this.copyTextToClipboard(candidateUrl);
								}}
								icon="link"
							/>
							<a target="_blank" href={candidateUrl} rel="noopener noreferrer">
								<Button
									title={'View Private URL'}
									className="button is-rounded is-circle is-small is-text"
									icon={'eye'}
								/>
							</a>
						</div>
					</div>
				</div>
				<div className="columns has-text-left">
					<div
						className={`column is-flex center-align ${
							isExpired ? 'has-text-danger' : ''
						}`}>
						<label className="is-7">
							Expiration Date: {moment(expirationDate).format('ll')}
						</label>
						<Button
							onClick={() => formikBag.submitForm()}
							type="submit"
							className="button is-primary is-rounded add-margin-left"
							label={'Extend'}
							loading={this.state.saving}
						/>
					</div>
					<div className="column is-flex pull-right">
						<Switch
							className="config-switch"
							id="lockCandidateSwitch"
							value={isLocked}
							activeLabel="Locked"
							inactiveLabel="Unlocked"
							onValueChange={value => this.setLockStatus(value)}
						/>
					</div>
				</div>
				<div className="columns has-text-left add-margin-top">
					<div className="column is-full action-columns" />
				</div>
			</Fragment>
		);
	}

	renderFooter(formikBag) {
		return (
			<footer className="modal-card-foot">
				<div className="fullwidth">
					<button
						type="button"
						className="button is-rounded is-light"
						onClick={() => this.handleClickCancel()}>
						Close
					</button>
				</div>
			</footer>
		);
	}

	getInitialValues() {
		const { linkToken } = this.state.token;
		return {
			linkToken: candidatesStore.getCandidateProfileLink({
				token: linkToken,
				absolute: true
			})
		};
	}

	async copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(text);
		} else {
			navigator.clipboard.writeText(text).then(
				() => {
					notificationStore.pushSuccessNotification(
						'Copy private profile link',
						"The candidate's private profile link was copied to your clipboard."
					);
				},
				error => {
					console.error(error);
					this.onCopyTextToClipboardError();
				}
			);
		}
	}

	fallbackCopyTextToClipboard(text) {
		var textArea = document.createElement('textarea');
		textArea.value = text;
		textArea.style.position = 'fixed'; //avoid scrolling to bottom
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		try {
			const result = document.execCommand('copy');
			if (result) {
				notificationStore.pushSuccessNotification(
					'Copy private profile link',
					"The candidate's private profile link was copied to your clipboard."
				);
			}
		} catch (err) {
			console.error(err);
			this.onCopyTextToClipboardError();
		}
		document.body.removeChild(textArea);
	}

	async save() {
		const tokenResponse = await candidatesStore.generateLinkToken(
			this.props.candidate.id,
			{ forceRenewal: true }
		);
		this.setState({
			token: tokenResponse.data,
			lockEndDate: tokenResponse.data.lockEndDate
		});
		this.setState({ saving: false });
	}

	async setLockStatus(value) {
		const {
			data: { lockEndDate }
		} = await candidatesStore.setLockStatus(this.props.candidate.id, { value });
		this.setState({ saving: false, lockEndDate });
	}
}
