import { EntityStore } from '../../../shared/stores/EntityStore';
import { action, makeObservable } from 'mobx';
import { api } from '../../../shared/api/api';

class CurrencyStore extends EntityStore {
	constructor() {
		super('currencies');
		makeObservable(this);
	}

	@action
	async getCurrencies(query) {
		const url = this.entityPluralEndpoint + '?q=' + query;

		return await api.get(encodeURI(url));
	}
}

export const currencyStore = new CurrencyStore();
