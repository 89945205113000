import React from 'react';
import { withRouter } from 'react-router-dom';
import { testResultsFilesStore } from './TestResultsFilesStore';
import { TestResultsFilesTable } from './TestResultsFilesTable';
import { Pagination } from '../layout/pagination/Pagination';
import { TestResultsFilesImport } from './TestResultsFilesImport';
import { ListStore } from '../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { ListHeaderComponent } from '../layout/list/ListHeaderComponent';
import { dialogStore } from '../layout/modals/DialogStore';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';

@rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER])
@withRouter
@observer
export class TestResultsFiles extends React.Component {
	constructor(props) {
		super(props);
		this.listStore = new ListStore(testResultsFilesStore, {
			entityName: 'Test Results File',
			createModalTitle: 'Import Test Results File ',
			createModal: (
				<TestResultsFilesImport handleSuccess={() => this.listStore.search()} />
			)
		});
	}

	componentDidMount() {
		const resetQuery = this.preSetQuery();
		this.listStore.search(resetQuery);
	}

	preSetQuery() {
		this.listStore.store.setQuery('sort', { column: 'createdAt', asc: true });
		this.listStore.store.setQuery(
			'pagination',
			this.listStore.store.defaultPagination
		);
		return false;
	}

	render() {
		return (
			<div className="TestResultsFiles container">
				<ListHeaderComponent
					title={`${this.listStore.modalsConfigs.entityName}s`}
					createActionTitle={'Upload Test Results File'}
					handleCreate={() => this.listStore.handleCreate()}
				/>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<TestResultsFilesTable
							data={this.listStore.data}
							sort={this.listStore.store.query.sort}
							onSort={this.listStore.handleSort}
							onEdit={this.handleUpdateWithoutModal}
							onDisable={item => this.listStore.handleDisable(item, true)}
							onDelete={this.handleDelete}
							onDownload={item => this.listStore.store.downloadFile(item)}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 bottom-pagination">
						<Pagination
							loading={this.listStore.loading}
							pagination={testResultsFilesStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
			</div>
		);
	}

	handleDelete = item => {
		dialogStore.openDialog(
			{
				title: 'Delete Test Result File',
				message: `Are you sure you want to delete ${item.filename}?`,
				confirmLabel: 'Delete',
				confirmButtonClass: 'is-danger'
			},
			() => {
				this.listStore.deleteFromDialog(item);
			}
		);
	};
}
