import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../layout/button/Button';
import { modalStore } from '../../layout/modals/ModalStore';
import { CandidateEdit } from './../CandidateEdit';
import { candidateProfileStore } from './CandidateProfileStore';
import { CandidateProfileTopSkills } from './top-skills/CandidateProfileTopSkills';
import { CandidateProfileRepos } from './repos/CandidateProfileRepos';
import { CandidateProfileTags } from './tags/CandidateProfileTags';
import { CandidateProfileImage } from './profile-image/CandidateProfileImage';
import { CandidateProfileScore } from './profile-score/CandidateProfileScore';
import { Switch } from '../../layout/form-fields/inputs/Switch';
import { Workshifts } from '../../timezones/Workshifts';
import moment from 'moment-timezone';
import { Icon } from './Icon';
import CandidateProfileShortBio from './CandidateProfileShortBio';
import { isMobile } from 'shared/utils';
import AnchorIcons from './AnchorIcons';
import { CandidateProfileLinkToken } from './link-token/CandidateProfileLinkToken';
import { candidatesStore } from '../CandidatesStore';

function CandidateProfileBio(props) {
	const [isIntersecting, setIsIntersecting] = useState(true);
	const [activeSection, setActiveSection] = useState();

	const bioRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsIntersecting(entry.isIntersecting);
			},
			{ rootMargin: isMobile() ? '-132px' : '-80px' }
		);
		observer.observe(bioRef.current);

		return () => observer.disconnect();
	}, []);

	useEffect(() => {
		const observerSections = new IntersectionObserver(
			entries => {
				const visibleSection = entries.find(
					entry => entry.isIntersecting
				)?.target;

				if (visibleSection) {
					setActiveSection(visibleSection.id);
				}
			},
			{ rootMargin: '-30% 0px -70% 0px' }
		);

		const experienceSection = document.getElementById('experience');
		const scorecardsSection = document.getElementById('scorecards');
		const technicalTestsSection = document.getElementById('technical-tests');
		const educationSection = document.getElementById('education');
		const certificationsSection = document.getElementById('certifications');

		if (experienceSection) {
			observerSections.observe(experienceSection);
		}

		if (scorecardsSection) {
			observerSections.observe(scorecardsSection);
		}

		if (technicalTestsSection) {
			observerSections.observe(technicalTestsSection);
		}

		if (educationSection) {
			observerSections.observe(educationSection);
		}

		if (certificationsSection) {
			observerSections.observe(certificationsSection);
		}

		return () => observerSections.disconnect();
	}, []);

	const handleAnchorClick = (
		e,
		sectionId,
		scrollOffset = isMobile() ? 132 : 80
	) => {
		e.preventDefault();
		if (activeSection !== sectionId) {
			setActiveSection(sectionId);
		}

		const yOffset = scrollOffset;

		const section = document.getElementById(sectionId);

		const y =
			section.getBoundingClientRect().top + document.body.scrollTop - yOffset;

		document.body.scrollTo({
			top: y,
			behavior: 'smooth'
		});
	};

	const renderCandidateWorkshift = () => {
		if (
			!(
				props.candidate.preferredWorkshifts &&
				props.candidate.preferredWorkshifts.length > 0
			)
		) {
			return;
		}
		return (
			<div className="workshift-container bio-subsection-wrapper">
				<div className="title is-inline-block">
					<Icon icon="fa-regular fa-clock" />
					Preferred Workshift
				</div>
				<div className="workshift-tags">
					<Workshifts
						showOnlyOptimalWorkshift={props.showOnlyOptimalWorkshift}
						candidate={props.candidate}
					/>
					<span className="workshift-timezone add-margin-left">
						{moment.tz.guess().replace('-', ' ')}
					</span>
				</div>
			</div>
		);
	};

	const candidate = props.candidate;

	const handleViewProfileClick = async () => {
		let tokenResponse = await candidatesStore.getLinkToken(candidate.id);

		if (!tokenResponse.data) {
			tokenResponse = await candidatesStore.generateLinkToken(candidate.id);
		}

		modalStore.openModal(
			{ title: 'Private URL', saveButtonLabel: 'Extend' },
			<CandidateProfileLinkToken
				token={tokenResponse.data}
				candidate={candidate}
				isNew={true}
				handleSuccess={() => {}}
			/>
		);
	};

	const config = candidate.candidateProfile;
	const showBio = true;

	return (
		<>
			<div id="header" className="bio-wrapper is-edit" ref={bioRef}>
				<div className="bio portal is-edit">
					<div className="bio-left">
						<CandidateProfileImage isEdit={true} candidate={props.candidate} />
						<AnchorIcons
							candidate={props.candidate}
							handleAnchorClick={handleAnchorClick}
							activeSection={activeSection}
						/>
					</div>
					<div className="bio-description-wrapper">
						<div className="name-container">
							<div
								className={`name has-edit-button ${
									!config.showPersonalInfo ? 'is-disabled' : ''
								}`}>
								<h1>{props.candidate.name}</h1>
								<Button
									title={'View Private URL'}
									className="button is-primary is-circle is-small is-rounded"
									icon={'eye'}
									onClick={handleViewProfileClick}
								/>
								<div className="edit-button-wrapper" title="Edit your name">
									<Button
										className="is-rounded is-small edit-button"
										icon="pencil-alt"
										iconClass="has-text-white"
										onClick={event => {
											modalStore.openModal(
												{ title: 'Edit Profile', position: 'center' },
												<CandidateEdit
													candidate={props.candidate}
													editAttribute={'name'}
													handleSuccess={() => {
														candidateProfileStore.reloadCandidate();
													}}
												/>
											);
										}}
									/>
									<Switch
										className="config-switch"
										id="personalInfoSwitch"
										value={config.showPersonalInfo}
										activeLabel="Show"
										inactiveLabel="Hide"
										onValueChange={value =>
											props.onConfigChange('showPersonalInfo', value)
										}
									/>
								</div>
							</div>
						</div>
						{showBio ? (
							<div className="aboutMe-container bio-subsection-wrapper">
								<div className="aboutMe">
									<h3
										className={`title is-5 has-edit-button ${
											!config.showAboutMe ? 'is-disabled' : ''
										}`}>
										<Icon icon="fa-regular fa-user" />
										Bio
										<div
											className="edit-button-wrapper about-me"
											title="Edit your bio">
											<Button
												className="is-rounded is-small edit-button"
												icon="pencil-alt"
												iconClass="has-text-white"
												onClick={event => {
													modalStore.openModal(
														{ title: 'Edit Bio', position: 'center' },
														<CandidateEdit
															candidate={props.candidate}
															editAttribute={'about'}
															handleSuccess={() => {
																candidateProfileStore.reloadCandidate();
															}}
														/>
													);
												}}
											/>
											<Switch
												className="config-switch"
												id="aboutMeSwitch"
												value={config.showAboutMe}
												activeLabel="Show"
												inactiveLabel="Hide"
												onValueChange={value =>
													props.onConfigChange('showAboutMe', value)
												}
											/>
										</div>
									</h3>
									<p>
										{props.candidate.aboutMe
											? props.candidate.aboutMe
											: 'Bio is empty.'}
									</p>
								</div>
							</div>
						) : null}
						<div className="top-skills-container bio-subsection-wrapper">
							<div
								className={`title is-inline-block has-edit-button ${
									!config.showSkills ? 'is-disabled' : ''
								}`}>
								<Icon icon="fa-solid fa-desktop" />
								Top Technologies
								<div
									className="edit-button-wrapper about-me"
									title="Show or hide skills">
									<Switch
										className="config-switch"
										id="topSkillsSwitch"
										value={config.showSkills}
										activeLabel="Show"
										inactiveLabel="Hide"
										onValueChange={value =>
											props.onConfigChange('showSkills', value)
										}
									/>
								</div>
							</div>
							{props.candidate.topSkills.length > 0 ? (
								<CandidateProfileTopSkills
									isDisabledStyle={!config.showSkills}
									skills={props.candidate.topSkills}
								/>
							) : (
								<div className="sub-section-content">
									<p>
										There are no skills listed in this candidate's profile yet.
									</p>
								</div>
							)}
						</div>
						<div className="is-flex">
							{renderCandidateWorkshift()}
							<div className="location-container bio-subsection-wrapper">
								<div
									className={`title is-inline-block has-edit-button ${
										!config.showLocation ? 'is-disabled' : ''
									}`}>
									<Icon icon="fa-solid fa-map-marker-alt" />
									Location
									<div
										className="edit-button-wrapper"
										title="Edit yur location">
										<Button
											className="is-rounded is-small edit-button"
											icon="pencil-alt"
											iconClass="has-text-white"
											onClick={event => {
												modalStore.openModal(
													{ title: 'Edit Location', position: 'center' },
													<CandidateEdit
														candidate={props.candidate}
														editAttribute={'location'}
														handleSuccess={() => {
															candidateProfileStore.reloadCandidate();
														}}
													/>
												);
											}}
										/>
										<Switch
											className="config-switch"
											id="locationSwitch"
											value={config.showLocation}
											activeLabel="Show"
											inactiveLabel="Hide"
											onValueChange={value =>
												props.onConfigChange('showLocation', value)
											}
										/>
									</div>
								</div>
								<div>
									<span>
										{props.candidate.country
											? props.candidate.country.name
											: null}
									</span>
								</div>
							</div>
						</div>
						<CandidateProfileRepos
							onConfigChange={props.onConfigChange}
							candidate={props.candidate}
							repos={props.candidate.candidateRepos}
							isEdit={true}
						/>
						<CandidateProfileTags
							onConfigChange={props.onConfigChange}
							candidate={props.candidate}
							tags={props.candidate.candidateTags}
							isEdit={true}
						/>
						<CandidateProfileScore
							candidate={props.candidate}
							isEdit={true}
							handleSuccessPublish={() => {
								candidateProfileStore.reloadCandidate();
							}}
						/>
					</div>
				</div>
			</div>
			<CandidateProfileShortBio
				candidate={candidate}
				isIntersecting={isIntersecting}
				handleAnchorClick={(e, sectionId) =>
					handleAnchorClick(e, sectionId, isMobile() ? 252 : 200)
				}
				activeSection={activeSection}
			/>
		</>
	);
}

export { CandidateProfileBio };
