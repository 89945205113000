import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { CandidatePortal } from '../candidate-portal/CandidatePortal';

@withRouter
@observer
export class CandidateApp extends React.Component {
	render() {
		return <CandidatePortal basename="/candidate-portal" />;
	}
}
