import * as React from 'react';
import { createEmptyCircle, emptyCircle } from './utils';
import { reducer } from './reducer';
import './style.css';
import _ from 'lodash';

function DynamicCircle({
	rating,
	outlined,
	outlineWidth,
	sharpnessCircle = 2.5,
	totalCircles = 5,
	width = 100,
	height = 100,
	emptyCircleColor = 'transparent',
	fullCircleColor = '#FFBC00'
}) {
	const id = _.uniqueId('circle');
	const internalTotalCircles = totalCircles < 0 ? 0 : totalCircles;
	const [circles, dispatch] = React.useReducer(
		reducer,
		Array(internalTotalCircles).fill(createEmptyCircle())
	);

	const getFullFillColor = circleData =>
		circleData.raw !== emptyCircle ? fullCircleColor : emptyCircleColor;

	const calcCirclePoints = (centerX, centerY, radius, numPoints) => {
		let points = '';
		const angleIncrement = (2 * Math.PI) / numPoints;

		for (let i = 0; i < numPoints; i++) {
			const currX = centerX + Math.cos(i * angleIncrement) * radius;
			const currY = centerY + Math.sin(i * angleIncrement) * radius;
			points += currX + ',' + currY + ' ';
		}

		return points;
	};

	const getCirclePoints = () => {
		const centerX = width / 2;
		const centerY = width / 2;
		const radius = width / 2;
		const numPoints = 100;

		return calcCirclePoints(centerX, centerY, radius, numPoints);
	};

	const getStrokeStyle = () => {
		if (typeof outlined === 'string') {
			return outlined;
		} else if (outlined) {
			return fullCircleColor;
		} else {
			return 'none';
		}
	};

	/**
	 * Responsible to remove a circle when circle count changes.
	 */
	React.useEffect(() => {
		const removeCircles = internalTotalCircles - circles.length;
		if (removeCircles < 0) {
			dispatch({
				type: 'REMOVE_CIRCLE',
				payload: removeCircles
			});
		}
	}, [internalTotalCircles, circles.length]);

	/**
	 * Responsible to add a new circle when circle count changes.
	 */
	React.useEffect(() => {
		const addCircles = internalTotalCircles - circles.length;
		if (addCircles > 0) {
			dispatch({
				type: 'ADD_CIRCLE',
				payload: addCircles
			});
		}
	}, [internalTotalCircles, circles.length]);

	/**
	 * Responsible to fill circles
	 */
	React.useEffect(() => {
		dispatch({
			type: 'FILL_CIRCLE',
			payload: typeof rating === 'string' ? parseFloat(rating) : rating
		});
	}, [rating, circles.length]);

	return (
		<div className="dynamic-circle-rating" aria-label={`${rating} of 5`}>
			{circles.map((circle, index) => (
				<div key={`${id}_${index}`} className="dynamic-circle-container">
					<svg
						className="dynamic-circle-svg"
						style={{
							fill: `url(#${id}_gradient${circle.raw})`,
							stroke: getStrokeStyle(),
							strokeWidth: outlineWidth ?? 'unset',
							width,
							height
						}}
						aria-hidden="true">
						<polygon points={getCirclePoints()} fillRule="nonzero" />
						<defs>
							{/* id has to be unique to each circle fullness(dynamic offset) - it indicates fullness above */}
							<linearGradient id={`${id}_gradient${circle.raw}`}>
								<stop
									id="stop1"
									offset={circle.percent}
									stopOpacity="1"
									stopColor={getFullFillColor(circle)}
								/>
								<stop
									id="stop2"
									offset={circle.percent}
									stopOpacity="0"
									stopColor={getFullFillColor(circle)}
								/>
								<stop
									id="stop3"
									offset={circle.percent}
									stopOpacity="1"
									stopColor={emptyCircleColor}
								/>
								<stop
									id="stop4"
									offset="100%"
									stopOpacity="1"
									stopColor={emptyCircleColor}
								/>
							</linearGradient>
						</defs>
					</svg>
				</div>
			))}
		</div>
	);
}

const MemoizedComponent = React.memo(DynamicCircle);

export { MemoizedComponent as DynamicCircle };
