import _ from 'lodash';
import { DragSource, DropTarget } from 'react-dnd';
import React from 'react';
import { Button } from '../../../layout/button/Button';
import { CandidateProfileRepo } from './CandidateProfileRepo';

export class RepoDraggableItem extends React.Component {
	render() {
		const {
			repo,
			isDragging,
			connectDragSource,
			connectDropTarget,
			isEditing
		} = this.props;

		return connectDragSource(
			connectDropTarget(
				<div
					className={`repo-wrapper ${isDragging ? 'is-dragging' : ''} ${
						isEditing ? 'is-editing' : ''
					}`}
					key={repo.index}>
					<div className="is-pulled-right button-group">
						<Button
							disabled={isEditing}
							className="is-rounded is-small edit-button"
							icon="trash"
							iconClass="has-text-danger"
							onClick={event => {
								this.props.onDelete(repo);
							}}
						/>
						<Button
							disabled={isEditing}
							className="is-middle is-small edit-button"
							icon="pencil-alt"
							iconClass="has-text-grey"
							onClick={event => {
								this.props.onEdit(repo);
							}}
						/>
						<Button
							disabled={isEditing}
							title="Drag & Drop to sort"
							className="is-rounded is-small edit-button is-move-cursor"
							icon="bars"
						/>
					</div>
					<CandidateProfileRepo key={repo.index} repo={repo} />
				</div>
			)
		);
	}
}

const source = {
	beginDrag(props) {
		// props.onDrag();
		return {
			index: props.index
		};
	}
};

const target = {
	hover(props, monitor) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;
		if (dragIndex === hoverIndex) {
			return;
		}
		props.onMove(dragIndex, hoverIndex);
		monitor.getItem().index = hoverIndex;
	}
};

export default _.flow(
	DropTarget('item', target, connect => ({
		connectDropTarget: connect.dropTarget()
	})),
	DragSource('item', source, (connect, monitor) => ({
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging()
	}))
)(RepoDraggableItem);
