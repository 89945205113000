import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PublicHeader } from '../components/layout/header/PublicHeader';
import LoginButton from '../components/login/LoginButton';

@withRouter
export class LoginPage extends React.Component {
	render() {
		return (
			<Fragment>
				<div className="wizard login">
					<PublicHeader />
					<section className="hero login">
						<div className="hero-body">
							<img
								className="is-hidden-mobile"
								src="/newHome/hero/hero-login-2-bg.jpg"
								alt="Hero"
							/>
							<div className="container has-text-centered">
								<h1 className="title is-2">Welcome Back!</h1>
								<h2 className="subtitle is-6">
									Sign in or{' '}
									<Link className="is-secondary-night-blue" to="/signup">
										create an account
									</Link>
								</h2>
								<div className="add-lg-padding-top">
									<LoginButton />
								</div>
							</div>
						</div>
						<div className="is-hidden"></div>
					</section>
				</div>
			</Fragment>
		);
	}
}
