import React from 'react';
import { candidateScorecardStore } from 'components/candidates/profile/scorecards/CandidateScorecardStore';
import { CandidateScorecardsView } from 'candidate-public-profile/components/sections/scorecards-view/CandidateScorecardsView';
import { CandidateProfileCertifications } from './sections/CandidateProfileCertifications';
import { CandidateProfileEducation } from './sections/CandidateProfileEducation';
import { CandidateProfileExperience } from './sections/CandidateProfileExperience';
import { CandidatePublicProfileTestResults } from './sections/CandidatePublicProfileTestResults';
import { Icon } from './Icon';

const TABS = {
	EXPERIENCE: 0,
	EDUCATION: 1,
	CERTIFICATIONS: 2,
	TECHNICAL_TESTS: 3,
	SCORECARDS: 4,
	WORK_PREFERENCES: 5,
	SELF_ASSESSMENT: 6,
	CONTACT_INFO: 7,
	COMPENSATION: 8,
	CHANGELOG: 9
};

export class CandidateProfileSections extends React.Component {
	state = {
		activeTab: null
	};
	sectionsConfigurations = {
		[TABS.EXPERIENCE]: 'showExperience',
		[TABS.EDUCATION]: 'showEducation',
		[TABS.CERTIFICATIONS]: 'showCertifications',
		[TABS.TECHNICAL_TESTS]: 'showTestResults',
		[TABS.SCORECARDS]: 'showScorecards'
	};
	componentDidMount() {
		const {
			candidateExperiences,
			candidateEducations,
			candidateCertifications,
			candidateTestResults,
			candidateScorecards
		} = this.props.candidate;

		if (candidateExperiences.length > 0) {
			this.setState({ activeTab: TABS.EXPERIENCE });
		} else if (candidateEducations.length > 0) {
			this.setState({ activeTab: TABS.EDUCATION });
		} else if (candidateCertifications.length > 0) {
			this.setState({ activeTab: TABS.CERTIFICATIONS });
		} else if (candidateTestResults.length > 0) {
			this.setState({ activeTab: TABS.TECHNICAL_TESTS });
		} else if (candidateScorecards.length > 0) {
			this.setState({ activeTab: TABS.SCORECARDS });
		}
	}

	/* eslint-disable indent */
	render() {
		const { candidate } = this.props;
		const {
			candidateExperiences,
			candidateEducations,
			candidateCertifications,
			candidateTestResults,
			candidateScorecards
		} = candidate;

		return (
			<div className={'panels is-public-profile'}>
				<div className="tabbed-panel">
					<div id="tabs-content-wrapper" className="tabs-content-wrapper">
						{candidateExperiences.length > 0 ? (
							<div
								className={'box tabbed-panel-content is-active'}
								id="experience">
								<div className="title-container">
									<Icon icon="fa-solid fa-briefcase" />
									<h2 className="title is-3">Experience</h2>
								</div>
								<CandidateProfileExperience
									experiences={candidateExperiences}
									onConfigChange={this.props.onExperienceConfigChange}
								/>
							</div>
						) : null}
						{candidateScorecards.length > 0 ? (
							<div
								className={'box is-scorecards tabbed-panel-content is-active'}
								id="scorecards">
								<div className="title-container">
									<Icon icon="fa-solid fa-gauge-simple-high" />
									<h2 className="title is-3">Scorecards</h2>
								</div>
								<CandidateScorecardsView
									candidate={candidate}
									scorecards={
										candidateScorecards || candidateScorecardStore.scorecards
									}
								/>
							</div>
						) : null}
						{candidateTestResults.length > 0 ? (
							<div
								className={'box is-test-results tabbed-panel-content is-active'}
								id="technical-tests">
								<div className="title-container">
									<Icon icon="fa-solid fa-chart-line" />
									<h2 className="title is-3">Technical Tests</h2>
								</div>
								<CandidatePublicProfileTestResults
									candidate={candidate}
									testResults={candidateTestResults}
								/>
							</div>
						) : null}
						{candidateEducations.length > 0 ? (
							<div
								className={
									'box tabbed-panel-content education-section is-active'
								}
								id="education">
								<div className="title-container">
									<Icon icon="fa-solid fa-graduation-cap" />
									<h2 className="title is-3">Education</h2>
								</div>
								<CandidateProfileEducation
									educations={candidateEducations}
									onConfigChange={this.props.onEducationConfigChange}
								/>
							</div>
						) : null}
						{candidateCertifications.length > 0 ? (
							<div
								className={
									'box tabbed-panel-content certification-section is-active'
								}
								id="certifications">
								<div className="title-container">
									<Icon icon="fa-solid fa-medal" />
									<h2 className="title is-3">Certifications</h2>
								</div>
								<CandidateProfileCertifications
									certifications={candidateCertifications}
									onConfigChange={this.props.onCertificationConfigChange}
								/>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}
