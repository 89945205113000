import { ModalComponent } from '../../../components/layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { candidatesStore } from '../CandidatesStore';
import { TestsDropdown } from '../../../components/tests/shared/TestsDropdown';
import { FieldRequiredIndicator } from '../../../components/layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({
	tests: Yup.array().min(1, 'Please select at least one Test').label('Tests')
});

export class CandidateProfileTestResultRequest extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(
						formikBag,
						'CandidateProfileTestResultRequest'
					)
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Tests <FieldRequiredIndicator />
							</label>
							<div className="control">
								<TestsDropdown
									value={formikBag.values.test}
									isMulti={true}
									placeholder={'Select one or more tests'}
									onValueChange={items => {
										formikBag.setFieldValue('tests', items);
									}}
									deduplicate
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		return {
			tests: null
		};
	}

	async save(values) {
		const testIds = values.tests.map(test => test.id);
		candidatesStore.createTestRequest({ testIds }).then(result => {
			this.resultNotification(
				result,
				null,
				null,
				'Request Test',
				'The request for taking a test assessment has been submitted. A representative will reach out soon.'
			);
		});
	}
}
