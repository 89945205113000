import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Dropdown } from '../../../components/layout/form-fields/inputs/Dropdown';
import { observer } from 'mobx-react';
import { UserMenu } from './user-menu/UserMenu';
import { candidatesStore } from '../../profile/CandidatesStore';
import { candidateProfileStore } from '../../profile/CandidateProfileStore';
import { isMobile } from '../../../shared/utils';

@withRouter
@observer
export class Header extends React.Component {
	componentDidMount() {
		this.loadCandidate();
	}

	componentWillUnmount() {
		candidateProfileStore.reset();
	}

	async loadCandidate() {
		const result = await candidatesStore.get();
		candidateProfileStore.setCandidate(result.data);
	}

	redirectHome() {
		this.props.history.push('/');
	}

	render() {
		const isLocked = candidateProfileStore.isLocked;

		return (
			<div className="Header">
				{isMobile() ? (
					<div className="warning-mobile-message">
						<p>This site is optimized for desktop devices.</p>
					</div>
				) : null}{' '}
				{isLocked ? (
					<div
						className={`warning-disable-editing-message ${
							isMobile() ? 'is-mobile' : ''
						}`}>
						<p>
							Your profile is being shared with a client so editing has been
							disabled. <br />
							If you would like to make changes, please reach out to{' '}
							<a href="mailto:talent.candidates@prediktive.com">
								talent.candidates@prediktive.com.
							</a>
						</p>
					</div>
				) : null}
				<nav className={`level ${isLocked ? 'is-locked' : ''}`}>
					<div className="level-left">
						<NavLink to={'/'} activeClassName="is-active">
							<div className="columns">
								<div className="column is-3 is-offset-1 logo prediktive">
									<img alt="logo" src="/logo.svg" />
								</div>
							</div>
						</NavLink>
					</div>
					<div className="level-right">
						{candidateProfileStore.candidate ? (
							<div className="level-item looking-for-job">
								<Dropdown
									enableFixedMode={true}
									value={candidateProfileStore.candidate.lookingForJob}
									styleVersion={`v2 ${this.getLookingForJobStatusStyle(
										candidateProfileStore.candidate.lookingForJob
									)}`}
									placeholder={'Select an option'}
									onValueChange={async value => {
										const result = await candidatesStore.update({
											candidate: {
												...candidateProfileStore.candidate,
												lookingForJob: value
											}
										});
										candidateProfileStore.setCandidate(result.data);
									}}
									options={candidatesStore.lookingForJobOptions}
									optionValue={'value'}
								/>
							</div>
						) : null}
						<div className="level-item">
							<UserMenu />
						</div>
					</div>
				</nav>
			</div>
		);
	}

	getLookingForJobStatusStyle(status) {
		const statusStyles = {
			yes: 'actively',
			no: 'notLooking',
			maybe: 'passively'
		};
		return statusStyles[status];
	}

	renderUserNotifications() {
		return (
			<div className="level-item">
				<span
					className="badge has-badge-rounded is-badge-danger"
					data-badge="10">
					<i className="fas fa-bell has-text-grey" />
					<span className="notification-separator" />
				</span>
			</div>
		);
	}
}
