import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { FormField, FormFieldTypes } from '../../layout/form-fields/FormField';
import { scorecardTemplateStore } from '../ScorecardTemplateStore';

const defaultValues = {
	name: ''
};

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name')
});

export class ScorecardTemplateCreateDialog extends ModalComponent {
	get isEdit() {
		return this.props.data !== undefined;
	}

	render() {
		return (
			<Formik
				initialValues={{
					...defaultValues,
					...this.props.data
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'ScorecardTemplateCreateDialog')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<div className="columns">
				<div className="column is-full">
					<FormField
						key="name"
						required
						className="has-text-left"
						label="Name"
						field="name"
						type={FormFieldTypes.TEXT}
						placeholder=""
						autoFocus={true}
					/>
				</div>
			</div>
		);
	}

	async save(values) {
		if (this.isEdit) {
			return scorecardTemplateStore
				.update(this.props.data.id, {
					scorecardTemplate: values
				})
				.then(result => {
					this.closeWithSuccess(result);

					return result;
				});
		} else {
			return scorecardTemplateStore.create(values).then(result => {
				this.closeWithSuccess(result);

				return result;
			});
		}
	}
}
