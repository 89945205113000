import { Icon } from '../../../layout/misc/Icon';
import React from 'react';

export class CandidateProfileRepo extends React.Component {
	render() {
		const { showIcon = true } = this.props;

		return (
			<div className="CandidateProfileRepo">
				{!showIcon ? null : this.props.repo.repoKey === 'github' ? (
					<Icon fab icon={'fab fa-github'} />
				) : (
					<Icon icon={'fa-globe-americas'} />
				)}
				{this.props.asLink ? (
					<a
						target="_blank"
						rel="noopener noreferrer"
						href={this.props.repo.url}>
						{this.props.repo.url}
					</a>
				) : (
					<span className="url-text">{this.props.repo.url}</span>
				)}
			</div>
		);
	}
}
