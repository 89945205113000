import { action, observable, runInAction, makeObservable } from 'mobx';
import { modalStore } from '../../components/layout/modals/ModalStore';
import { dialogStore } from '../../components/layout/modals/DialogStore';
import { notificationStore } from '../../components/layout/notifications/NotificationStore';

/**
 * TODO: this is not an actual store, this is a helper and should be rename to *Helper.
 * TODO: also rename it's instance usage on components as *helper
 */
export class ListStore {
	@observable.ref data = [];
	@observable loading = false;

	store = null;
	modalsConfigs = {
		entityName: '',
		createModalTitle: null,
		createModal: null,
		editModal: null,
		editModalTitle: null,
		apiObjectName: null
	};

	constructor(store, modalsConfigs) {
		makeObservable(this);
		this.store = store;
		this.modalsConfigs = modalsConfigs;
	}

	@action
	async search(reset = false) {
		this.loading = true;
		const result = await this.store.search(reset);
		runInAction(() => {
			this.data = result.data.rows;
			this.loading = false;
		});
	}

	handleCreate() {
		modalStore.openModal(
			{
				title: `${
					this.modalsConfigs.createModalTitle
						? this.modalsConfigs.createModalTitle
						: `Create ${this.modalsConfigs.entityName}`
				}`
			},
			this.modalsConfigs.createModal
		);
	}

	handleEdit = item => {
		modalStore.openModal(
			{
				title: `${
					this.modalsConfigs.editModalTitle
						? this.modalsConfigs.editModalTitle
						: `Edit ${this.modalsConfigs.entityName}`
				}`
			},
			this.modalsConfigs.editModal(item)
		);
	};

	handleDelete = (item, onSuccess, isFromDialog = false) => {
		dialogStore.openDialog(
			{
				title: `Delete ${this.modalsConfigs.entityName}`,
				message: `Are you sure you want to delete this ${this.modalsConfigs.entityName}?`
			},
			() => this.delete(item, onSuccess, null, isFromDialog)
		);
	};

	handleImport() {
		modalStore.openModal(
			{
				title: `${
					this.modalsConfigs.ImportModalTitle
						? this.modalsConfigs.ImportModalTitle
						: `Import ${this.modalsConfigs.entityName}`
				}`
			},
			this.modalsConfigs.ImportModal
		);
	}

	async deleteFromDialog(item, onSuccess, onFailure = null) {
		return this.delete(item, onSuccess, onFailure, true);
	}

	handleUpdateWithoutModal = (item, data, onSuccess, onFailure = null) => {
		this.store.update(item.id, data).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					`${this.modalsConfigs.entityName} Updated`,
					`The ${this.modalsConfigs.entityName} has been successfully updated.`
				);
				if (onSuccess) {
					onSuccess(item);
					return;
				}
				this.search();
				return;
			}
			if (onFailure) {
				onFailure(item);
				return;
			}
			notificationStore.pushErrorNotification(
				'Updated Failed',
				`Failed to update the ${this.modalsConfigs.entityName}.`
			);
		});
	};

	async delete(item, onSuccess, onFailure = null, isFromDialog = false) {
		if (isFromDialog) {
			dialogStore.setLoading(true);
		}
		return this.store.delete(item.id).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					`${this.modalsConfigs.entityName} Deleted`,
					`The ${this.modalsConfigs.entityName} has been successfully deleted.`
				);
				if (isFromDialog) {
					dialogStore.closeDialog();
				}
				if (onSuccess) {
					onSuccess(item);
				} else {
					this.search();
				}
				return true;
			} else {
				if (onFailure) {
					onFailure(item, result);
				} else {
					notificationStore.pushErrorNotification(
						'Delete Failed',
						`Failed to delete the ${this.modalsConfigs.entityName}.`
					);
				}
			}
		});
	}

	handleDisable = (item, isFromDialog = false) => {
		return new Promise((resolve, reject) => {
			dialogStore.openDialog(
				{
					title: `${item.disabled ? 'Enable' : 'Disable'} ${
						this.modalsConfigs.entityName
					}`,
					message: `Are you sure you want to ${
						item.disabled ? 'enable' : 'disable'
					} this ${this.modalsConfigs.entityName}?`
				},
				() => resolve(this.disable(item, isFromDialog)),
				() => resolve(false)
			);
		});
	};

	async disable(item, isFromDialog = false) {
		if (isFromDialog) {
			dialogStore.setLoading(true);
		}
		item.disabled = !item.disabled;
		let apiObjectName =
			this.modalsConfigs.apiObjectName ||
			this.modalsConfigs.entityName.toLowerCase();
		return await this.store
			.update(item.id, { [apiObjectName]: item })
			.then(result => {
				if (isFromDialog) {
					dialogStore.closeDialog();
				}
				if (
					result.status === 'success' &&
					result.data.disabled === item.disabled
				) {
					notificationStore.pushSuccessNotification(
						`${this.modalsConfigs.entityName} ${
							item.disabled ? 'Disabled' : 'Enabled'
						}`,
						`The ${this.modalsConfigs.entityName} has been successfully ${
							item.disabled ? 'disabled' : 'enabled'
						}.`
					);
					this.search();
					return true;
				} else {
					notificationStore.pushErrorNotification(
						'Disable Failed',
						`Failed to ${item.disabled ? 'enabled' : 'disable'} ${
							this.modalsConfigs.entityName
						}.`
					);
					this.search();
					return false;
				}
			});
	}

	handleSort = column => {
		let asc = true;
		if (this.store.query.sort && Array.isArray(this.store.query.sort)) {
			let match = this.store.query.sort.find(item => item.column === column);
			if (match && !match.hideIndicator) {
				asc = !match.asc;
			}
		} else if (
			this.store.query.sort &&
			this.store.query.sort.column === column
		) {
			asc = !this.store.query.sort.asc;
		}
		this.store.setQuery('sort', { column: column, asc: asc });
		this.search();
	};

	handlePaginationChange = (page, limit) => {
		this.store.setQuery('pagination', { page: page, limit: limit });
		this.search();
	};
}
