import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormField } from '../layout/form-fields/FormField';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { Switch } from '../layout/form-fields/inputs/Switch';
import { clientStore } from './ClientStore';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	email: Yup.string().required().email().label('Email')
});

export class ClientEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					name: this.props.client.name,
					email: this.props.client.email,
					disabled: this.props.client.disabled
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'ClientEdit')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-10">
						<FormField
							required
							className="has-text-left"
							label="Email"
							field="email"
							type="text"
							placeholder="email@domain.com"
							icon="fa-envelope"
						/>
					</div>
					<div className="column is-2">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								{!formikBag.values.disabled ? 'Enabled' : 'Disabled'}
							</label>
							<div className="control">
								<Switch
									id="disabled-switch"
									value={!formikBag.values.disabled}
									onValueChange={value => {
										formikBag.setFieldValue('disabled', !value);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	async save(values) {
		const client = {
			name: values.name,
			email: values.email,
			disabled: values.disabled,
			id: this.props.client.id
		};
		clientStore
			.update(this.props.client.id, { client: client })
			.then(result => {
				if (result.status === 'success') {
					notificationStore.pushSuccessNotification(
						'Edit Client',
						'The client has been successfully updated.'
					);
					this.closeWithSuccess();
				} else {
					notificationStore.pushErrorNotification(
						'',
						'Failed to update the user.'
					);
				}
			});
	}
}
