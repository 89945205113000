import { CandidateProfileImage } from 'components/candidates/profile/profile-image/CandidateProfileImage';
import React from 'react';
import AnchorIcons from './AnchorIcons';

const CandidatePublicProfileShortBio = React.forwardRef(
	({ candidate, handleAnchorClick, activeSection }, ref) => {
		return (
			<div className="short-bio-wrapper hidden" ref={ref}>
				<div className="short-bio">
					<CandidateProfileImage candidate={candidate} />
					<div className="name-container">
						<h2 onClick={e => handleAnchorClick(e, 'header')}>
							{candidate.name}
						</h2>
						<AnchorIcons
							candidate={candidate}
							handleAnchorClick={handleAnchorClick}
							activeSection={activeSection}
						/>
					</div>
				</div>
			</div>
		);
	}
);

export default CandidatePublicProfileShortBio;
