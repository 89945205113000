import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../misc/Icon';

export class Unauthorized extends React.Component {
	render() {
		return (
			<div className="Unauthorized">
				<div className="title is-4">
					<p>
						<Icon icon="fa-exclamation-circle"></Icon>
					</p>
					<p>You don't have access to this page.</p>
					<p>
						Go back
						<Link className="button is-primary" to="/">
							Home
						</Link>
					</p>
				</div>
			</div>
		);
	}
}
