import { Button } from '../../../layout/button/Button';
import { modalStore } from '../../../layout/modals/ModalStore';
import { CandidateProfileTagsEdit } from './CandidateProfileTagsEdit';
import { candidateProfileStore } from '../CandidateProfileStore';
import React from 'react';
import { Icon } from '../Icon';

export class CandidateProfileTags extends React.Component {
	render() {
		const tags = this.props.tags.map(item => {
			return { ...item.tag };
		});
		return (
			<div
				className={`bio-subsection-wrapper CandidateProfileTags ${
					this.props.isEdit ? 'is-disabled' : ''
				}`}>
				<div className="title ">
					<div className="repos-container is-inline-block has-edit-button">
						<Icon icon="fa-solid fa-tags" />
						<span>Tags</span>
						<div className="edit-button-wrapper" title="Edit your repos">
							{this.props.isEdit && (
								<Button
									className="is-rounded is-small edit-button"
									icon="pencil-alt"
									iconClass="has-text-white"
									onClick={event => {
										modalStore.openModal(
											{ title: 'Edit Candidate Tags', position: 'center' },
											<CandidateProfileTagsEdit
												candidate={this.props.candidate}
												tags={tags}
												handleSuccess={() => {
													candidateProfileStore.reloadCandidate();
												}}
											/>
										);
									}}
								/>
							)}
						</div>
					</div>
				</div>
				{tags.length > 0 ? (
					<div className="sub-section">
						<div className="sub-section-content">
							{tags.map(tag => {
								return (
									<span key={tag.id} className="tag is-red add-margin-right">
										{tag.name}
									</span>
								);
							})}
						</div>
					</div>
				) : (
					<div className="sub-section-content">
						<p>This candidate has no tags yet.</p>
					</div>
				)}
			</div>
		);
	}
}
