import React from 'react';
import { withRouter } from 'react-router-dom';
import { testStore } from './TestStore';
import { TestsTable } from './TestsTable';
import { InputField } from '../layout/form-fields/inputs/InputField';
import { Pagination } from '../layout/pagination/Pagination';
import { TestEdit } from './TestEdit';
import { ListStore } from '../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { ListHeaderTestComponent } from '../layout/list/ListHeaderTestComponent';
import { dialogStore } from '../layout/modals/DialogStore';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';

@rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER])
@withRouter
@observer
export class Tests extends React.Component {
	constructor(props) {
		super(props);
		this.listStore = new ListStore(testStore, {
			entityName: 'Test',
			createModalTitle: 'Create Test',
			createModal: (
				<TestEdit isNew={true} handleSuccess={() => this.listStore.search()} />
			),
			editModal: item => (
				<TestEdit
					isNew={false}
					test={item}
					handleSuccess={() => this.listStore.search()}
				/>
			)
		});
	}

	componentDidMount() {
		const resetQuery = this.preSetQuery();
		this.listStore.search(resetQuery);
	}

	preSetQuery() {
		this.listStore.store.setQuery('sort', { column: 'name', asc: true });
		this.listStore.store.setQuery(
			'pagination',
			this.listStore.store.defaultPagination
		);
		return false;
	}

	render() {
		return (
			<div className="Tests container">
				<ListHeaderTestComponent
					title={`${this.listStore.modalsConfigs.entityName}s`}
					createActionTitle={this.listStore.modalsConfigs.createModalTitle}
					handleCreate={() => this.listStore.handleCreate()}
					handleProcessResults={() => this.listStore.search()}
				/>
				<div className="columns">
					<div className="column is-3">
						<InputField
							className="has-text-left"
							field="search"
							type="search"
							debounce={true}
							debounceTime={400}
							placeholder="Name"
							icon="fa-search"
							onValueChange={value => {
								testStore.setQuery('text', value);
								this.listStore.search();
							}}
						/>
					</div>
					<div className="column is-9">
						<Pagination
							loading={this.listStore.loading}
							pagination={testStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<TestsTable
							data={this.listStore.data}
							sort={this.listStore.store.query.sort}
							onSort={this.listStore.handleSort}
							onEdit={this.listStore.handleEdit}
							onDisable={item => this.listStore.handleDisable(item, true)}
							onDelete={this.handleDelete}
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 bottom-pagination">
						<Pagination
							loading={this.listStore.loading}
							pagination={testStore.query.pagination}
							onPaginationChange={this.listStore.handlePaginationChange}
						/>
					</div>
				</div>
			</div>
		);
	}

	handleDelete = item => {
		dialogStore.openDialog(
			{
				title: 'Delete Test',
				message: `Are you sure you want to delete ${item.name}?`,
				confirmLabel: 'Delete',
				confirmButtonClass: 'is-danger'
			},
			() => {
				this.listStore.deleteFromDialog(item);
			}
		);
	};
}
