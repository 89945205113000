// Hardcoded list of score range filters
export const SCORE_FILTER_OPTIONS = [
	{ text: 'Score', value: '' },
	{ text: '0-9%', value: '0,9' },
	{ text: '10-19%', value: '10,19' },
	{ text: '20-29%', value: '20,29' },
	{ text: '30-39%', value: '30,39' },
	{ text: '40-49%', value: '40,49' },
	{ text: '50-59%', value: '50,59' },
	{ text: '60-69%', value: '60,69' },
	{ text: '70-79%', value: '70,79' },
	{ text: '80-89%', value: '80,89' },
	{ text: '90-99%', value: '90,99' },
	{ text: '100%', value: '100,100' }
];

// Hardcoded list of Created_on filters
export const CREATED_ON_FILTER_OPTIONS = [
	{ text: 'Any time', value: '' },
	{ text: 'Past week', value: '7' },
	{ text: 'Past two weeks', value: '14' },
	{ text: 'Past month', value: '30' },
	{ text: 'Past six months', value: '180' },
	{ text: 'Past year', value: '365' }
];
