import * as Yup from 'yup';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { Formik } from 'formik';
import { FormField } from '../layout/form-fields/FormField';
import React, { Fragment } from 'react';
import { jobOpeningStore } from './JobOpeningStore';
import { clientStore } from '../clients/ClientStore';
import Select from 'react-select';
import { FormFieldError } from '../layout/form-fields/FormFieldError';
import { FieldRequiredIndicator } from '../layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({
	title: Yup.string().required().label('Title'),
	atsLink: Yup.string().url().label('ATS Link'),
	clientId: Yup.string().required().label('Client')
});

export class JobOpeningCreate extends ModalComponent {
	state = {
		clients: []
	};

	componentDidMount() {
		clientStore.getAll().then(result => {
			if (result && result.data) {
				const clients = result.data
					.filter(item => item.disabled === false)
					.map(item => {
						return { value: item.id, label: item.name };
					});
				this.setState({ clients: clients });
			}
		});
	}

	getInitialValues() {
		let selectedClient = '';
		if (this.props.job && this.props.job.client) {
			selectedClient = {
				value: this.props.job.client.id,
				label: this.props.job.client.name
			};
		}
		return {
			title: '',
			atsLink: '',
			clientId:
				this.props.job && this.props.job.clientId
					? this.props.job.clientId
					: '',
			selectedClient: selectedClient
		};
	}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.create(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'JobOpeningCreate')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Title"
							field="title"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="ATS Link"
							field="atsLink"
							type="text"
							placeholder="ATS Link"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Client <FieldRequiredIndicator />
							</label>
							<div className="control">
								<Fragment>
									<Select
										onFocus={() => formikBag.setFieldTouched('clientId')}
										isDisabled={
											this.props.job &&
											this.props.job.client &&
											this.props.job.client.id !== null
										}
										field="clientId"
										value={formikBag.values.selectedClient}
										placeholder="Client"
										onChange={item => {
											formikBag.setFieldValue('selectedClient', item);
											formikBag.setFieldValue('clientId', item.value);
										}}
										options={this.state.clients}
										className="react-select"
										menuPosition="fixed"
									/>
									<FormFieldError field={'clientId'} />
								</Fragment>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	async create(values) {
		if (values.clientId === '') {
			values.clientId = null;
		}
		jobOpeningStore.create(values).then(result => {
			this.resultNotification(result, 'Job Opening', 'Create');
		});
	}
}
