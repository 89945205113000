import { withRouter } from 'react-router-dom';
import React from 'react';
import { InputField } from '../../layout/form-fields/inputs/InputField';
import { recruiterStore } from './RecruiterStore';
import { RecruiterEdit } from './RecruiterEdit';
import { RecruiterCreate } from './RecruiterCreate';
import { ListStore } from '../../../shared/stores/ListStore';
import { observer } from 'mobx-react';
import { Loader } from '../../layout/misc/Loader';
import { Icon } from '../../layout/misc/Icon';
import {
	rolesAllowed,
	USER_ROLES
} from '../../../shared/security/RolesAllowed';
import { Dropdown } from '../../layout/form-fields/inputs/Dropdown';

const statusOptions = [
	{ text: 'Status', value: 'all' },
	{ text: 'Active', value: 'active' },
	{ text: 'Inactive', value: 'inactive' }
];

@rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER])
@withRouter
@observer
export class Recruiters extends React.Component {
	state = {
		data: [],
		filterText: '',
		filterStatus: 'active',
		loading: true
	};

	constructor(props) {
		super(props);
		this.listStore = new ListStore(recruiterStore, {
			entityName: 'Recruiter',
			createModal: (
				<RecruiterCreate
					handleSuccess={() => {
						this.search();
						this.setState({ filterText: '' });
					}}
				/>
			),
			editModal: item => (
				<RecruiterEdit
					recruiter={item}
					handleSuccess={() => {
						this.search();
						this.setState({ filterText: '' });
					}}
				/>
			)
		});
	}

	componentDidMount() {
		this.search();
	}

	search() {
		this.setState({ loading: true });
		const { filterText, filterStatus } = this.state;

		recruiterStore
			.getRecruiters({
				query: filterText,
				status: filterStatus
			})
			.then(result => {
				if (result.status === 'success' && result.data) {
					this.setState({ data: result.data, loading: false });
				}
			});
	}

	render() {
		return (
			<div className="Recruiters container">
				<div className="columns">
					<div className="column is-8">
						<InputField
							className="has-text-left"
							field="title"
							type="search"
							icon="fa-search"
							placeholder="Filter"
							value={this.state.filterText}
							onValueChange={value => {
								this.setState({ filterText: value }, () => {
									this.search();
								});
							}}
						/>
					</div>
					<div className="column is-2">
						<Dropdown
							enableFixedMode={true}
							value={this.state.filterStatus}
							placeholder="Status"
							onValueChange={value => {
								this.setState(
									{
										filterStatus: value
									},
									() => {
										this.search();
									}
								);
							}}
							options={statusOptions}
							optionValue={'value'}
						/>
					</div>
					<div className="column is-2">
						<button
							className="button is-primary is-rounded is-big"
							onClick={() => this.listStore.handleCreate()}>
							{`Create ${this.listStore.modalsConfigs.entityName}`}
						</button>
					</div>
				</div>
				<div className="columns">
					<div className="column is-12 table-wrapper">
						<table className="table is-fullwidth is-bordered is-hoverable">
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th style={{ width: '180px' }} />
								</tr>
							</thead>
							<tbody>
								{this.state.data.map(row => {
									const hasCandidates = row.candidatesCount > 0;

									return (
										<tr key={row.id} className={row.disabled ? 'disabled' : ''}>
											<td className="has-text-weight-bold">{row.name}</td>
											<td>{row.email}</td>
											<td className="actions-column">
												<button
													className="button is-rounded is-circle is-small is-text"
													onClick={() => this.listStore.handleEdit(row)}>
													<Icon className="is-small" icon="fa-pencil-alt" />
												</button>
												<button
													disabled={hasCandidates}
													className={`button is-rounded is-circle is-small is-text ${
														hasCandidates
															? 'tooltip is-tooltip-top disabled'
															: ''
													}`}
													data-tooltip={
														hasCandidates
															? 'One or more candidates are associates with this recruiter.'
															: null
													}
													onClick={() => {
														this.listStore.handleDelete(
															row,
															this.onDeleteSuccess,
															true
														);
													}}>
													<Icon className="is-small" icon="fa-trash" />
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						{this.state.loading ? <Loader type="ripple" /> : null}
					</div>
				</div>
			</div>
		);
	}

	onDeleteSuccess = item => {
		this.search();
		this.setState({ filterText: '' });
	};
}
