import React, { Fragment } from 'react';
import { modalStore } from '../layout/modals/ModalStore';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormField } from '../layout/form-fields/FormField';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { clientStore } from './ClientStore';

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	email: Yup.string().required().email().label('Email')
});

export class ClientCreate extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={{
					name: '',
					email: ''
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.create(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'ClientCreate')}
			</Formik>
		);
	}

	renderForm() {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Name"
							field="name"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Email"
							field="email"
							type="text"
							placeholder="email@domain.com"
							icon="fa-envelope"
						/>
					</div>
				</div>
			</Fragment>
		);
	}

	async create(values) {
		const { name, email } = values;
		clientStore.create({ name: name, email: email }).then(result => {
			if (result.status === 'success') {
				notificationStore.pushNotification({
					type: notificationStore.SUCCESS_NOTIFICATION,
					title: 'Create Client',
					message: 'The client has been successfully created.'
				});
				this.closeWithSuccess();
			} else {
				notificationStore.pushNotification({
					type: notificationStore.ERROR_NOTIFICATION,
					title: 'Create Client',
					message: 'An error has occurred. Please try again.'
				});
				modalStore.closeDialog();
			}
		});
	}
}
