import React from 'react';

const formatCurrency = (value, currencyCode, NumberFormatOptions) => {
	return new Intl.NumberFormat('en-us', {
		style: 'currency',
		currency: currencyCode,
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
		...NumberFormatOptions
	}).format(value);
};

export const Currency = ({
	value,
	code,
	NumberFormatOptions = {},
	...props
}) => {
	return (
		<span {...props}>{formatCurrency(value, code, NumberFormatOptions)}</span>
	);
};
