import React from 'react';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';
import { Provider, observer } from 'mobx-react';
import { AdminApp } from './AdminApp';
import { PublicApp } from './PublicApp';
import { CandidateApp } from './CandidateApp';
import { authStore, GOOGLE_CLIENT_ID } from '../shared/security/AuthStore';
import { Notifications } from '../components/layout/notifications/Notifications';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { GoogleOAuthProvider } from '@react-oauth/google';

@withRouter
@observer
class App extends React.Component {
	render() {
		const userIsAdmin =
			authStore.ready && authStore.profile && !authStore.isCandidate();
		const userIsCandidate =
			authStore.ready && authStore.profile && authStore.isCandidate();
		const nonLoggedUser = authStore.ready && authStore.profile == null;
		return (
			<Provider>
				<DndProvider backend={HTML5Backend}>
					<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
						<Notifications />
						{userIsAdmin ? <AdminApp /> : null}
						{userIsCandidate ? <CandidateApp /> : null}
						{nonLoggedUser ? <PublicApp /> : null}
					</GoogleOAuthProvider>
				</DndProvider>
			</Provider>
		);
	}

	componentDidMount() {
		this.initGA();
		authStore.initAuthentication();
	}

	initGA() {
		ReactGA.initialize(process.env.REACT_APP_GA_ID, {
			gaOptions: { page: this.props.history.location.pathname }
		});
		this.props.history.listen(location => {
			ReactGA.set({ page: location.pathname });
			ReactGA.send('pageview');
		});
	}
}
export default App;
