import React from 'react';
import { api } from '../../shared/api/api';
import { dialogStore } from '../../components/layout/modals/DialogStore';
import { notificationStore } from '../../components/layout/notifications/NotificationStore';
import { withRouter } from 'react-router-dom';
import { Button } from '../../components/layout/button/Button';
import { authStore } from '../../shared/security/AuthStore';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

function LoginButton({ label, history, render }) {
	const handleLoginSuccess = async tokenResponse => {
		const { data: userInfo } = await axios.get(
			'https://www.googleapis.com/oauth2/v3/userinfo',
			{
				headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
			}
		);

		const { email, sub: googleId, picture: imageUrl, name } = userInfo;
		loginUser(email, googleId, imageUrl, name);
	};

	const handleSignupClick = () => {
		history.push('/signup');
	};

	const loginUser = async (email, googleId, imageUrl, name) => {
		const data = await api.post('login', {
			email: email,
			googleID: googleId,
			imageUrl: imageUrl,
			name: name
		});

		if (data.token && data.profile) {
			authStore.setToken(data.token, data.profile);
		}

		if (['fail', 'user-not-found'].includes(data.status)) {
			dialogStore.openDialog(
				{
					title: "You don't have an account",
					message:
						"It seems like you don't have an account on Prediktive yet. Please sign-up to access our Talent Network",
					confirmLabel: 'Create account',
					confirmButtonClass: 'primary-v2',
					hideCancelButton: true
				},
				() => {
					handleSignupClick();
				}
			);
		} else if (data.status === 'deactivated-user') {
			dialogStore.openDialog({
				title: 'Your account is inactive',
				message: (
					<>
						Your Prediktive account was deactivated. If you want to reactivate
						your account contact us at{' '}
						<strong>
							<a href="mailto:talent.candidates@prediktive.com">
								talent.candidates@prediktive.com
							</a>
						</strong>
					</>
				),
				confirmLabel: 'Close',
				confirmButtonClass: 'primary-v2',
				hideCancelButton: true
			});
		} else {
			history.push('/');
		}
	};

	const handleLoginFailure = error => {
		/**
		 * 	"idpiframe_initialization_failed":	initialization of the Google Auth API failed (this will occur if a client doesn't have third party cookies enabled)
		 *	"popup_closed":	The user closed the popup before finishing the sign in flow.
		 *	"access_denied":	The user denied the permission to the scopes required
		 *	"immediate_failed":	No user could be automatically selected without prompting the consent flow.
		 */
		const errorMessages = {
			idpiframe_initialization_failed: [
				"Couldn't initialize Authentication API",
				'Please check if you have cookies enabled'
			],
			popup_closed: [
				'Authentication aborted',
				'It seems you accidentally closed the popup before the authentication was done, please try again.'
			],
			access_denied: [
				'Insufficient permissions',
				'Please accept the minimum required permissions'
			],
			immediate_failed: null // This one shouldn't trigger a notification
		};

		const errorMessage = errorMessages[error.error || error.type];

		if (errorMessage) {
			notificationStore.pushErrorNotification(...errorMessage);
		}
	};

	const login = useGoogleLogin({
		onSuccess: handleLoginSuccess,
		onError: handleLoginFailure,
		onNonOAuthError: handleLoginFailure,
		flow: 'implicit'
	});

	return render ? (
		render({ onClick: login })
	) : (
		<Button
			onClick={login}
			type="submit"
			className="button primary-v2 Button is-medium"
			label={label || 'Connect With Google'}
		/>
	);
}

export default withRouter(LoginButton);
