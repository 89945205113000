import React from 'react';
import { CandidateProfileExperience } from './experience/CandidateProfileExperience';
import { CandidateProfileEducation } from './education/CandidateProfileEducation';
import { CandidateProfileCertifications } from './certifications/CandidateProfileCertifications';
import { CandidateProfileTestResults } from './test-results/CandidateProfileTestResults';
import { Button } from '../../components/layout/button/Button';
import { modalStore } from '../../components/layout/modals/ModalStore';
import { CandidateProfileEducationEdit } from './education/CandidateProfileEducationEdit';
import { CandidateProfileTestResultRequest } from './test-results/CandidateProfileTestResultRequest';
import { CandidateProfileCertificationEdit } from './certifications/CandidateProfileCertificationEdit';
import { candidateProfileStore } from './CandidateProfileStore';
import { CandidateProfileExperienceEdit } from './experience/CandidateProfileExperienceEdit';
import { observer } from 'mobx-react';
import { Icon } from './Icon';

const TABS = {
	EXPERIENCE: 0,
	EDUCATION: 1,
	CERTIFICATIONS: 2,
	TECHNICAL_TESTS: 3
};

@observer
export class CandidateProfileSections extends React.Component {
	state = {
		activeTab: null
	};
	sectionsConfigurations = [
		'showExperience',
		'showEducation',
		'showCertifications',
		'showTestResults'
	];
	componentDidMount() {
		if (this.props.candidate.candidateExperiences.length > 0) {
			this.setState({ activeTab: 0 });
		} else if (this.props.candidate.candidateEducations.length > 0) {
			this.setState({ activeTab: 1 });
		} else if (this.props.candidate.candidateCertifications.length > 0) {
			this.setState({ activeTab: 2 });
		} else if (this.props.candidate.candidateTestResults.length > 0) {
			this.setState({ activeTab: 3 });
		} else {
			this.setState({ activeTab: 0 });
		}
	}

	renderSectionButtons(section) {
		if (candidateProfileStore.isLocked) {
			return;
		}

		if (section === TABS.TECHNICAL_TESTS) {
			return (
				<div className="panel-controls">
					<div className="tabbed-panel-option pull-right add-button">
						<a
							href={'void'}
							onClick={e => {
								this.handleAddClick(e, section);
							}}>
							<Button
								className="is-rounded is-circle is-xsmall is-blue"
								icon={'tasks'}
							/>
							<span>Request Tests</span>
						</a>
					</div>
				</div>
			);
		}
		return (
			<div className="panel-controls">
				<div className="tabbed-panel-option pull-right add-button">
					<a
						href={'void'}
						onClick={e => {
							this.handleAddClick(e, section);
						}}>
						<Button
							className="is-rounded is-circle is-xsmall is-blue"
							icon={'plus'}
						/>
						<span>Add</span>
					</a>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="panels">
				<div className="tabbed-panel">
					<div id="tabs-content-wrapper" className="tabs-content-wrapper">
						<div
							className={'box tabbed-panel-content is-active'}
							id="experience">
							<div className="title-container">
								<Icon className="section-icon" icon="fa-solid fa-briefcase" />
								<h2 className="title is-3">Experience</h2>
								{this.renderSectionButtons(TABS.EXPERIENCE)}
							</div>
							<CandidateProfileExperience
								experiences={this.props.candidate.candidateExperiences}
								isEdit={this.props.isEdit}
								onConfigChange={this.props.onExperienceConfigChange}
							/>
						</div>
						<div
							className={'box is-test-results tabbed-panel-content is-active'}
							id="technical-tests">
							<div className="title-container">
								<Icon className="section-icon" icon="fa-solid fa-chart-line" />
								<h2 className="title is-3">Technical Tests</h2>
								{this.renderSectionButtons(TABS.TECHNICAL_TESTS)}
							</div>
							<CandidateProfileTestResults
								candidate={this.props.candidate}
								testResults={this.props.candidate.candidateTestResults}
								isEdit={this.props.isEdit}
							/>
						</div>
						<div
							className={'box tabbed-panel-content education-section is-active'}
							id="education">
							<div className="title-container">
								<Icon
									className="section-icon"
									icon="fa-solid fa-graduation-cap"
								/>
								<h2 className="title is-3">Education</h2>
								{this.renderSectionButtons(TABS.EDUCATION)}
							</div>
							<CandidateProfileEducation
								educations={this.props.candidate.candidateEducations}
								isEdit={this.props.isEdit}
								onConfigChange={this.props.onEducationConfigChange}
							/>
						</div>
						<div
							className={
								'box tabbed-panel-content certification-section is-active'
							}
							id="certifications">
							<div className="title-container">
								<Icon className="section-icon" icon="fa-solid fa-medal" />
								<h2 className="title is-3">Certifications</h2>
								{this.renderSectionButtons(TABS.CERTIFICATIONS)}
							</div>
							<CandidateProfileCertifications
								certifications={this.props.candidate.candidateCertifications}
								isEdit={this.props.isEdit}
								onConfigChange={this.props.onCertificationConfigChange}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	handleAddClick(e, section) {
		e.preventDefault();
		const { candidate } = this.props;

		switch (section) {
			case TABS.EXPERIENCE:
				modalStore.openModal(
					{ title: 'New Experience', position: 'center', size: 'XL' },
					<CandidateProfileExperienceEdit
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			case TABS.EDUCATION:
				modalStore.openModal(
					{ title: 'New Education' },
					<CandidateProfileEducationEdit
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			case TABS.CERTIFICATIONS:
				modalStore.openModal(
					{ title: 'New Certification' },
					<CandidateProfileCertificationEdit
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			case TABS.TECHNICAL_TESTS:
				modalStore.openModal(
					{ title: 'Request Test(s)', saveButtonLabel: 'Request Tests' },
					<CandidateProfileTestResultRequest
						candidate={candidate}
						isNew={true}
						handleSuccess={() => {
							candidateProfileStore.reloadCandidate();
						}}
					/>
				);
				break;

			default:
				break;
		}
	}
}
