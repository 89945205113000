import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { Button } from '../../../layout/button/Button';
import { modalStore } from '../../../layout/modals/ModalStore';
import { candidateProfileStore } from '../CandidateProfileStore';
import { CandidateProfileTestResultEdit } from './CandidateProfileTestResultEdit';
import { CandidateProfileTestResultsCanvas } from './CandidateProfileTestResultsCanvas';
import { dialogStore } from '../../../layout/modals/DialogStore';
import { notificationStore } from '../../../layout/notifications/NotificationStore';
import { candidatesStore } from '../../CandidatesStore';
import { Switch } from '../../../layout/form-fields/inputs/Switch';
import { Icon } from '../Icon';

export class CandidateProfileTestResults extends React.Component {
	getGroupedTestResults(testResults) {
		if (!testResults) return [];
		const testResultsGroup = new Map();
		testResults.forEach(testResult => {
			const testName = testResult.test.name;
			if (!testResultsGroup.has(testName)) {
				testResultsGroup.set(testName, [testResult]);
				return;
			}

			const groupedTests = testResultsGroup.get(testName);
			groupedTests.push(testResult);
		});
		return [].concat(...testResultsGroup.values());
	}
	render() {
		const getNumberWithOrdinal = n => {
			const s = ['th', 'st', 'nd', 'rd'];
			const v = n % 100;
			return n + (s[(v - 20) % 10] || s[v] || s[0]);
		};
		const isTestResultAlreadyShown = testResultToCheck => {
			return !this.props.testResults.every(testResult => {
				const sameTestResult = testResult.id === testResultToCheck.id;
				const sameTestNameAndIsShown =
					testResult.test.name === testResultToCheck.test.name &&
					testResult.visible;
				const alreadyShown = !sameTestResult && sameTestNameAndIsShown;
				if (alreadyShown) {
					return false;
				}
				return true;
			});
		};
		const groupedTestsResults = this.getGroupedTestResults(
			this.props.testResults
		);
		const testResultEntries = groupedTestsResults.map((testResult, index) => {
			const showPercentile = testResult.test.sample_size >= 50;

			return (
				<div
					key={testResult.id}
					className={`content description-section test-result ${
						!testResult.visible ? 'is-disabled' : ''
					}`}>
					<div className="is-pulled-right button-group">
						<Button
							className="is-rounded is-small edit-button"
							icon="trash"
							iconClass="has-text-danger"
							onClick={event => {
								dialogStore.openDialog(
									{
										title: 'Delete test result',
										message:
											'This test result entry will be deleted. Are you sure?',
										confirmLabel: 'Delete',
										confirmButtonClass: 'is-danger'
									},
									() => this.delete(testResult)
								);
							}}
						/>
						<Button
							className="is-rounded is-small edit-button"
							icon="pencil-alt"
							iconClass="has-text-grey"
							onClick={event => {
								modalStore.openModal(
									{ title: 'Edit test result' },
									<CandidateProfileTestResultEdit
										testResult={testResult}
										isNew={false}
										handleSuccess={() => {
											candidateProfileStore.reloadCandidate();
										}}
									/>
								);
							}}
						/>
					</div>
					<div className="is-pulled-right switch">
						<Switch
							className="config-switch"
							id={`testresult${testResult.id}Switch`}
							value={testResult.visible}
							activeLabel="Show"
							inactiveLabel="Hide"
							onValueChange={value => {
								if (value && isTestResultAlreadyShown(testResult)) {
									notificationStore.pushErrorNotification(
										'Show Test Result Failed',
										'A test result with same name is already being shown'
									);
									return false;
								}
								return this.props.onConfigChange(testResult.id, value);
							}}
						/>
					</div>
					<div className="title">{testResult.test.name} </div>
					<div className="test-result-card">
						<div className="test-info-container is-flex">
							<div className="score-container">
								<div className="title is-spaced is-7">Score</div>
								<div className="subtitle is-1">
									<span className="has-medium-weight">
										{Math.round(testResult.score)}/
									</span>
									<span className="has-less-weight">100</span>
								</div>
							</div>
							<div className="percentile-container">
								<div className="title is-spaced is-7">
									Percentile
									<span
										className="tooltip is-tooltip-top"
										data-tooltip="Candidate score relative to average score.">
										<i className="icon fas fa-info-circle" />
									</span>
								</div>
								<span className="subtitle is-1 has-medium-weight">
									{showPercentile && testResult.percentile != null
										? getNumberWithOrdinal(testResult.percentile)
										: 'N/A'}
								</span>
								{showPercentile && testResult.percentile != null ? (
									<div
										className="tooltip is-tooltip-top"
										data-tooltip="Number of candidates that took this test.">
										<div className="subtitle is-4 has-less-weight">
											n=
											{testResult.test.sample_size
												? testResult.test.sample_size
												: 0}
										</div>
									</div>
								) : null}
							</div>
						</div>
						<div className="time-info-container is-flex">
							<div className="date-taken">
								<Icon icon="fa-regular fa-calendar" />
								{moment(testResult.takenAt).format('D MMM YYYY')}
							</div>
							<div className="time-taken">
								<Icon icon="fa-solid fa-stopwatch" />
								{testResult.timeTaken}&apos;
							</div>
						</div>
					</div>
				</div>
			);
		});
		return (
			<Fragment>
				{this.props.testResults.length > 1 ? (
					<Fragment>
						<div className="graph">
							<CandidateProfileTestResultsCanvas
								candidate={this.props.candidate}
								testResults={this.props.testResults}
							/>
						</div>
					</Fragment>
				) : (
					<div className="add-lg-margin-top" />
				)}
				<div className="test-results-container">{testResultEntries}</div>
			</Fragment>
		);
	}

	async delete(item) {
		candidatesStore.deleteTestResult(item.id).then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'Test result Deleted',
					'The test result has been successfully deleted.'
				);
				candidateProfileStore.reloadCandidate();
			} else {
				notificationStore.pushErrorNotification(
					'Delete Failed',
					'Failed to delete the test result'
				);
			}
		});
	}
}
