import { ModalComponent } from '../../../layout/modals/ModalComponent';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../../../layout/form-fields/FormField';
import { DateInput } from '../../../layout/form-fields/inputs/DateInput';
import { candidatesStore } from '../../CandidatesStore';
import { TestsDropdown } from '../../../tests/shared/TestsDropdown';
import { FieldRequiredIndicator } from '../../../layout/form-fields/inputs/FieldRequiredIndicator';
import { shift } from '@floating-ui/dom';

const ValidationSchema = Yup.object().shape({
	score: Yup.number().required().min(0).max(100).label('Score'),
	test: Yup.mixed().required().label('Test'),
	takenAt: Yup.string().label('Taken at').nullable().required(),
	timeTaken: Yup.number().required().min(0).label('Time taken')
});

export class CandidateProfileTestResultEdit extends ModalComponent {
	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				validate={values => {}}
				onSubmit={values => {
					this.saving();
					this.save({
						...values,
						// Conditionally add previous test ID
						// This information is necessary when switching test types, we need to recalculate both
						initialTest: !this.props.isNew
							? this.props.testResult.test
							: values.test
					});
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'CandidateProfileTestResultEdit')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-fullwidth">
						<div className="field has-text-left">
							<label className="subtitle is-7">Test</label>
							<div className="control">
								<TestsDropdown
									value={formikBag.values.test}
									onFocus={() => {
										formikBag.setFieldTouched('testId');
									}}
									onValueChange={item => {
										formikBag.setFieldValue('testId', item.id);
										formikBag.setFieldValue('test', item);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Score"
							field="score"
							type="number"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Taken at <FieldRequiredIndicator />
							</label>
							<div className="control">
								<DateInput
									value={formikBag.values.takenAt}
									popperPlacement="top-start"
									popperModifiers={[
										shift({
											padding: 5,
											crossAxis: true
										})
									]}
									onBlur={() => formikBag.setFieldTouched('takenAt')}
									onChange={newDate => {
										formikBag.setFieldValue('takenAt', newDate);
									}}
								/>
								<p className="help is-danger">{formikBag.errors.takenAt}</p>
							</div>
						</div>
					</div>
					<div className="column is-6">
						<div className="field has-text-left">
							<div className="control">
								<FormField
									required
									className="has-text-left"
									label="Time to Complete (minutes)"
									field="timeTaken"
									type="number"
								/>
								<p className="help is-danger">{formikBag.errors.timeTaken}</p>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	getInitialValues() {
		if (this.props.isNew) {
			return {
				test: null,
				score: '',
				timeTaken: '',
				takenAt: ''
			};
		} else {
			return {
				test: this.props.testResult.test,
				score: this.props.testResult.score,
				timeTaken: this.props.testResult.timeTaken,
				takenAt: new Date(this.props.testResult.takenAt)
			};
		}
	}

	async save(values) {
		if (this.props.isNew) {
			candidatesStore
				.createTestResult(this.props.candidate.id, {
					testResult: values
				})
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Test result',
						'The test result entry has been saved successfully.'
					);
				});
		} else {
			candidatesStore
				.updateTestResult(this.props.testResult.id, {
					testResult: values
				})
				.then(result => {
					this.resultNotification(
						result,
						null,
						null,
						'Test Result',
						'The test result entry has been saved successfully.'
					);
				});
		}
	}
}
