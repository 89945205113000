import React, { Fragment } from 'react';
import { Field } from 'formik';
import ReactCountryFlag from 'react-country-flag';
import Cleave from 'cleave.js/react';
import countryCodes from './country-codes.json';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { CountryFlagDropdown } from './CountryFlagDropdown';

export class PhoneInput extends React.Component {
	state = {
		countries: null,
		currentCountry: null,
		cleave: null,
		value: null
	};

	renderCountryOption = option => {
		return (
			<Fragment>
				<ReactCountryFlag
					countryCode={option.id}
					svg
					style={{
						width: '1.3rem',
						height: '1.3rem'
					}}
					title={option.id}
				/>{' '}
				<span>{option.name} </span>
				<span> {option.dialCode}</span>
			</Fragment>
		);
	};

	renderSelectedOption = option => {
		return (
			<Fragment>
				<ReactCountryFlag
					countryCode={option.id}
					svg
					style={{
						width: '1.8rem',
						height: '1.8rem'
					}}
					title={option.id}
				/>
			</Fragment>
		);
	};

	async componentDidMount() {
		const countriesOption = this.getCountriesOptions();
		const countryDialCode = this.getDialCodeFromCountryOptions(
			this.props.phoneCountryValue,
			countriesOption
		);
		this.setState({
			countries: countriesOption,
			currentCountry: this.props.phoneCountryValue
		});
		if (!this.props.phoneNumberValue) return;
		const phoneNumber = this.getPhoneNumberFromValue(
			this.props.phoneNumberValue,
			countryDialCode
		);
		this.setState({
			currentPhoneNumber: phoneNumber
		});
	}

	initCleaveField = cleave => {
		this.setState({ cleave });
	};

	renderCleaveField = field => (
		<Cleave
			{...field}
			className={'input v2'}
			onChange={this.onChangePhoneInput}
			placeholder={this.props.phoneNumberPlaceholder || 'Phone number'}
			options={{ phone: true, phoneRegionCode: this.state.currentCountry }}
		/>
	);

	render() {
		return (
			<div>
				{this.state.countries ? (
					<Fragment>
						<div
							className={
								'field has-addons phone-input' +
								(this.props.isInvalidPhoneNumber ? ' has-error' : '')
							}>
							<div className="control flag-dropdown">
								<CountryFlagDropdown
									enableFixedMode={false}
									value={this.state.currentCountry}
									styleVersion={'v2'}
									placeholder={
										this.props.phoneNumberCountryPlaceholder || 'Country'
									}
									onValueChange={value => {
										return this.onChangePhoneCountry(value);
									}}
									options={this.state.countries}
									allCountries={this.getCountriesOptions()}
									filterCountries={countries => this.setState({ countries })}
									optionValue={'value'}
									optionValueRenderSelection={'flag'}
									isInvalid={this.props.isInvalidPhoneNumberCountry}
								/>
							</div>
							<Field
								type="text"
								placeholder="Code"
								value={this.getDialCodeFromCountry(this.state.currentCountry)}
								name="countryCode"
								disabled={true}
								className={'input v2 middle'}
							/>
							<Field
								component={this.renderCleaveField}
								value={this.state.currentPhoneNumber}
								type="text"
								name="phoneNumber"
							/>
						</div>
					</Fragment>
				) : null}
			</div>
		);
	}

	onChangePhoneInput = event => {
		const phoneInput = event.target.value.trim();
		const currentCountryDialCode = this.getDialCodeFromCountry(
			this.state.currentCountry
		);
		this.props.handlePhoneInputChange(
			`${currentCountryDialCode} ${phoneInput}`
		);
	};

	onChangePhoneCountry = value => {
		this.setState({
			currentCountry: value
		});
		const currentCountryDialCode = this.getDialCodeFromCountry(value);
		this.props.handlePhoneInputChange(
			`${currentCountryDialCode} ${this.state.currentPhoneNumber}`
		);
		this.props.handlePhoneCountryChange(value);
	};

	getCountries = () => {
		const result = countryCodes.map(country => {
			return {
				id: country.code,
				name: country.name,
				dialCode: country.dial_code
			};
		});
		return result;
	};

	getCountriesOptions = () => {
		return this.getCountries().map(item => {
			return {
				text: this.renderCountryOption(item),
				value: item.id,
				name: item.name,
				flag: this.renderSelectedOption(item),
				dialCode: item.dialCode
			};
		});
	};

	getPhoneNumberFromValue = (phoneNumber, countryDialCode) => {
		const PLUS_CHAR = '+';
		const FIRST_CHAR_PHONENUMBER = 0;
		const itHasCountryCode = PLUS_CHAR === phoneNumber[FIRST_CHAR_PHONENUMBER];
		if (!itHasCountryCode) return phoneNumber;
		const countryCodeLength = countryDialCode.length;
		const phoneNumberWithoutCountryCode = phoneNumber.slice(countryCodeLength);
		return phoneNumberWithoutCountryCode;
	};

	getDialCodeFromCountryOptions(country, countriesOptions) {
		const selectedCountry = countriesOptions.find(
			countryOption => country === countryOption.value
		);
		return selectedCountry ? selectedCountry.dialCode.trim() : '';
	}

	getDialCodeFromCountry(country) {
		const selectedCountry = this.getCountriesOptions().find(
			countryOption => country === countryOption.value
		);
		return selectedCountry ? selectedCountry.dialCode.trim() : '';
	}
}
