import React from 'react';
import { testResultsFilesStore } from '../../../test-results-files/TestResultsFilesStore';
import { notificationStore } from '../../../layout/notifications/NotificationStore';
import { Button } from '../../../layout/button/Button';

export class CandidateProfileTestResultProcess extends React.Component {
	state = {
		loading: false
	};
	handleProcessTestResults = () => {
		this.setState({ loading: true });
		testResultsFilesStore.processTestResultsFromCurrentFile().then(result => {
			if (result.status === 'success') {
				notificationStore.pushSuccessNotification(
					'Test Results',
					'Test Results has been successfully processed.'
				);
			} else {
				notificationStore.pushErrorNotification(
					'',
					'Failed to process test results.'
				);
			}
			this.setState({ loading: false });
			this.props.handleSuccess();
		});
	};
	render() {
		return (
			<Button
				type="button"
				className="button is-text is-blue-text main-action-button"
				onClick={() => {
					this.handleProcessTestResults();
				}}
				label="Process Results"
				loading={this.state.loading}
			/>
		);
	}
}
