import React from 'react';

export class Switch extends React.Component {
	state = {
		checked: this.props.value,
		disabled: false
	};

	enableSwitch = () => {
		this.setState({
			disabled: false
		});
	};

	disableSwitch = () => {
		this.setState({
			disabled: true
		});
	};

	handleOnChange = async () => {
		this.disableSwitch();
		this.setState({
			checked: !this.state.checked
		});
		if (this.props.onValueChange) {
			const valueUpdated = await this.props.onValueChange(!this.props.value);
			if (!valueUpdated) {
				this.setState({
					checked: !this.state.checked
				});
			}
		}
		this.enableSwitch();
	};

	static getDerivedStateFromProps = (props, state) => {
		const stateChanged = props.value !== state.checked;
		const disabledPropsChanged =
			props.disabled !== undefined ? props.disabled !== state.disabled : false;
		const newState = {
			checked: stateChanged ? props.value : state.checked,
			disabled: disabledPropsChanged ? props.disabled : state.disabled
		};
		return newState;
	};

	componentDidMount = () => {
		if (this.props.disabled) this.setState({ disabled: this.props.disabled });
	};

	render() {
		return (
			<div
				style={this.props.style || {}}
				className={`field switch-button ${this.props.className || ''}`}>
				<input
					id={'disable-' + this.props.id}
					checked={this.state.checked}
					disabled={this.state.disabled}
					onChange={this.handleOnChange}
					type="checkbox"
					name="switchRoundedDefault"
					className="switch is-rounded"
				/>
				<label htmlFor={'disable-' + this.props.id} />
				{this.props.activeLabel && this.props.inactiveLabel ? (
					<div className="switch-label has-text-left">
						{this.state.checked
							? this.props.activeLabel
							: this.props.inactiveLabel}
					</div>
				) : null}
			</div>
		);
	}
}
