import { ModalComponent } from '../layout/modals/ModalComponent';
import { Button } from '../layout/button/Button';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import * as Yup from 'yup';
import { FormField } from '../layout/form-fields/FormField';
import { notificationStore } from '../layout/notifications/NotificationStore';
import { testResultsFilesStore } from './TestResultsFilesStore';

const SUPPORTED_FORMATS = [
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'xls',
	'xlsx'
];

const ValidationSchema = Yup.object().shape({
	file: Yup.mixed()
		.required()
		.test(
			'only-xlsx-files',
			'File type not supported. Use .xlsx.',
			value =>
				value &&
				(SUPPORTED_FORMATS.includes(value.type) ||
					SUPPORTED_FORMATS.includes((value.name.split('.') || []).pop()))
		)
});

export class TestResultsFilesImport extends ModalComponent {
	state = {
		processStatus: {},
		fileProcessed: false
	};

	displayFileProcessedStatus = processStatus => {
		const status = !processStatus.readingFileInfo.errorRecordsCount
			? 'success'
			: 'warning';
		return (
			<div className="columns">
				<div className="column is-9 has-text-left">
					<div>File processed status:</div>
					<div>
						<span>File records processed: </span>
					</div>
					<div>
						<span>File records errors: </span>
					</div>
				</div>
				<div className="column is-4 has-text-left">
					<div>
						<span className={`tag is-${status}`}>{status}</span>
					</div>
					<div>
						<span>
							{this.state.processStatus.readingFileInfo.successfulRecordsCount}
						</span>
					</div>
					<div>
						<span>
							{this.state.processStatus.readingFileInfo.errorRecordsCount}
						</span>
					</div>
				</div>
			</div>
		);
	};

	displayTestsProcessedStatus = processStatus => {
		const status = processStatus.errorTests.length > 0 ? 'warning' : 'success';
		return (
			<div className="columns">
				<div className="column is-9 has-text-left">
					<div>Test processed status:</div>
					<div>
						<span>Tests processed: </span>
					</div>
					<div>
						<span>Tests with errors: </span>
					</div>
				</div>
				<div className="column is-4 has-text-left">
					<div>
						<span className={`tag is-${status}`}>{status}</span>
					</div>
					<div>
						<span>{this.state.processStatus.processedTests.length}</span>
					</div>
					<div>
						<span>{this.state.processStatus.errorTests.length}</span>
					</div>
				</div>
			</div>
		);
	};

	displayTestResultsProcessedStatus = processStatus => {
		const testsProcessed = processStatus.processedTests;
		let totalTestResultsErrors = 0;
		let totalTestResultProcessedSuccessfully = 0;
		let previousTotalSampleSize = 0;
		let newTotalSampleSize = 0;
		const testResultErrorsMessages = [];
		testsProcessed.forEach(testResponse => {
			totalTestResultsErrors += testResponse.errorTestResults.length;
			totalTestResultProcessedSuccessfully += testResponse.processedTestResults;
			previousTotalSampleSize += testResponse.previousSampleSize;
			newTotalSampleSize += testResponse.newSampleSize;
			testResultErrorsMessages.concat(testResponse.errorTestResults);
		});
		const status = totalTestResultsErrors > 0 ? 'warning' : 'success';
		return (
			<div className="columns">
				<div className="column is-9 has-text-left">
					<div>Test Results processed status:</div>
					<div>
						<span>Test Results processed: </span>
					</div>
					<div>
						<span>Tests Results with errors: </span>
					</div>
					<div>
						<span>Total test results before processing this file: </span>
					</div>
					<div>
						<span>Total test results processed by this operation: </span>
					</div>
					<div>
						<span>New test results processed: </span>
					</div>
				</div>
				<div className="column is-4 has-text-left">
					<div>
						<span className={`tag is-${status}`}>{status}</span>
					</div>
					<div>
						<span>{totalTestResultProcessedSuccessfully}</span>
					</div>
					<div>
						<span>{totalTestResultsErrors}</span>
					</div>
					<div>
						<span>{previousTotalSampleSize}</span>
					</div>
					<div>
						<span>{newTotalSampleSize}</span>
					</div>
					<div>
						<span>{newTotalSampleSize - previousTotalSampleSize}</span>
					</div>
				</div>
			</div>
		);
	};

	displayFileErrorMessages = processStatus => {
		const fileErrors = processStatus.readingFileInfo.errorsMessages;
		return fileErrors.map((error, index) => {
			return (
				<Fragment key={index}>
					<div className="has-text-danger">Error: {error}</div>
				</Fragment>
			);
		});
	};

	displayTestsErrorMessages = processStatus => {
		const testErrors = processStatus.errorTests;
		return testErrors.map((error, index) => {
			return (
				<Fragment key={index}>
					<div className="has-text-danger">
						* Test id: {error.testId} - Error: {error.message}
					</div>
				</Fragment>
			);
		});
	};

	render() {
		return (
			<Formik
				initialValues={{
					file: undefined
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'TestResultsFilesImport')
				}
			</Formik>
		);
	}

	renderFooter(formikBag) {
		return (
			<footer className="modal-card-foot">
				<div className="fullwidth">
					{this.state.fileProcessed ? (
						<button
							type="button"
							className="button is-primary is-rounded"
							onClick={() => this.closeWithSuccess()}>
							Accept
						</button>
					) : (
						<Fragment>
							<button
								type="button"
								className="button is-rounded is-light"
								onClick={() => this.handleClickCancel()}>
								Cancel
							</button>
							<Button
								disabled={!formikBag.dirty || !formikBag.isValid}
								onClick={() => formikBag.submitForm()}
								type="submit"
								className="button is-primary is-rounded"
								label={this.state.fileProcessed ? 'Accept' : 'Save'}
								loading={this.state.saving}
							/>
						</Fragment>
					)}
				</div>
			</footer>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				{!this.state.fileProcessed ? (
					<Fragment>
						<div className="columns">
							<div className="column is-full">
								<FormField
									className="has-text-left"
									label="File"
									field="file"
									type="file"
									formikBag={formikBag}
								/>
								{!formikBag.errors.file ? null : (
									<p className="help is-danger">{formikBag.errors.file}</p>
								)}
							</div>
						</div>
						<div className="columns">
							<div className="column is-full add-md-padding-top">
								<p className="subtitle is-6 has-text-left">
									Please upload a .xlsx containing Tests data from Skillmetter
								</p>
							</div>
						</div>
					</Fragment>
				) : (
					<Fragment>
						<div>
							{this.displayFileProcessedStatus(this.state.processStatus)}
						</div>
						<div>
							{this.displayTestsProcessedStatus(this.state.processStatus)}
						</div>
						<div>
							{this.displayTestResultsProcessedStatus(this.state.processStatus)}
						</div>
						<div>{this.displayFileErrorMessages(this.state.processStatus)}</div>
						<div>
							{this.displayTestsErrorMessages(this.state.processStatus)}
						</div>
					</Fragment>
				)}
			</Fragment>
		);
	}

	async save(values) {
		testResultsFilesStore
			.importTestsFromFile(values.file)
			.then(async result => {
				if (result.status === 'success') {
					notificationStore.pushSuccessNotification(
						'Test imported',
						'Tets has been successfully imported.'
					);
					await this.setState({
						processStatus: result.data,
						fileProcessed: true
					});
					// this.saving();
					this.setState({ saving: false });
					// this.closeWithSuccess();
				} else {
					notificationStore.pushErrorNotification(
						'',
						'Failed to import the test.'
					);
				}
			});
	}
}
