import React from 'react';
import { Icon } from './Icon';

export default function AnchorIcons({ handleAnchorClick, activeSection }) {
	return (
		<div className="anchor-icons">
			<div className="icons-container">
				<button
					onClick={e => handleAnchorClick(e, 'experience')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'experience' ? 'active' : ''
					}`}
					data-tooltip="Experience">
					<Icon icon="fa-solid fa-briefcase" />
				</button>
				<button
					onClick={e => handleAnchorClick(e, 'technical-tests')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'technical-tests' ? 'active' : ''
					}`}
					data-tooltip="Technical Tests">
					<Icon icon="fa-solid fa-chart-line" />
				</button>
				<button
					onClick={e => handleAnchorClick(e, 'education')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'education' ? 'active' : ''
					}`}
					data-tooltip="Education">
					<Icon icon="fa-solid fa-graduation-cap" />
				</button>
				<button
					onClick={e => handleAnchorClick(e, 'certifications')}
					className={`tooltip is-tooltip-top ${
						activeSection === 'certifications' ? 'active' : ''
					}`}
					data-tooltip="Certifications">
					<Icon icon="fa-solid fa-medal" />
				</button>
			</div>
		</div>
	);
}
