import React from 'react';
import { Loader } from '../misc/Loader';
import { Icon } from '../misc/Icon';

export class Button extends React.Component {
	render() {
		return (
			<button
				title={this.props.title}
				disabled={this.props.disabled || this.props.loading}
				type={this.props.type ? this.props.type : 'button'}
				className={this.props.className + ' button Button'}
				onClick={event => {
					if (this.props.onClick) {
						this.props.onClick(event);
					}
				}}>
				{this.props.label}
				{this.props.icon ? (
					<Icon
						className={`is-small ${this.props.iconClass}`}
						icon={`fa-${this.props.icon}`}
					/>
				) : null}
				{this.props.loading ? <Loader /> : null}
			</button>
		);
	}
}
