import * as Yup from 'yup';
import { ModalComponent } from '../layout/modals/ModalComponent';
import { Formik } from 'formik';
import { Fragment } from 'react';
import { FormField } from '../layout/form-fields/FormField';
import React from 'react';
import { jobOpeningStore } from './JobOpeningStore';
import { clientStore } from '../clients/ClientStore';
import Select from 'react-select';
import { Switch } from '../layout/form-fields/inputs/Switch';
import { FieldRequiredIndicator } from '../layout/form-fields/inputs/FieldRequiredIndicator';

const ValidationSchema = Yup.object().shape({
	title: Yup.string().required().label('Title'),
	atsLink: Yup.string().url().label('ATS Link')
});

export class JobOpeningEdit extends ModalComponent {
	state = {
		clients: []
	};

	componentDidMount() {
		clientStore.getAll().then(result => {
			if (result && result.data) {
				const clients = result.data
					.filter(item => item.disabled === false)
					.map(item => {
						return { value: item.id, label: item.name };
					});
				this.setState({ clients: clients });
			}
		});
	}

	getInitialValues() {
		let selectedClient = {};
		if (this.props.job.client) {
			selectedClient = {
				value: this.props.job.client.id,
				label: this.props.job.client.name
			};
		}
		return {
			title: this.props.job.title,
			atsLink: this.props.job.atsLink || '',
			clientId: this.props.job.clientId,
			disabled: this.props.job.disabled,
			selectedClient: selectedClient
		};
	}

	render() {
		return (
			<Formik
				initialValues={this.getInitialValues()}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag => this.renderModalContainer(formikBag, 'JobOpeningEdit')}
			</Formik>
		);
	}

	renderForm(formikBag) {
		return (
			<Fragment>
				<div className="columns">
					<div className="column is-full">
						<FormField
							required
							className="has-text-left"
							label="Title"
							field="title"
							type="text"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-full">
						<FormField
							className="has-text-left"
							label="ATS Link"
							field="atsLink"
							type="text"
							placeholder="ATS Link"
						/>
					</div>
				</div>
				<div className="columns">
					<div className="column is-10">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								Client <FieldRequiredIndicator />
							</label>
							<div className="control">
								<Select
									field="clientId"
									value={formikBag.values.selectedClient}
									placeholder="Client"
									onChange={item => {
										formikBag.setFieldValue('selectedClient', item);
										formikBag.setFieldValue('clientId', item.value);
									}}
									options={this.state.clients}
									className="react-select"
									menuPosition="fixed"
								/>
							</div>
						</div>
					</div>
					<div className="column is-2">
						<div className="field has-text-left">
							<label className="subtitle is-7">
								{!formikBag.values.disabled ? 'Enabled' : 'Disabled'}
							</label>
							<div className="control">
								<Switch
									id="disabled-switch"
									value={!formikBag.values.disabled}
									onValueChange={value => {
										formikBag.setFieldValue('disabled', !value);
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}

	async save(values) {
		jobOpeningStore.update(this.props.job.id, { job: values }).then(result => {
			this.resultNotification(result, 'Job Opening', 'Edit');
		});
	}
}
