import React from 'react';

export const Tooltip = ({
	disabled = false,
	className = '',
	position = 'top',
	message = '',
	WrapperProps = {},
	children
}) => {
	const TooltipProps = {
		className
	};

	if (!disabled) {
		// Apply css classes for tooltip rendering
		TooltipProps.className += !disabled ? ' tooltip ' : '';
		TooltipProps.className += !position ? ' is-tooltip-' + position : '';
		// Apply Message
		TooltipProps['data-tooltip'] = message;
	}

	return (
		<span {...TooltipProps} {...WrapperProps}>
			{children}
		</span>
	);
};
