import React from 'react';

/**
 * Simple Footer for public pages such as Un-authenticated homepage and Signup
 * @return {JSX.Element}
 * @constructor
 */
export const PublicFooter = () => {
	return (
		<footer className="public-footer">
			<div className="footer-container">
				<div className="footer-section--social">
					<div className="container">
						<img className="logo" alt="Prediktive" src="/logo-white.svg" />
						<div className="socials-wrapper">
							<div className="socials">
								<a
									href="https://www.linkedin.com/company/prediktive/"
									target="_blanck"
									className="link">
									<i className="fab fa-linkedin" />
								</a>
								<a
									href="https://www.instagram.com/prediktive/"
									target="_blanck"
									className="link">
									<i className="fab fa-instagram" />
								</a>
							</div>
							<div className="contact-info">
								<a
									href="maioto:hello@prediktive.com"
									target="_blanck"
									className="link">
									<i className="fas fa-envelope" />
									<span>hello@prediktive.com</span>
								</a>
								<a href="tel:+1-650-285-2374" target="_blanck" className="link">
									<i className="fas fa-phone-volume" />
									<span>+1-650-285-2374</span>
								</a>
							</div>
						</div>
						<div className="copyright">
							© {new Date().getFullYear()} Prediktive. All Rights Reserved
						</div>
						<div className="footer-section--terms">
							<a
								href="https://prediktive.com/terms"
								target="_blank"
								className="link"
								rel="noopener noreferrer">
								Terms of Service
							</a>
							<a
								href="https://prediktive.com/privacy"
								target="_blank"
								className="link"
								rel="noopener noreferrer">
								Privacy Policy
							</a>
							<a
								href="https://prediktive.com/cookie_policy"
								target="_blank"
								className="link"
								rel="noopener noreferrer">
								Cookie Policy
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
