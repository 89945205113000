import React, { Fragment } from 'react';
import { Dropdown } from './Dropdown';
import { FieldRequiredIndicator } from './FieldRequiredIndicator';

export class ConditionalDropdown extends React.Component {
	state = {
		loaded: false,
		showDropdown: null,
		value: null
	};
	componentDidMount() {
		if (this.props.value !== null) {
			this.setState({ showDropdown: true, value: this.props.value });
		}
		if (this.props.value === this.props.noSelectDefaultValue) {
			this.setState({ showDropdown: false });
		}
		this.setState({ loaded: true });
	}

	render() {
		return (
			<Fragment>
				<div className="conditional-dropdown">
					<div className="columns">
						<div className="column is-fullwidth flex-top">
							<label className="title is-5 has-text-weight-bold is-pulled-left add-margin-right">
								{this.props.label}{' '}
								{this.props.required ? <FieldRequiredIndicator /> : null}
								<br />
								<label className="title is-6">
									{this.props.extraInfoLabel}
								</label>
							</label>
							<div className="container-radio-right">
								<label className="radio has-text-centered radio-right">
									<input
										type="radio"
										name={`${this.props.entityName}-answer`}
										value={'no'}
										onChange={() => {
											let newState = {};
											newState['value'] = this.props.noSelectDefaultValue;
											this.props.handleChange(this.props.noSelectDefaultValue);
											this.setState({ showDropdown: false, ...newState });
										}}
										defaultChecked={
											this.props.value === this.props.noSelectDefaultValue
										}
									/>
									<br />
									<label>No</label>
								</label>
								<label className="radio has-text-centered radio-right">
									<input
										type="radio"
										name={`${this.props.entityName}-answer`}
										value={'yes'}
										onChange={() => {
											let newState = {};
											if (!this.props.defaultValue) {
												newState['value'] = null;
												this.props.handleChange(null);
												this.setState({ showDropdown: true, ...newState });
												return;
											}
											newState['value'] = this.props.defaultValue.value;
											this.props.handleChange(this.props.defaultValue.value);
											this.setState({ showDropdown: true, ...newState });
										}}
										defaultChecked={
											this.props.value !== 0 && this.props.value !== null
										}
									/>
									<br />
									<label>Yes</label>
								</label>
							</div>
						</div>
					</div>
					{this.state.showDropdown ? (
						<div className="columns dropdown-section">
							<div className="column is-fullwidth">
								<div className="columns">
									<div className="column is-3 dropdown-label">
										<label className="title is-5 has-text-weight-bold add-margin-right">
											{this.props.dropdownLabel}
										</label>
									</div>
									<div className="column is-9">
										<div className=" has-text-left">
											<div className="control">
												<Dropdown
													enableFixedMode={true}
													value={this.state.value}
													styleVersion={`${this.props.styleVersion} conditional`}
													onValueChange={value => {
														return this.props.handleChange(value);
													}}
													options={this.props.options}
													optionValue={this.props.optionValue}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : null}
				</div>
			</Fragment>
		);
	}
}
