import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ModalComponent } from '../../layout/modals/ModalComponent';
import { FormField, FormFieldTypes } from '../../layout/form-fields/FormField';
import { scorecardTemplateStore } from '../ScorecardTemplateStore';

const defaultValues = {
	name: '',
	weight: 0
};

const ValidationSchema = Yup.object().shape({
	name: Yup.string().required().label('Name'),
	weight: Yup.number().required().positive().integer().label('Weight'),
	description: Yup.string().nullable().label('Description')
});

export class ScorecardTemplateItemForm extends ModalComponent {
	get isEdit() {
		return this.props.data !== undefined;
	}

	render() {
		return (
			<Formik
				initialValues={{
					...defaultValues,
					...this.props.data
				}}
				validationSchema={ValidationSchema}
				onSubmit={values => {
					this.saving();
					this.save(values);
				}}>
				{formikBag =>
					this.renderModalContainer(formikBag, 'ScorecardTemplateItemForm')
				}
			</Formik>
		);
	}

	renderForm(formikBag) {
		const { disabled } = this.props;
		return (
			<div className="columns">
				<div className="column is-full">
					<FormField
						autoFocus={true}
						key="name"
						required
						className="has-text-left"
						label="Name"
						field="name"
						type={FormFieldTypes.TEXT}
						placeholder=""
						value={formikBag.name}
						maxlength={255}
					/>
					<FormField
						key="weight"
						required
						className="has-text-left"
						label="Weight"
						field="weight"
						type={FormFieldTypes.NUMBER}
						placeholder="Weight Percentage %"
						value={formikBag.weight}
						disabled={disabled}
					/>
					<FormField
						key="description"
						className="has-text-left"
						label="Description"
						field="description"
						type={FormFieldTypes.TEXTAREA}
						placeholder=""
						value={formikBag.description}
						maxlength={255}
					/>
				</div>
			</div>
		);
	}

	async save(values) {
		const { templateId, sectionId, data } = this.props;

		if (this.isEdit) {
			return scorecardTemplateStore
				.updateItem(templateId, sectionId, data.id, values)
				.then(result => {
					this.closeWithSuccess(result);
				});
		} else {
			return scorecardTemplateStore
				.createItem(templateId, sectionId, values)
				.then(result => {
					this.closeWithSuccess(result);
				});
		}
	}
}
