import React from 'react';
import Async from 'react-select/async';
import { countryStore } from '../../../settings/countries/CountryStore';

export class CountryDropdown extends React.Component {
	state = {
		value: null,
		loaded: false
	};

	async componentDidMount() {
		if (this.props.filterByNames) {
			const countryNames = this.props.filterByNames;
			const filteredCountries = await Promise.all(
				countryNames.map(async countryName =>
					(await this.getCountries(countryName)).find(
						country => country.name === countryName
					)
				)
			);
			this.setState({
				value: filteredCountries
			});
			this.setState({ loaded: true });
			return;
		}

		if (this.props.value) {
			this.setState({ value: this.props.value });
		}
		this.setState({ loaded: true });
	}

	render() {
		if (this.state.loaded) {
			const { placeholder = 'Country' } = this.props;

			return (
				<Async
					className={
						'react-select ' +
						(this.props.styleVersion ? this.props.styleVersion : '') +
						(this.props.isInvalid ? ' has-error' : '')
					}
					cacheOptions
					value={this.state.value}
					placeholder={placeholder}
					defaultOptions
					loadOptions={this.loadOptions}
					getOptionValue={this.getOptionValue}
					getOptionLabel={this.getOptionLabel}
					noOptionsMessage={this.noOptionsMessage}
					onChange={item => {
						if (this.props.onValueChange) {
							this.props.onValueChange(item);
						}
						this.setState({ value: item });
					}}
					{...this.props.AsyncProps}
				/>
			);
		} else {
			return null;
		}
	}

	loadOptions = inputValue => {
		return new Promise(resolve => {
			resolve(this.getCountries(inputValue));
		});
	};

	getCountries = async inputValue => {
		inputValue = inputValue.trim();
		const result = await countryStore.getCountries(inputValue);
		return result.data;
	};

	noOptionsMessage = value => {
		if (value.inputValue && value.inputValue.length >= 2) {
			return 'No matches found';
		} else {
			return 'Enter text to search countries';
		}
	};

	getOptionValue = option => option.id;

	getOptionLabel = option => `${option.name} - ${option.id}`;
}
