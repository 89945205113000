import { ProfileImage } from '../../../components/layout/misc/ProfileImage';
import { Icon } from '../../../components/layout/misc/Icon';
import React from 'react';
import { modalStore } from '../../../components/layout/modals/ModalStore';
import { authStore } from '../../../shared/security/AuthStore';
import { CandidateProfileImageEdit } from './CandidateProfileImageEdit';
import { candidateProfileStore } from '../CandidateProfileStore';
import { observer } from 'mobx-react';

@observer
export class CandidateProfileImage extends React.Component {
	render() {
		const isLocked = candidateProfileStore.isLocked;

		return (
			<div
				className={
					'profile-pic-wrapper' + (!isLocked ? ' has-pointer is-edit' : '')
				}
				onClick={() => {
					if (!isLocked) {
						modalStore.openModal(
							{ title: 'Upload Profile Picture', position: 'center' },
							<CandidateProfileImageEdit
								candidate={this.props.candidate}
								handleSuccess={() => {
									authStore.syncLoggedUserProfile();
									candidateProfileStore.reloadCandidate();
								}}
							/>
						);
					}
				}}>
				<ProfileImage
					className="profile-picture"
					src={
						this.props.candidate.imageUrl ? this.props.candidate.imageUrl : null
					}
				/>
				{isLocked ? null : <Icon icon="fa-pencil-alt" />}
			</div>
		);
	}
}
