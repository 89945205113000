import React from 'react';
import { Field } from 'formik';
import { FormFieldError } from './FormFieldError';
import { Icon } from '../misc/Icon';
import { FileInput } from './inputs/FileInput';
import { FieldRequiredIndicator } from './inputs/FieldRequiredIndicator';

export const FormFieldTypes = {
	TEXT: 'text',
	TEXTAREA: 'textarea',
	NUMBER: 'number',
	EMAIL: 'email',
	SEARCH: 'search',
	FILE: 'file'
};

export class FormField extends React.Component {
	render() {
		if (
			this.props.type === 'number' ||
			this.props.type === 'text' ||
			this.props.type === 'email' ||
			this.props.type === 'search' ||
			this.props.type === 'textarea' ||
			this.props.type === 'file'
		) {
			return (
				<div
					className={
						'field ' +
						this.props.className +
						(this.props.isInvalid ? ' has-error' : '')
					}>
					<label
						className={
							this.props.styleVersion === 'v2'
								? 'title is-5 has-text-weight-bold'
								: 'subtitle is-7'
						}>
						{this.props.label}{' '}
						{this.props.required ? <FieldRequiredIndicator /> : null}
					</label>
					<p className={'control ' + (this.props.icon ? 'has-icons-left' : '')}>
						{this.renderField()}
						{this.iconDisplay()}
					</p>
					<FormFieldError field={this.props.field} />
				</div>
			);
		}
		return <div>Field type not supported</div>;
	}

	iconDisplay() {
		if (this.props.icon) {
			return <Icon className={'is-small is-left'} icon={this.props.icon} />;
		}
		return null;
	}

	renderField() {
		if (
			this.props.type === 'number' ||
			this.props.type === 'text' ||
			this.props.type === 'email' ||
			this.props.type === 'search'
		) {
			return (
				<Field
					autoFocus={this.props.autoFocus}
					disabled={this.props.disabled}
					name={this.props.field}
					className={
						'input ' + (this.props.styleVersion ? this.props.styleVersion : '')
					}
					placeholder={
						this.props.label || this.props.placeholder || this.props.field
					}
					type={this.props.type ? this.props.type : 'text'}
					maxlength={
						this.props.type === 'text' && this.props.maxlength
							? this.props.maxlength
							: undefined
					}
					{...(this.props.onChange
						? { onChange: this.props.onChange }
						: undefined)}
				/>
			);
		} else if (this.props.type === 'textarea') {
			return (
				<Field
					autoFocus={this.props.autoFocus}
					component={this.props.type}
					disabled={this.props.disabled}
					name={this.props.field}
					className="input"
					placeholder={
						this.props.placeholder || this.props.label || this.props.field
					}
					rows={4}
					maxlength={this.props.maxlength ? this.props.maxlength : 500}
				/>
			);
		} else if (this.props.type === 'file') {
			return (
				<Field
					component={FileInput}
					styleVersion={this.props.styleVersion}
					setFieldValue={this.props.formikBag.setFieldValue}
					disabled={this.props.disabled}
					{...(this.props.acceptedFormats
						? { accept: this.props.acceptedFormats }
						: {})}
					name={this.props.field}
				/>
			);
		}
	}
}
