import React from 'react';
import { rolesAllowed, USER_ROLES } from '../../shared/security/RolesAllowed';
import CandidatesByScore from './components/CandidatesByScore';

let Dashboard = () => {
	return (
		<div className="Dashboard container">
			<div className="level ListHeader">
				<div className="level-left">
					<h1 className="title">Dashboard</h1>
				</div>
			</div>

			<div className="columns is-flex center-align is-justify-content-center">
				<CandidatesByScore />
			</div>
		</div>
	);
};

Dashboard = rolesAllowed([USER_ROLES.ADMIN, USER_ROLES.MANAGER])(Dashboard);

export { Dashboard };
